import React from "react";

export const PerfilConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/perfil",
      component: React.lazy(() => import("./Perfil")),
    },
  ],
};
