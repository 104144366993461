export const CARGA_CONTADORES_METRICAS_SOLICITADA =
  "[DASHBOARD.METRICAS] SOLICITA CARGA DE LOS CONTADORES";
export function solicitarCargaContadores() {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONTADORES_METRICAS_SOLICITADA,
    });
}

export const CARGA_CONTADORES_EXITOSA =
  "[DASHBOARD.METRICAS] SE HAN CARGADO LOS CONTADORES CON ÉXITO";
export function cargaContadoresExitosa(
  contadorTotalCompras,
  contadorCompras,
  contadorEventos,
  contadorEmpresas
) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONTADORES_EXITOSA,
      payload: {
        contadorTotalCompras,
        contadorCompras,
        contadorEventos,
        contadorEmpresas,
      },
    });
}

export const CARGA_CONTADORES_ERRONEA =
  "[DASHBOARD.METRICAS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS CONTADORES";
export function cargaContadoresErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONTADORES_ERRONEA,
      payload: error,
    });
}

export const CARGA_CONTADORES_FINALIZADA =
  "[DASHBOARD.METRICAS] LA CARGA DE LOS CONTADORES HA FINALIZADO";
export function cargaContadoresFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONTADORES_FINALIZADA,
    });
}
