import axios from "axios";
import Global from "../../fuse-configs/Global";
import {
  RegistroInscripciones,
  DatosRedsysParaTPV,
} from "app/components/iframe/model";

const LAMBDA = `${Global.url}/iframe`;

/**
 * Obtiene los datos de un evento si este sigue activo
 * @param {Id del evento cuyos datos se quieren obtener} idEvento
 */
export const obtenerEventoActivo = (idEvento) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}`;
  return axios.get(requestURL);
};

export const obtenerMetodosPagoEvento = (idEvento) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/metodosPago`;
  return axios.get(requestURL);
};

export const obtenerEmpresaOrganizadoraEvento = (idEvento) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/organizador`;
  return axios.get(requestURL);
};

/**
 * Devuelve los datos de los productos de un evento determinado
 * (inscripciones, complementos) ...
 * @param {Id del evento cuyos productos se quieren obtener} idEvento
 */
export const obtenerProductosEvento = (idEvento) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/productos`;
  return axios.get(requestURL);
};

export const obtenerDatosPagoDeCompra = (idCompra) => {
  const requestURL = `${LAMBDA}/compra/${idCompra}/datosPago`;
  return axios.get(requestURL);
};

/**
 * Realiza el registro de las inscripciones (compra, inscripciones, complementos, ...)
 * @param {Estructura de datos con los datos para realizar el registro} reg
 */
export const registrarInscripciones = (reg = new RegistroInscripciones()) => {
  const { idEvento } = reg;
  const requestURL = `${LAMBDA}/evento/${idEvento}/registrar/inscripciones`;
  return axios.post(requestURL, reg);
};

/**
 * Devuelve los datos codificados para Redsys.
 * @param {Id del evento} idEvento
 * @param {Datos de redsys sin codificar} datosSinCodificar
 * @param {Tema a añadir a la url que se le da a Redsys} theme
 */
export const obtenerDatosCodificadosTPVRedsys = (
  idEvento,
  datosSinCodificar = new DatosRedsysParaTPV(),
  theme
) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/tpv/redsys/parametros`;
  return axios.post(requestURL, { ...datosSinCodificar, theme });
};

/**
 * Actualiza la configuración del iframe de un evento.
 * @param {Id del evento} idEvento
 * @param {Campos a actualizar con sus correspondientes nuevos valores} camposAActualizarYValores
 */
export const actualizarConfigIframe = (idEvento, camposAActualizarYValores) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/config`;
  return axios.put(requestURL, camposAActualizarYValores);
};

/**
 * Obtiene la configuracion del iframe de un evento
 * @param {Id del evento} idEvento
 */
export const obtenerConfiguracionIframe = (idEvento) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/config`;
  return axios.get(requestURL);
};

/**
 * Obtiene el estado de un inscrito privado
 * @param {Id del evento} idEvento
 * @param {CIF del supuesto inscrito} cif
 */
export const obtenerInscritoPrivado = (idEvento, cif) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/inscritoPrivado/${cif}`;
  return axios.get(requestURL);
};

/**
 * Obtiene los inscritos privados de un evento dado su id.
 * @param {Id del evento cuyos inscritos privados se quieren obtener} idEvento
 */
export const obtenerInscritosPrivadosPorIdEvento = (idEvento) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/inscritosPrivados`;
  return axios.get(requestURL);
};

/**
 * Guarda una lista de inscritos privados asociados a un evento
 * @param {Id del evento cuyos inscritos privados se van a guardar} idEvento
 * @param {Lista de inscritos privados a guardar} inscritosPrivados
 */
export const guardarListaDeInscritosPrivados = (
  inscritosPrivados,
  idEvento
) => {
  const requestURL = `${LAMBDA}/evento/${idEvento}/inscritosPrivados`;
  return axios.post(requestURL, inscritosPrivados);
};
