import * as Actions from "../actions";

const initialState = {
  widgets: {
    tableData: null,
    chartData: null,
    cargando: true,
    error: null,
  },
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CARGAR_WIDGETS_SUPERREVISOR_SOLICITADO:
      return {
        ...state,
        widgets: {
          ...state.widgets,
          cargando: true,
        },
      };
    case Actions.CARGA_WIDGETS_SUPERREVISOR_EXITOSA:
      const { tableData, chartData } = action.payload;
      return {
        ...state,
        widgets: {
          ...state.widgets,
          tableData,
          chartData,
        },
      };
    case Actions.CARGA_WIDGETS_SUPERREVISOR_ERRONEA:
      return {
        ...state,
        widgets: {
          ...state.widgets,
          error: action.payload,
        },
      };
    case Actions.CARGA_WIDGETS_SUPERREVISOR_FINALIZADA:
      return { ...state, widgets: { ...state.widgets, cargando: false } };
    default:
      return state;
  }
};
