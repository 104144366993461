import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export let addCampoCustom = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/addCampoCustom";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let addCampoCustomCreado = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/addCampoCustomCreado";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let deleteCamposCustom = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/deleteCamposCustom";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let addCampoCustomSelect = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/addCampoCustomSelect";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let sortCamposCustom = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/sortCamposCustom";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let updateCampoCustom = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/updateCampoCustom";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let setCampoComun = (userToken, form) => {
  let requestURL = Global.url + "/camposcustom/setCampoComun";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};
