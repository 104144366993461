import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as iframeService from "app/services/iframeService";
import * as Actions from "app/components/iframe/store/actions";
import * as Model from "app/components/iframe/model";

export default function useFormularioCIF({ idEvento, mostrarError }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [intentos, setIntentos] = useState(0);
  const [comprobandoCif, setComprobandoCif] = useState(false);
  const [cif, setCif] = useState("");

  const comprobarCif = async () => {
    if (cif === "") return;

    setComprobandoCif(true);
    setIntentos((intentos) => intentos + 1);
    try {
      const { data } = await iframeService.obtenerInscritoPrivado(
        idEvento,
        cif
      );
      const { result, status } = data;

      if (!status) throw new Error("Error en el status!");

      const { pagado, inscrito, datos } = result;

      if (pagado) {
        const pathname = "/buy/es/event/payment/private/done";
        history.push({ pathname });
        return;
      }

      if (inscrito) {
        // inyectar en datos de facturación los datos del inscrito privado
        const comprador = {
          ...new Model.Comprador({ correo: datos.email }),
          ...datos,
        };

        dispatch(Actions.actualizarComprador(comprador));

        // ocultar formulario y permitir ver el iframe
        dispatch(Actions.ocultarLoginIframePrivado());
      } else {
        mostrarError();
      }
    } catch (e) {
      console.log("ERROR", e);
    } finally {
      setCif("");
      setComprobandoCif(false);
    }
  };

  return { intentos, cif, setCif, comprobarCif, comprobandoCif };
}
