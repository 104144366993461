import * as Actions from "../actions";

const initialState = {
  data: {
    eventos: null,
  },
};

const emailEditorReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_EMAIL_EDITOR_EVENTS:
      return {
        ...state,
        data: {
          ...state.data,
          eventos: {
            ...state.data.eventos,
            ...action.payload.data.eventos,
          },
        },
      };
    case Actions.RESET_EMAIL_EDITOR_EVENTS:
      return {
        ...state,
        data: {
          ...state.data,
          eventos: null,
        },
      };

    default:
      return state;
  }
};

export default emailEditorReducer;
