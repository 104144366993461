import axios from "axios";
import Global from "../../fuse-configs/Global.js";
import querystring from "querystring";

export const resetPassword = (newPassword, recoverToken) => {
  let url = Global.url + "/resetPassword";
  let message = { password: newPassword, token: recoverToken };
  return axios.put(url, querystring.stringify(message));
};

export const checkIfRecoverTokenIsValid = (recoverToken) => {
  let url = Global.url + "/resetPassword/checkRecoverToken";
  let message = { token: recoverToken };
  return axios.post(url, querystring.stringify(message));
};

export const enviarCorreoRecuperarPassword = (email) => {
  const url = Global.url + "/resetPassword/sendRecoverEmail";
  return axios.post(url, querystring.stringify({ email }));
};
