import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export const getCanalesCobro = (userToken, idEvento) => {
  let requestURL = Global.url + "/canales/" + idEvento;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL, HEADERS);
};

export const crearCanalCobro = (userToken, nuevoCanal) => {
  let requestURL = Global.url + "/canales";
  axios.defaults.headers.common["token"] = userToken;
  console.log("crearCanalCOnbro", nuevoCanal);
  return axios.post(
    requestURL,
    querystring.stringify({ datos: JSON.stringify(nuevoCanal) }),
    HEADERS
  );
};

export const updateCanalCobro = (userToken, idCanal, canalActualizado) => {
  let requestURL = Global.url + "/canales/" + idCanal;
  axios.defaults.headers.common["token"] = userToken;
  return axios.put(
    requestURL,
    querystring.stringify({ datos: canalActualizado }),
    HEADERS
  );
};

export const eliminarCanalCobro = (userToken, idCanal) => {
  let requestURL = Global.url + "/canales/" + idCanal;
  axios.defaults.headers.common["token"] = userToken;
  return axios.delete(requestURL, HEADERS);
};
