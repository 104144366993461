import { authRoles } from "app/components/auth";

const navigationConfig = [
  {
    id: "menu",
    title: "Menu",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "dashboard",
        title: "Home",
        type: "item",
        icon: "home",
        url: "/apps/dashboard",
      },
      {
        id: "eventos",
        title: "Eventos",
        type: "item",
        icon: "event",
        url: "/eventos",
        auth: ["superadmin", "admin", "admin_empresa", "user_empresa"],
      },
      {
        id: "empresas",
        title: "Empresas",
        type: "item",
        icon: "business_center",
        url: "/empresas",
        auth: authRoles.admin,
      },
      {
        id: "usuarios",
        title: "Usuarios",
        type: "item",
        icon: "group",
        url: "/usuarios",
        auth: ["superadmin", "admin", "admin_empresa"],
      },
      //REVISORES ABSTRACTS
      ////////////////////////////////////////
      {
        id: "instrucciones",
        title: "Instrucciones Calificación",
        type: "item",
        icon: "info",
        url: "/instrucciones_calificacion",
        auth: ["super_revisor", "revisor"],
      },
      {
        id: "comunicaciones",
        title: "Comunicaciones",
        type: "item",
        icon: "assignment",
        url: "/comunicaciones",
        auth: ["super_revisor", "revisor"],
      },
      {
        id: "usuarios_com",
        title: "Usuarios",
        type: "item",
        icon: "group",
        url: "/usuariosComunicaciones",
        auth: ["super_revisor"],
      },
      {
        id: "notificaciones",
        title: "Email/Notificaciones",
        type: "item",
        icon: "email",
        url: "/notificaciones",
        auth: ["super_revisor"],
      },
      ///////////////////////////////////////////////////
      {
        id: "emaileditor",
        title: "Email Tpl Editor",
        type: "item",
        icon: "group",
        url: "/maileditor",
        auth: ["superadmin", "admin", "admin_empresa", "user_empresa"],
      },
    ],
  },
];

export default navigationConfig;
