import { spawn } from "redux-saga/effects";

import { masterSaga as iframeSaga } from "app/components/iframe/store/sagas";
import { masterSaga as dashboardSaga } from "app/components/dashboard/store/sagas";
import { masterSaga as authSaga } from "app/components/auth/store/sagas";
import { masterSaga as configuracionSaga } from "app/main/apps/eventos/components/configuracion/iframe/store/sagas";
import { masterSaga as abstractsSaga } from "app/components/abstracts/store/sagas";

export function* rootSaga() {
  yield spawn(iframeSaga);
  yield spawn(dashboardSaga);
  yield spawn(authSaga);
  yield spawn(configuracionSaga);
  yield spawn(abstractsSaga);
}
