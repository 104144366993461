import * as Actions from "../actions";

const initialState = {
  datosCargados: false,
};

const contersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CAMBIAR_ESTADO_DATOS_CARGADOS:
      return {
        ...state,
        datosCargados: action.payload,
      };
    default:
      return state;
  }
};

export default contersReducer;
