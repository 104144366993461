import { takeEvery, call, put } from "redux-saga/effects";

import {
  // EVENTOS MAS ACTIVOS
  SOLICITAR_CARGA_EVENTOS_MAS_ACTIVOS,
  cargaEventosMasActivosExitosa,
  cargaEventosMasActivosErronea,
  cargaEventosMasActivosFinalizada,

  // ULTIMOS EVENTOS
  SOLICITAR_CARGA_ULTIMOS_EVENTOS,
  cargaUltimosEventosExitosa,
  cargaUltimosEventosErronea,
  cargaUltimosEventosFinalizada,

  // EVENTOS EMPRESA
  SOLICITAR_CARGA_TODOS_LOS_EVENTOS,
  cargaTodosLosEventosExitosa,
  cargaTodosLosEventosErronea,
  cargaTodosEventosFinalizada,
} from "../actions";

import {
  obtenerEventosMasActivos,
  obtenerTodosLosEventos,
  obtenerUltimosEventos,
} from "app/services/eventosService";

export function* observador() {
  yield takeEvery(SOLICITAR_CARGA_EVENTOS_MAS_ACTIVOS, cargaEventosMasActivos);
  yield takeEvery(SOLICITAR_CARGA_ULTIMOS_EVENTOS, cargaUltimosEventos);
  yield takeEvery(SOLICITAR_CARGA_TODOS_LOS_EVENTOS, cargaTodosLosEventos);
}

function* cargaEventosMasActivos() {
  try {
    const { data } = yield call(obtenerEventosMasActivos);
    const { result: eventos } = data;
    yield put(cargaEventosMasActivosExitosa(eventos));
  } catch (e) {
    yield put(cargaEventosMasActivosErronea(e));
  } finally {
    yield put(cargaEventosMasActivosFinalizada());
  }
}

function* cargaUltimosEventos() {
  try {
    const { data } = yield call(obtenerUltimosEventos);
    const { result: eventos } = data;
    yield put(cargaUltimosEventosExitosa(eventos));
  } catch (e) {
    yield put(cargaUltimosEventosErronea(e));
  } finally {
    yield put(cargaUltimosEventosFinalizada());
  }
}

function* cargaTodosLosEventos() {
  try {
    const { data } = yield call(obtenerTodosLosEventos);
    const { result: eventos } = data;
    yield put(cargaTodosLosEventosExitosa(eventos));
  } catch (e) {
    yield put(cargaTodosLosEventosErronea(e));
  } finally {
    yield put(cargaTodosEventosFinalizada());
  }
}
