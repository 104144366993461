import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import styles from "./PaymentAfter.styles";
import { withStyler } from "../../../wrappers";

const useStyles = makeStyles(styles);

const PaymentAfter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  let message = t(
    "Su petición ha sido realizada correctamente. Le hemos enviado un correo electrónico para poder finalizar su inscripción (en caso de no encontrarlo revise su bandeja de SPAM). Recuerde finalizar el proceso en la mayor brevedad posible dado que el aforo es limitado."
  );

  return (
    <Paper
      className={classes.container}
      style={{ backgroundColor: props.styles.backgroundColor }}
    >
      <Grid container justify="center">
        <Grid item xs={12}>
          <CheckCircleIcon
            style={{
              color: green[500],
              fontSize: 100,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: 20 }}> {message} </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyler(PaymentAfter);
