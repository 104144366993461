import SuperAdminView from "./SuperAdminView";
import AdminView from "./AdminView";
import UserEmpresaView from "./UserEmpresaView";
import AdminEmpresaView from "./AdminEmpresaView";
import ProtectedView from "./ProtectedView";

export {
  SuperAdminView,
  AdminEmpresaView,
  AdminView,
  UserEmpresaView,
  ProtectedView,
};
