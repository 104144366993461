// import React, { useEffect, useState } from "react";
// import {
//   TextField,
//   Button,
//   Typography,
//   Grid,
//   Divider,
//   Paper,
//   Icon,
// } from "@material-ui/core";
// import { useDispatch } from "react-redux";
// import * as Actions from "../store/actions";

// //alerts

// import { toast, ToastContainer, Slide } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// //Services
// import {
//   LoginAutorAbstract,
//   SigninAutorAbstract,
// } from "../../../services/eventosService/abstractUploadService";

// const VistaLogin = (props) => {
//   const [dniLogin, setDniLogin] = useState(false);
//   const [nombre, setNombre] = useState("");
//   const [dni, setDni] = useState("");
//   const [email, setEmail] = useState("");
//   const dispatch = useDispatch();

//   const setSnackbar = (message, type) => {
//     if (type == "success") {
//       toast.success("✔️ " + message, {
//         position: "top-right",
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         containerId: "asociar_eventos",
//       });
//     }
//     if (type == "error") {
//       toast.error("❌ " + message, {
//         position: "top-right",
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         containerId: "asociar_eventos",
//       });
//     }
//   };

//   const handleLogin = () => {
//     if (dniLogin.length >= 9) {
//       let form = {
//         dni: dniLogin.toUpperCase(),
//         id_evento: props.id_evento,
//       };
//       LoginAutorAbstract(form).then((res) => {
//         if (!res.data.status) {
//           setSnackbar(
//             "El dni introducido no corresponde a ningún autor registrado.",
//             "error"
//           );
//         } else {
//           console.log(res.data);
//           dispatch(Actions.setIdAutor(res.data.result[0].ID));
//           props.handleNext();
//         }
//       });
//     } else {
//       setSnackbar("El dni introducido no es valido", "error");
//     }
//   };

//   const handleSignin = (e) => {
//     e.preventDefault();
//     if (dni.length >= 9) {
//       let form = {
//         id_evento: props.id_evento,
//         dni: dni.toUpperCase(),
//         nombre: nombre,
//         email: email,
//       };
//       SigninAutorAbstract(form).then((res) => {
//         if (!res.data.status) {
//           setSnackbar(res.data.message, "error");
//         } else {
//           console.log(res.data);
//           setSnackbar("Registro realizado con éxito", "success");
//           dispatch(Actions.setIdAutor(res.data.result[0].ID));
//           props.handleNext();
//         }
//       });
//     } else {
//       setSnackbar("El dni introducido no es valido", "error");
//     }
//   };

//   return (
//     <div className="m-10">
//       <ToastContainer />
//       <Grid container className="flex items-center justify-center text-center">
//         <Grid item xs={5}>
//           <Paper className="p-10 m-5 min-h-sm flex items-center justify-center">
//             <form onSubmit={handleSignin}>
//               <Icon fontSize="large">person_add</Icon>
//               <Typography variant="h6">Registro</Typography>
//               <Typography className="mt-5" variant="h7">
//                 DNI o Pasaporte del autor principal:
//               </Typography>
//               <TextField
//                 className="mt-5"
//                 label="Nombre y Apellidos"
//                 variant="outlined"
//                 required
//                 onChange={(e) => setNombre(e.target.value)}
//                 fullWidth
//               />
//               <TextField
//                 className="mt-5"
//                 label="DNI/Pasaporte"
//                 variant="outlined"
//                 required
//                 onChange={(e) => setDni(e.target.value)}
//                 fullWidth
//               />
//               <TextField
//                 className="mt-5"
//                 label="Email"
//                 variant="outlined"
//                 type="email"
//                 required
//                 onChange={(e) => setEmail(e.target.value)}
//                 fullWidth
//               />
//               <Button
//                 className="mt-10"
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//               >
//                 Registrarse
//               </Button>
//             </form>
//           </Paper>
//         </Grid>
//         <Grid item xs={5}>
//           <Paper className="p-10 m-5 min-h-sm flex items-center justify-center">
//             <div>
//               <Icon fontSize="large">person_outline</Icon>
//               <Typography variant="h6">Login</Typography>
//               <Typography className="mt-5" variant="h7">
//                 DNI o Pasaporte del autor principal:
//               </Typography>
//               <TextField
//                 className="mt-5"
//                 id="outlined-basic"
//                 label="DNI/Pasaporte"
//                 variant="outlined"
//                 onChange={(e) => setDniLogin(e.target.value)}
//                 fullWidth
//               />
//               <Button
//                 className="mt-10"
//                 variant="contained"
//                 color="primary"
//                 onClick={handleLogin}
//               >
//                 Acceder
//               </Button>
//             </div>
//           </Paper>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default VistaLogin;

import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

//COMPONENTS
import LoginBox from "./components/LoginBox";
import RegisterBox from "./components/RegisterBox";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

export default function VistaLogin(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <ToastContainer enableMultiContainer containerId="abstract_login" />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Iniciar Sesión" {...a11yProps(0)} />
          <Tab label="Registrarse" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <LoginBox {...props} handleChangeIndex={handleChangeIndex} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <RegisterBox {...props} handleChangeIndex={handleChangeIndex} />
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel> */}
      </SwipeableViews>
    </div>
  );
}
