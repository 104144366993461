import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProtectedView } from "app/shared/views/auth";

//ACTIONS
import { solicitaCargaAbstractsEvento } from "./store/actions";

//COMPONENTS
import TablaAbstracts from "./components/tablaAbstracts";
import TablaAbstractsRevisor from "./components/tablaAbstractsRevisor";
import MenuOpcionesTabla from "./components/menuOpcionesTabla";
import { toast, ToastContainer, Slide } from "react-toastify";

const Abstracts = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ authX }) => authX.user.data);
  const { role } = useSelector(({ authX }) => authX.user);

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "main_abstracts_list",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "main_abstracts_list",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "main_abstracts_list",
      });
    }
  };

  useEffect(() => {
    dispatch(solicitaCargaAbstractsEvento(user.token));
  }, [dispatch]);
  return (
    <ProtectedView>
      <div className="w-full h-full p-5 overflow-scroll">
        <ToastContainer
          enableMultiContainer
          containerId="main_abstracts_list"
          transition={Slide}
        />
        <div>
          {/* SUPER REVISOR */}
          {role === 5 && (
            <div>
              <MenuOpcionesTabla setSnackbar={setSnackbar} />
              <TablaAbstracts />
            </div>
          )}

          {role === 6 && <TablaAbstractsRevisor />}
        </div>
      </div>
    </ProtectedView>
  );
};

export default Abstracts;
