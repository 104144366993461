import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { pantallaDeCargaAparecePorSegundaVez } from "../store/actions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100%",
  },
  loading: {
    textAlign: "center",
  },
  logo: {
    display: "block",
    margin: "0 auto",
  },
  loadingMessage: {
    marginTop: "10px",
    marginBottom: "20px",
  },
});

const Loading = ({ icon, message, lang, backgroundColor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [showIcon, setShowIcon] = useState(false);

  const mensajeCarga = useSelector(
    ({ iframe }) => iframe.iframeConfig.mensajeCarga
  );

  const cargadoPorPrimeraVez = useSelector(
    ({ iframe }) => iframe.iframeConfig.cargandoPorPrimeraVez
  );

  useEffect(() => {
    if (icon) setShowIcon(true);

    compruebaIdioma();

    if (!cargadoPorPrimeraVez) scrollToTop();
    else cambiarCargadoPorPrimeraVez();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const compruebaIdioma = () => {
    /**
     * Si no precargas todos los idiomas que vas a usar
     * e intentas usarlos fuera de un componente de react
     * y cambias el idioma fuera de este componente, no funciona.
     * Por ello, precarga todos los idiomas.
     */
    i18n.loadLanguages(["es", "en", "ca"]);

    if (!localStorage.getItem("lang")) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage(localStorage.getItem("lang"));
    }
  };

  const cambiarCargadoPorPrimeraVez = () => {
    dispatch(pantallaDeCargaAparecePorSegundaVez());
  };

  const scrollToTop = () => {
    document.getElementById("loadingContainer").scrollIntoView();
  };

  return (
    <Grid
      id="loadingContainer"
      className={classes.container}
      container
      justify="center"
      alignItems="center"
      style={{ backgroundColor: backgroundColor ? backgroundColor : "white" }}
    >
      <Grid item xs={10}>
        <div className={classes.loading}>
          {showIcon && (
            <img
              className={classes.logo}
              src="assets/images/idcongress/logo_dark.png"
              alt="logo"
            />
          )}
          <Typography className={classes.loadingMessage}>
            {message ? t(message) : t(mensajeCarga)}
          </Typography>
          <CircularProgress style={{ color: "#5479B6" }} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Loading;
