import { takeEvery, call, put } from "redux-saga/effects";

import {
  CARGAR_WIDGETS_REVISOR_SOLICITADO,
  cargaWidgetsRevisorExitosa,
  cargaWidgetsRevisorErronea,
  cargaWidgetsRevisorFinalizada,
} from "../actions";

import { obtenerWidgetsRevisor } from "app/services/eventosService";

export function* observador() {
  yield takeEvery(CARGAR_WIDGETS_REVISOR_SOLICITADO, cargaWidgets);
}

function* cargaWidgets(action) {
  try {
    let email = action.payload;
    const { data } = yield call(obtenerWidgetsRevisor, email);
    const { counterData, chartData } = data;

    yield put(cargaWidgetsRevisorExitosa(counterData, chartData));
  } catch (e) {
    yield put(cargaWidgetsRevisorErronea(e));
  } finally {
    yield put(cargaWidgetsRevisorFinalizada());
  }
}
