import { lazy } from "react";

const BASE_URL = "/abstractUpload";

export const router = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
        settingsPanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      //reset Password
      path: `${BASE_URL}/resetPassword/:recovery_token`,
      component: lazy(() => import("./ResetPassword")),
    },
    {
      //vista Inicial
      path: `${BASE_URL}/:id_evento`,
      component: lazy(() => import("./AbstractUpload")),
    },
  ],
};
