import { takeEvery, call, put } from "redux-saga/effects";

import {
  CARGA_ABSTRACTS_SOLICITADA,
  cargaAbstractsEvento,
  cargaAbstractsErronea,
  cargaAbstractsFinalizada,
  cargaCategoriasAbstractEvento,
  cargaRevisoresEvento,
  loadIdEvento,
  getAbstractConfig,
  cargaCriteriosEvaluacion,
  cargaTiposComunicacionEvento,
  getNotificationsInfo,
} from "../actions";

import {
  getAbstractsEvento,
  getAbstractCategories,
  getRevisoresEvento,
  getCriteriosEvaluacionEvento,
  getTiposComunicacionEvento,
} from "app/services/eventosService/abstractsService";

export function* observador() {
  yield takeEvery(CARGA_ABSTRACTS_SOLICITADA, cargaAbstracts);
}

function* cargaAbstracts(action) {
  try {
    const token = action.payload;
    //cargamos abstracts del evento
    let { data } = yield call(getAbstractsEvento, token);
    let { id_evento, abstracts, status } = data;
    if (!status) throw new Error("Error en el status");
    yield put(cargaAbstractsEvento(abstracts));
    //cargamos categorias de abstracts
    let data2 = yield call(getAbstractCategories, id_evento);
    let categorias = data2.data.result;
    let status2 = data2.data.status;
    if (!status2) throw new Error("Error en el status2");
    yield put(cargaCategoriasAbstractEvento(categorias));
    //cargamos revisores del evento
    let data3 = yield call(getRevisoresEvento, id_evento);
    let revisores = data3.data.result;
    let status3 = data3.data.status;
    if (!status3) throw new Error("Error en el status3");
    yield put(cargaRevisoresEvento(revisores));
    //cargamos los criterios de evaluacion
    let data4 = yield call(getCriteriosEvaluacionEvento, id_evento);
    let criterios = data4.data.result;
    let status4 = data4.data.status;
    if (!status4) throw new Error("Error en el status4");
    yield put(cargaCriteriosEvaluacion(criterios));
    //cargamos los criterios de evaluacion
    let data5 = yield call(getTiposComunicacionEvento, id_evento);
    let tipos_com = data5.data.result;
    let status5 = data5.data.status;
    if (!status5) throw new Error("Error en el status5");
    yield put(cargaTiposComunicacionEvento(tipos_com));
    //cargamos id del evento en el store
    yield put(loadIdEvento(id_evento));
    //cargamos configuracion del evento
    yield put(getAbstractConfig(token, id_evento));
    //cargamos la informacion de control de flujo de notificaciones
    yield put(getNotificationsInfo(id_evento));
  } catch (e) {
    yield put(cargaAbstractsErronea(e));
  } finally {
    yield put(cargaAbstractsFinalizada());
  }
}
