import React, { useState } from "react";
import { IconButton, ListItemText, Popover, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  dropwdownContainer: {
    display: "flex",
    alignItems: "center",
  },
  dropdownIcon: {
    marginLeft: "-20px",
  },
  flag: {
    marginRight: "5px",
  },
});

const DesplegableIdiomas = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const idioma = i18n.language;

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setUserMenu(false);
    localStorage.setItem("language", lang);
  };

  return (
    <React.Fragment>
      <div>
        <div className={classes.dropwdownContainer}>
          <IconButton
            onClick={userMenuClick}
            className="w-64 h-64 pt-0 pb-0 mt-0 mb-0"
          >
            <img src={`/assets/images/flags/${idioma}.png`} alt="Idioma" />
          </IconButton>
          <ArrowDropDownIcon className={classes.dropdownIcon} />
        </div>

        <Popover
          open={Boolean(userMenu)}
          anchorEl={userMenu}
          onClose={userMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "py-8",
          }}
        >
          <React.Fragment>
            <MenuItem onClick={(e) => setLanguage("es")}>
              <div className={classes.flag}>
                <img src={`/assets/images/flags/es.png`} alt="Idioma" />
              </div>
              <ListItemText className="pl-0" primary={t("Español")} />
            </MenuItem>
            <MenuItem onClick={(e) => setLanguage("en")}>
              <div className={classes.flag}>
                <img src={`/assets/images/flags/en.png`} alt="Idioma" />
              </div>
              <ListItemText className="pl-0" primary={t("Inglés")} />
            </MenuItem>
            <MenuItem onClick={(e) => setLanguage("ca")}>
              <div className={classes.flag}>
                <img src={`/assets/images/flags/ca.png`} alt="Idioma" />
              </div>
              <ListItemText className="pl-0" primary={t("Catalán")} />
            </MenuItem>
          </React.Fragment>
        </Popover>
      </div>
    </React.Fragment>
  );
};

export default DesplegableIdiomas;
