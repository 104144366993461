import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import { Loading } from "app/shared";

//REDUX
import * as Actions from "../store/actions";

//SERVICES
import { setRevisoresAbstract } from "app/services/eventosService/abstractsService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    width: "100%",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: "auto",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function ModalAsignarRevisor(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ authX }) => authX.user.data);
  const {
    currentRevisoresAsignados,
    currentRevisoresNoAsignados,
    currentAbstract,
  } = useSelector(({ abstracts }) => abstracts.abstracts);
  const [checked, setChecked] = React.useState([]);
  //const [fechaMaxCorrecion, setFechaMaxCorrecion] = React.useState(false);

  //COMPROBAMOS SI DENTRO DE LOS REVISORES NO ASIGNADOS
  //HAY ALGUN FILTRO DE CATEGORIA Y TIPO DE COMUNICACION PARA MOSTRAR/OCULTAR
  let filteredNoAsignados = [];
  currentRevisoresNoAsignados.map((row) => {
    //primero comprobamos si el usuario tiene filtro
    if (row.categorias || row.tipos_com) {
      let alreadyAdded = false;
      //ahora comprobamos filtro de categorias si existe
      if (row.categorias) {
        JSON.parse(row.categorias).map((row2) => {
          if (row2 == currentAbstract.id_categoria) {
            filteredNoAsignados.push(row);
            alreadyAdded = true;
            return false;
          }
        });
      }
      if (row.tipos_com && !alreadyAdded) {
        JSON.parse(row.tipos_com).map((row2) => {
          if (row2 == currentAbstract.id_tipo_abstract) {
            filteredNoAsignados.push(row);
            return false;
          }
        });
      }
    } else {
      filteredNoAsignados.push(row);
    }
  });
  /*
   const [left, setLeft] = React.useState(
     currentRevisoresNoAsignados ? currentRevisoresNoAsignados : []
   );
   */
  const [left, setLeft] = React.useState(
    filteredNoAsignados ? filteredNoAsignados : []
  );
  const [right, setRight] = React.useState(
    currentRevisoresAsignados ? currentRevisoresAsignados : []
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleClose = () => {
    props.setOpenModalRevisores(false);
  };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "asigna_cat",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "asigna_cat",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "asigna_cat",
      });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const guardarRevisores = async () => {
    console.log("Esto es lo que se ha seleccionado:", right);
    console.log("Este es el tamaño del array:", right.length);
    if (right.length === 0 || !props.fechaMaxCorrecion) {
      setSnackbar(
        "Debe seleccionar un revisor y establecer una fecha máxima de revisión del abstract.",
        "error"
      );
      return;
    }
    console.log("fechaMaxcorreccion", props.fechaMaxCorrecion);

    let form = {
      id_abstract: props.id_abstract,
      revisores: JSON.stringify(right),
      fecha_max_correcion: props.fechaMaxCorrecion,
    };

    let res = await setRevisoresAbstract(form);
    if (!res.data.status) {
      setSnackbar(
        "Hubo un error al asignar los revisores. Intenlo de nuevo más tarde.",
        "error"
      );
    } else {
      setSnackbar("Revisores asociados correctamente", "success");
      props.setOpenModalRevisores(false);
      dispatch(Actions.getAbstractInfo(user.token, props.id_abstract));
      dispatch(Actions.getCurrentRevisores(props.id_abstract, props.id_evento));
      dispatch(Actions.solicitaCargaAbstractsEvento(user.token));
    }
  };

  /* const handleChangeFechaMaxCorrecion = (fecha) => {
    setFechaMaxCorrecion(fecha);
    console.log("Valor de la fecha:", fecha);
    console.log("New date de la fecha", new Date(fecha));
    console.log(
      "New date de la fecha formateada",
      new Date(fecha).toLocaleString()
    );
  }; */

  const customList = (title, items) => (
    <Card className="min-h-512">
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((row) => {
          const labelId = `transfer-list-all-item-${row.ID}-label`;

          return (
            <ListItem
              key={row.ID}
              role="listitem"
              button
              onClick={handleToggle(row)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(row) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${row.nombre} ${row.apellidos}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.openModalRevisores}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ToastContainer
        enableMultiContainer
        containerId="asigna_cat"
        transition={Slide}
      />
      <DialogTitle id="alert-dialog-title">Asignar Revisores</DialogTitle>
      <DialogContent>
        {!currentRevisoresNoAsignados || !currentRevisoresAsignados ? (
          <div className="flex items-center justify-center text-center">
            <Loading loadingMessage={"Cargando..."} />
          </div>
        ) : (
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            className="full-width w-full items-center flex"
          >
            {/* INPUT FECHA CORRECION */}
            {/* <Grid item xs={12}>
              <TextField
                id="datetime-local"
                label="Fecha máxima de correción"
                type="datetime-local"
                // defaultValue={new Date().toISOString()}
                // className={classes.textField}
                onChange={(e) => handleChangeFechaMaxCorrecion(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
            <Grid item xs={5}>
              {customList("Revisores Disponibles", left)}
            </Grid>
            <Grid item xs={2}>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              {customList("Revisores asignados", right)}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={guardarRevisores} color="primary" autoFocus>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
