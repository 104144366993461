export const CARGAR_WIDGETS_SUPERREVISOR_SOLICITADO =
  "[DASHBOARD] CARGA WIDGETS SUPER REVISOR";
export function solicitarCargaWidgetsSuperRevisor(email) {
  return (dispatch) =>
    dispatch({
      type: CARGAR_WIDGETS_SUPERREVISOR_SOLICITADO,
      payload: email,
    });
}

export const CARGA_WIDGETS_SUPERREVISOR_EXITOSA =
  "[DASHBOARD] CARGA WIDGETS SUPERREVISOR EXITOSA";
export function cargaWidgetsSuperRevisorExitosa(tableData, chartData) {
  return (dispatch) =>
    dispatch({
      type: CARGA_WIDGETS_SUPERREVISOR_EXITOSA,
      payload: {
        tableData,
        chartData,
      },
    });
}

export const CARGA_WIDGETS_SUPERREVISOR_ERRONEA =
  "[DASHBOARD] CARGA WIDGETS SUPERREVISOR ERRONEA";
export function cargaWidgetsSuperRevisorErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_WIDGETS_SUPERREVISOR_ERRONEA,
      payload: error,
    });
}

export const CARGA_WIDGETS_SUPERREVISOR_FINALIZADA =
  "[DASHBOARD] CARGA WIDGETS SUPERREVISOR FINALIZADA";
export function cargaWidgetsSuperRevisorFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_WIDGETS_SUPERREVISOR_FINALIZADA,
    });
}
