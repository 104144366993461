import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Global from "../../fuse-configs/Global";
import { useTranslation } from "react-i18next";
import withReducer from "app/store/withReducer";

//REDUX
import * as Actions from "./store/actions";
import reducer from "./store/reducers";

//UI COMPONENTS
import {
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
  Icon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "app/shared";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Services
import {
  saveEmailCustomTemplate,
  loadEmailDesign,
} from "app/services/eventosService/emailEditorService";

//TEMPORAL
import axios from "axios";
import querystring from "querystring";

import EmailEditor from "react-email-editor";

const setSnackbar = (message, type) => {
  if (type == "success") {
    toast.success("✔️ " + message, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  if (type == "error") {
    toast.error("❌ " + message, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  if (type == "info") {
    toast.info(message, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: true,
    });
  }
};

const EmailEditorComponent = (props) => {
  const dispatch = useDispatch();
  const emailEditorRef = useRef(null);
  const [IdEvento, setIdEvento] = React.useState(false);
  const [currentEvent, setCurrentEvent] = React.useState(false);
  const [TplType, setTplType] = React.useState(false);
  const [loadedDesign, setLoadedDesign] = React.useState(true);
  const [currentDesign, setCurrentDesign] = React.useState(false);
  const [asunto, setAsunto] = React.useState(false);
  const [isTemplateEnabled, setIsTemplateEnabled] = React.useState(false);
  const [displayAsuntoModal, setDisplayAsuntoModal] = React.useState(false);
  const user = useSelector(({ authX }) => authX.user.data);
  const editorReducer = useSelector(
    ({ EmailEditor }) => EmailEditor.emailEditorReducer.data
  );
  const { t } = useTranslation();

  const getTemplatevars = () => {
    let TemplateVars;
    switch (TplType) {
      // EMAIL PAGO PENDIENTE
      case 1:
        return (TemplateVars = [
          ["Nombre del evento", "${this.datos[0].nombre_evento}"],
          ["Url Proforma", "${this.url_factura}"],
          ["Url pago TPV", "${this.url_tpv}"],
          [
            "Importe de la compra",
            "${parseFloat(this.datos_trf.total).toFixed(2)}",
          ],
          ["Entidad Bancaria TRF", "${JSON.parse(this.datos_trf.config)[1]}"],
          ["IBAN TRF", "${JSON.parse(this.datos_trf.config)[0]}"],
          ["CONCEPTO TRANSFERENCIA", "${this.datos_trf.codigo_transferencia}"],
          ["Logo del evento", "${this.logo_evento}"],
        ]);
      // EMAIL BADGES COMPRADOR
      case 2:
        return (TemplateVars = [
          ["Nombre del evento", "${this.datos[0].nombre_evento}"],
          ["Url Factura", "${this.url_factura}"],
          ["Localización del evento", "${this.datos[0].localizacion}"],
          ["Fecha Inicio del evento", "${this.str_dia}"],
          ["Hora apertura de puertas", "${this.str_hora}"],
          ["Url logo del evento", "${this.logo_evento}"],
        ]);
      // EMAIL BADGES INSCRITO
      case 3:
        return (TemplateVars = [
          ["Nombre del evento", "${this.data.nombre_evento}"],
          ["Localización del evento", "${this.data.localizacion}"],
          ["Fecha Inicio del evento", "${this.str_dia}"],
          ["Hora apertura de puertas", "${this.str_hora}"],
          ["Url logo del evento", "${this.logo_evento}"],
          ["Nombre tipo de inscripcion", "${this.data.tipo_acreditacion}"],
          ["Lista de complementos asociados", "${this.tpl_str_comp}"],
          ["Url badge PDF", "${this.complete_path_pdf}"],
          ["URL CÓDIGO QR", "${this.qr_url}"],
          ["CÓDIGO DE INSCRIPCIÓN", "${this.data.codigo_badge}"],
          ["Nombre del Asistente", "${this.data.nombre}"],
          ["Apellido1 del Asistente", "${this.data.apellido1}"],
          ["Apellido2 del Asistente", "${this.data.apellido2}"],
        ]);
      // EMAIL REEMBOLSO
      case 4:
        return (TemplateVars = [
          ["Nombre del evento", "${this.datos[0].nombre_evento}"],
          ["Url Factura de abono", "${this.url_factura}"],
          ["Url logo del evento", "${this.logo_evento}"],
        ]);
      // EMAIL BADGES COMPRADOR ONLINE
      case 5:
        return (TemplateVars = [
          ["Nombre del evento", "${this.datos[0].nombre_evento}"],
          ["Url Factura", "${this.url_factura}"],
          ["URL RETRANSMISIÓN EVENTO", "${this.datos[0].url_evento_online}"],
          ["Fecha Inicio del evento", "${this.str_dia}"],
          ["Hora de inicio retransmisión", "${this.str_hora}"],
          ["Url logo del evento", "${this.logo_evento}"],
        ]);
      // EMAIL BADGES INSCRITO ONLINE
      case 6:
        return (TemplateVars = [
          ["Nombre del evento", "${this.data.nombre_evento}"],
          ["URL RETRANSMISIÓN EVENTO", "${this.data.url_evento_online}"],
          ["Fecha Inicio del evento", "${this.str_dia}"],
          ["Hora apertura de puertas", "${this.str_hora}"],
          ["Url logo del evento", "${this.logo_evento}"],
          ["Nombre tipo de inscripcion", "${this.data.tipo_acreditacion}"],
          ["Lista de complementos asociados", "${this.tpl_str_comp}"],
          ["CÓDIGO DE ACCESO", "${this.data.codigo_badge}"],
          ["Nombre del Asistente", "${this.data.nombre}"],
          ["Apellido1 del Asistente", "${this.data.apellido1}"],
          ["Apellido2 del Asistente", "${this.data.apellido2}"],
        ]);
      default:
        return [];
    }
  };

  useEffect(() => {
    dispatch(Actions.resetEventsEmailEditor());
    dispatch(Actions.getEventsForEmailEditor(user.token));
  }, [dispatch]);

  let SendEmailTemplate = (template) => {
    let requestURL = Global.url + "/eventos/testemailtemplate";
    return axios.post(requestURL, querystring.stringify(template));
  };

  const exportHtml = async () => {
    const { design, html } = await new Promise((res, rej) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        res(data);
      });
    });
    console.log(html);
    console.log(design);
    console.log(JSON.stringify(design));
    console.log(JSON.stringify(currentDesign));
    console.log(currentDesign);
    copyVariable2Clipboard(html);
  };

  const handleSendEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      let form = {
        template: html,
      };
      SendEmailTemplate(form).then((res) => {
        if (!res.data.status) {
          console.log("El mail no se ha enviado....");
        } else {
          console.log("Email enviado correctamente.");
        }
      });
    });
  };

  const copyVariable2Clipboard = (variable) => {
    const elem = document.createElement("textarea");
    elem.value = variable;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setSnackbar(t("Variable copiada al portapapeles"), "info");
  };

  const handleChangeTplType = (type) => {
    setLoadedDesign(false);
    setTplType(type);
    let form = {
      id_evento: IdEvento,
      id_tipo: type,
    };
    loadEmailDesign(user.token, form).then((res) => {
      console.log("este es el resultado de la peticion");
      console.log(res);
      if (!res.data.status) {
        setSnackbar(
          "Hubo un problema de conexión con el servidor, vuelva a seleccionar un tipo de plantilla o recargue la pagina. Si el problema persiste contacte con Soporte",
          "error"
        );
      } else {
        if (res.data.design) {
          console.log("Tiene diseño");
          setCurrentDesign(JSON.parse(res.data.design));
          emailEditorRef.current.editor.loadDesign(JSON.parse(res.data.design));
        } else {
          setCurrentDesign(false);
          emailEditorRef.current.editor.loadBlank();
        }
        //SETEAMOS EL ASUNTO, SI EXISTE EL QUE HAY GUARDADO O REINICIAMOS SI NO EXISTE
        setAsunto(res.data.asunto);
        //INDICAMOS SI LA PLANTILLA ESTA ACTUALMENTE ACTIVA O NO
        setIsTemplateEnabled(res.data.active);
        setLoadedDesign(true);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!IdEvento || !TplType) {
      setSnackbar(
        "Debe seleccionar un Evento y un Tipo de Correo para poder guardar la plantilla",
        "error"
      );
      return;
    }
    const { design, html } = await new Promise((res, rej) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        res(data);
      });
    });
    console.log("id del evento ==>", IdEvento);
    console.log("Tipo de plantilla ==>", TplType);
    let form = {
      id_evento: IdEvento,
      id_tipo: TplType,
      contenido: html,
      asunto,
      editor_design: JSON.stringify(design),
    };
    let { data } = await saveEmailCustomTemplate(user.token, form);
    if (!data.status) {
      setSnackbar(
        "Hubo un error al guardar la plantilla. Intentelo más tarde.",
        "error"
      );
    } else {
      setSnackbar("Plantilla guardada correctamente", "success");
      //actualizamos los datos de la plantilla haciendo otra vez la peticion
      handleChangeTplType(TplType);
    }
  };

  const handleChangeAutocomplete = (value) => {
    setIdEvento(value.ID);
    setCurrentEvent(value);
    setTplType(false);
  };

  const onLoad = (design) => {
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  if (!editorReducer.eventos) {
    return <Loading loadingMessage="Cargando..." />;
  }

  return (
    <Grid container className="p-10 min-h-full mb-256">
      <ToastContainer className="mt-52" transition={Slide} />
      <Grid item xs={12} className="text-20 font-bold">
        EMAIL TEMPLATE EDITOR
        <Grid item>
          <Button variant="contained" color="primary" onClick={exportHtml}>
            COPIAR PLANTILLA A PORTAPAPELES
          </Button>
          {/* <button onClick={handleSendEmail}>Send test email</button> */}
        </Grid>
        <Grid item className="text-right">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar plantilla
          </Button>
        </Grid>
      </Grid>
      {/* HEADER OPCIONES Y VARIABLES */}
      <Grid container>
        {/* CAJA OPCIONES */}
        <Grid item xs={6} className="p-5">
          <Paper elevation={5} className="w-full p-10">
            <Grid container>
              <Grid item xs={12} className="text-14 mb-2 font-bold">
                Opciones:
              </Grid>
              <Grid item xs={12} className="p-1">
                {/* <TextField
                  required
                  id="filled-basic"
                  label="Id Evento"
                  variant="filled"
                  onChange={(e) => setIdEvento(e.target.value)}
                /> */}
                <Autocomplete
                  id="combo-box-demo"
                  options={Object.values(editorReducer.eventos)}
                  getOptionLabel={(option) => option.nombre}
                  disableClearable={true}
                  onChange={(e, value) => handleChangeAutocomplete(value)}
                  // style={{ width: 1000 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Evento"
                      variant="outlined"
                      className="w-full"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className="p-1">
                {currentEvent && (
                  <Grid container>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="demo-simple-select-filled-label">
                        Tipo de Plantilla
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={TplType}
                        onChange={(e) => handleChangeTplType(e.target.value)}
                      >
                        {currentEvent.facturacion == 1 && (
                          <MenuItem value={1}>Email Pago Pendiente</MenuItem>
                        )}
                        {currentEvent.online == 0 &&
                          currentEvent.facturacion == 1 && (
                            <MenuItem value={2}>
                              Email Badges Comprador (Presencial)
                            </MenuItem>
                          )}
                        {currentEvent.online == 0 && (
                          <MenuItem value={3}>
                            Email Badges Inscrito (Presencial)
                          </MenuItem>
                        )}
                        {currentEvent.facturacion == 1 && (
                          <MenuItem value={4}>Email Reembolso</MenuItem>
                        )}
                        {currentEvent.online == 1 &&
                          currentEvent.facturacion == 1 && (
                            <MenuItem value={5}>
                              Email Instrucciones Comprador (Online)
                            </MenuItem>
                          )}
                        {currentEvent.online == 1 && (
                          <MenuItem value={6}>
                            Email Instrucciones Inscrito (Online)
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <Grid
                      container
                      className="pt-2 flex items-center justify-center text-center"
                    >
                      <Grid item xs={11}>
                        <TextField
                          id="filled-basic"
                          label="Asunto"
                          value={asunto ? asunto : ""}
                          onChange={(e) => setAsunto(e.target.value)}
                          variant="filled"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Info">
                          <IconButton
                            onClick={(e) => setDisplayAsuntoModal(true)}
                            aria-label="delete"
                          >
                            <Icon>help_outline</Icon>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {IdEvento && TplType && loadedDesign && (
                <Grid item xs={12} className="mt-5 border-1 rounded-6">
                  <p className="p-5 flex justify-center items-center text-center font-bold">
                    ESTADO:{` `}
                    {isTemplateEnabled ? (
                      <span className="pl-1 text-center text-green-400 font-bold">
                        Plantilla Personalizada activa
                      </span>
                    ) : (
                      <span className="pl-1 text-center text-red-400 font-bold">
                        Plantilla Personalizada inactiva
                      </span>
                    )}
                  </p>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {/* CAJA VARIABLES */}
        <Grid item xs={6} className="p-5">
          <Paper elevation={5} className="w-full p-10">
            <Grid container>
              <Grid item xs={12} className="text-14 mb-5 font-bold">
                Variables:
              </Grid>
              <Grid item xs={12}>
                {getTemplatevars().map((row) => {
                  return (
                    <Tooltip key={row[0]} title={row[1]} placement="bottom">
                      <Button
                        variant="contained"
                        color="primary"
                        className="m-2"
                        size="small"
                        key={row[0]}
                        onClick={(e) => copyVariable2Clipboard(row[1])}
                      >
                        {row[0]}
                      </Button>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        className={`mt-10 mb-256 ${loadedDesign ? "" : "hidden"}`}
      >
        <EmailEditor
          projectId={6787}
          ref={emailEditorRef}
          onLoad={onLoad}
          options={{
            displayMode: "email",
            customJS: [Global.frontURL + "/custom.js"],
            locale: "es-ES",
          }}
        />
      </Grid>
      {!loadedDesign && (
        <Grid container className={`mt-10 mb-56`}>
          <Loading loadingMessage="Cargando..." />
        </Grid>
      )}
      {/* MODAL INFO ASUNTO */}
      <Dialog
        open={displayAsuntoModal}
        onClose={(e) => setDisplayAsuntoModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Acerca del Asunto en Emails Personalizados"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Al modificar las plantillas de correo, tenemos la opción de
              indicar un asunto personalizado. Este tiene un tamaño{" "}
              <b>máximo de 255 carácteres</b>. Si se excede este límite el
              asunto se cortará a partir del carácter número 255.
            </p>
            <p className="mt-5">
              En caso de no completarlo, se usará el texto por defecto del
              sistema.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => setDisplayAsuntoModal(false)}
            color="primary"
            autoFocus
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withReducer("EmailEditor", reducer)(EmailEditorComponent);
