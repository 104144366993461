import { ConfigIframe } from "app/model";
import {
  actualizarConfigIframe,
  obtenerConfiguracionIframe,
} from "app/services/iframeService";
import { call, put, takeEvery } from "redux-saga/effects";

import * as Actions from "../actions";

export function* observador() {
  yield takeEvery(Actions.CARGA_CONFIG_SOLICITADA, cargaConfig);
  yield takeEvery(Actions.CAMBIO_COLOR_SOLICITADO, cambiaColor);
  yield takeEvery(Actions.CAMBIO_IDIOMA_POR_DEFECTO_SOLICITADO, cambioIdioma);
}

export function* cargaConfig(action) {
  try {
    const idEvento = action.payload;

    if (!idEvento) throw new Error("El id del Evento es null o undefined");

    const { data } = yield call(obtenerConfiguracionIframe, idEvento);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    yield put(Actions.cargaConfigExitosa(new ConfigIframe(result)));
  } catch (e) {
    yield put(Actions.cargaConfigErronea(e));
  } finally {
    yield put(Actions.cargaConfigFinalizada());
  }
}

export function* cambiaColor(action) {
  try {
    const { color, idEvento } = action.payload;
    yield put(Actions.cambiarColor(color));

    const { data } = yield call(actualizarConfigIframe, idEvento, { color });
    const { status } = data;

    if (!status) throw new Error("Error en el status");
  } catch (e) {
    console.log("ERROR al cambiar el color", e);
  }
}

export function* cambioIdioma(action) {
  try {
    const { idioma, idEvento } = action.payload;
    yield put(Actions.cambiarIdioma(idioma));

    const { data } = yield call(actualizarConfigIframe, idEvento, { idioma });
    const { status } = data;

    if (!status) throw new Error("Error en el status");
  } catch (e) {
    console.log("ERROR al cambiar el color", e);
  }
}
