export default {
  container: {
    padding: "7%",
  },
  legendContainer: {
    marginBottom: "20px",
  },
  errorBanner: {
    marginTop: "5%",
    padding: "5%",
    background: "lightcoral",
    borderRadius: "4px",
  },
};
