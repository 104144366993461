import { takeEvery, call, put } from "redux-saga/effects";
import { cargaProductosEvento } from "app/components/iframe/Iframe.logic";
import {
  CARGA_PRODUCTOS_SOLICITADA,
  cargaProductosExitosa,
  cargaProductosErronea,
  cargaProductosFinalizada,
  cambiarCantidadInscripciones,
  recalcularPrecio,
} from "../actions/compra.actions";

export function* observador() {
  yield takeEvery(CARGA_PRODUCTOS_SOLICITADA, cargaProductos);
}

export function* cargaProductos(action) {
  try {
    const idEvento = action.payload;
    if (!idEvento) {
      throw new Error("El id del Evento es null o undefined");
    }
    let productos = yield call(cargaProductosEvento, idEvento);

    if (productos.length === 1) {
      let [unicoTipoInscripcion] = productos;
      yield call(
        cargaProductosCuandoHayUnSoloTipoInscripcion,
        unicoTipoInscripcion
      );
      return;
    }

    yield call(cargaProductosCuandoHayMasDeUnTipoInscripcion, productos);
  } catch (e) {
    yield put(cargaProductosErronea(e));
  } finally {
    yield put(cargaProductosFinalizada());
  }
}

/**
 * Guarda los tipos de inscripción disponibles para el evento.
 * @param {Tipos de inscripción disponibles para el evento} tiposInscripcion
 */
function* cargaProductosCuandoHayMasDeUnTipoInscripcion(tiposInscripcion) {
  const productos = tiposInscripcion;
  yield put(cargaProductosExitosa(productos));
}

/**
 * Guarda el único tipo de inscripción disponible para el evento y lo añade
 * a las inscripciones escogidas, recalculando el precio.
 * @param {único tipo de inscripcion disponible para el evento} tipoInscripcion
 */
function* cargaProductosCuandoHayUnSoloTipoInscripcion(tipoInscripcion) {
  /**
   * Mantenemos la integridad de los datos. Se añade la inscripcion a inscripciones,
   * luego habrá un tipo de inscripcion que ha sido escogida 1 vez.
   */
  const tIActualizado = { ...tipoInscripcion, cantidadEntradas: 1 };
  const productos = [tIActualizado];
  yield put(cargaProductosExitosa(productos));
  yield put(
    cambiarCantidadInscripciones({
      indice: 0,
      nuevaCantidad: 1,
      prevCantidadEntradas: 0,
    })
  );
  /**
   * Recalculamos precio ya que hemos añadido una inscripción.
   */
  yield put(recalcularPrecio());
}
