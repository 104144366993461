import { lazy } from "react";

const login = () => import("./login/Login");
const registro = () => import("./signin/SignIn");
const contrasenaOlvidada = () =>
  import("./recuperarContrasena/components").then((module) => ({
    default: module.ContrasenaOlvidada,
  }));
const recuperarContrasena = () =>
  import("./recuperarContrasena/RecuperarContrasena");

export const router = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    { path: "/login", component: lazy(login) },
    { path: "/signin", component: lazy(registro) },
    { path: "/forgottenPassword", component: lazy(contrasenaOlvidada) },
    { path: "/recoverPassword/:token", component: lazy(recuperarContrasena) },
  ],
};
