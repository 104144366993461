import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, TextField } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

//SERVICES
import { setAsignacionAutomaticaAbstract } from "app/services/eventosService/abstractsService";

//ACTIONS
import { solicitaCargaAbstractsEvento } from "../store/actions";

//COMPONENTS
import ModalAceptarAbstracts from "./modalAceptarAbstracts";
import ModalAsignacionAutomatica from "./modalAsignacionAutomatica";
import ModalEliminarAsignaciones from "./modalEliminarAsignaciones";

export default function MenuOpcionesTabla(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayModalAceptar, setDisplayModalAceptar] = React.useState(false);
  const [displayModalAsignarAuto, setDisplayModalAsignarAuto] = React.useState(
    false
  );
  const [displayModalEliminar, setDisplayModalEliminar] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector(({ authX }) => authX.user.data);
  const { abstractsData } = useSelector(
    ({ abstracts }) => abstracts.abstracts.abstracts
  );
  const { revisores } = useSelector(({ abstracts }) => abstracts.abstracts);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAceptarAbstracts = async () => {
    setAnchorEl(null);
    setDisplayModalAceptar(true);
  };

  const handleAsignarAutomaticamente = () => {
    setAnchorEl(null);
    setDisplayModalAsignarAuto(true);
  };

  const handleEliminarAsignaciones = () => {
    setAnchorEl(null);
    setDisplayModalEliminar(true);
  };

  return (
    <div className="flex items-end justify-end text-right m-5">
      <Button
        variant="contained"
        color="primary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Opciones
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAsignarAutomaticamente}>
          Asignar comunicaciones entre revisores automaticamente
        </MenuItem>
        <MenuItem onClick={handleEliminarAsignaciones}>
          Eliminar todas las asignaciones de revisión
        </MenuItem>
        <MenuItem onClick={handleAceptarAbstracts}>
          Aceptar/Rechazar Comunicaciones
        </MenuItem>
      </Menu>

      {/* MODALS */}
      {displayModalAceptar && (
        <ModalAceptarAbstracts
          displayModalAceptar={displayModalAceptar}
          setDisplayModalAceptar={setDisplayModalAceptar}
        />
      )}
      {displayModalAsignarAuto && (
        <ModalAsignacionAutomatica
          displayModalAsignarAuto={displayModalAsignarAuto}
          setDisplayModalAsignarAuto={setDisplayModalAsignarAuto}
        />
      )}
      {displayModalEliminar && (
        <ModalEliminarAsignaciones
          displayModalEliminar={displayModalEliminar}
          setDisplayModalEliminar={setDisplayModalEliminar}
        />
      )}
    </div>
  );
}
