/**
 * ================================
 *
 * CARGAR DATOS DEL EVENTO
 *
 * ================================
 */
export const CARGA_DATOS_EVENTO_SOLICITADA =
  "[IFRAME.EVENTOS] SE HA SOLICITADO CARGAR LOS DATOS DE UN EVENTO";

export function solicitarCargaDatosEvento(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EVENTO_SOLICITADA,
      payload: idEvento,
    });
}

export const EVENTO_CADUCADO = "[IFRAME.EVENTOS] EL EVENTO YA HA CADUCADO";
export function eventoCaducado() {
  return (dispatch) => dispatch({ type: EVENTO_CADUCADO });
}

export const CARGA_DATOS_EVENTO_CORRECTA =
  "[IFRAME.EVENTOS] SE HAN CARGADO LOS DATOS CORRECTAMENTE";

export function cargaDatosEventoExitosa(datos) {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EVENTO_CORRECTA,
      payload: datos,
    });
}

export const CARGA_DATOS_EVENTO_ERRONEA =
  "[IFRAME.EVENTOS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS DATOS DEL EVENTO";

export function cargaDatosEventoErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EVENTO_ERRONEA,
      payload: error,
    });
}

export const CARGA_DATOS_EVENTO_FINALIZADA =
  "[IFRAME.EVENTOS] LA CARGA DE LOS DATOS DEL EVENTO HA FINALIZADO";

export function cargaDatosEventoFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EVENTO_FINALIZADA,
    });
}

/**
 * ================================
 *
 * CARGAR IMAGEN DEL EVENTO
 *
 * ================================
 */
export const CARGA_IMAGEN_EVENTO_SOLICITADA =
  "[IFRAME.EVENTOS] SE HA SOLICITADO CARGAR LA IMAGEN DEL EVENTO";

export function solicitarCargaImagenEvento(idEvento, nombreImagen) {
  return (dispatch) =>
    dispatch({
      type: CARGA_IMAGEN_EVENTO_SOLICITADA,
      payload: { idEvento, nombreImagen },
    });
}

export const CARGA_IMAGEN_EVENTO_CORRECTA =
  "[IFRAME.EVENTOS] SE HA CARGADO LA IMAGEN CORRECTAMENTE";

export function cargaImagenEventoExitosa(imagen) {
  return (dispatch) =>
    dispatch({
      type: CARGA_IMAGEN_EVENTO_CORRECTA,
      payload: imagen,
    });
}

export const CARGA_IMAGEN_EVENTO_ERRONEA =
  "[IFRAME.EVENTOS] SE HA PRODUCIDO UN ERROR AL CARGAR LA IMAGEN DEL EVENTO";

export function cargaImagenEventoErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_IMAGEN_EVENTO_ERRONEA,
      payload: error,
    });
}

export const CARGA_IMAGEN_EVENTO_FINALIZADA =
  "[IFRAME.EVENTOS] LA CARGA DE LA IMAGEN DEL EVENTO HA FINALIZADO";

export function cargaImagenEventoFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_IMAGEN_EVENTO_FINALIZADA,
    });
}

/**
 * ================================
 *
 * OBTENER EMPRESA ORGANIZADORA
 *
 * ================================
 */
export const CARGA_DATOS_EMPRESA_ORGANIZADORA_SOLICITADA =
  "[IFRAME.EVENTOS] SE HA SOLICITADO CARGAR LOS DATOS DE LA EMPRESA ORGANIZADORA DEL EVENTO";

export function solicitarCargaDatosEmpresaOrganizadora(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EMPRESA_ORGANIZADORA_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_DATOS_EMPRESA_ORGANIZADORA_CORRECTA =
  "[IFRAME.EVENTOS] SE HAN CARGADO LOS DATOS DE LA EMPRESA ORGANIZADORA DEL EVENTO CORRECTAMENTE";

export function cargaDatosEmpresaOrganizadoraExitosa(datos) {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EMPRESA_ORGANIZADORA_CORRECTA,
      payload: datos,
    });
}

export const CARGA_DATOS_EMPRESA_ORGANIZADORA_ERRONEA =
  "[IFRAME.EVENTOS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS DATOS DE LA EMPRESA ORGANIZADORA DEL EVENTO";

export function cargaDatosEmpresaOrganizadoraErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EMPRESA_ORGANIZADORA_ERRONEA,
      payload: error,
    });
}

export const CARGA_DATOS_EMPRESA_ORGANIZADORA_FINALIZADA =
  "[IFRAME.EVENTOS] LA CARGA DE LOS DATOS DE LA EMPRESA ORGANIZADORA DEL EVENTO HA FINALIZADO";

export function cargaDatosEmpresaOrganizadoraFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_DATOS_EMPRESA_ORGANIZADORA_FINALIZADA,
    });
}
