import { lazy } from "react";

export const router = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/apps/dashboard",
      component: lazy(() => import("./Dashboard")),
    },
  ],
};
