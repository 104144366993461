import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
} from "@material-ui/core";

import { solicitarCambioPassword } from "app/services/eventosService/abstractUploadService";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ModalRecuperarPassword(props) {
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const [email, setEmail] = useState("");
  const [solicitado, setSolicitado] = useState(false);

  const handleClose = () => {
    props.setDisplayModalPassword(false);
    setSolicitado(false);
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "reset_password",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "reset_password",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = {
      id_evento: abstract_data.evento.data.result.ID,
      nombre_evento: abstract_data.evento.data.result.nombre,
      email,
    };
    console.log("formulario enviado:", form);
    let result = await solicitarCambioPassword(form);
    if (!result.data.status) {
      setSnackbar(result.data.message, "error");
    } else {
      setSolicitado(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.displayModalPassword}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <ToastContainer enableMultiContainer containerId="reset_password" />
        <form onSubmit={handleSubmit}>
          {solicitado ? (
            <DialogContent>
              <Grid
                container
                className="flex items-center justify-center text-center"
              >
                <Grid item xs={12}>
                  <Icon fontSize="large" className="text-green-500">
                    check_circle
                  </Icon>
                </Grid>
                <Grid item xs={12}>
                  Su solicitud ha sido realizada correctamente. Recibirá un
                  email donde podrá restablecer su contraseña.
                </Grid>
              </Grid>
            </DialogContent>
          ) : (
            <>
              <DialogTitle id="form-dialog-title">
                Restablecer contraseña
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Indique la cuenta de email asociada a su usuario para recibir
                  un enlace donde podrá crear una nueva contraseña.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                />
              </DialogContent>
            </>
          )}

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {solicitado ? "Cerrar" : "Cancelar"}
            </Button>
            {!solicitado && (
              <Button type="submit" color="primary">
                Enviar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
