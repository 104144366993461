import React from "react";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InfoIcon from "@material-ui/icons/Info";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";

import { parseForShowing } from "app/model/Helper";
import {
  enviarCorreoPagarDespues,
  actualizarMetodoPagoTransferenciaPorIdCompra,
} from "app/services/eventosService";

const useStyles = makeStyles({
  container: {
    margin: "0 auto",
    width: "80%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
  },
  btn_submit: {
    marginTop: "20px",
  },
  paymentIcon: {
    fontSize: "80px",
    marginTop: "15px",
  },
  paymentIconText: {
    marginTop: "10px",
  },
  emailAlert: {
    marginTop: "5px",
    padding: "10px",
    backgroundColor: "#ADE5F7",
    borderRadius: "5px",
  },
  emailText: {
    fontSize: "1.1rem",
    marginLeft: "10px",
  },
  emailIcon: {
    fontSize: "20px",
  },
  cursiva: {
    fontStyle: "italic",
  },
});

const WireTransfer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const datosFacturacion = useSelector(
    ({ iframe }) => iframe.pagos.datosFacturacion
  );

  const {
    setWireTransferSelected,
    wireTransferSelected,
    paymentId,
    idioma,
    styles,
  } = props;

  const sendEmailWithWireTransferInfo = () => {
    try {
      actualizarMetodoPagoTransferenciaPorIdCompra(
        datosFacturacion.idCompra,
        datosFacturacion.codigoTransferencia
      );
      setWireTransferSelected(true);
      enviarCorreoPagarDespues(paymentId, idioma);
    } catch (e) {
      // TODO: Añadir pantalla de error
    }
  };

  if (wireTransferSelected) {
    return (
      <Paper
        className={classes.container}
        style={{ backgroundColor: styles.backgroundColor }}
      >
        <Typography variant="h4" gutterBottom align="center">
          {t("Datos de transferencia")}
        </Typography>
        <Grid container spacing={2} align="center">
          <Grid item xs={12}>
            <CheckCircleIcon
              style={{
                color: green[500],
                fontSize: 100,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom >
              {t("Le hemos enviado un correo electrónico a la dirección indicada con la información para realizar la transferencia. Recuerde revisar la bandeja de SPAM y de Promociones de su cuenta de correo.")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              {t("Concepto")}
            </Typography>
            <Typography> {datosFacturacion.codigoTransferencia}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              {t("Importe")}
            </Typography>
            <Typography>
              {" "}
              {parseForShowing(datosFacturacion.total)} €
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return (
      <div>
        <AccountBalanceIcon
          className={classes.paymentIcon}
          onClick={sendEmailWithWireTransferInfo}
        />
        <Typography className={classes.paymentIconText}>
          {t("Transferencia Bancaria")}
        </Typography>
      </div>
    );
  }
};

export default WireTransfer;
