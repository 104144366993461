export const GUARDAR_ULTIMOS_USUARIOS =
  "[DASHBOARD-USUARIOS] GUARDA ULTIMOS USUARIOS REGISTRADOS";
export function guardarUltimosUsuarios(usuarios) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_ULTIMOS_USUARIOS, payload: usuarios });
  };
}

/**
 * ULTIMOS USUARIOS
 */
export const SOLICITAR_CARGA_ULTIMOS_USUARIOS =
  "[DASHBOARD.USUARIOS] CARGA ÚLTIMOS USUARIOS SOLICITADA";
export function solicitarCargaUltimosUsuarios() {
  return (dispatch) => {
    dispatch({ type: SOLICITAR_CARGA_ULTIMOS_USUARIOS });
  };
}

export const CARGA_ULTIMOS_USUARIOS_EXITOSA =
  "[DASHBOARD.USUARIOS] SE HAN CARGADO LOS ÚLTIMOS USUARIOS CON ÉXITO";
export function cargaUltimosUsuariosExitosa(usuarios) {
  return (dispatch) => {
    dispatch({ type: CARGA_ULTIMOS_USUARIOS_EXITOSA, payload: usuarios });
  };
}

export const CARGA_ULTIMOS_USUARIOS_ERRONEA =
  "[DASHBOARD.USUARIOS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS ÚLTIMOS USUARIOS";
export function cargaUltimosUsuariosErronea(error) {
  return (dispatch) => {
    dispatch({ type: CARGA_ULTIMOS_USUARIOS_ERRONEA, payload: error });
  };
}

export const CARGA_ULTIMOS_USUARIOS_FINALIZADA =
  "[DASHBOARD.USUARIOS] LA CARGA DE LOS ÚLTIMOS USUARIOS HA FINALIZADO";
export function cargaUltimosUsuariosFinalizada() {
  return (dispatch) => {
    dispatch({ type: CARGA_ULTIMOS_USUARIOS_FINALIZADA });
  };
}
