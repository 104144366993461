// import MenuTiposInscripcion from "./MenuTiposInscripcion";
import Loading from "./Loading";
import Consentimientos from "./Consentimientos";
import Desglose from "./Desglose";
import Descuentos from "./Descuentos";
import ListaAlertas from "./ListaAlertas";

export * from "./formularios";
export * from "./botones";
export * from "./pagos";
export * from "./legal";
export * from "./vistas";
export * from "./MenuTiposInscripcion";
export * from "./wrappers";
export { ListaAlertas, Loading, Consentimientos, Desglose, Descuentos };
