import { spawn } from "redux-saga/effects";

// SAGAS
import { observador as observadorMetricas } from "./metricas.sagas";
import { observador as observadorEventos } from "./eventos.sagas";
import { observador as observadorUsuarios } from "./usuarios.sagas";
import { observador as observadorSuperRevisor } from "./superRevisor.sagas";
import { observador as observadorRevisor } from "./revisor.sagas";

export function* masterSaga() {
  yield spawn(observadorMetricas);
  yield spawn(observadorEventos);
  yield spawn(observadorUsuarios);
  yield spawn(observadorSuperRevisor);
  yield spawn(observadorRevisor);
}
