import { lazy } from "react";

const BASE_URL = "/buy/:idioma/event";

const vistaInicial = {
  ROUTE: `${BASE_URL}/:id`,
  IMPORT: () => import("./IframeGateway"),
};

const vistaMetodosPago = {
  ROUTE: `${BASE_URL}/:eventId/payment/:paymentId`,
  IMPORT: () => import("./components/pagos/MetodosPago"),
};

const vistaRedirigirDesdeCorreo = {
  ROUTE: `${BASE_URL}/payment/fromEmail/:buyId`,
  IMPORT: () => import("./components/pagos/components/DirectPaymentFromEmail"),
};

const vistaPagarDespues = {
  ROUTE: `${BASE_URL}/payment/paymentAfter`,
  IMPORT: () =>
    import("./components/pagos/components/PaymentAfter/PaymentAfter"),
};

const vistaInscripcionPrivadaRealizada = {
  ROUTE: `${BASE_URL}/payment/private/done`,
  IMPORT: () =>
    import(
      "./components/vistas/InscripcionPrivadaRealizada/InscripcionPrivadaRealizada"
    ),
};

const vistaEventoGratuito = {
  ROUTE: `${BASE_URL}/:eventId/free/payment/:buyId`,
  IMPORT: () => import("./components/pagos/components/PaymentFree"),
};

const vistaPagoRealizadoCorrectamente = {
  ROUTE: `${BASE_URL}/payment/redsys/success/:eventId/:buyId`,
  IMPORT: () => import("./components/pagos/components/RedsysPaymentSuccesful"),
};

const vistaPagoRealizadoErroneamente = {
  ROUTE: `${BASE_URL}/payment/redsys/error`,
  IMPORT: () => import("./components/pagos/components/RedsysPaymentError"),
};

const vistaPoliticaPrivacidad = {
  ROUTE: `${BASE_URL}/:eventId/privacyPolitics`,
  IMPORT: () => import("./components/legal/PrivacyPolitics"),
};

const vistaTerminosCondiciones = {
  ROUTE: `${BASE_URL}/:eventId/termsConditions`,
  IMPORT: () => import("./components/legal/TermsConditions"),
};

export const router = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
        settingsPanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: vistaRedirigirDesdeCorreo.ROUTE,
      component: lazy(vistaRedirigirDesdeCorreo.IMPORT),
    },
    {
      path: vistaInscripcionPrivadaRealizada.ROUTE,
      component: lazy(vistaInscripcionPrivadaRealizada.IMPORT),
    },
    {
      path: vistaPagarDespues.ROUTE,
      component: lazy(vistaPagarDespues.IMPORT),
    },
    {
      path: vistaEventoGratuito.ROUTE,
      component: lazy(vistaEventoGratuito.IMPORT),
    },
    {
      path: vistaPagoRealizadoCorrectamente.ROUTE,
      component: lazy(vistaPagoRealizadoCorrectamente.IMPORT),
    },
    {
      path: vistaPagoRealizadoErroneamente.ROUTE,
      component: lazy(vistaPagoRealizadoErroneamente.IMPORT),
    },
    {
      path: vistaMetodosPago.ROUTE,
      component: lazy(vistaMetodosPago.IMPORT),
    },
    {
      path: vistaPoliticaPrivacidad.ROUTE,
      component: lazy(vistaPoliticaPrivacidad.IMPORT),
    },
    {
      path: vistaTerminosCondiciones.ROUTE,
      component: lazy(vistaTerminosCondiciones.IMPORT),
    },
    {
      path: vistaInicial.ROUTE,
      component: lazy(vistaInicial.IMPORT),
    },
  ],
};
