import React, { useState } from "react";
import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

function LanguageMenu(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setUserMenu(false);
    //guardamos variable en el localstorage
    localStorage.setItem("language", lang);
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={userMenuClick}
        className="w-64 h-64 pt-0 pb-0 mt-0 mb-0"
      >
        {/* <Icon>language</Icon> */}
        <img src={`/assets/images/flags/${currentLanguage}.png`} />
      </IconButton>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          <MenuItem onClick={(e) => setLanguage("es")}>
            <ListItemIcon className="min-w-40">
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText className="pl-0" primary={t("Español")} />
          </MenuItem>
          <MenuItem onClick={(e) => setLanguage("en")}>
            <ListItemIcon className="min-w-40">
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText className="pl-0" primary={t("Inglés")} />
          </MenuItem>
          <MenuItem onClick={(e) => setLanguage("ca")}>
            <ListItemIcon className="min-w-40">
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText className="pl-0" primary={t("Catalan")} />
          </MenuItem>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default LanguageMenu;
