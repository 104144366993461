import React from "react";
import "./Skeleton.css";

export const Skeleton = ({ height }) => {
  return (
    <div
      style={{
        height,
      }}
      className="skeleton"
    ></div>
  );
};
