import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//MATERIAL-UI
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  Tooltip,
  ListItemSecondaryAction,
  IconButton,
  Icon,
  Chip,
  TextField,
  Button,
  Paper,
} from "@material-ui/core";
import { Loading } from "app/shared";

//ALERTS
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//REDUX
import * as Actions from "../../store/actions";

//SERVICES
import {
  crearAportacion,
  eliminarAportacion,
  crearAportacionDeOtroAutor,
} from "app/services/eventosService/abstractUploadService";

const BoxAportaciones = () => {
  const dispatch = useDispatch();
  const [numAbstract, setNumAbstract] = React.useState("");
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );

  let { ID: id_abstract, keywords } = abstract_data.currentAbstract;

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_aportaciones",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_aportaciones",
      });
    }
  };

  const addAportacion = async (row) => {
    try {
      let form = {
        id_symposium: abstract_data.currentAbstract.ID,
        id_comunicacion: row.ID,
      };
      let result = await crearAportacion(form);
      if (!result.data.status) {
        setSnackbar(result.data.message, "error");
      } else {
        setSnackbar(result.data.message, "success");
        dispatch(Actions.getAportaciones(abstract_data.currentAbstract.ID));
      }
    } catch (error) {
      setSnackbar(
        "No ha sido posible añadir la aportación en este momento. Intentelo de nuevo más tarde.",
        "error"
      );
    }
  };

  const handleCrearAportacionOtroAutor = async () => {
    if (numAbstract.length === 0) {
      return;
    } else {
      let form = {
        id_symposium: abstract_data.currentAbstract.ID,
        num_abstract: numAbstract,
      };
      let result = await crearAportacionDeOtroAutor(form);
      if (!result.data.status) {
        setSnackbar(result.data.message, "error");
      } else {
        setSnackbar(result.data.message, "success");
        //Refrescamos aportaciones
        dispatch(Actions.getAportaciones(abstract_data.currentAbstract.ID));
        setNumAbstract("");
      }
    }
  };

  const handleEliminarAportacion = async (id_aportacion) => {
    try {
      let form = {
        id_aportacion,
      };

      let result = await eliminarAportacion(form);
      if (!result.data.status) {
        setSnackbar(
          "Hubo un problema al intentar eliminar la aportacion. Intentelo de nuevo más tarde.",
          "error"
        );
      } else {
        setSnackbar("Aportación eliminada correctamente", "success");
        dispatch(Actions.getAportaciones(abstract_data.currentAbstract.ID));
      }
    } catch (error) {
      setSnackbar(
        "Hubo un problema al intentar eliminar la aportacion. Intentelo de nuevo más tarde.",
        "error"
      );
    }
  };

  if (
    abstract_data.currentTipoAbstract.grupal == 1 &&
    !abstract_data.aportacionesAbstract
  ) {
    return <Loading loadingMessage="Cargando..." />;
  }

  let aportacionesBD = abstract_data.aportacionesAbstract;

  return (
    <Grid container>
      <ToastContainer
        enableMultiContainer
        containerId="abstract_aportaciones"
      />
      {/* LISTADO DE ABSTRACTS DEL AUTOR LOGUEADO */}
      <Grid item xs={6} className="p-5">
        <Grid item xs={12} className="font-bold text-14 text-center pb-5">
          Tus comunicaciones:
        </Grid>
        <Grid
          item
          className="border-1 rounded-sm max-h-320 overflow-scroll"
          xs={12}
        >
          {abstract_data.abstracts.data.result.map((row) => {
            if (row.grupal == 0 && row.publicado == 1) {
              return (
                <div>
                  <List dense={false}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <Icon>description</Icon>
                        </Avatar>
                      </ListItemAvatar>
                      <Grid container>
                        <Grid className="text-12" item xs={12}>
                          {row.num_abstract}
                        </Grid>
                        <Grid className="text-10 text-gray-500" item xs={12}>
                          {row.titulo}
                        </Grid>
                      </Grid>
                      {/* <ListItemText
                      className="text-10"
                      primary={row.num_abstract}
                      // secondary={row.titulo}
                      secondary="ESTO ES UN TITULO DE ABSTRACT QUE ES MUYY LARGO Y SEGURAMENTE SE CORTARA EN EL BOTON DE LA PANTALLA"
                    /> */}
                      <ListItemSecondaryAction>
                        <Tooltip title="Añadir">
                          <IconButton
                            onClick={(e) => addAportacion(row)}
                            edge="end"
                            aria-label="delete"
                          >
                            <Icon>arrow_forward</Icon>
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </div>
              );
            }
          })}
        </Grid>
        <Grid item xs={12} className="mt-10 font-bold text-14 text-center pb-5">
          Añadir trabajo de otro autor:
        </Grid>
        <Grid item xs={12} className="border-1 rounded-sm pt-10 pb-10">
          <FormControl variant="outlined">
            <InputLabel htmlFor="standard-adornment-password">
              Identificador de trabajo
            </InputLabel>
            <Input
              id="standard-adornment-password"
              fullWidth
              value={numAbstract}
              onChange={(e) => setNumAbstract(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Añadir">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => handleCrearAportacionOtroAutor()}
                    >
                      {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                      <Icon>add</Icon>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* BOX LISTADO DE COMUNICACIONES */}
      <Grid item xs={6} className="p-5">
        <Grid item xs={12} className="font-bold text-14 text-center pb-5">
          Listado de aportaciones:
        </Grid>
        <Grid
          item
          className="border-1 rounded-sm max-h-320 overflow-scroll"
          xs={12}
        >
          <Grid
            item
            xs={12}
            className="text-12 p-10 text-gray-500 flext items-center justify-center"
          >
            {aportacionesBD.length === 0 && (
              <>
                <Grid item xs={12}>
                  <Icon>info</Icon>
                </Grid>
                <Grid item xs={12}>
                  Rellene la lista de aportaciones.
                </Grid>
              </>
            )}
            {aportacionesBD.length > 0 &&
              aportacionesBD.map((row, index) => {
                return (
                  <Paper elevation={5} className="mb-5">
                    <List dense={false}>
                      <ListItem>
                        <ListItemAvatar>#{index + 1}</ListItemAvatar>
                        <Grid container>
                          <Grid className="text-12" item xs={12}>
                            {row.num_abstract}
                          </Grid>
                          <Grid className="text-10 text-gray-500" item xs={12}>
                            {row.titulo}
                          </Grid>
                          <Grid className="text-10 text-gray-500" item xs={12}>
                            {row.nombre_autor} {row.apellido1_autor}{" "}
                            {row.apellido2_autor}
                          </Grid>
                        </Grid>

                        <ListItemSecondaryAction>
                          <Tooltip title="Eliminar">
                            <IconButton
                              onClick={(e) => handleEliminarAportacion(row.ID)}
                              edge="end"
                              aria-label="delete"
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Paper>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoxAportaciones;
