const authRoles = {
  superadmin: ["superadmin"],
  admin: ["superadmin", "admin"],
  admin_empresa: ["superadmin", "admin", "admin_empresa"],
  user_empresa: ["superadmin", "admin", "admin_empresa", "admin_empresa"],
  super_revisor: ["super_revisor"],
  revisor: ["revisor"],
  onlyGuest: [],
};

export default authRoles;
