import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Icon,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//services
import {
  setAsignacionAutomaticaAbstract,
  getAsignacionesComunicaciones,
} from "../../../services/eventosService/abstractsService";

//actions
import { solicitaCargaAbstractsEvento } from "../store/actions";

export default function FormDialog(props) {
  const dispatch = useDispatch();
  const [revsPorAbstract, setRevsPorAbstrct] = React.useState(1);
  const [showError, setShowError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [showPostProcessErrors, setShowPostProcessErrors] = React.useState(
    false
  );
  const [asignacionesDuplicadas, setAsignacionesDuplicadas] = React.useState(
    []
  );
  const [noAsignados, setNoAsignados] = React.useState([]);
  const [sinCambios, setSinCambios] = React.useState(false);

  const user = useSelector(({ authX }) => authX.user.data);
  const { abstractsData } = useSelector(
    ({ abstracts }) => abstracts.abstracts.abstracts
  );
  const { revisores, id_evento } = useSelector(
    ({ abstracts }) => abstracts.abstracts
  );

  const handleClose = () => {
    dispatch(solicitaCargaAbstractsEvento(user.token));
    props.setDisplayModalAsignarAuto(false);
    console.log(revsPorAbstract);
  };

  const checkRevisorFilter = (revisor, id_categoria, id_tipo_abstract) => {
    //casteamos a string por si no coinciden los types al hacer el includes
    id_categoria = String(id_categoria);
    id_tipo_abstract = String(id_tipo_abstract);
    let categorias_filter = false;
    let tipo_com_filter = false;
    let filter_ok = false;
    if (revisor.categorias || revisor.tipos_com) {
      if (revisor.categorias) {
        let categorias = JSON.parse(revisor.categorias);
        categorias_filter = categorias.includes(id_categoria);
      }
      if (revisor.tipos_com && !filter_ok) {
        let tipos_com = JSON.parse(revisor.tipos_com);
        tipo_com_filter = tipos_com.includes(id_tipo_abstract);
      }
      filter_ok = categorias_filter && tipo_com_filter;
    } else {
      filter_ok = true;
    }

    //logs
    // console.log("=================================================");
    // console.log("Categoria Abstract: ", id_categoria);
    // console.log("Tipo abstract: ", id_tipo_abstract);
    // console.log("Filtro categoria revisor:", revisor.categorias);
    // console.log("Filtro tipos Abstract revisor: ", revisor.tipos_com);
    // console.log("Check result ==>", filter_ok);
    // console.log("=================================================");

    return filter_ok;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Number.isInteger(parseInt(revsPorAbstract))) {
      if (revsPorAbstract > 0 && revsPorAbstract <= 10) {
        setSubmitting(true);
        let result1 = await getAsignacionesComunicaciones({ id_evento });
        console.log(result1);
        if (!result1.data.status) {
          console.log("error al obtener asignaciones actuales");
          return;
        }
        //creamos el objeto para almacenar nuevas asignaciones
        let newAsignaciones = [];
        revisores.map((row) => {
          let newItem = {
            id_revisor: row.ID,
            abstracts_asignados: [],
          };
          newAsignaciones.push(newItem);
        });
        let asignaciones = result1.data.result;
        //guardaremos el index del map de revisores para ir asignando de forma
        //equitativa en diferentes iteraciones.
        let current_revisor_index = 0;
        let abstracts_no_asignables = [];
        //recorremos comunicaciones
        abstractsData.map((abstract) => {
          //obtenemos cuantos revisores hay asignados para esta comunicacion
          let currentAsignados = asignaciones.filter(
            (row) => row.id_abstract == abstract.ID
          );
          // ahora sabemos cuantas revisiones quedarian por asignar
          let rev_por_asignar = revsPorAbstract - currentAsignados.length;
          let num_revisores_no_aptos = 0;
          while (rev_por_asignar > 0) {
            revisores.map((revisor, index) => {
              let revisor_filter_ok = checkRevisorFilter(
                revisor,
                abstract.id_categoria,
                abstract.id_tipo_abstract
              );
              if (index >= current_revisor_index && rev_por_asignar > 0) {
                if (revisor_filter_ok) {
                  //añadimos asignacion
                  newAsignaciones.map((row) => {
                    if (revisor.ID == row.id_revisor) {
                      row.abstracts_asignados.push(abstract.ID);
                      current_revisor_index = index + 1;
                      rev_por_asignar--;
                      //cuando acaba el loop reiniciamos el flag de index de revisor
                      if (index === revisores.length - 1) {
                        current_revisor_index = 0;
                        num_revisores_no_aptos = 0;
                      }
                    }
                  });
                } else {
                  //comprobamos si ninguno de los revisores cumple el filtro
                  //para asi salir del bucle
                  num_revisores_no_aptos++;
                  current_revisor_index = index + 1;
                  if (num_revisores_no_aptos == revisores.length) {
                    abstracts_no_asignables.push(abstract);
                    rev_por_asignar = 0;
                  }
                  if (index === revisores.length - 1) {
                    current_revisor_index = 0;
                    num_revisores_no_aptos = 0;
                  }
                }
              }
            });
          }
        });

        //una vez realizada la asignación la guardamos en base de datos
        let form = {
          asignacion: JSON.stringify(newAsignaciones),
        };
        let result_asignacion = await setAsignacionAutomaticaAbstract(form);
        if (!result_asignacion.data.status) {
          console.log("Error al insertar las asignaciones!!!!");
        } else {
          console.log("Asignaciones insertadas correctamente!!");
        }
        //Comprobamos si se han producido errores
        //1 Comprobamos si se ha asignado la misma comunicacion a un revisor varias veces
        let erroresDuplicados = [];
        newAsignaciones.map((row) => {
          let asignados = row.abstracts_asignados;
          let aux_array = asignados.filter(
            (row, index) => asignados.indexOf(row) !== index
          );
          let duplicados = aux_array.filter(
            (row, index) => aux_array.indexOf(row) === index
          );
          if (duplicados.length > 0) {
            let revisor = revisores.filter((row2) => row2.ID == row.id_revisor);
            let newItem = {
              nombre: `${revisor[0].nombre} ${revisor[0].apellidos}`,
              nums_com: [],
            };
            duplicados.map((row3) => {
              let abstract = abstractsData.filter((row4) => row4.ID == row3);
              newItem.nums_com.push(abstract[0].num_abstract);
            });
            erroresDuplicados.push(newItem);
          }
        });

        console.log("Errores tipo duplicado:", erroresDuplicados);

        //2 comprobamos si hay comunicaciones no asignadas

        console.log("Asignacion de abstracts", newAsignaciones);
        console.log("Abstracts no asignados:", abstracts_no_asignables);

        if (
          erroresDuplicados.length > 0 ||
          abstracts_no_asignables.length > 0
        ) {
          setAsignacionesDuplicadas(erroresDuplicados);
          setNoAsignados(abstracts_no_asignables);
          setShowPostProcessErrors(true);
        }

        //comprobamos si se han producido cambios o no
        let revisores_sin_cambios = newAsignaciones.filter(
          (row10) => row10.abstracts_asignados.length === 0
        );
        if (revisores_sin_cambios.length === newAsignaciones.length) {
          setSinCambios(true);
        }

        setFinished(true);
        setSubmitting(false);
      } else {
        setShowError(true);
      }
    } else {
      setShowError(true);
    }
    console.log(revsPorAbstract);
  };

  return (
    <div>
      <Dialog
        open={props.displayModalAsignarAuto}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Asignar Comunicaciones automaticamente.
          </DialogTitle>
          <DialogContent>
            {!finished ? (
              <Grid container>
                <DialogContentText>
                  Introduzca el número de revisiones necesarias para cada
                  comunicación. El sistema tratara de asignar dichas revisiones
                  de forma equitativa entre los revisores disponibles.
                </DialogContentText>
                <Grid
                  container
                  className="flex text-center items-center justify-center"
                >
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Nº Revisiones"
                      type="number"
                      fullWidth
                      defaultValue={1}
                      inputProps={{ min: 0, step: 1 }}
                      required
                      onChange={(e) => setRevsPorAbstrct(e.target.value)}
                    />
                  </Grid>
                  {showError && (
                    <Grid
                      item
                      xs={12}
                      className="text-10 text-red-500 font-bold"
                    >
                      El valor debe estar comprendido entre 1 y 10.
                    </Grid>
                  )}
                </Grid>
                <Paper elevation={5} className="p-5 mt-10">
                  <Grid container>
                    <Grid item xs={12}>
                      Le aconsejamos que antes de asignar las comunicaciones
                      tenga en cuenta las siguientes consideraciones:
                    </Grid>
                    <Grid item xs={12} className="mt-5">
                      <ul>
                        <li>
                          Si el número de revisores es inferior al número de
                          revisiones por comunicación introducido, a alguno de
                          los revisores se le asignara la misma comunicación
                          varias veces.
                        </li>
                        <li className="mt-5">
                          Dependiendo de los filtros configurados para la
                          asignación de comunicaciones a revisores, es posible
                          que alguna comunicación no pueda ser asignada a ningún
                          revisor.
                        </li>
                        <li className="mt-5">
                          El número de revisiones para una misma comunicación
                          debe estar comprendido entre 1 y 10.
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : (
              <Grid container>
                {!showPostProcessErrors ? (
                  <Grid
                    item
                    xs={12}
                    className="text-green-500 text-center font-bold"
                  >
                    Proceso finalizado correctamente
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    className="text-red-500 text-center font-bold"
                  >
                    Proceso finalizado con errores
                  </Grid>
                )}
                {sinCambios && (
                  <Grid
                    container
                    className="flex items-center justify-center text-center"
                  >
                    <Grid
                      item
                      xs={12}
                      className="mt-10 font-bold text-16 text-center"
                    >
                      No se ha producido ningún cambio. A partir de los datos
                      introducidos no ha sido necesario realizar ninguna nueva
                      asignación.
                    </Grid>
                  </Grid>
                )}
                {asignacionesDuplicadas.length > 0 && (
                  <Grid item xs={12}>
                    <Paper elevation={5} className="mt-10">
                      <Grid container>
                        <Grid item xs={12} className="font-bold p-10">
                          Asignaciones duplicadas
                        </Grid>
                        <Grid item xs={12} className="p-5">
                          <Table size="small" aria-label="a dense table">
                            <TableHead className="bg-black">
                              <TableRow>
                                <TableCell className="text-white">
                                  Revisor
                                </TableCell>
                                <TableCell className="text-white" align="right">
                                  Asignado varias veces en
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {asignacionesDuplicadas.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                      {row.nombre}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.nums_com.map((row2, index2) => {
                                        if (index2 == row.nums_com.length - 1) {
                                          return row2;
                                        } else {
                                          return `${row2}, `;
                                        }
                                      })}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                        <Grid item xs={12} className="mt-10 p-5">
                          <Grid
                            container
                            className="flex items-center justify-center text-center"
                          >
                            <Grid item xs={12}>
                              <Icon
                                fontSize="small"
                                className="mr-2 text-blue-500"
                              >
                                info
                              </Icon>
                            </Grid>
                            <Grid item xs={12}>
                              Para solventar estos errores acceda a cada una de
                              las comunicaciones con error y, de forma manual,
                              elimine la duplicidad.
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
                {noAsignados.length > 0 && (
                  <Grid item xs={12}>
                    <Paper elevation={5} className="mt-10">
                      <Grid container>
                        <Grid item xs={12} className="font-bold p-10">
                          Comunicaciones no asignadas
                        </Grid>
                        <Grid item xs={12} className="p-5">
                          <Table size="small" aria-label="a dense table">
                            <TableHead className="bg-black">
                              <TableRow>
                                <TableCell className="text-white">
                                  Número de comunicación
                                </TableCell>
                                <TableCell className="text-white" align="right">
                                  Titulo
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {noAsignados.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                      {row.num_abstract}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.titulo}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                        <Grid item xs={12} className="mt-10 p-5">
                          <Grid
                            container
                            className="flex items-center justify-center text-center"
                          >
                            <Grid item xs={12}>
                              <Icon
                                fontSize="small"
                                className="mr-2 text-blue-500"
                              >
                                info
                              </Icon>
                            </Grid>
                            <Grid item xs={12}>
                              Para solventar este error modifique los flitros de
                              los revisores actuales o añada nuevos revisores
                              con un filtro compatible con las comunicaciones no
                              asignadas (o sin ningun filtro). Una vez
                              realizados estos cambios, tendrá disponibles
                              revisores en dichas comunicaciones para asi poder
                              asignarlos manualmente.
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          {!finished ? (
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                disabled={submitting ? true : false}
              >
                Asignar
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          )}
        </form>
      </Dialog>
    </div>
  );
}
