import React from "react";
import { Typography, Paper, IconButton } from "@material-ui/core";
import history from "../../../../@history";
import { withTranslation } from "react-i18next";

class Contador extends React.Component {
  constructor(props) {
    super(props);
    const { cifra, titulo, subtitulo, rutaImagen, link = "" } = props;
    this.cifra = cifra;
    this.titulo = titulo;
    this.subtitulo = subtitulo;
    this.rutaImagen = rutaImagen;
    this.link = link;
  }

  nombreImagen() {
    const rutaArr = this.rutaImagen.split("/");
    const nombreImg = rutaArr[rutaArr.length - 1];
    return nombreImg;
  }

  redirigir() {
    if (this.link) return;
    else history.push(this.link);
  }

  render() {
    const { t } = this.props;
    return (
      <Paper style={{ width: "100%" }}>
        <div className="flex items-center justify-between pr-4 pl-16 pt-4">
          <Typography className="text-16">{t(this.titulo)}</Typography>
          <IconButton
            onClick={this.redirigir}
            aria-label="more"
            className="bg-blue mr-5"
          >
            <img
              className="w-32"
              src={this.rutaImagen}
              alt={this.nombreImagen()}
            />
          </IconButton>
        </div>
        <div className="text-center pt-12 pb-28">
          <Typography className="text-5xl leading-none text-blue">
            {Math.trunc(this.cifra)}
          </Typography>
          <Typography className="text-16" color="textSecondary">
            {t(this.subtitulo)}
          </Typography>
        </div>
      </Paper>
    );
  }
}

export default withTranslation()(Contador);
