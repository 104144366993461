import axios from "axios";
import Global from "app/fuse-configs/Global";
// import Global from "../../../../../fuse-configs/Global";

export const GET_COUNTERS = "[ANALYTICS DASHBOARD APP] GET COUNTERS";

//obtenemos el token para enviarlo como header en todas las peticiones

export function getCounters(token) {
  axios.defaults.headers.common["token"] = token;
  const url = Global.url;
  const request = axios.get(url + "/dashboard/counters");
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COUNTERS,
        payload: response,
      })
    );
}

export const GUARDAR_CONTADOR_EMPRESA =
  "[COUNTERS] GUARDA EL CONTADOR DEL No. DE EMPRESAS";
export function guardarContadorEmpresa(contador) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_CONTADOR_EMPRESA, payload: contador });
  };
}
export const GUARDAR_CONTADOR_EVENTOS =
  "[COUNTERS] GUARDA EL CONTADOR DEL No. DE EVENTOS";
export function guardarContadorEventos(contador) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_CONTADOR_EVENTOS, payload: contador });
  };
}

export const GUARDAR_CONTADOR_COMPRAS =
  "[COUNTERS] GUARDA EL CONTADOR DEL No. DE COMPRAS";
export function guardarContadorCompras(contador) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_CONTADOR_COMPRAS, payload: contador });
  };
}

export const GUARDAR_CONTADOR_TOTAL_COMPRAS =
  "[COUNTERS] GUARDA EL CONTADOR DEL TOTAL DE LAS COMPRAS";
export function guardarContadorTotalCompras(contador) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_CONTADOR_TOTAL_COMPRAS, payload: contador });
  };
}
