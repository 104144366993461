import React from "react";
import { useSelector } from "react-redux";
import { FormularioInscripcion } from "./components";

export default function ListaFormulariosInscripcion() {
  const inscripciones = useSelector(
    ({ iframe }) => iframe.compra.inscripciones
  );
  return (
    <>
      {inscripciones.map((inscripcion, indice) => (
        <FormularioInscripcion
          inscripcion={inscripcion}
          key={indice}
          indice={indice}
        />
      ))}
    </>
  );
}
