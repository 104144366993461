import { lazy } from "react";

export const router = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/usuariosComunicaciones",
      component: lazy(() => import("./UsuariosComunicaciones")),
    },
    {
      path: "/notificaciones",
      component: lazy(() => import("./Notificaciones")),
    },
    {
      path: "/instrucciones_calificacion",
      component: lazy(() => import("./InstruccionesCalificacion")),
    },
    {
      path: "/comunicaciones",
      component: lazy(() => import("./Abstracts")),
    },
  ],
};
