export default class CampoTabla {
  constructor(nombre, link) {
    if (!nombre || link === undefined) {
      throw new Error("El nombre y el link son obligatorios");
    }

    this.nombre = nombre;
    this.link = link;
  }
}
