import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };
const baseURL = Global.url + "/pagos";

/**
 * Devuelve los datos necesarios para la generación de una factura
 * @param {*} idCompra Id compra cuya datos de devolución se quieren generar
 */
export let obtenerDatosFacturaProforma = (idEvento, idCompra) => {
  let requestURL = `${baseURL}/${idCompra}/proforma/datos/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerDatosFacturaDevolucion = (idEvento, idCompra) => {
  let requestURL = `${baseURL}/${idCompra}/devolucion/datos/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerPrefijoSufijoProformaPorIdEvento = (idEvento) => {
  let requestURL = `${baseURL}/proforma/prefijosSufijos/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerPrefijoSufijoFacturaPorIdEvento = (idEvento) => {
  let requestURL = `${baseURL}/factura/prefijosSufijos/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerPrefijoSufijoDevolucionPorIdEvento = (idEvento) => {
  let requestURL = `${baseURL}/devolucion/prefijosSufijos/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerPrefijoSufijoDocumentosPorIdEvento = (idEvento) => {
  let requestURL = `${baseURL}/prefijosSufijos/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let subirDevolucionS3 = (nombreDevolucion, devolucion) => {
  let requestURL = `${baseURL}/devolucion/${nombreDevolucion}/guardar/S3`;
  return axios.post(requestURL, querystring.stringify(devolucion), HEADERS);
};

export let guardarFacturaPrefijoSufijo = (facturaPrefijoSufijo) => {
  let requestURL = `${baseURL}/prefijosSufijos`;
  return axios.post(
    requestURL,
    querystring.stringify(facturaPrefijoSufijo),
    HEADERS
  );
};

export let obtenerIdUnicoProformaPorIdEvento = (idEvento) => {
  let requestURL = `${baseURL}/proforma/idUnico/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerIdUnicoFacturaPorIdEvento = (idEvento) => {
  let requestURL = `${baseURL}/factura/idUnico/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let obtenerDatosCompraPorIdCompraYIdEvento = (
  idCompra,
  tipo,
  idEvento
) => {
  let requestURL = `${baseURL}/${idCompra}/documento/${tipo}/evento/${idEvento}`;
  return axios.get(requestURL);
};

export let solicitarDevolucionPorIdCompra = (userToken, idCompra, idEvento) => {
  const requestURL = `${baseURL}/devolucion/redsys/${idCompra}/${idEvento}`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};
