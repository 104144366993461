import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };
const baseURL = Global.url + "/compras";

/**
 * Actualiza el método de pago de una compra
 * @param {Number} idCompra Id compra cuya metodo de pago se quiere cambiar a transferencia
 * @param {String} codigoTransferencia Codigo alfanúmerico que sirve como concepto para la transferencia
 */
export let actualizarMetodoPagoTransferenciaPorIdCompra = (
  idCompra,
  codigoTransferencia
) => {
  let requestURL = `${baseURL}/${idCompra}/metodoPago`;
  return axios.put(
    requestURL,
    querystring.stringify({ metodoPago: "transferencia", codigoTransferencia }),
    HEADERS
  );
};

export let facturaYaExistePorIdCompra = (idCompra) => {
  let requestURL = `${baseURL}/${idCompra}/factura/existe`;
  return axios.get(requestURL);
};

export const obtenerCompraPorIdCompra = (idCompra) => {
  const requestURL = `${baseURL}/${idCompra}`;
  return axios.get(requestURL);
};

export let obtenerCodigoTransferenciaUnico = async () => {
  let requestURL = `${baseURL}/transferencia/codigoUnico`;
  return axios.get(requestURL);
};
