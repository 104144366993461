import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button, makeStyles } from "@material-ui/core";
import { useButton } from "./hooks";

const styles = {
  btn_submit_buy: {
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginLeft: "5px",
    marginRight: "5px",
  },
};

const useStyles = makeStyles(styles);

const ComprarButton = ({ action }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { color } = useButton();
  const evento_data = useSelector(
    ({ iframe }) => iframe.evento.datos
  );
  const total = useSelector(
    ({ iframe }) => iframe.compra.total
  );

  return (
    <Button
      onClick={() => action(1)}
      fullWidth={true}
      className={classes.btn_submit_buy}
      color="primary"
      type="submit"
      variant="contained"
      style={{
        backgroundColor: color ? color : "#E0E0E0",
      }}
    >
      {evento_data.facturacion === 0 || total === 0 ? t("Finalizar") : t("Acceder a métodos de pago")}
    </Button>
  );
};

export default ComprarButton;
