import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Divider, Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useIframe } from "./hooks";
import * as Components from "./components";
import * as Actions from "./store/actions";
import { RegistroInscripciones } from "./model";
import * as Documentos from "./utils/documentos";
import { GeneradorProforma } from "./utils/documentos/GeneradorProforma/GeneradorProforma";
// TODO: Mover a utils
import { completeNumber } from "app/model/Helper";
import { mountSearchWithTheme } from "./utils/Styler";
import Global from "app/fuse-configs/Global";

// SERVICES
import * as eventosService from "app/services/eventosService";
import * as iframeService from "app/services/iframeService";

import { cargaIdProforma, enviarCorreoEventoOnline } from "./Iframe.logic";
import { styles } from "./Iframe.styles";

const useStyles = makeStyles(styles);

const MENSAJE_ERROR_CARGA_DATOS =
  "Ha habido un problema al guardar los datos, vuelva a intentarlo más tarde.";
const MENSAJE_ALERTA_POLITICA_PRIVACIDAD =
  "Debes aceptar la política de privacidad para poder continuar.";

const Iframe = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const idEvento = props.match.params.id;
  const idioma = props.match.params.idioma;

  const [mostrarAlertas, setMostrarAlertas] = useState(false);
  const [cargandoForms, setCargandoForms] = useState(false);
  const [formsCargados, setFormsCargados] = useState(false);

  const { iframe } = useIframe();

  // IFRAME
  const { privacidad } = iframe;
  const { pagos, compra, evento } = iframe;

  // COMPRA
  const { tiposInscripcion } = compra;
  const { inscripciones } = compra;
  const { descuentos } = compra;
  const { costesDistribucionBrutos } = compra;
  const { iva } = compra;
  const { total } = compra;
  const { comprador } = compra;

  // TIPOS INSCRIPCION
  const { cargando: tiCargando } = tiposInscripcion;

  // DATOS EVENTO
  const { cargando: eCargando, caducado } = evento;
  const { hayFacturacion, online, imagen, idioma: idiomaDocs } = evento.datos;
  const { nombre: nombreEvento, empresaOrganizadora: eo } = evento.datos;

  // EMPRESA ORGANIZADORA
  const { contenido: empresaOrganizadora } = eo;

  // IMAGEN
  const { contenido: imagenEvento } = imagen;

  // PRIVACIDAD
  const { politicaPrivacidad, notificacionesComerciales } = privacidad;

  // PAGOS
  const { prefijosSufijos } = pagos;

  // PREFIJOS Y SUFIJOS
  const { contenido } = prefijosSufijos;
  const { prefijo, sufijo } = contenido;

  // CODIGO TRANSFERENCIA
  const { codigoTransferencia: cto, cuentaTransferencia: cta } = pagos;
  const { contenido: codigoTransferencia } = cto;
  const { contenido: cuentaTransferencia } = cta;

  useEffect(() => {
    cargaDatos();
    i18n.changeLanguage(idioma);
    // eslint-disable-next-line
  }, []);

  const cargaDatos = () => {
    dispatch(Actions.solicitarCargaProductos(idEvento));
    dispatch(Actions.solicitarCargaDatosEvento(idEvento));
    dispatch(Actions.solicitarCargaDatosEmpresaOrganizadora(idEvento));
    dispatch(Actions.solicitarCargaCuentaTransferencia(idEvento));
    dispatch(Actions.solicitarCargaCodigoTransferencia(idEvento));
    dispatch(Actions.solicitarCargaPrefijoSufijoProforma(idEvento));
  };

  const { debugMode } = Global;

  const alertas = [
    {
      text: t(MENSAJE_ALERTA_POLITICA_PRIVACIDAD),
    },
  ];

  const [cargando, setCargando] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(0);

  const cambiarMensajeCargando = (msg) => {
    dispatch(Actions.cambiarMensajePantallaCarga(t(msg)));
  };

  const volverAtras = () => {
    setFormsCargados(false);
  };

  const cargarFormulariosInscripciones = async () => {
    const tiEscogidos = tiposInscripcion.array.filter(
      (ti) => ti.cantidadEntradas > 0
    );
    return await Promise.all(
      tiEscogidos.map(async (i) => {
        let { cantidadEntradas, ID } = i;
        let { data } = await eventosService.getCamposCustomEntrada(ID);
        return { camposCustom: data.result, cantidadEntradas, ID };
      })
    );
  };

  const continuarCompra = async () => {
    const noHaEscogidoInscripciones = inscripciones.length === 0;
    if (noHaEscogidoInscripciones) return;

    const noHaAceptadoPoliticaPrivacidad = !politicaPrivacidad;
    if (noHaAceptadoPoliticaPrivacidad) {
      setMostrarAlertas(true);
      setTimeout(() => {
        setMostrarAlertas(false);
      }, 3000);
      return;
    }

    setMostrarAlertas(false);
    setCargandoForms(true);
    cambiarMensajeCargando("Cargando formularios de inscripción ... ");

    // TODO: meter dentro de solicitar carga de formularios
    const formularios = await cargarFormulariosInscripciones();

    dispatch(Actions.solicitarCargaFormularios(formularios));

    setTimeout(() => {
      setCargandoForms(false);
      setFormsCargados(true);
    }, 500);
  };

  const formulariosValidos = () => {
    return comprador.datosValidos;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!formulariosValidos()) return;
      setCargando(true);
      cambiarMensajeCargando("Registrando sus inscripciones ... ");

      const codigoProforma = completeNumber(await cargaIdProforma(idEvento));
      const nombreProforma = prefijo + codigoProforma + sufijo;

      /**
       * Generamos la proforma en el idioma deseado por el
       * creador del evento. Tenemos que usar la función 't'
       * luego toca cambiar el idioma previamente para que
       * traduzca en el lenguaje deseado
       */
      const idiomaDelUsuario = i18n.language;
      cambiarIdioma(idiomaDocs);

      const datosProforma = generarDatosProforma(nombreProforma, inscripciones);

      const pdf = await new GeneradorProforma(datosProforma, t)
        .createPDF()
        .toBase64();

      /**
       * Volvemos a dejar el idioma que estaba empleando el usuario
       */
      cambiarIdioma(idiomaDelUsuario);

      // generamos datos de compra
      const registroInscripciones = new RegistroInscripciones({
        idEvento,
        inscripciones,
        total: hayFacturacion ? total : 0,
        datosFacturacion: obtenerDatosFactProf(),
        nombreProforma,
        notificacionesComerciales,
        idDescuento:
          descuentos.array.length > 0 ? descuentos.array[0].id_descuento : null,
        iva,
        idioma: i18n.language,
        codigoTransferencia,
      });

      const esGratis = registroInscripciones.total === 0;
      const compra = await guardarInscripciones(registroInscripciones);
      const idCompra = compra.ID;

      const haPulsadoContinuar = buttonClicked === 1;
      const haPulsadoPagarDespues = buttonClicked === 2;

      if (haPulsadoContinuar && esGratis) {
        realizarAccionesEventoGratuito(idCompra);
        return;
      }

      /* 
        si ha llegado aquí, es que no es gratis, ya que pagar despues
        solo aparece como opcion cuando el evento no es gratis 
      */
      eventosService.subirDocumentoS3(
        "proforma",
        nombreProforma,
        idEvento,
        pdf
      );

      if (haPulsadoContinuar) {
        const datosFact = {
          ...obtenerDatosFactProf(),
          total,
          codigoProforma,
          nombreProforma,
          idCompra,
          codigoTransferencia,
        };

        continuarAPasarelaDePago(datosFact, idEvento, idCompra);
      }

      if (haPulsadoPagarDespues) {
        eventosService.enviarCorreoPagarDespues(idCompra, idioma);
        continuarAPagarDespues();
      }
    } catch (e) {
      if (debugMode) console.error(e);
      cambiarMensajeCargando(MENSAJE_ERROR_CARGA_DATOS);
    }
  };

  const cambiarIdioma = (nuevoIdioma) => {
    localStorage.setItem("language", nuevoIdioma);
    i18n.changeLanguage(nuevoIdioma);
  };

  const generarDatosProforma = (codigoProforma, inscripciones) => {
    const organizador = obtenerOrganizadorProf();
    const eventoProf = obtenerEventoProf(organizador);
    const datosFact = obtenerDatosFactProf();
    const costes = obtenerCostesProf();

    return new Documentos.DatosProforma({
      evento: eventoProf,
      datosFacturacion: datosFact,
      codigoDocumento: codigoProforma,
      costes,
      politicaPrivacidad: evento.tpl_pie_facturas,
      cuentaTransferencia,
      inscripciones,
    });
  };

  const obtenerOrganizadorProf = () => {
    return new Documentos.Organizador({
      nombre: empresaOrganizadora.nombre,
      cif: empresaOrganizadora.cif,
      direccion: empresaOrganizadora.direccion,
      email: empresaOrganizadora.email,
      municipio: empresaOrganizadora.municipio,
      pais: empresaOrganizadora.pais,
    });
  };

  const obtenerEventoProf = (organizador = new Documentos.Organizador()) => {
    return new Documentos.Evento({
      imagen: imagenEvento,
      nombre: nombreEvento,
      organizador,
    });
  };

  const obtenerDatosFactProf = () => {
    return new Documentos.DatosFacturacion({ ...comprador });
  };

  const obtenerCostesProf = () => {
    return new Documentos.Costes({
      total,
      iva,
      distribucionBrutos: costesDistribucionBrutos,
    });
  };

  const guardarInscripciones = async (registroInscripciones) => {
    const { data } = await iframeService.registrarInscripciones(
      registroInscripciones
    );
    const { result: compra } = data;
    return compra;
  };

  const realizarAccionesEventoGratuito = (idCompra) => {
    const esOnline = online === 1;

    if (esOnline) {
      enviarCorreoEventoOnline(idCompra);
    } else {
      const enviarCorreoEventoFisico = eventosService.sendBadges;
      enviarCorreoEventoFisico(idCompra);
    }

    let pathname = `/buy/${idioma}/event/${idEvento}/free/payment/${idCompra}`;
    redirigir(pathname);
  };

  const continuarAPasarelaDePago = (datosFact, idEvento, idCompra) => {
    // guardar datos para la pasarela
    dispatch(Actions.guardaDatosFacturacion(datosFact));
    let pathname = `/buy/${idioma}/event/${idEvento}/payment/${idCompra}`;
    redirigir(pathname);
  };

  const continuarAPagarDespues = () => {
    let pathname = `/buy/${idioma}/event/payment/paymentAfter`;
    redirigir(pathname);
  };

  const redirigir = (pathname) => {
    history.push({
      pathname,
      search: mountSearchWithTheme(props.styles.theme),
    });
  };

  if (tiCargando || eCargando || cargandoForms || cargando)
    return (
      <Components.Loading
        lang={idioma}
        backgroundColor={props.styles.backgroundColor}
      />
    );

  if (caducado) return <Components.EventoFinalizado />;

  if (formsCargados)
    return (
      <div id="mainContainer">
        <div className={classes.container_inscription}>
          <Components.VolverAtrasButton action={volverAtras} />
          <form
            onSubmit={handleSubmit}
            className="container"
            autoComplete="off"
          >
            {total > 0 && hayFacturacion && <Components.FormularioComprador />}

            <Divider light />

            <Components.ListaFormulariosInscripcion />

            <Grid container spacing={1} justify="center">
              <Grid item xs={total <= 0 ? 12 : 5}>
                <Components.ComprarButton action={setButtonClicked} />
              </Grid>

              {total > 0 && hayFacturacion && (
                <Grid item xs={5}>
                  <Components.PagarDespuesButton action={setButtonClicked} />
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </div>
    );

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: props.styles.backgroundColor }}
    >
      {/* <Components.DesplegableIdiomas /> */}
      <Components.MenuTiposInscripcion />
      {hayFacturacion && (
        <React.Fragment>
          <Components.Descuentos />
          <div className={classes.footer}>
            <Box className={classes.footer_bottom}>
              <Divider />
              <Components.Desglose />
            </Box>
          </div>
        </React.Fragment>
      )}

      <div className={classes.footer}>
        <Divider />
        <Components.Consentimientos idioma={idioma} />
        {mostrarAlertas && <Components.ListaAlertas alertas={alertas} />}
        <Components.ContinuarCompraButton action={continuarCompra} />
      </div>
    </div>
  );
};

export default Iframe;
