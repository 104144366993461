import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// SERVICES
import { getMetodosPagoPorEvento } from "app/services/eventosService/entradasService";

// COMPONENTS
import Loading from "../Loading";
import { Redsys, WireTransfer } from "./components";
import { obtenerCompraPorIdCompra } from "app/services/eventosService";
import {
  cambiarMensajePantallaCarga,
  cargaCodigoTransferenciaExitosa,
} from "../../store/actions";

// WRAPPERS
import { withStyler } from "../wrappers";

const useStyles = makeStyles({
  btn_submit: {
    marginTop: "20px",
  },
  mainContainer: {
    height: "100vh",
  },
  container: {
    margin: "0 auto",
    width: "75%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
  },
  paymentMethods: {
    marginTop: "20px",
  },
  paymentIcon: {
    fontSize: "80px",
    marginTop: "15px",
  },
  paymentMethod: {
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    width: "150px",
    height: "150px",
    textAlign: "center",
    margin: "0 auto",
  },
  paymentIconText: {
    marginTop: "10px",
  },
  title: {
    marginTop: "50px",
    textAlign: "center",
    fontSize: "20px",
  },
});

const MetodosPago = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const datosFact = useSelector(({ iframe }) =>
    iframe && iframe.pagos && iframe.pagos.datosFacturacion
      ? iframe.pagos.datosFacturacion
      : null
  );

  const tabletScreenSize = useMediaQuery("(max-width:1000px)");

  const [configs, setConfigs] = useState({});
  const [UIIsLoading, setUIIsLoading] = useState(true);

  const paymentId = props.match.params.paymentId;
  const eventId = props.match.params.eventId;
  const idioma = props.match.params.idioma;

  const [wireTransferSelected, setWireTransferSelected] = useState(false);

  useEffect(() => {
    cambiarMensajeCargando("Cargando métodos de pago ...");
    if (datosFact) cargarMetodosPago();
    i18n.changeLanguage(idioma);
    // eslint-disable-next-line
  }, []);

  const cambiarMensajeCargando = (msg) => {
    dispatch(cambiarMensajePantallaCarga(t(msg)));
  };

  const cargarMetodosPago = async () => {
    const [requestMetodos, requestCompra] = await Promise.all([
      getMetodosPagoPorEvento(eventId),
      obtenerCompraPorIdCompra(paymentId),
    ]);

    const { data: compraData } = requestCompra;
    const { result } = compraData;
    const { codigo_transferencia: ct } = result;

    dispatch(cargaCodigoTransferenciaExitosa(ct));

    const { data } = requestMetodos;
    data.result.map((metodo) => mapMetodo(metodo));
    setUIIsLoading(false);
  };

  const mapMetodo = (response) => {
    const { metodo } = response;
    switch (metodo) {
      case "tpv":
        parseTPV();
        break;
      case "transferencia":
        parseTransferencia();
        break;
      default:
        break;
    }
  };

  const parseTPV = () => {
    //let [tokenizacion] = JSON.parse(config);
    //let [url, clave, code, terminal, tokenizacion] = JSON.parse(config);
    let tpvData = { eventId };
    setConfigs((oldObject) => ({ ...oldObject, Tarjeta: tpvData }));
  };

  const parseTransferencia = () => {
    //let [IBAN, banco] = JSON.parse(config);
    let wireTransferData = {
      email: datosFact.correo,
    };
    setConfigs((oldObject) => ({
      ...oldObject,
      Transferencia: wireTransferData,
    }));
  };

  if (UIIsLoading)
    return (
      <Loading
        backgroundColor={{ backgroundColor: props.styles.backgroundColor }}
      />
    );

  if (wireTransferSelected) {
    return (
      <WireTransfer
        paymentId={paymentId}
        wireTransferSelected={wireTransferSelected}
        setWireTransferSelected={setWireTransferSelected}
        idioma={idioma}
        styles={props.styles}
      />
    );
  } else {
    return (
      <div
        className={classes.mainContainer}
        style={{ backgroundColor: props.styles.backgroundColor }}
      >
        {datosFact && configs && (
          <React.Fragment>
            <Typography className={classes.title}>
              {t("Escoja un método de pago")}
            </Typography>
            <Grid container className={classes.container} spacing={2}>
              {Object.keys(configs).map((field, i) => {
                return (
                  <Grid key={i} item xs={tabletScreenSize ? 12 : 6}>
                    <Paper className={classes.paymentMethod}>
                      {field === "Tarjeta" && (
                        <Redsys
                          tpv={configs.Tarjeta}
                          idioma={idioma}
                          styles={props.styles}
                        />
                      )}
                      {field === "Transferencia" && (
                        <WireTransfer
                          paymentId={paymentId}
                          IBAN={configs.Transferencia.IBAN}
                          banco={configs.Transferencia.banco}
                          idioma={idioma}
                          concepto={configs.Transferencia.concept}
                          wireTransferSelected={wireTransferSelected}
                          setWireTransferSelected={setWireTransferSelected}
                        />
                      )}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        )}
      </div>
    );
  }
};

export default withStyler(MetodosPago);
