import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//COMPONENTS
import BoxEditorSeccion1 from "./BoxEditorSeccion1";
import BoxEditorSeccion2 from "./BoxEditorSeccion2";
import BoxEditorSeccion3 from "./BoxEditorSeccion3";
import BoxEditorSeccion4 from "./BoxEditorSeccion4";
import BoxEditorSeccionAux1 from "./BoxEditorSeccionAux1";
import BoxKeywords from "./BoxKeywords";
import BoxAportaciones from "./BoxAportaciones";
import BoxPresentador from "./BoxPresentador";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "lightgray",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function EditorsAccordion() {
  const classes = useStyles();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const { currentTipoAbstract } = abstract_data;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //nombre y descripcion de secciones de texto custom
  let {
    seccion1_nombre,
    seccion1_descripcion,
    seccion2_nombre,
    seccion2_descripcion,
    seccion3_nombre,
    seccion3_descripcion,
    seccion4_nombre,
    seccion4_descripcion,
    seccion_aux1_nombre,
    seccion_aux1_descripcion,
  } = abstract_data.evento.data.result;

  //KEYWORDS
  let keywords = 0;
  if (abstract_data.currentAbstract.keywords) {
    let aux_array = JSON.parse(abstract_data.currentAbstract.keywords);
    keywords = aux_array.length;
  }

  //APORTACIONES
  let aportaciones = 0;
  if (abstract_data.aportacionesAbstract) {
    aportaciones = abstract_data.aportacionesAbstract.length;
  }

  return (
    <Grid item xs={8}>
      {/* SECCION1 */}
      {currentTipoAbstract.seccion1_visible == 1 && (
        <ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            className={classes.root}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              {seccion1_nombre ? seccion1_nombre : "Introducción"}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {seccion1_descripcion
                ? seccion1_descripcion
                : "Introducción de la comunicación"}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BoxEditorSeccion1 />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {/* SECCION2 */}
      {currentTipoAbstract.seccion2_visible == 1 && (
        <ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary
            className={classes.root}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>
              {seccion2_nombre ? seccion2_nombre : "Cuerpo de la comunicación"}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {seccion2_descripcion
                ? seccion2_descripcion
                : "(objetivos, método, recursos)"}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BoxEditorSeccion2 />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {/* SECCION3 */}
      {currentTipoAbstract.seccion3_visible == 1 && (
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary
            className={classes.root}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>
              {seccion3_nombre ? seccion3_nombre : "Discusión"}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {seccion3_descripcion
                ? seccion3_descripcion
                : "(resultados y conclusiones)"}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BoxEditorSeccion3 />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {/* SECCION4 */}
      {currentTipoAbstract.seccion4_visible == 1 && (
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <ExpansionPanelSummary
            className={classes.root}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              {seccion4_nombre ? seccion4_nombre : "Bibliografia"}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {seccion4_descripcion
                ? seccion4_descripcion
                : "Referencias Bibliográficas"}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BoxEditorSeccion4 />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {/* SECCION AUX1 */}
      {currentTipoAbstract.seccion_aux1_visible == 1 && (
        <ExpansionPanel
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <ExpansionPanelSummary
            className={classes.root}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              {seccion_aux1_nombre ? seccion_aux1_nombre : "Anexo"}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {seccion_aux1_descripcion
                ? seccion_aux1_descripcion
                : "Sección Auxiliar"}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <BoxEditorSeccionAux1 />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {/* SECCION KEYWORDS */}
      <ExpansionPanel
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <ExpansionPanelSummary
          className={classes.root}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}>Palabras Clave</Typography>
          <Typography className={classes.secondaryHeading}>
            {keywords ? `${keywords} palabras clave` : "Sin palabras clave"}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <BoxKeywords />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {/* LISTADO DE APORTACIONES (Solo mostramos si la comunicacion es de tipo "grupal") */}
      {abstract_data.currentAbstract.grupal == 1 && (
        <>
          <ExpansionPanel
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <ExpansionPanelSummary
              className={classes.root}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography className={classes.heading}>
                Listado de aportaciones
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {aportaciones
                  ? `${aportaciones} aportaciones`
                  : "Sin aportaciones"}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <BoxAportaciones />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {abstract_data.aportacionesAbstract && (
            <ExpansionPanel
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <ExpansionPanelSummary
                className={classes.root}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.heading}>Presentador</Typography>
                <Typography className={classes.secondaryHeading}>
                  Seleccione el autor encargado de dirigir la mesa
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <BoxPresentador />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </>
      )}
    </Grid>
  );
}
