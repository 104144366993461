import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

export let LoginUsuarioAbstract = (form) => {
  let requestURL = Global.url + "/eventos/loginUsuarioAbstract";
  return axios.post(requestURL, querystring.stringify(form));
};

export let SigninUsuarioAbstract = (form) => {
  let requestURL = Global.url + "/eventos/signinUsuarioAbstract";
  return axios.post(requestURL, querystring.stringify(form));
};

export let CrearAbstract = (form) => {
  let requestURL = Global.url + "/abstracts/crearAbstract";
  return axios.post(requestURL, querystring.stringify(form));
};

export let guardarBorradorAbstract = (form) => {
  let requestURL = Global.url + "/eventos/guardarBorradorAbstract";
  return axios.post(requestURL, querystring.stringify(form));
};

export let uploadAbstractFile = (id_evento, id_abstract, form) => {
  let requestURL =
    Global.url + `/eventos/uploadAbstractFile/${id_evento}/${id_abstract}`;
  return axios.post(requestURL, form);
};

export let deleteAbstractFile = (form) => {
  let requestURL = Global.url + `/eventos/deleteAbstractFile`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let deleteAbstract = (form) => {
  let requestURL = Global.url + `/eventos/deleteAbstract`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let publishAbstract = (form) => {
  let requestURL = Global.url + `/eventos/publishAbstract`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let publishSymposium = (form) => {
  let requestURL = Global.url + `/abstracts/publishSymposium`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let updateAbstractUploadConfig = (form) => {
  let requestURL = Global.url + `/eventos/saveAbstractUploadConfig`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let createAbstractCategory = (form) => {
  let requestURL = Global.url + `/eventos/createAbstractCategory`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let deleteAbstractCategory = (form) => {
  let requestURL = Global.url + `/eventos/deleteAbstractCategory`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let crearCampoAutor = (form) => {
  let requestURL = Global.url + `/abstracts/crearCampoAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let borrarCampoAutor = (form) => {
  let requestURL = Global.url + `/abstracts/borrarCampoAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let actualizarCampoAutor = (form) => {
  let requestURL = Global.url + `/abstracts/actualizarCampoAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let crearTipoAbstract = (form) => {
  let requestURL = Global.url + `/abstracts/crearTipoAbstract`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let actualizarTipoAbstract = (form) => {
  let requestURL = Global.url + `/abstracts/actualizarTipoAbstract`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let borrarTipoAbstract = (form) => {
  let requestURL = Global.url + `/abstracts/borrarTipoAbstract`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let setAbstractKeywords = (form) => {
  let requestURL = Global.url + `/abstracts/setAbstractKeywords`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let crearAportacion = (form) => {
  let requestURL = Global.url + `/abstracts/crearAportacion`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let eliminarAportacion = (form) => {
  let requestURL = Global.url + `/abstracts/eliminarAportacion`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let crearAportacionDeOtroAutor = (form) => {
  let requestURL = Global.url + `/abstracts/crearAportacionDeOtroAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let guardarPlantillaInstrucciones = (form) => {
  let requestURL = Global.url + `/abstracts/guardarPlantillaInstrucciones`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let createAbstractCriteria = (form) => {
  let requestURL = Global.url + `/abstracts/createAbstractCriteria`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let deleteAbstractCriteria = (form) => {
  let requestURL = Global.url + `/abstracts/deleteAbstractCriteria`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let updatePresentador = (form) => {
  let requestURL = Global.url + `/abstracts/updatePresentador`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let crearCampoContactoAdministrativo = (form) => {
  let requestURL = Global.url + `/abstracts/crearCampoContactoAdministrativo`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let borrarCampoContactoAdministrativo = (form) => {
  let requestURL = Global.url + `/abstracts/borrarCampoContactoAdministrativo`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let actualizarCampoContactoAdministrativo = (form) => {
  let requestURL =
    Global.url + `/abstracts/actualizarCampoContactoAdministrativo`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let setConfigSeccionesTexto = (form) => {
  let requestURL = Global.url + `/abstracts/setConfigSeccionesTexto`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let createAutor = (form) => {
  let requestURL = Global.url + `/abstracts/createAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let updateAutor = (form) => {
  let requestURL = Global.url + `/abstracts/updateAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let deleteAutor = (form) => {
  let requestURL = Global.url + `/abstracts/deleteAutor`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let orderAutores = (form) => {
  let requestURL = Global.url + `/abstracts/orderAutores`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let solicitarCambioPassword = (form) => {
  let requestURL = Global.url + `/abstracts/solicitarCambioPassword`;
  return axios.post(requestURL, querystring.stringify(form));
};

export let resetAbstractUserPassword = (form) => {
  let requestURL = Global.url + `/abstracts/resetAbstractUserPassword`;
  return axios.post(requestURL, querystring.stringify(form));
};
