export const CARGA_FORMULARIOS_SOLICITADA =
  "[IFRAME.FORMULARIOS] SE HA SOLICITADO CARGAR LOS FORMULARIOS DE UN EVENTO";

export function solicitarCargaFormularios(inscripciones) {
  return (dispatch) =>
    dispatch({
      type: CARGA_FORMULARIOS_SOLICITADA,
      payload: inscripciones,
    });
}

export const CARGA_FORMULARIOS_CORRECTA =
  "[IFRAME.FORMULARIOS] SE HAN CARGADO LOS FORMULARIOS CORRECTAMENTE";

export function cargaFormulariosExitosa(formularios) {
  return (dispatch) =>
    dispatch({
      type: CARGA_FORMULARIOS_CORRECTA,
      payload: formularios,
    });
}

export const CARGA_FORMULARIOS_ERRONEA =
  "[IFRAME.FORMULARIOS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS FORMULARIOS";

export function cargaFormulariosErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_FORMULARIOS_ERRONEA,
      payload: error,
    });
}

export const CARGA_FORMULARIOS_FINALIZADA =
  "[IFRAME.FORMULARIOS] LA CARGA DE LOS FORMULARIOS HA FINALIZADO";

export function cargaFormulariosFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_FORMULARIOS_FINALIZADA,
    });
}
