import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  CircularProgress,
  Icon,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SERVICES
import { orderAutores } from "app/services/eventosService/abstractUploadService";

import * as Actions from "../../store/actions";

export default function ModalOrdenarAutores(props) {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const { currentAbstract, autores } = abstract_data;

  let autor_principal = autores.filter((row) => row.principal === 1);

  let coautores = autores.filter((row) => row.principal === 0);

  const getItems = (rows) =>
    rows.map((row, index) => ({
      id: `item-${index}`,
      primary: `${row.nombre} ${row.apellido1} ${row.apellido2}`,
      secondary: row.principal == 1 ? "Autor Principal" : "Co-Autor",
      id_autor: row.ID,
    }));

  const [items, setItems] = React.useState(
    coautores ? getItems(coautores) : getItems([])
  );

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log(result);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  const getListStyle = (isDraggingOver) => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newitems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newitems);
  };

  const handleCloseModal = () => {
    props.setDisplayModalOrdenarAutores(false);
    dispatch(Actions.resetCurrentAbstract());
    dispatch(Actions.resetAutores());
  };

  const handleSubmitModal = async () => {
    let form = {
      id_evento: currentAbstract.id_evento,
      id_abstract: currentAbstract.ID,
      autores: JSON.stringify(items),
    };
    console.log("Esto es lo que se envia:");
    console.log(form);
    let result = await orderAutores(form);
    if (!result.data.status) {
      console.log("hubo un error");
      setSnackbar(
        "Hubo un problema al actualizar el orden de los autores. Intentelo de nuevo más tarde.",
        "error"
      );
      return;
    } else {
      setSnackbar(
        "El orden de los autores se ha actualizado correctamente.",
        "success"
      );
    }
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "ordenar_autores",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "ordenar_autores",
      });
    }
  };

  if (!abstract_data.currentAbstract || !abstract_data.autores) {
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open={props.displayModalTablaAutores}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-10">
          <div className="flex items-center text-center justify-center w-full">
            {"Cargando"}...
          </div>
          <div className="mt-10 flex items-center text-center justify-center w-full">
            <CircularProgress />
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={props.displayModalOrdenarAutores}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <ToastContainer
        enableMultiContainer
        containerId="ordenar_autores"
        transition={Slide}
      />
      <DialogTitle id="form-dialog-title">Orden de Autores</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Arrastre los autores para establecer el orden deseado, pulse guardar y
          después cerrar.
          <p className="text-11 text-gray-500">
            (El autor principal siempre tendrá el primer lugar. No es posible
            modificar su orden.)
          </p>
        </DialogContentText>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <RootRef rootRef={provided.innerRef}>
                <List style={getListStyle(snapshot.isDraggingOver)}>
                  <ListItem className="border-1 border-gray mb-2">
                    #0
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText
                      primary={`${autor_principal[0].nombre} ${autor_principal[0].apellido1} ${autor_principal[0].apellido2}`}
                      secondary="Autor Principal"
                    />
                    <ListItemSecondaryAction>
                      <Icon>lock</Icon>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          className="border-1 border-gray mb-2"
                          ContainerComponent="li"
                          ContainerProps={{ ref: provided.innerRef }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          #{index + 1}
                          <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                          <ListItemText
                            primary={item.primary}
                            secondary={item.secondary}
                          />
                          <ListItemSecondaryAction>
                            <DragIndicatorIcon />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Cerrar
        </Button>
        <Button onClick={handleSubmitModal} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
