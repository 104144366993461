import * as Actions from "../actions";

const initialState = {
  ultimosUsuarios: {
    array: [],
    cargando: true,
    error: null,
  },
};

const usuariosReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GUARDAR_ULTIMOS_USUARIOS:
      return {
        ...state,
        ultimosUsuarios: action.payload,
      };

    case Actions.SOLICITAR_CARGA_ULTIMOS_USUARIOS:
      return {
        ...state,
        ultimosUsuarios: {
          ...state.ultimosUsuarios,
          cargando: true,
        },
      };

    case Actions.CARGA_ULTIMOS_USUARIOS_EXITOSA:
      return {
        ...state,
        ultimosUsuarios: {
          ...state.ultimosUsuarios,
          array: action.payload,
        },
      };

    case Actions.CARGA_ULTIMOS_USUARIOS_ERRONEA:
      return {
        ...state,
        ultimosUsuarios: {
          ...state.ultimosUsuarios,
          error: action.payload,
        },
      };

    case Actions.CARGA_ULTIMOS_USUARIOS_FINALIZADA:
      return {
        ...state,
        ultimosUsuarios: {
          ...state.ultimosUsuarios,
          cargando: false,
        },
      };

    default:
      return state;
  }
};

export default usuariosReducer;
