export default {
  container: {
    margin: "0 auto",
    maxWidth: "920px",
    width: "100%",
  },
  entrada: {
    padding: "25px",
    display: "flex",
    alignItems: "center",
  },
  entrada_info: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  entrada_select: {
    marginLeft: "auto !important",
  },
  entrada_precio: {
    marginTop: "10px",
    height: "24px",
    width: "60px",
  },
  entrada_texto: {
    fontSize: "12px",
    color: "grey",
    maxWidth: "98%",
  },
  inscription_name: {
    width: "100%",
  },
};
