import React, { useState, useEffect } from "react";
import { themes } from "app/components/iframe/styles";
import { resetBackgrounds } from "../../../utils/Styler";

export default function Styler(props) {
  const [styles, setStyles] = useState({
    theme: "default",
    backgroundColor: "white",
  });

  const queryParameters = props.location.search;

  useEffect(() => {
    applyTheme();
    /* 
      Refresh in every .search change because if we change
      search attribute, we want to refresh to the theme desired
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  const applyTheme = () => {
    /* Update styles if required */
    const theme = queryParameters.split("=")[1];
    if (theme === themes.LIGHT) {
      /* Forces Fuse to set its container background color to transparent */
      resetBackgrounds();
      /* Updates theme and background color that Component receives */
      setStyles(() => ({
        theme,
        backgroundColor: "transparent",
      }));
    }
  };

  const Component = props.children;
  return <Component {...props} styles={styles} />;
}
