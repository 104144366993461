import * as Actions from "../actions";

const initialState = {
  color: "",
  mensajeCarga: "",
  cargandoPorPrimeraVez: true,
  cargando: true,
  error: null,
  publico: false,
  mostrarLogin: true,
  tema: {
    colorFondo: "white",
    transparente: "no",
    colorFuente: "black"
  }
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CARGA_CONFIG_SOLICITADA:
      return { ...state, cargando: true };
    case Actions.CARGA_CONFIG_ERRONEA:
      return { ...state, error: action.payload };
    case Actions.CARGA_CONFIG_FINALIZADA:
      return { ...state, cargando: false };
    case Actions.CAMBIAR_MENSAJE_DE_CARGA:
      return { ...state, mensajeCarga: action.payload };
    case Actions.CAMBIAR_COLOR_TEMA_IFRAME:
      return { ...state, color: action.payload };
    case Actions.CAMBIAR_IFRAME_PUBLICO_PRIVADO:
      return { ...state, publico: action.payload };
    case Actions.PANTALLA_DE_CARGA_APARECE_POR_SEGUNDA_VEZ:
      return { ...state, cargandoPorPrimeraVez: false };
    case Actions.OCULTAR_LOGIN_IFRAME_PRIVADO:
      return { ...state, mostrarLogin: false };
    case Actions.CAMBIAR_TEMA_IFRAME:
      return { ...state, tema: action.payload };
    default:
      return state;
  }
};
