import moment from "moment";
import { billRGPD } from "./RGPD";

// ESTILOS
import { generarEstilosProformaOFacturaBackOffice } from "./proforma/estilosProforma";
import { generarEstilosDevolucionBackOffice } from "./devolucion/estilosDevolucion";

export const completeNumber = (number) => {
  let numberToString = number.toString();
  let length = numberToString.length;

  for (let i = length; i < 5; i++) {
    numberToString = "0" + numberToString;
  }

  return numberToString;
};

// PROFORMAS y FACTURAS BACKOFFICE ===========
export const generarDefinicionPDFProformaOFactura = (
  datosCompraDoc,
  imagen,
  titulo,
  nombreDoc,
  inscritos,
  cuentaTransferencia = "",
  t
) => {
  let { credenciales, filasTabla } = datosCompraDoc;
  let { cliente, organizador } = credenciales;
  let { tpl_pie_facturas: rgpd } = organizador;

  if (rgpd === "" || rgpd === null) {
    // Si la politica está vacía, ponemos la de por defecto
    rgpd = billRGPD(
      organizador.nombre,
      organizador.cif,
      organizador.direccion,
      organizador.email,
      t
    );
  }

  let {
    filas,
    baseImponible,
    total,
    costesDistBrutos,
  } = generarFilasTablaFacturaOProforma(filasTabla, t);

  let datosAsistentes = generarDatosAsistentes(inscritos);

  let tableFooter = generarPieTablaFacturaOProforma(
    filasTabla[0].iva_aplicado,
    baseImponible,
    total,
    costesDistBrutos,
    t
  );

  let content = [];

  if (imagen !== "") {
    content.push({
      image: `data:image/jpeg;base64,${imagen}`,
      fit: [80, 80],
      alignment: "left",
      style: "eventLogo",
    });
  }

  content = [
    ...content,
    [
      {
        stack: [
          { text: organizador.nombre, style: "subheaderTop" },
          { text: organizador.direccion, style: "subheader" },
          { text: organizador.municipio, style: "subheader" },
          { text: organizador.pais, style: "subheader" },
        ],
        style: "headerLeft",
      },
      {
        stack: [
          { text: t(titulo.toUpperCase()), style: "titleLeft" },
          { text: nombreDoc, style: "subtitleLeft" },
          { text: moment().format("DD/MM/YY"), style: "date" },
        ],
        style: "header",
      },
      {
        text: `CIF: ${organizador.cif}`,
        style: "cifEmpresa",
      },
      {
        stack: [
          t("FACTURA A:"),
          { text: cliente.nombre.toUpperCase(), style: "clientDataLine" },
          { text: cliente.cif.toUpperCase(), style: "clientDataLine" },
          { text: cliente.telefono, style: "clientDataLine" },
          { text: cliente.direccion, style: "clientDataLine" },
        ],
        style: "clientData",
      },
      {
        text: organizador.eventoNombre.replace(
          /(?!$|\n)([^\n]{80}(?!\n))/g,
          "$1\n"
        ),
        style: "eventData",
      },
      {
        style: "tableConcepts",
        table: {
          body: filas,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.3;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },

      {
        style: "tableExample",
        table: {
          body: tableFooter,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },
      { text: t("DATOS DEL/LOS INSCRITOS:"), style: "assistantSubtitle" },
      ...datosAsistentes,
      generarPieDatosTransferenciaProforma(cuentaTransferencia, t),
      {
        text: rgpd,
        style: "rgpd",
      },
    ],
  ];

  let docDefinition = {
    content,
    styles: generarEstilosProformaOFacturaBackOffice(),
  };

  return docDefinition;
};

let generarPieDatosTransferenciaProforma = (cuentaTransferencia, t) => {
  if (cuentaTransferencia === "" || !cuentaTransferencia) {
    return {};
  }

  return {
    stack: [
      t("Cuenta de pago:") + ` ${cuentaTransferencia}`,
      t("Estado de pago: Pendiente"),
    ],
    style: "datosTransferencia",
  };
};

let generarFilasTablaFacturaOProforma = (filasTabla, t) => {
  let filas = [
    [
      { text: t("CONCEPTO"), style: "concept" },
      { text: t("IMPORTE"), style: "cost" },
      { text: t("IVA"), style: "iva" },
      { text: t("Total"), style: "total" },
    ],
  ];

  // base imponible total
  let baseImponible = 0;
  // gastos de distribucion (brutos)
  let costesDistBrutos = 0;
  let ivaTotal = 0;

  let bruto;
  let neto;
  let filaActual;

  // Generamos columnas con los conceptos
  filasTabla.map((registro) => {
    neto = registro.precio_aplicado;
    bruto = parseFloat((neto / (1 + registro.iva / 100)).toFixed(2));

    filaActual = [
      {
        text: registro.concepto.toUpperCase() + " (x" + registro.cantidad + ")",
        style: "conceptCell",
      },
      { text: parseForShowing(bruto) + "€", style: "centerCell" },
      { text: registro.iva + "%", style: "centerCell" },
      { text: parseForShowing(neto) + "€", style: "centerCell" },
    ];

    filas.push(filaActual);

    baseImponible += bruto;

    if (registro.gastos_distribucion) {
      costesDistBrutos += registro.gastos_distribucion;
    }

    ivaTotal += neto - bruto;
  });

  return {
    filas,
    ivaTotal,
    baseImponible,
    total: filasTabla[0].total,
    costesDistBrutos,
  };
};

let generarPieTablaFacturaOProforma = (
  ivaTotal,
  baseImponible,
  total,
  costesDistBrutos,
  t
) => {
  let pieTabla = [
    // Columna vacía para definir tamaños de la tabla
    [
      { text: "", style: "concept" },
      { text: "", style: "cost" },
      { text: "", style: "iva" },
      { text: "", style: "total" },
    ],
    // Total neto
    [
      { text: "", style: "blank" },
      { text: "Subtotal", style: "bottomFooterText" },
      { text: `${parseForShowing(baseImponible)}€`, style: "bottomCenter" },
      { text: "", style: "blankBetween" },
    ],
  ];

  if (Number(costesDistBrutos) > 0) {
    pieTabla = [
      ...pieTabla,
      // Gastos de distribución
      [
        { text: "", style: "blank" },
        { text: t("Gastos dist."), style: "bottomFooterText" },
        {
          text: `${parseForShowing(costesDistBrutos)}€`,
          style: "bottomCenter",
        },
        { text: "", style: "blankBetween" },
      ],
    ];
  }

  pieTabla = [
    ...pieTabla,
    // IVA aplicado (en valor absoluto)
    [
      { text: "", style: "blank" },
      { text: t("IVA"), style: "bottomFooterText" },
      {
        text: `${parseForShowing(ivaTotal)}€`,
        style: "bottomCenter",
      },
      { text: "", style: "blankBetween" },
    ],
    [
      { text: "", style: "blank" },
      { text: "_________", style: "bottomLine" },
      "",
      "",
    ],
    // Total
    [
      { text: "", style: "blank" },
      { text: t("Total"), style: "bottomFooterText" },
      { text: `${parseForShowing(total)}€`, style: "bottomCenter" },
      { text: "", style: "blankBetween" },
    ],
  ];

  return pieTabla;
};

let generarDatosAsistentes = (asistentes) => {
  let pdfElement = { text: "", style: "assistantInfoLine" };
  let pdfElements = [];

  asistentes.map(({ nombre, apellido1, apellido2 }) => {
    pdfElement.text = `- ${nombre} ${apellido1} ${apellido2}`;
    pdfElements.push({ ...pdfElement });
  });

  return pdfElements;
};

// === DEVOLUCIONES BACKOFFICE ===
export const generarDefinicionPDFDevolucion = (
  rows,
  credenciales,
  rgpd,
  eventImage,
  nombreDevolucion,
  inscritos,
  nombreFactura,
  numFactura,
  t
) => {
  let { cliente, organizador } = credenciales;

  if (rgpd === "" || rgpd === null) {
    // Si la politica está vacía, ponemos la de por defecto
    rgpd = billRGPD(
      organizador.nombre,
      organizador.cif,
      organizador.direccion,
      organizador.email,
      t
    );
  }

  let {
    billRows,
    grossTotal,
    total,
    grossDistributionCosts,
  } = generarFilasTablaDevolucion(rows, t);

  let datosAsistentes = generarDatosAsistentes(inscritos);

  let tableFooter = generarPieTablaDevolucion(
    rows[0].iva_aplicado,
    grossTotal,
    total,
    grossDistributionCosts,
    t
  );

  let content = [];

  if (eventImage !== "") {
    content.push({
      image: `data:image/jpeg;base64,${eventImage}`,
      fit: [80, 80],
      alignment: "left",
      style: "eventLogo",
    });
  }

  content = [
    ...content,
    [
      {
        stack: [
          { text: organizador.nombre, style: "subheaderTop" },
          { text: organizador.direccion, style: "subheader" },
          { text: organizador.municipio, style: "subheader" },
          { text: organizador.pais, style: "subheader" },
        ],
        style: "headerLeft",
      },
      {
        stack: [
          { text: t("FACTURA RECTIFICATIVA"), style: "titleLeft" },
          {
            text: `${t("Factura Rectificada:")} ${nombreFactura}`,
            style: "subtitleLeft",
          },
          { text: nombreDevolucion, style: "subtitleLeft" },
          { text: moment().format("DD/MM/YY"), style: "date" },
        ],
        style: "header",
      },
      {
        text: `CIF: ${organizador.cif}`,
        style: "cifEmpresa",
      },
      {
        stack: [
          t("FACTURA A:"),
          { text: cliente.nombre.toUpperCase(), style: "clientDataLine" },
          { text: cliente.cif.toUpperCase(), style: "clientDataLine" },
          { text: cliente.telefono, style: "clientDataLine" },
          { text: cliente.direccion, style: "clientDataLine" },
        ],
        style: "clientData",
      },
      {
        text: organizador.eventoNombre.replace(
          /(?!$|\n)([^\n]{80}(?!\n))/g,
          "$1\n"
        ),
        style: "eventData",
      },
      {
        style: "tableConcepts",
        table: {
          body: billRows,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.3;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },

      {
        style: "tableExample",
        table: {
          body: tableFooter,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },
      { text: t("DATOS DEL/LOS INSCRITOS:"), style: "assistantSubtitle" },
      ...datosAsistentes,
      {
        text: rgpd,
        style: "rgpd",
      },
    ],
  ];

  let docDefinition = {
    content,
    styles: generarEstilosDevolucionBackOffice(),
  };

  return docDefinition;
};

let generarFilasTablaDevolucion = (rows, t) => {
  // Cabeceras de la tabla
  let billRows = [
    [
      { text: t("CONCEPTO"), style: "concept" },
      { text: t("IMPORTE"), style: "cost" },
      { text: t("IVA"), style: "iva" },
      { text: t("Total"), style: "total" },
    ],
  ];

  // base imponible total
  let grossTotal = 0;
  // gastos de distribucion (brutos)
  let grossDistributionCosts = 0;
  let ivaTotal = 0;

  let gross;
  let net;
  let row;

  // Generamos columnas con los conceptos
  rows.map((registro) => {
    net = registro.precio_aplicado;
    gross = parseFloat((net / (1 + registro.iva / 100)).toFixed(2));

    row = [
      {
        text: registro.concepto.toUpperCase() + " (x" + registro.cantidad + ")",
        style: "conceptCell",
      },
      { text: parseForShowing(gross) + "€", style: "centerCell" },
      { text: registro.iva + "%", style: "centerCell" },
      { text: "- " + parseForShowing(net) + "€", style: "centerCell" },
    ];

    billRows.push(row);

    grossTotal += gross;

    if (registro.gastos_distribucion) {
      grossDistributionCosts += registro.gastos_distribucion;
    }

    ivaTotal += net - gross;
  });

  return {
    billRows,
    ivaTotal,
    grossTotal,
    total: rows[0].total,
    grossDistributionCosts,
  };
};

let generarPieTablaDevolucion = (
  ivaAplicado,
  grossTotal,
  total,
  costesDistBrutos,
  t
) => {
  let pieTabla = [
    // Columna vacía para definir tamaños de la tabla
    [
      { text: "", style: "concept" },
      { text: "", style: "cost" },
      { text: "", style: "iva" },
      { text: "", style: "total" },
    ],
    // Total neto
    [
      { text: "", style: "blank" },
      { text: t("Subtotal"), style: "bottomFooterText" },
      { text: `- ${parseForShowing(grossTotal)}€`, style: "bottomCenter" },
      { text: "", style: "blankBetween" },
    ],
  ];

  if (Number(costesDistBrutos) > 0) {
    pieTabla = [
      ...pieTabla,
      // Gastos de distribución
      [
        { text: "", style: "blank" },
        { text: t("Gastos dist."), style: "bottomFooterText" },
        {
          text: `- ${parseForShowing(costesDistBrutos)}€`,
          style: "bottomCenter",
        },
        { text: "", style: "blankBetween" },
      ],
    ];
  }

  pieTabla = [
    ...pieTabla,
    // IVA aplicado (en valor absoluto)
    [
      { text: "", style: "blank" },
      { text: "IVA", style: "bottomFooterText" },
      {
        text: `- ${parseForShowing(ivaAplicado)}€`,
        style: "bottomCenter",
      },
      { text: "", style: "blankBetween" },
    ],
    [
      { text: "", style: "blank" },
      { text: "_________", style: "bottomLine" },
      "",
      "",
    ],
    // Total
    [
      { text: "", style: "blank" },
      { text: t("Total"), style: "bottomFooterText" },
      { text: `- ${parseForShowing(total)}€`, style: "bottomCenter" },
      { text: "", style: "blankBetween" },
    ],
  ];

  return pieTabla;

  // return [
  //   // Columna vacía para definir tamaños de la tabla
  //   [
  //     { text: "", style: "concept" },
  //     { text: "", style: "cost" },
  //     { text: "", style: "iva" },
  //     { text: "", style: "total" },
  //   ],
  //   // Total neto
  //   [
  //     { text: "", style: "blank" },
  //     { text: t("Subtotal"), style: "bottomFooterText" },
  //     { text: `- ${parseForShowing(grossTotal)}€`, style: "bottomCenter" },
  //     { text: "", style: "blankBetween" },
  //   ],
  //   // Gastos de distribución
  //   [
  //     { text: "", style: "blank" },
  //     { text: t("Gastos dist."), style: "bottomFooterText" },
  //     {
  //       text: `- ${parseForShowing(costesDistBrutos)}€`,
  //       style: "bottomCenter",
  //     },
  //     { text: "", style: "blankBetween" },
  //   ],
  //   // IVA aplicado (en valor absoluto)
  //   [
  //     { text: "", style: "blank" },
  //     { text: "IVA", style: "bottomFooterText" },
  //     {
  //       text: `- ${parseForShowing(ivaAplicado)}€`,
  //       style: "bottomCenter",
  //     },
  //     { text: "", style: "blankBetween" },
  //   ],
  //   [
  //     { text: "", style: "blank" },
  //     { text: "_________", style: "bottomLine" },
  //     "",
  //     "",
  //   ],
  //   // Total
  //   [
  //     { text: "", style: "blank" },
  //     { text: t("Total"), style: "bottomFooterText" },
  //     { text: `- ${parseForShowing(total)}€`, style: "bottomCenter" },
  //     { text: "", style: "blankBetween" },
  //   ],
  // ];
};

//MISCELANEA
export const parseForShowing = (number) => {
  let twoDecimals = [];
  let parsed = number.toLocaleString().split(",");
  // si no hay decimales lo inicializamos vacío.
  let decs = parsed[1] ? parsed[1] : [];

  // si hay 2 decimales los coge, sino, rellena a 0.
  for (let i = 0; i < 2; i++) {
    twoDecimals[i] = decs[i] ? decs[i] : "0";
  }

  // Numeros enteros, una coma y los decimales.
  return [...parsed[0], ",", twoDecimals.join("")].join("");
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = "" + d.getFullYear(),
    hours = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    seconds = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  let str_date =
    [day, month, year].join("/") + " " + [hours, minutes, seconds].join(":");
  return str_date;
};

export const formatUTCDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getUTCMonth() + 1),
    day = "" + d.getUTCDate(),
    year = "" + d.getUTCFullYear(),
    hours = "" + d.getUTCHours(),
    minutes = "" + d.getUTCMinutes(),
    seconds = "" + d.getUTCSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  // let str_date =
  //   [day, month, year].join("/") + " " + [hours, minutes, seconds].join(":");
  let str_date = [day, month, year].join("/");
  return str_date;
};
