const styles = {
  container: {
    marginTop: "5px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
};

export default styles;
