import React from "react";
import { useTranslation } from "react-i18next";
import { Button, makeStyles } from "@material-ui/core";
import { useButton } from "../hooks";

import styles from "./VerificarCIFButton.styles";

const useStyles = makeStyles(styles);

const VerificarCIFButton = ({ action }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { color } = useButton();

  return (
    <Button
      onClick={action}
      fullWidth={true}
      className={classes.verificarCifButton}
      color="primary"
      type="submit"
      variant="contained"
      style={{
        backgroundColor: color ? color : "#E0E0E0",
      }}
    >
      {t("Acceder")}
    </Button>
  );
};

export default VerificarCIFButton;
