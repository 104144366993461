import * as Actions from "../actions";

const initialState = {
  data: null,
  contadorEmpresas: 0,
  contadorEventos: 0,
  contadorCompras: 0,
  contadorTotalCompras: 0,
};

const contersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COUNTERS:
      return {
        ...state,
        data: { ...action.payload },
      };
    case Actions.GUARDAR_CONTADOR_EMPRESA:
      return {
        ...state,
        contadorEmpresas: action.payload,
      };
    case Actions.GUARDAR_CONTADOR_EVENTOS:
      return {
        ...state,
        contadorEventos: action.payload,
      };
    case Actions.GUARDAR_CONTADOR_COMPRAS:
      return {
        ...state,
        contadorCompras: action.payload,
      };
    case Actions.GUARDAR_CONTADOR_TOTAL_COMPRAS:
      return {
        ...state,
        contadorTotalCompras: action.payload,
      };
    default:
      return state;
  }
};

export default contersReducer;
