export const billRGPD = (company, cif, address, email, t) => {
  let rgpd = t("La información contenida en este documento(s), enviada desde");
  rgpd += ` ${company} `;
  rgpd += t("con C.I.F.");
  rgpd += ` ${cif} `;
  rgpd += t(
    "es confidencial/privilegiada y está destinada a ser leída sólo por la(s) persona(s) a la(s) que va dirigida. Le recordamos que sus datos han sido incorporados en el sistema de tratamiento de"
  );
  rgpd += ` ${company} `;
  rgpd += t(
    "y que siempre y cuando se cumplan los requisitos exigidos por la normativa, usted podrá ejercer sus derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición/revocación, en los términos que establece la normativa vigente en materia de protección de datos, dirigiendo su petición a la dirección postal"
  );
  rgpd += ` ${address} `;
  rgpd += t("o bien a través de correo electrónico");
  rgpd += ` ${email}. `;
  rgpd += t(
    "Si usted lee este documento y no es el destinatario señalado, el empleado o el agente responsable de entregar el mensaje al destinatario, o ha recibido esta comunicación por error, le informamos que está totalmente prohibida, y puede ser ilegal, cualquier divulgación, distribución o reproducción de esta comunicación, y le rogamos que nos lo notifique inmediatamente y nos devuelva el mensaje original a la dirección arriba mencionada."
  );
  return rgpd;

  // return `La información contenida en este documento(s), enviada desde ${company} con C.I.F. ${cif} es confidencial/privilegiada y está destinada a ser leída sólo por la(s) persona(s) a la(s) que va dirigida. Le recordamos que sus datos han sido incorporados en el sistema de tratamiento de ${company} y que siempre y cuando se cumplan los requisitos exigidos por la normativa, usted podrá ejercer sus derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición/revocación, en los términos que establece la normativa vigente en materia de protección de datos, dirigiendo su petición a la dirección postal ${address} o bien a través de correo electrónico ${email}. Si usted lee este documento y no es el destinatario señalado, el empleado o el agente responsable de entregar el mensaje al destinatario, o ha recibido esta comunicación por error, le informamos que está totalmente prohibida, y puede ser ilegal, cualquier divulgación, distribución o reproducción de esta comunicación, y le rogamos que nos lo notifique inmediatamente y nos devuelva el mensaje original a la dirección arriba mencionada.`;
};

export const proformaRGPD = (company, cif, address, email, t) => {
  let rgpd = t("La información contenida en este documento(s), enviada desde");
  rgpd += ` ${company} `;
  rgpd += t("con C.I.F.");
  rgpd += ` ${cif} `;
  rgpd += t(
    "es confidencial/privilegiada y está destinada a ser leída sólo por la(s) persona(s) a la(s) que va dirigida. Le recordamos que sus datos han sido incorporados en el sistema de tratamiento de"
  );
  rgpd += ` ${company} `;
  rgpd += t(
    "y que siempre y cuando se cumplan los requisitos exigidos por la normativa, usted podrá ejercer sus derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición/revocación, en los términos que establece la normativa vigente en materia de protección de datos, dirigiendo su petición a la dirección postal"
  );
  rgpd += ` ${address} `;
  rgpd += t("o bien a través de correo electrónico");
  rgpd += ` ${email}. `;
  rgpd += t(
    "Si usted lee este documento y no es el destinatario señalado, el empleado o el agente responsable de entregar el mensaje al destinatario, o ha recibido esta comunicación por error, le informamos que está totalmente prohibida, y puede ser ilegal, cualquier divulgación, distribución o reproducción de esta comunicación, y le rogamos que nos lo notifique inmediatamente y nos devuelva el mensaje original a la dirección arriba mencionada."
  );
  return rgpd;
  // return `La información contenida en este documento(s), enviada desde ${company} con C.I.F. ${cif} es confidencial/privilegiada y está destinada a ser leída sólo por la(s) persona(s) a la(s) que va dirigida. Le recordamos que sus datos han sido incorporados en el sistema de tratamiento de ${company} y que siempre y cuando se cumplan los requisitos exigidos por la normativa, usted podrá ejercer sus derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición/revocación, en los términos que establece la normativa vigente en materia de protección de datos, dirigiendo su petición a la dirección postal ${address} o bien a través de correo electrónico ${email}. Si usted lee este documento y no es el destinatario señalado, el empleado o el agente responsable de entregar el mensaje al destinatario, o ha recibido esta comunicación por error, le informamos que está totalmente prohibida, y puede ser ilegal, cualquier divulgación, distribución o reproducción de esta comunicación, y le rogamos que nos lo notifique inmediatamente y nos devuelva el mensaje original a la dirección arriba mencionada.`;
};
