import { takeEvery, call, put } from "redux-saga/effects";
import {
  LOGIN_SOLICITADO,
  loginExitoso,
  loginErroneo,
  loginFinalizado,
  SUPERADMIN_SE_LOGUEA,
  superAdminLogueado,
  ADMIN_SE_LOGUEA,
  adminLogueado,
  ADMIN_EMPRESA_SE_LOGUEA,
  adminEmpresaLogueado,
  USER_EMPRESA_SE_LOGUEA,
  userEmpresaLogueado,
  superRevisorLogueado,
  SUPER_REVISOR_SE_LOGUEA,
  revisorLogueado,
  REVISOR_SE_LOGUEA,
} from "../actions";
import { autenticarUsuario } from "app/services/authenticationService";
import { USER_TYPES } from "../reducers/login.reducer";
import {
  solicitarCargaContadores,
  solicitarCargaTodosLosEventos,
  solicitarCargaEventosMasActivos,
  solicitarCargaUltimosEventos,
  solicitarCargaUltimosUsuarios,
} from "app/components/dashboard/store/actions";
import { guardarToken } from "app/interceptors";
import decode from "jwt-decode";

export function* observador() {
  yield takeEvery(LOGIN_SOLICITADO, autenticar);
  yield takeEvery(SUPERADMIN_SE_LOGUEA, cargarDashboardSuperAdmin);
  yield takeEvery(ADMIN_SE_LOGUEA, cargarDashboardAdmin);
  yield takeEvery(ADMIN_EMPRESA_SE_LOGUEA, cargarDashboardAdminEmpresa);
  yield takeEvery(USER_EMPRESA_SE_LOGUEA, cargarDashboardUserEmpresa);
  yield takeEvery(SUPER_REVISOR_SE_LOGUEA, cargarDashboardSuperRevisor);
  yield takeEvery(REVISOR_SE_LOGUEA, cargarDashboardRevisor);
}

export function* autenticar(action) {
  try {
    const { email, password } = action.payload;
    const { data } = yield call(autenticarUsuario, email, password);
    const { status, result: token } = data;

    if (!status) throw new Error("Error en el status");
    if (!token) throw new Error("Login incorrecto");

    yield put(loginExitoso());
    yield call(gestionarToken, token);
  } catch (e) {
    yield put(loginErroneo(e));
  } finally {
    yield put(loginFinalizado());
  }
}

export function* gestionarToken(token) {
  localStorage.setItem("jwt_access_token", token);

  const tokenDecodificado = decode(token);
  let rol = tokenDecodificado.role;
  guardarToken(tokenDecodificado.data.token);

  switch (rol) {
    case USER_TYPES.SUPERADMIN:
      yield put(superAdminLogueado());
      break;
    case USER_TYPES.ADMIN:
      yield put(adminLogueado());
      break;
    case USER_TYPES.ADMIN_EMPRESA:
      yield put(adminEmpresaLogueado());
      break;
    case USER_TYPES.USER_EMPRESA:
      yield put(userEmpresaLogueado());
      break;
    case USER_TYPES.SUPER_REVISOR:
      yield put(superRevisorLogueado());
      break;
    case USER_TYPES.REVISOR:
      yield put(revisorLogueado());
      break;
    default:
      throw new Error("Tipo de usuario no existente");
  }
}

export function* cargarDashboardSuperAdmin() {
  yield put(solicitarCargaTodosLosEventos());
  yield put(solicitarCargaContadores());
  yield put(solicitarCargaEventosMasActivos());
  yield put(solicitarCargaUltimosEventos());
  yield put(solicitarCargaUltimosUsuarios());
}

export function* cargarDashboardAdmin(action) {}

export function* cargarDashboardAdminEmpresa(action) {}

export function* cargarDashboardUserEmpresa(action) {}

export function* cargarDashboardSuperRevisor(action) {}

export function* cargarDashboardRevisor(action) {}
