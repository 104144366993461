import React, { useRef, useLayoutEffect } from "react";
import { Select } from "@material-ui/core";

export default React.memo(function SelectWithValidation(props) {
    const inputContainerRef = useRef();
    useLayoutEffect(() => {
        if (props.native) {
            return;
        }
        const input = inputContainerRef.current.querySelector("input");
        input.setAttribute("type", "select");
        input.setAttribute("tabindex", "-1");
        input.setAttribute("aria-hidden", "");
        if (props.required) {
            input.setAttribute("required", "");
        }
        // invisible
        input.style.opacity = 0;
        // don't interfere with mouse pointer
        input.style.pointerEvents = "none";
        // align validation messages with fake input
        input.style.position = "absolute";
        input.style.bottom = 0;
        input.style.left = 0;
    }, [props.native, props.required]);
    return (
        <div ref={inputContainerRef}>
            <Select {...props} />
        </div>
    );
});
