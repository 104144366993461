import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";

//components
import { toast, ToastContainer, Slide } from "react-toastify";

//SERVICES
import {
  aceptarComunicaciones,
  mailAceptarComunicaciones,
} from "app/services/eventosService/abstractsService";

//ACTIONS
import * as Actions from "../store/actions";

export default function ModalAsignarCategoria(props) {
  const dispatch = useDispatch();
  const [notaCorte, setNotaCorte] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const user = useSelector(({ authX }) => authX.user.data);
  const { id_evento } = useSelector(({ abstracts }) => abstracts.abstracts);

  const handleClose = () => {
    props.setDisplayModalAceptar(false);
  };

  const handleChange = (e) => {
    setNotaCorte(e.target.value);
  };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "aceptar_abstracts",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "aceptar_abstracts",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "aceptar_abstracts",
      });
    }
  };

  
  const handleAceptarAbstracts = async () => {
    if (!notaCorte) {
      setSnackbar(
        "Debe indicar una nota media de corte como criterio para aceptar o rechazar comunicaciones",
        "error"
      );
      return;
    }
    if (notaCorte < 0 || notaCorte > 10) {
      setSnackbar(
        "La nota media de corte debe comprenderse entre 0 y 10",
        "error"
      );
      return;
    }
    let res = await aceptarComunicaciones(id_evento, notaCorte);
    console.log(res);
    if (!res.data.status) {
      setSnackbar(
        "Hubo un error al intentar Aceptar/Rechazar las comunicaciones. Inténtelo de nuevo más tarde",
        "error"
      );
    }
    setSubmitting(true);

    //Si todo ha ido bien informamos

    setSnackbar(
      "Comunicaciones aceptadas o rechazadas según criterio","success"
    );
    //Enviamos un mail con los candidatos aceptados y rechazados
        await mailAceptarComunicaciones(id_evento)
 
    //si hay alguno que no se ha podido modificar (no tiene nota final), tambien informamos
    if (res.data.sin_calificacion.length > 0) {
      setSnackbar(
        `${res.data.sin_calificacion.length} no se han podido aceptar o rechazar, ya que no esta especificada su nota final`,
        "error"
      );
    }
    //actualizamos la informacion de la tabla de comunicaciones
    dispatch(Actions.solicitaCargaAbstractsEvento(user.token));
  };

  //   const guardarCategoria = () => {
  //     setCategoriaAbstract(props.id_abstract, categoria).then((res) => {
  //       if (!res.data.status) {
  //         setSnackbar(
  //           "Hubo un error al asignar la categoria. Intenlo de nuevo más tarde.",
  //           "error"
  //         );
  //       } else {
  //         setSnackbar("Categoria asociada correctamente", "success");
  //         props.setOpenModalCategorias(false);
  //         dispatch(Actions.getAbstractInfo(user.token, props.id_abstract));
  //       }
  //     });
  //   };

  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId="aceptar_abstracts"
        transition={Slide}
      />
      <Dialog
        open={props.displayModalAceptar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          Aceptar/Rechazar Comunicaciones
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Establezca una nota mínima de corte para aceptar comunicaciones.
            (Las comunicaciones con notas inferiores serán rechazadas)
          </DialogContentText>
          <Grid
            container
            className="flex items-center justify-center text-center"
          >
            <Grid item xs={4}>
              <TextField
                id="filled-basic"
                label="Nota media de corte"
                variant="filled"
                fullWidth
                onChange={handleChange}
                type="number"
                inputProps={{ min: 0, max: 10, step: 0.1 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button 
            disabled={submitting ? true : false}
            onClick={handleAceptarAbstracts} 
            color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
