import { spawn } from "redux-saga/effects";

// SAGAS
import { observador as observadorConfig } from "./config.sagas";
import { observador as observadorInscripcionesPrivadas } from "./inscripcionesPrivadas.sagas";

export function* masterSaga() {
  yield spawn(observadorConfig);
  yield spawn(observadorInscripcionesPrivadas);
}
