import React, { useEffect, useState } from "react";
import { getEventInfo } from "app/services/eventosService";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";

const useStyles = makeStyles({
  container: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
    paddingBottom: "5%",
    height: "100vh",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "white !important",
  },
  title: {
    fontSize: "40px",
    textAlign: "center",
    marginBottom: "40px",
  },
});

const TermsAndConditions = (props) => {
  let eventId = props.match.params.eventId;
  let idioma = props.match.params.idioma;

  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [termsConditions, setTermsConditions] = useState("");
  const [UIIsLoading, setUIIsLoading] = useState(true);

  useEffect(() => {
    if (eventId) loadEventInfo();
    i18n.changeLanguage(idioma);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEventInfo = async () => {
    try {
      let { data } = await getEventInfo(eventId);
      let { result } = data;
      let { tpl_terminos_condiciones } = result;
      setTermsConditions(tpl_terminos_condiciones);
      setUIIsLoading(false);
      scrollToTop();
    } catch (e) {
      // error
    }
  };

  const scrollToTop = () => {
    document.getElementById("mainContainer").scrollIntoView();
  };

  if (UIIsLoading) {
    return <Loading message={t("Cargando términos y condiciones ...")} />;
  }

  return (
    <div id="mainContainer" className={classes.container}>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            {t("Términos y condiciones")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div dangerouslySetInnerHTML={{ __html: termsConditions }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;
