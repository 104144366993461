import React from "react";
import { AppBar, Typography, Grid, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HeadsetIcon from "@material-ui/icons/Headset";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "#2D323E",
  },
  submenu: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  submenu_rigth: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    marginRight: "30px",
  },
  privacyPolitics: {
    textAlign: "center",
  },
  social_media: {
    textAlign: "center",
  },
  contact: {
    display: "flex",
    flexDirection: "row",
  },
  icon_style: {
    color: "#555555",
    marginLeft: "1.25rem",
    "&:hover": {
      color: "white",
    },
  },
  logo: {
    height: "3rem",
  },
  image_container: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#2D323E",
  },
  image_display: {
    margin: "0 auto",
    width: "50%",
  },
});

function FooterLayout1(props) {
  const classes = useStyles();
  const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);
  const { t } = useTranslation();

  const openUrlTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar id="fuse-footer" className="relative z-10" color="default">
        <Grid
          container
          flex="true"
          direction="column"
          // alignItems="center"
          // justify="center"
          className={classes.footer ? classes.footer : "w-full"}
        >
          <Grid item xs={12} className="flex items-center justify-center">
            <Grid
              container
              flex="true"
              direction="row"
              alignItems="center"
              className={classes.submenu}
            >
              <Grid
                item
                sm={4}
                xs={12}
                className={
                  classes.privacyPolitics
                    ? classes.privacyPolitics
                    : "pt-2 pb-2"
                }
              >
                <Typography style={{ color: "#555555" }} variant="caption">
                  Copyright &copy; IDCongress Soft 2020.{" "}
                  {t("Todos los derechos reservados")}
                  <br />
                </Typography>
                <Typography style={{ color: "#555555" }} variant="caption">
                  <Link
                    className="no-underline text-gray-500"
                    to={"/legal/aviso_legal"}
                    target="_blank"
                  >
                    {t("Aviso Legal")}
                  </Link>{" "}
                  /
                  <Link to={"/legal/cookies"} target="_blank">
                    {t("Política de cookies")}
                  </Link>{" "}
                  /
                  <Link to={"/legal/politica_privacidad"} target="_blank">
                    {t("Política de privacidad")}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                className={
                  classes.social_media ? classes.social_media : "pt-2 pb-2"
                }
              >
                <FacebookIcon
                  className={classes.icon_style}
                  fontSize="small"
                  onClick={(e) =>
                    openUrlTab("https://es-la.facebook.com/idcongress/")
                  }
                />
                <TwitterIcon
                  className={classes.icon_style}
                  fontSize="small"
                  onClick={(e) =>
                    openUrlTab("https://twitter.com/idcongress?lang=es")
                  }
                />
                <LinkedInIcon
                  className={classes.icon_style}
                  fontSize="small"
                  onClick={(e) =>
                    openUrlTab(
                      "https://es.linkedin.com/company/idcongress?trk=public_profile_experience-item_result-card_subtitle-click"
                    )
                  }
                />
                <YouTubeIcon
                  className={classes.icon_style}
                  fontSize="small"
                  onClick={(e) =>
                    openUrlTab(
                      "https://www.youtube.com/channel/UC3i7qtAMVjEM8k2pSLX6fhg"
                    )
                  }
                />
                {/* <Grid container direction="column" justify="center">
                  <Grid item xs={12}>
                    <MailOutlineIcon
                      style={{ color: "#555555" }}
                      fontSize="small"
                    />
                    <Typography style={{ color: "#555555" }} variant="caption">
                      contacto@idcongress.es &middot;{" "}
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                className="flex items-center justify-center pt-2 pb-2"
              >
                <Grid
                  container
                  direction="column"
                  className="items-center justify-center"
                >
                  {/* <Grid item xs={12} className="mb-5">
                    <HeadsetIcon
                      style={{ color: "#555555" }}
                      fontSize="small"
                    />
                    <Typography style={{ color: "#555555" }} variant="caption">
                      {" "}
                      +34 96 321 15 37{" "}
                    </Typography>
                  </Grid> 
                  <Grid item xs={12}>
                    <img
                      className={classes.logo}
                      src="assets/images/idcongress/logo_bureau_footer_blanco.png"
                      alt="logo_vlc"
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </ThemeProvider>
  );
}

export default FooterLayout1;
