import * as Actions from "../actions";

const initialState = {
  data: {
    evento: null,
    dni: "",
    id_usuario: null,
    usuario: null,
    abstracts: null,
    campos_autor: null,
    campos_contacto: null,
    autores: null,
    currentAbstract: null,
    aportacionesAbstract: null,
    categorias: [],
    tipos_abstract: null,
    currentTipoAbstract: null,
    currentKeywords: null,
  },
};

const abstractUploadReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_EVENT_DATA_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          evento: {
            ...state.data.evento,
            ...action.payload,
          },
        },
      };
    case Actions.SET_DNI_AUTOR:
      return {
        ...state,
        data: {
          ...state.data,
          dni: action.payload,
        },
      };
    case Actions.SET_ID_USUARIO:
      return {
        ...state,
        data: {
          ...state.data,
          id_usuario: action.payload,
        },
      };
    case Actions.GET_ABSTRACTS_USUARIO:
      return {
        ...state,
        data: {
          ...state.data,
          abstracts: action.payload,
        },
      };
    case Actions.SET_AUTORES:
      return {
        ...state,
        data: {
          ...state.data,
          autores: action.payload,
        },
      };
    case Actions.SET_CURRENT_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          currentAbstract: action.payload,
        },
      };
    case Actions.GET_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          currentAbstract: action.payload.data.result,
        },
      };
    case Actions.GET_AUTORES:
      return {
        ...state,
        data: {
          ...state.data,
          autores: action.payload.data.result,
        },
      };
    case Actions.GET_CATEGORIAS_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          categorias: action.payload.data.result,
        },
      };
    case Actions.GET_CAMPOS_AUTOR_EVENTO:
      return {
        ...state,
        data: {
          ...state.data,
          campos_autor: action.payload.data.result,
        },
      };
    case Actions.GET_TIPOS_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          tipos_abstract: action.payload.data.result,
        },
      };
    case Actions.SET_CURRENT_TIPO_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          currentTipoAbstract: action.payload,
        },
      };
    case Actions.GET_APORTACIONES_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          aportacionesAbstract: action.payload.data.result,
        },
      };
    case Actions.RESET_APORTACIONES:
      return {
        ...state,
        data: {
          ...state.data,
          aportacionesAbstract: [],
        },
      };
    case Actions.CLEAN_ABSTRACT_IMAGES:
      return state;
    case Actions.RESET_AUTORES:
      return {
        ...state,
        data: {
          ...state.data,
          autores: null,
        },
      };
    case Actions.RESET_CURRENT_ABSTRACT:
      return {
        ...state,
        data: {
          ...state.data,
          currentAbstract: null,
        },
      };
    case Actions.GET_AVAILABLE_KEYWORDS:
      return {
        ...state,
        data: {
          ...state.data,
          currentKeywords: action.payload.data.result,
        },
      };
    case Actions.GET_CAMPOS_CONTACTO_ADMIN_EVENTO:
      return {
        ...state,
        data: {
          ...state.data,
          campos_contacto: action.payload.data.result,
        },
      };
    case Actions.GET_EVENT_INFO_BY_TOKEN:
      return {
        ...state,
        data: {
          ...state.data,
          evento: action.payload,
        },
      };
    case Actions.GET_USUARIO_ABSTRACTS:
      return {
        ...state,
        data: {
          ...state.data,
          usuario: action.payload.data.result,
        },
      };

    default:
      return state;
  }
};

export default abstractUploadReducer;
