import React from "react";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withFormsy } from "formsy-react";
import _ from "@lodash";

function DateTimeFormsy(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "className",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "multiline",
    "name",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
  ]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.setValue(date);
    if (props.onChange) {
      props.onChange(date);
    }
  };

  // const errorMessage = props.getErrorMessage();
  //const selectedDate = props.getValue() || '';

  // function changeValue(event) {
  //     props.setValue(event.currentTarget.selectedDate);
  //     if (props.onChange) {
  //         props.onChange(event);
  //     }
  // }

  return (
    // <TextField
    //     {...importedProps}
    //     onChange={changeValue}
    //     value={value}
    //     error={Boolean(errorMessage)}
    //     helperText={errorMessage}
    // />

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          {...importedProps}
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default React.memo(withFormsy(DateTimeFormsy));
