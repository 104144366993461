import axios from "axios";
import Global from "app/fuse-configs/Global";
// import Global from "../../../../../fuse-configs/Global";

export const GET_WIDGETS = "[ANALYTICS DASHBOARD APP] GET WIDGETS";

//obtenemos el token para enviarlo como header en todas las peticiones

export function getWidgets(token) {
  axios.defaults.headers.common["token"] = token;
  const url = Global.url;
  const request = axios.get(url + "/dashboard");
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_WIDGETS,
        payload: response,
      })
    );
}

export function getTablasTop(token) {
  axios.defaults.headers.common["token"] = token;
  const url = Global.url;
  const request = axios.get(url + "/dashboard");
  return (dispatch) =>
    request.then((response) => {
      console.log("top", response);
      dispatch({
        type: GET_WIDGETS,
        payload: response,
      });
    });
}
