import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// MODEL
import { parseForShowing } from "app/model/Helper";

const useStyles = makeStyles({
  footer_banner: {
    padding: "3px 10px",
  },
  footer_banner_text_small: {
    padding: "3px 10px",
    fontSize: "13px !important",
  },
  footer_banner_big: {
    padding: "0px 10px",
    fontSize: "20px !important",
    fontWeight: "bold",
    textAlign: "right",
  },
  footer_banner_small: {
    padding: "3px 10px",
    fontSize: "15px !important",
    fontWeight: "bold",
    textAlign: "right",
  },
  footer_banner_main: {
    padding: "10px 10px",
  },
});

const Desglose = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const iframe = useSelector(({ iframe }) => iframe);
  const { compra } = iframe;
  const { total, costesDistribucionBrutos, precioBruto } = compra;

  return (
    <React.Fragment>
      {costesDistribucionBrutos > 0 && (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.footer_banner}>
              {t("Gastos de distribución")}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.footer_banner_small}>
              {parseForShowing(costesDistribucionBrutos)} €
            </Typography>
          </Box>
        </Box>
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography className={classes.footer_banner_text_small}>
            {t("Total (bruto)")}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.footer_banner_small}>
            {parseForShowing(precioBruto)} €
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography className={classes.footer_banner_main}>
            {t("Total (incluye impuestos)")}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.footer_banner_big}>
            {parseForShowing(total)} €
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Desglose;
