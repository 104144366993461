import { call, put, takeEvery } from "redux-saga/effects";
import * as IframeAPI from "app/services/iframeService";
import * as Actions from "../actions";

export function* observador() {
  yield takeEvery(
    Actions.CARGA_INSCRITOS_PRIVADOS_SOLICITADA,
    cargaInscritosPrivados
  );
}

export function* cargaInscritosPrivados(action) {
  try {
    const idEvento = action.payload;

    if (!idEvento) throw new Error("El id del Evento es null o undefined");

    const { data } = yield call(
      IframeAPI.obtenerInscritosPrivadosPorIdEvento,
      idEvento
    );
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    yield put(Actions.cargaInscritosPrivadosExitosa(result));
  } catch (e) {
    yield put(Actions.cargaInscritosPrivadosErronea(e));
  } finally {
    yield put(Actions.cargaInscritosPrivadosFinalizada());
  }
}
