import { takeEvery, call, put } from "redux-saga/effects";

import {
  CARGA_CONTADORES_METRICAS_SOLICITADA,
  cargaContadoresExitosa,
  cargaContadoresErronea,
  cargaContadoresFinalizada,
} from "../actions";

import { obtenerContadores } from "app/services/eventosService";

export function* observador() {
  yield takeEvery(CARGA_CONTADORES_METRICAS_SOLICITADA, cargaContadores);
}

function* cargaContadores() {
  try {
    const { data } = yield call(obtenerContadores);
    const { result } = data;
    const {
      contadorTotalCompras,
      contadorCompras,
      contadorEvento,
      contadorEmpresa,
    } = result;

    yield put(
      cargaContadoresExitosa(
        contadorTotalCompras,
        contadorCompras,
        contadorEvento,
        contadorEmpresa
      )
    );
  } catch (e) {
    yield put(cargaContadoresErronea(e));
  } finally {
    yield put(cargaContadoresFinalizada());
  }
}
