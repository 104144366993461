import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, makeStyles, Button } from "@material-ui/core";
import {
  getDescuentoPorCodigo,
  obtenerCantidadDeDescuentosPorEvento,
} from "app/services/eventosService/descuentosService";
import { useDispatch, useSelector } from "react-redux";
import { guardarDescuento, recalcularPrecio } from "../store/actions";

const useStyles = makeStyles({
  root: {},
  container: {
    margin: "0 auto",
    maxWidth: "920px",
    width: "94%",
  },
  applied: {
    margin: "0 auto",
    maxWidth: "920px",
    width: "94%",
    backgroundColor: "#7CFC00",
    padding: "10px",
    borderRadius: "5px",
  },
  input: {
    margin: "1rem",
    backgroundColor: "transparent",
  },
  inputContainer: {
    borderRadius: "4px",
    border: "1px solid lightgray",
  },
  wrongDiscount: {
    width: "100%",
    backgroundColor: "#FF0000",
    padding: "10px",
    borderRadius: "5px",
  },
});

const Descuentos = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [descuentoUsado, setDescuentoUsado] = useState("");
  const [wrongDiscount, setWrongDiscount] = useState(false);
  const [mostrarSeccionDescuentos, setMostrarSeccionDescuentos] = useState(
    false
  );

  const iframe = useSelector(({ iframe }) => iframe);
  const { evento, compra, iframeConfig } = iframe;

  // COMPRA
  const { array: descuentos } = compra.descuentos;

  // CONFIG
  const { color: iframeColor } = iframeConfig;

  // EVENTO
  const { ID: idEvento } = evento.datos;

  useEffect(() => {
    // comprobar si hay descuentos para este evento
    comprobarSiHayDescuentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const comprobarSiHayDescuentos = async () => {
    try {
      const { data } = await obtenerCantidadDeDescuentosPorEvento(idEvento);
      const { result, status } = data;

      if (!status) throw new Error("Error al comprobar si hay descuentos");

      if (result.count && result.count > 0) {
        setMostrarSeccionDescuentos(true);
      }
    } catch {
      // TODO: añadir log o algo asi
    }
  };

  const compruebaSiElCodigoEsValido = async () => {
    try {
      const {
        data: { status, result: aplicacionesDescuento },
      } = await getDescuentoPorCodigo(descuentoUsado, idEvento);

      if (!status || aplicacionesDescuento.length < 1) throw new Error();

      aplicacionesDescuento.map(aplicarDescuento);

      dispatch(recalcularPrecio());

      setDescuentoUsado("");
    } catch (e) {
      setWrongDiscount(true);
    }
  };

  const aplicarDescuento = (descuento) => {
    dispatch(guardarDescuento(descuento));
  };

  if (!mostrarSeccionDescuentos) return null;

  return (
    <div className={classes.root}>
      {descuentos.length > 0 ? (
        <div className={classes.applied}>
          {t("Descuento aplicado: ") + descuentos[0].codigo}
        </div>
      ) : (
        <Grid
          container
          alignItems="center"
          className={classes.container}
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography>
              {t("Introduzca aquí su código de descuento")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.inputContainer}>
              <input
                className={classes.input}
                type="text"
                value={descuentoUsado}
                onChange={(e) => setDescuentoUsado(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Button
              className="normal-case no-underline"
              color="primary"
              onClick={(e) => compruebaSiElCodigoEsValido()}
              style={{ backgroundColor: iframeColor }}
              variant="contained"
            >
              {t("Aplicar")}
            </Button>
          </Grid>
          {wrongDiscount && (
            <Grid item xs={12}>
              <div className={classes.wrongDiscount}>
                {t("El descuento aplicado no es válido.")}
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Descuentos;
