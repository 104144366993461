import { useDispatch, useSelector } from "react-redux";
import * as eventosService from "app/services/eventosService";
import * as Model from "../model";
import * as Actions from "../store/actions";

function useIframePrivado() {
  const iframe = useSelector(({ iframe }) => iframe);
  const dispatch = useDispatch();

  const { compra } = iframe;
  const { tiposInscripcion } = compra;
  const { comprador } = compra;

  const cargarFormularioInscripcion = async () => {
    const [tIEscogido] = tiposInscripcion.array.filter(
      (tI) => tI.cantidadEntradas > 0
    );

    const { data } = await eventosService.getCamposCustomEntrada(tIEscogido.ID);
    const { result: camposCustom } = data;

    let custom = [];

    if (camposCustom && camposCustom.length > 0) {
      custom = camposCustom.map((c) => ({ ...c, value: "" }));
      // Ordena campos custom tal como el que creó el campo, especificó
      custom = custom.sort((a, b) => (a.orden > b.orden ? 1 : -1));
    }

    // Inyectamos los datos del inscrito privado
    const formularios = [
      new Model.FormularioInscripcion({
        nombre: comprador.nombre,
        primerApellido: comprador.apellido1,
        segundoApellido: comprador.apellido2,
        correo: comprador.correo,
        custom,
      }),
    ];

    // Guardamos los formularios
    dispatch(Actions.guardarFormulariosInscripcion(formularios));
  };

  return { iframe, cargarFormularioInscripcion };
}

export default useIframePrivado;
