export default class InscritoPrivado {
  constructor({
    idEvento,
    nombre,
    apellido1,
    apellido2,
    cif,
    email,
    direccion,
    telefono,
  }) {
    this.idEvento = idEvento;
    this.nombre = nombre;
    this.apellido1 = apellido1;
    this.apellido2 = apellido2;
    this.cif = cif;
    this.email = email;
    this.direccion = direccion;
    this.telefono = telefono;
  }
}
