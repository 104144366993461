export class DatosProforma {
  constructor({
    evento = new Evento(),
    datosFacturacion = new DatosFacturacion(),
    codigoDocumento,
    costes = new Costes(),
    politicaPrivacidad,
    cuentaTransferencia,
    inscripciones,
  }) {
    this.evento = evento;
    this.datosFacturacion = datosFacturacion;
    this.codigoDocumento = codigoDocumento;
    this.costes = costes;
    this.politicaPrivacidad = politicaPrivacidad;
    this.cuentaTransferencia = cuentaTransferencia;
    this.inscripciones = inscripciones;
  }
}

export class Evento {
  constructor({ imagen, nombre, organizador = new Organizador() }) {
    this.imagen = imagen;
    this.nombre = nombre;
    this.organizador = organizador;
  }
}

export class DatosFacturacion {
  constructor({ nombre, cif, telefono, direccion, correo }) {
    this.nombre = nombre;
    this.cif = cif;
    this.telefono = telefono;
    this.direccion = direccion;
    this.correo = correo;
  }
}

export class Costes {
  constructor({ total, iva, distribucionBrutos }) {
    this.total = total;
    this.iva = iva;
    this.distribucionBrutos = distribucionBrutos;
  }
}

export class Organizador {
  constructor({ nombre, cif, direccion, email, municipio, pais }) {
    this.nombre = nombre;
    this.cif = cif;
    this.direccion = direccion;
    this.email = email;
    this.municipio = municipio;
    this.pais = pais;
  }
}

export default DatosProforma;
