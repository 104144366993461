export default class Comprador {
  constructor({
    nombre = "",
    cif = "",
    direccion = "",
    correo = "",
    telefono = "",
  }) {
    this.nombre = nombre;
    this.cif = cif;
    this.direccion = direccion;
    this.correo = correo;
    this.telefono = telefono;
  }
}
