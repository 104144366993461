import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { default as styles } from "./FormularioComprador.styles";
import { useFormularioComprador } from "./hooks";

const useStyles = makeStyles(styles);

const Formulario = () => {
  const { t } = useTranslation();
  const { comprador, actualizarComprador, privado } = useFormularioComprador();
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      <h3> {t("Datos de facturación")} </h3>
      <Grid item xs={12}>
        <TextField
          fullWidth={true}
          required
          variant="outlined"
          type="text"
          label={t("Nombre")}
          value={comprador.nombre}
          name="nombre"
          onChange={(e) => actualizarComprador(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth={true}
          required
          variant="outlined"
          type="text"
          label={t("CIF")}
          value={comprador.cif}
          name="cif"
          disabled={privado}
          onChange={(e) => actualizarComprador(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth={true}
          required
          variant="outlined"
          type="text"
          label={t("Dirección, localidad, CP, País")}
          value={comprador.direccion}
          name="direccion"
          onChange={(e) => actualizarComprador(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth={true}
          required
          variant="outlined"
          type="email"
          label={t("Correo")}
          name="correo"
          value={comprador.correo}
          onChange={(e) => actualizarComprador(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label={t("Teléfono")}
          fullWidth={true}
          name="telefono"
          error={!comprador.datosValidos}
          helperText={comprador.datosValidos ? "" : comprador.tlfError}
          value={comprador.telefono}
          onChange={(e) => actualizarComprador(e.target.name, e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default Formulario;
