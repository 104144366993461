import { combineReducers } from "redux";

// REDUCERS
import abstractUploadReducer from "./abstracts.reducer";

const reducer = combineReducers({
  abstractUploadReducer,
});

export default reducer;
