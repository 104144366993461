import moment from "moment";
import { parseForShowing } from "app/model/Helper";
import { styles } from "./GeneradorFactura.styles";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class GeneradorFactura {
  constructor(infoFactura, codigoFactura, imagenEvento, infoPieFactura, t) {
    this.definicionDocumento = generarFactura(
      infoFactura,
      codigoFactura,
      imagenEvento,
      infoPieFactura,
      t
    );

    this.pdf = null;
  }

  createPDF() {
    this.pdf = pdfMake.createPdf(this.definicionDocumento);
    return this;
  }

  toBlob() {
    return new Promise((res, rej) => {
      if (!this.pdf) rej("PDF does not exist");
      this.pdf.getBlob(res);
    });
  }

  toBase64() {
    return new Promise((res, rej) => {
      if (!this.pdf) rej("PDF does not exist");
      this.pdf.getBase64(res);
    });
  }

  abrirEnNavegador() {
    this.pdf.open();
  }

  descargarPDF(nombrePDF) {
    this.pdf.download(nombrePDF, (err) => {
      console.log("ERROR AL DESCARGAR", err);
    });
  }
}

export const generarFactura = (
  infoFactura,
  codigoFactura,
  imagenEvento,
  infoPieFactura,
  t
) => {
  let { rows, credentials } = infoFactura;
  let { client, organizer } = credentials;
  let { buyers, rgpd } = infoPieFactura;

  if (rgpd === "" || rgpd === null) {
    // Si la politica está vacía, ponemos la de por defecto
    rgpd = generarTextoRGPD(
      organizer.nombre,
      organizer.cif,
      organizer.direccion,
      organizer.email,
      t
    );
  }

  let {
    billRows,
    grossTotal,
    total,
    grossDistributionCosts,
  } = generarCuerpoTabla(rows, t);

  let tableFooter = generarPieTabla(
    rows[0].iva_aplicado,
    grossTotal,
    total,
    grossDistributionCosts,
    t
  );

  let assistantInfo = generarPieConInformacionAsistentes(buyers);

  let content = [];

  if (imagenEvento) {
    content.push({
      image: `data:image/jpeg;base64,${imagenEvento}`,
      fit: [80, 80],
      alignment: "left",
      style: "eventLogo",
    });
  }

  content = [
    ...content,
    [
      {
        stack: [
          { text: organizer.nombre, style: "subheaderTop" },
          { text: organizer.direccion, style: "subheader" },
          { text: organizer.municipio, style: "subheader" },
          { text: organizer.pais, style: "subheader" },
        ],
        style: "headerLeft",
      },
      {
        stack: [
          { text: t("FACTURA"), style: "titleLeft" },
          { text: codigoFactura, style: "subtitleLeft" },
          { text: moment().format("DD/MM/YY"), style: "date" },
        ],
        style: "header",
      },
      {
        text: t("CIF:") + ` ${organizer.cif}`,
        style: "cifEmpresa",
      },
      {
        stack: [
          t("FACTURA A:"),
          { text: client.nombre.toUpperCase(), style: "clientDataLine" },
          { text: client.cif.toUpperCase(), style: "clientDataLine" },
          { text: client.telefono, style: "clientDataLine" },
          { text: client.direccion, style: "clientDataLine" },
        ],
        style: "clientData",
      },
      {
        text: organizer.eventoNombre,
        style: "eventData",
      },
      {
        style: "tableConcepts",
        table: {
          body: billRows,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.3;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },

      {
        style: "tableExample",
        table: {
          body: tableFooter,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },
      { text: t("DATOS DEL/LOS INSCRITOS:"), style: "assistantSubtitle" },
      ...assistantInfo,
      {
        text: rgpd,
        style: "rgpd",
      },
    ],
  ];

  let docDefinition = {
    content,
    styles,
  };

  return docDefinition;
};

let generarCuerpoTabla = (rows, t) => {
  // Cabeceras de la tabla
  let billRows = [
    [
      { text: t("CONCEPTO"), style: "concept" },
      { text: t("IMPORTE"), style: "cost" },
      { text: t("IVA"), style: "iva" },
      { text: t("Total"), style: "total" },
    ],
  ];

  // base imponible total
  let grossTotal = 0;
  // gastos de distribucion (brutos)
  let grossDistributionCosts = 0;

  let gross;
  let net;
  let row;

  // Generamos columnas con los conceptos
  rows.map((registro) => {
    gross = parseFloat(
      (registro.precio_aplicado / (1 + registro.iva / 100)).toFixed(2)
    );
    net = registro.precio_aplicado;

    row = [
      {
        text: registro.concepto.toUpperCase() + " (x" + registro.cantidad + ")",
        style: "conceptCell",
      },
      { text: parseForShowing(gross) + "€", style: "centerCell" },
      { text: registro.iva + "%", style: "centerCell" },
      { text: parseForShowing(net) + "€", style: "centerCell" },
    ];

    billRows.push(row);

    grossTotal += gross;

    if (registro.gastos_distribucion) {
      grossDistributionCosts += registro.gastos_distribucion;
    }

    return registro;
  });

  return {
    billRows,
    grossTotal,
    total: rows[0].total,
    grossDistributionCosts,
  };
};

let generarPieTabla = (
  ivaAplicado,
  grossTotal,
  total,
  grossDistributionCosts,
  t
) => {
  let pieTabla = [
    // Columna vacía para definir tamaños de la tabla
    [
      { text: "", style: "concept" },
      { text: "", style: "cost" },
      { text: "", style: "iva" },
      { text: "", style: "total" },
    ],
    // Total neto
    [
      { text: "", style: "blank" },
      { text: t("Subtotal"), style: "bottomFooterText" },
      {
        text: `${parseForShowing(grossTotal)}€`,
        style: "bottomCenter",
      },
      { text: "", style: "blankBetween" },
    ],
  ];

  if (Number(grossDistributionCosts) > 0) {
    // Si no hay gastos de distribución, no lo añadimos al pie de la tabla
    pieTabla = [
      ...pieTabla,
      // Gastos de distribución
      [
        { text: "", style: "blank" },
        { text: t("Gastos dist."), style: "bottomFooterText" },
        {
          text: `${parseForShowing(grossDistributionCosts)}€`,
          style: "bottomCenter",
        },
        { text: "", style: "blankBetween" },
      ],
    ];
  }

  pieTabla = [
    ...pieTabla,
    // IVA aplicado (en valor absoluto)
    [
      { text: "", style: "blank" },
      { text: t("IVA"), style: "bottomFooterText" },
      {
        text: `${parseForShowing(ivaAplicado)}€`,
        style: "bottomCenter",
      },
      { text: "", style: "blankBetween" },
    ],
    [
      { text: "", style: "blank" },
      { text: "_________", style: "bottomLine" },
      "",
      "",
    ],
    // Total
    [
      { text: "", style: "blank" },
      { text: t("Total"), style: "bottomFooterText" },
      { text: `${parseForShowing(total)}€`, style: "bottomCenter" },
      { text: "", style: "blankBetween" },
    ],
  ];

  return pieTabla;
};

let generarPieConInformacionAsistentes = (asistentes) => {
  return asistentes.map(({ nombre, apellido1, apellido2 }) => ({
    text: `- ${nombre} ${apellido1} ${apellido2}`,
    style: "assistantInfoLine",
  }));
};

const generarTextoRGPD = (empresa, cif, direccion, email, t) => {
  let rgpd = t("La información contenida en este documento(s), enviada desde");
  rgpd += ` ${empresa} `;
  rgpd += t("con C.I.F.");
  rgpd += ` ${cif} `;
  rgpd += t(
    "es confidencial/privilegiada y está destinada a ser leída sólo por la(s) persona(s) a la(s) que va dirigida. Le recordamos que sus datos han sido incorporados en el sistema de tratamiento de"
  );
  rgpd += ` ${empresa} `;
  rgpd += t(
    "y que siempre y cuando se cumplan los requisitos exigidos por la normativa, usted podrá ejercer sus derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición/revocación, en los términos que establece la normativa vigente en materia de protección de datos, dirigiendo su petición a la dirección postal"
  );
  rgpd += ` ${direccion} `;
  rgpd += t("o bien a través de correo electrónico");
  rgpd += ` ${email}. `;
  rgpd += t(
    "Si usted lee este documento y no es el destinatario señalado, el empleado o el agente responsable de entregar el mensaje al destinatario, o ha recibido esta comunicación por error, le informamos que está totalmente prohibida, y puede ser ilegal, cualquier divulgación, distribución o reproducción de esta comunicación, y le rogamos que nos lo notifique inmediatamente y nos devuelva el mensaje original a la dirección arriba mencionada."
  );
  return rgpd;
};
