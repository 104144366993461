import React from "react";
import {
  makeStyles,
  TextField,
  Grid,
  Chip,
  Typography,
  MenuItem,
} from "@material-ui/core";
import Inscripciones from "../Inscripciones";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import * as Helper from "../../../../model/Helper";
import styles from "./MenuTiposInscripcion.styles";

import { useMenuTiposInscripcion } from "./hooks";

const useStyles = makeStyles(styles);

const MenuTiposInscripcion = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const iframe = useSelector(({ iframe }) => iframe);
  const { compra, evento, iframeConfig } = iframe;
  const { tiposInscripcion } = compra;
  const { array: tiposIArray } = tiposInscripcion;

  const { color: iframeColor } = iframeConfig;
  const { hayFacturacion } = evento.datos;

  const logica = useMenuTiposInscripcion({ dispatch, iframe });

  return (
    <div className={classes.container}>
      {tiposIArray.map((tipoInscripcion, tiIndex) => (
        <React.Fragment key={tiIndex}>
          {/* OPCIONES ENTRADAS */}
          <div className={classes.entrada}>
            <div className={classes.entrada_info}>
              <Grid container spacing={8}>
                <Grid item xs={10}>
                  <Typography className={classes.inscription_name}>
                    {tipoInscripcion.nombre}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.entrada_texto}>
                {tipoInscripcion.descripcion}
              </div>
              {hayFacturacion && (
                <Chip
                  label={
                    Helper.parseForShowing(tipoInscripcion.precio_online) + " €"
                  }
                  className={classes.entrada_precio}
                  style={{
                    backgroundColor: iframeColor ? iframeColor : "#E0E0E0",
                  }}
                />
              )}
            </div>
            <TextField
              className={classes.entrada_select}
              id="outlined"
              select
              name="randomized"
              label={t("Cantidad")}
              value={
                tipoInscripcion.cantidadEntradas
                  ? tipoInscripcion.cantidadEntradas
                  : 0
              }
              onChange={(e) =>
                logica.cambiarCantidadEntradas(
                  e.target.value,
                  tiIndex,
                  tipoInscripcion.cantidadEntradas
                )
              }
              variant="outlined"
              disabled={logica.tipoInscripcionDeshabilitada(tipoInscripcion)}
            >
              {logica
                .cantidadDeInscripciones(tipoInscripcion)
                .map((cantidad, cantidadIndex) => {
                  return (
                    <MenuItem key={cantidadIndex} value={cantidad}>
                      {cantidad}
                    </MenuItem>
                  );
                })}
            </TextField>
          </div>

          {/* LISTA INSCRIPCIONES */}
          <Inscripciones id={tipoInscripcion.ID} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default MenuTiposInscripcion;
