import * as Actions from "../actions";

export const USER_TYPES = {
  SUPERADMIN: 1,
  ADMIN: 2,
  ADMIN_EMPRESA: 3,
  USER_EMPRESA: 4,
  SUPER_REVISOR: 5,
  REVISOR: 6,
};

const initialState = {
  role: "",
  cargando: false,
  error: null,
  logged: false,
  data: {
    displayName: "John Doe",
    photoURL: "assets/images/avatars/Velazquez.jpg",
    email: "johndoe@withinpixels.com",
    token: "token",
    shortcuts: ["calendar", "mail", "contacts", "todo"],
  },
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SUPERADMIN_SE_LOGUEA:
      return {
        ...state,
        role: USER_TYPES.SUPERADMIN,
        logged: true,
      };

    case Actions.ADMIN_SE_LOGUEA:
      return {
        ...state,
        role: USER_TYPES.ADMIN,
        logged: true,
      };

    case Actions.ADMIN_EMPRESA_SE_LOGUEA:
      return {
        ...state,
        role: USER_TYPES.ADMIN_EMPRESA,
        logged: true,
      };

    case Actions.USER_EMPRESA_SE_LOGUEA:
      return {
        ...state,
        role: USER_TYPES.USER_EMPRESA,
        logged: true,
      };

    case Actions.SUPER_REVISOR_SE_LOGUEA:
      return {
        ...state,
        role: USER_TYPES.SUPER_REVISOR,
        logged: true,
      };
    case Actions.REVISOR_SE_LOGUEA:
      return {
        ...state,
        role: USER_TYPES.REVISOR,
        logged: true,
      };

    case Actions.LOGIN_SOLICITADO:
      return {
        ...state,
        cargando: true,
        error: null,
      };

    case Actions.LOGIN_EXITO:
      return state;

    case Actions.LOGIN_ERRONEO:
      return { ...state, error: action.payload };

    case Actions.LOGIN_FINALIZADO:
      return { ...state, cargando: false };

    case Actions.RECUPERAR_LOGIN:
      return { ...state, role: action.payload, logged: true };

    case Actions.SET_USER_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      };
    }
    case Actions.UPDATE_USER_PHOTO: {
      let newState = state;
      newState.data.photoURL = action.payload;
      return {
        ...state,
        ...newState,
      };
    }

    case Actions.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
