import React from 'react';
import {
    makeStyles,
    Paper,
    Grid,
    Typography
} from '@material-ui/core';
import Logo from "../../login/images/logo.png";
import Background from "../../login/images/fondo_izquerda.jpg";

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        display: 'flex',
        backgroundSize: 'cover', 
    },
    container: {
        margin: '0 auto',
        width: '50%',
        maxWidth: '500px',
        padding: '20px',
        marginTop: '20px',
        textAlign: 'center',
        height: '500px',
        [theme.breakpoints.down('sm')] : {
            height: '350px',
            width: '70%'
        }
    },
    logoIdCongress: {
        backgroundImage: `url(${Logo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '18vh',
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')] : {
            height: '10vh',
        }
    },
    notFoundCode: {
        fontSize: '100px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '70px'
        }
    },
    message: {
        fontSize: '40px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '20px'
        }
    }
}))

const NotFound = props => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid className={classes.wrapper} container justify="center" alignContent="center">
                <Grid item xs={12}>
                    <Paper className={classes.container}>
                        <Grid container justify="center" spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.logoIdCongress}></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.notFoundCode}>
                                    404
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.message}>
                                    Página no encontrada
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NotFound;