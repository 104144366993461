import { spawn } from "redux-saga/effects";

// SAGAS
import { observador as observadorCompra } from "./compra.sagas";
import { observador as observadorEvento } from "./evento.sagas";
import { observador as observadorPagos } from "./pagos.sagas";
import { observador as observadorFormularios } from "./formularios.sagas";
import { observador as observadorConfig } from "./iframeConfig.sagas";

export function* masterSaga() {
  yield spawn(observadorCompra);
  yield spawn(observadorEvento);
  yield spawn(observadorConfig);
  yield spawn(observadorPagos);
  yield spawn(observadorFormularios);
}
