import * as Actions from "../actions";

const initialState = {
  id_evento: null,
  abstracts: {
    abstractsData: null,
    cargando: true,
    error: null,
  },
  criteriosEvaluacion: null,
  currentAbstract: null,
  currentRevisoresAsignados: null,
  currentRevisoresNoAsignados: null,
  currentCorrecionesAbstract: null,
  coautores: null,
  categorias: null,
  revisores: null,
  abstractConfig: null,
  tiposComunicacion: null,
  notifications_info: null,
  currentCorrecionesAbstractPrimeraFase: null,
  nombreAutores: null,
  revisoresAbstracts: null
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CARGA_ABSTRACTS_SOLICITADA:
      return {
        ...state,
        abstracts: {
          ...state.abstracts,
          cargando: true,
        },
      };

    case Actions.CARGA_ABSTRACTS_EXITO:
      console.log(action.payload);
      return {
        ...state,
        abstracts: {
          ...state.abstracts,
          abstractsData: action.payload,
        },
      };

    case Actions.CARGA_ABSTRACTS_ERROR:
      return {
        ...state,
        abstracts: {
          ...state.abstracts,
          error: action.payload,
        },
      };

    case Actions.CARGA_ABSTRACTS_FINALIZADO:
      return {
        ...state,
        abstracts: {
          ...state.abstracts,
          cargando: false,
        },
      };

    case Actions.GET_ABSTRACT_INFO:
      return {
        ...state,
        currentAbstract: action.payload.data.result,
      };

    case Actions.GET_COAUTORES_ABSTRACT:
      return {
        ...state,
        coautores: action.payload.data.result,
      };
    case Actions.RESET_CURRENT_ABSTRACT:
      return {
        ...state,
        currentAbstract: null,
        coautores: null,
      };
    case Actions.CARGA_CATEGORIAS_ABSTRACT_EVENTO:
      return {
        ...state,
        categorias: action.payload,
      };
    case Actions.CARGA_REVISORES_EVENTO:
      return {
        ...state,
        revisores: action.payload,
      };
    case Actions.LOAD_ID_EVENTO:
      return {
        ...state,
        id_evento: action.payload,
      };
    case Actions.GET_ABSTRACT_CONFIG:
      return {
        ...state,
        abstractConfig: action.payload.data.result[0],
      };
    case Actions.GET_CURRENT_REVISORES:
      return {
        ...state,
        currentRevisoresAsignados: action.payload.data.asignados,
        currentRevisoresNoAsignados: action.payload.data.noAsignados,
      };
    case Actions.RESET_REVISORES_ASIGNADOS:
      return {
        ...state,
        currentRevisoresAsignados: null,
        currentRevisoresNoAsignados: null,
      };
    case Actions.LOAD_ABSTRACT_INFO:
      return {
        ...state,
        currentAbstract: action.payload,
      };
    case Actions.LOAD_CRITERIOS_EVALUACION:
      return {
        ...state,
        criteriosEvaluacion: action.payload,
      };
    case Actions.GET_CORRECIONES_ABSTRACT:
      return {
        ...state,
        currentCorrecionesAbstract: action.payload.data.result,
      };
    case Actions.GET_CORRECIONES_ABSTRACT_PRIMERA_FASE:
      return {
        ...state,
        currentCorrecionesAbstractPrimeraFase: action.payload.data.result,
      };
    case Actions.CARGA_TIPOS_COMUNICACION_EVENTO:
      return {
        ...state,
        tiposComunicacion: action.payload,
      };
    case Actions.UPDATE_REVISORES_EVENTO:
      return {
        ...state,
        revisores: action.payload.data.result,
      };
    case Actions.GET_NOTIFICATIONS_INFO:
      return {
        ...state,
        notifications_info: action.payload.data.result,
      };
    case Actions.RESET_NOTIFICATIONS_INFO:
      return {
        ...state,
        notifications_info: null,
      };
    case Actions.GET_NOMBRES_AUTORES:
      console.log("entro");
      return {
        ...state,
        nombreAutores: action.payload.data.result[0],
      };
    case Actions.GET_REVISORES_ABSTRACTS:
      return {
        ...state,
        revisoresAbstracts: action.payload.data.result[0],
      };
    default:
      return state;
  }
};
