import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../auth/store/reducers/login.reducer";
import { ProtectedView } from "app/shared/views/auth";

const DashboardSuperAdmin = lazy(() => import("./DashboardSuperAdmin"));
const DashboardAdmin = lazy(() => import("./DashboardAdmin"));
const DashboardAdminEmpresa = lazy(() => import("./DashboardAdminEmpresa"));
const DashboardUserEmpresa = lazy(() => import("./DashboardUserEmpresa"));
const DashboardSuperRevisor = lazy(() => import("./DashboardSuperRevisor"));
const DashboardRevisor = lazy(() => import("./DashboardRevisor"));

const loaders = {
  [USER_TYPES.SUPERADMIN]: <DashboardSuperAdmin />,
  [USER_TYPES.ADMIN]: <DashboardAdmin />,
  [USER_TYPES.ADMIN_EMPRESA]: <DashboardAdminEmpresa />,
  [USER_TYPES.USER_EMPRESA]: <DashboardUserEmpresa />,
  [USER_TYPES.SUPER_REVISOR]: <DashboardSuperRevisor />,
  [USER_TYPES.REVISOR]: <DashboardRevisor />,
};

const importaDinamicamenteDashboard = (role) => {
  return <ProtectedView>{loaders[role]}</ProtectedView>;
};

const Dashboard = () => {
  const rol = useSelector(({ auth }) => auth.user.role);
  return importaDinamicamenteDashboard(rol);
};

export default Dashboard;
