import axios from "axios";
import Global from "../../fuse-configs/Global.js";
import querystring from "querystring";

export const registro = (datosRegistro) => {
  let url = Global.url + "/signin";
  return axios.post(url, querystring.stringify(datosRegistro));
};

export const registrarEmpresaYUsuario = (empresa, usuario) => {
  const url = `${Global.url}/signin/2`;
  const datosRegistro = querystring.stringify({
    usuario: JSON.stringify(usuario),
    empresa: JSON.stringify(empresa),
  });
  return axios.post(url, datosRegistro);
};
