import { takeEvery, call, put } from "redux-saga/effects";

import {
  cargaInformacionEvento,
  cargaImagenEvento as cargarImagen,
  cargaDatosEmpresaOrganizadora as cargaEmpresa,
} from "../../Iframe.logic";

import {
  CARGA_DATOS_EVENTO_SOLICITADA,
  CARGA_IMAGEN_EVENTO_SOLICITADA,
  cargaDatosEventoExitosa,
  cargaDatosEventoErronea,
  cargaDatosEventoFinalizada,
  solicitarCargaImagenEvento,
  cargaImagenEventoExitosa,
  cargaImagenEventoErronea,
  cargaImagenEventoFinalizada,
  CARGA_DATOS_EMPRESA_ORGANIZADORA_SOLICITADA,
  cargaDatosEmpresaOrganizadoraFinalizada,
  cargaDatosEmpresaOrganizadoraErronea,
  cargaDatosEmpresaOrganizadoraExitosa,
  eventoCaducado,
} from "../actions";

export function* observador() {
  yield takeEvery(CARGA_DATOS_EVENTO_SOLICITADA, cargaDatosEvento);
  yield takeEvery(CARGA_IMAGEN_EVENTO_SOLICITADA, cargaImagenEvento);
  yield takeEvery(
    CARGA_DATOS_EMPRESA_ORGANIZADORA_SOLICITADA,
    cargaDatosEmpresaOrganizadora
  );
}

function* cargaDatosEvento(action) {
  try {
    const idEvento = action.payload;
    const evento = yield call(cargaInformacionEvento, idEvento);
    if (evento) {
      yield put(
        cargaDatosEventoExitosa({
          ...evento,
          hayFacturacion: evento.facturacion === 1,
        })
      );

      yield put(
        solicitarCargaImagenEvento(idEvento, evento.img_evento_facturas)
      );
    } else {
      yield put(eventoCaducado());
    }
  } catch (e) {
    // console.log("ERROR", e);
    yield put(cargaDatosEventoErronea(e));
  } finally {
    yield put(cargaDatosEventoFinalizada());
  }
}

function* cargaImagenEvento(action) {
  try {
    const { idEvento, nombreImagen } = action.payload;
    const imagen = yield call(cargarImagen, idEvento, nombreImagen);
    yield put(cargaImagenEventoExitosa(imagen));
  } catch (e) {
    yield put(cargaImagenEventoErronea(e));
  } finally {
    yield put(cargaImagenEventoFinalizada());
  }
}

function* cargaDatosEmpresaOrganizadora(action) {
  try {
    const idEvento = action.payload;
    const empresa = yield call(cargaEmpresa, idEvento);
    yield put(cargaDatosEmpresaOrganizadoraExitosa(empresa));
  } catch (e) {
    yield put(cargaDatosEmpresaOrganizadoraErronea(e));
  } finally {
    yield put(cargaDatosEmpresaOrganizadoraFinalizada());
  }
}
