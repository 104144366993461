export const CAMBIAR_ESTADO_CONFORME_POLITICA_PRIVACIDAD =
  "[IFRAME.PRIVACIDAD] CAMBIA EL ESTADO DE LA CONFORMIDAD CON LA POLITICA DE PRIVACIDAD";

export function cambiarEstadoConformePoliticaPrivacidad(nuevoEstado) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_ESTADO_CONFORME_POLITICA_PRIVACIDAD,
      payload: nuevoEstado,
    });
}

export const CAMBIAR_ESTADO_ACEPTA_NOTIFICACIONES_COMERCIALES =
  "[IFRAME.PRIVACIDAD] CAMBIA EL ESTADO DE SI ACEPTA NOTIFICACIONES COMERCIALES O NO";

export function cambiarEstadoAceptaNotificacionesComerciales(nuevoEstado) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_ESTADO_ACEPTA_NOTIFICACIONES_COMERCIALES,
      payload: nuevoEstado,
    });
}
