import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import * as Actions from "../../store/actions";

//alerts
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SERVICES
import { createAutor } from "app/services/eventosService/abstractUploadService";

export default function ModalCrearAutor(props) {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const { campos_autor } = abstract_data;
  // let campos_json = [];
  // if (props.currentAutor.campos_json) {
  //   campos_json = JSON.parse(props.currentAutor.campos_json);
  // }
  const [nombre, setNombre] = React.useState("");
  const [apellido1, setApellido1] = React.useState("");
  const [apellido2, setApellido2] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [camposJson, setCamposJson] = React.useState([]);

  const handleClose = () => {
    props.setDisplayModalCrearAutor(false);
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "update_autores",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "update_autores",
      });
    }
  };

  const handleChangeCampoCustom = (value, rowData, rowSchema) => {
    //primero eliminamos el campo que se ha actualizado si existe
    let aux_array = Object.values(camposJson).filter((row) => row !== rowData);
    //En caso de no existir ese campo en los datos del autor, creamos la estructura de campo
    //(si no existia no se habria filtrado en el comando anterior)
    if (aux_array.length === Object.values(camposJson).length) {
      rowData = {
        id_campo: rowSchema.ID,
        nombre: rowSchema.nombre,
        type: rowSchema.tipo,
        value: value,
      };
    } else {
      //sino, solo actualizamos el valor en el esquema anterior
      //ahora actualizamos el valor dentro del objeto json auxiliar
      rowData.value = value;
    }
    //por ultimo añadimos el objeto json auxiliar al state de campos custom
    aux_array.push(rowData);
    setCamposJson(aux_array);
    console.log(aux_array);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //comprobamos que no se use el email 2 veces
    let email_usado = abstract_data.autores.filter((row) => row.email == email);
    if (email_usado.length > 0) {
      setSnackbar(
        "El email indicado ya se esta usando por otro autor de esta comunicación.",
        "error"
      );
      return;
    }
    let form = {
      id_evento: abstract_data.currentAbstract.id_evento,
      id_abstract: abstract_data.currentAbstract.ID,
      nombre,
      apellido1,
      apellido2,
      email,
      camposJson: JSON.stringify(camposJson),
    };

    let result = await createAutor(form);
    if (!result.data.status) {
      setSnackbar(
        "Hubo un problema al intentar crear el autor. Por favor, intentelo de nuevo más tarde",
        "error"
      );
      return;
    } else {
      setSnackbar("Autor creado correctamente.", "success");
      //actualizamos datos de autores en el state
      dispatch(Actions.getAutores(abstract_data.currentAbstract.ID));
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.displayModalCrearAutor}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <ToastContainer
          enableMultiContainer
          containerId="update_autores"
          transition={Slide}
        />
        <DialogTitle id="form-dialog-title">Crear un nuevo autor</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Rellene los datos necesarios y pulse el botón "Guardar".
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  autoFocus
                  variant="filled"
                  label="Nombre"
                  type="text"
                  onChange={(e) => setNombre(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="filled"
                  label="Primer Apellido"
                  type="text"
                  onChange={(e) => setApellido1(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="filled"
                  label="Segundo Apellido"
                  onChange={(e) => setApellido2(e.target.value)}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="filled"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                  fullWidth
                />
              </Grid>
              {campos_autor.map((row) => {
                let row2 = [];
                Object.values(camposJson).map((row_aux) => {
                  if (row_aux.id_campo == row.ID) {
                    row2 = row_aux;
                  }
                });
                return (
                  <Grid item xs={4}>
                    <TextField
                      variant="filled"
                      label={row.nombre}
                      type={row.tipo}
                      onChange={(e) =>
                        handleChangeCampoCustom(e.target.value, row2, row)
                      }
                      required={row.obligatorio === 1}
                      fullWidth
                    />
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
