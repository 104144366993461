import { ConfigIframe } from "app/model";

/**
 * CONFIG
 */
export const CARGA_CONFIG_SOLICITADA =
  "[IFRAME.CONFIG] SOLICITA LA CARGA DEL CONFIG DEL IFRAME";
export function solicitarCargaConfig(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONFIG_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_CONFIG_EXITOSA = "[IFRAME.CONFIG] CARGA DEL CONFIG EXITOSA";
export function cargaConfigExitosa(config = new ConfigIframe()) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONFIG_EXITOSA,
      payload: config,
    });
}

export const CARGA_CONFIG_ERRONEA =
  "[IFRAME.CONFIG] HA HABIDO UN ERROR AL CARGAR EL CONFIG";
export function cargaConfigErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONFIG_ERRONEA,
      payload: error,
    });
}

export const CARGA_CONFIG_FINALIZADA =
  "[IFRAME.CONFIG] LA CARGA DEL CONFIG HA FINALIZADO";
export function cargaConfigFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_CONFIG_FINALIZADA,
    });
}

/**
 *
 * COLOR
 *
 */
export const CAMBIO_COLOR_SOLICITADO =
  "[IFRAME.CONFIG] CAMBIO DE COLOR SOLICITADO";
export function solicitarCambioColor(idEvento, color) {
  return (dispatch) =>
    dispatch({
      type: CAMBIO_COLOR_SOLICITADO,
      payload: { idEvento, color },
    });
}

export const CAMBIAR_COLOR = "[IFRAME.CONFIG] CAMBIA EL COLOR DEL IFRAME";
export function cambiarColor(color) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_COLOR,
      payload: color,
    });
}

/**
 *
 * IDIOMAS
 *
 */
export const CAMBIO_IDIOMA_POR_DEFECTO_SOLICITADO =
  "[IFRAME.CONFIG] CAMBIO DE IDIOMA POR DEFECTO SOLICITADO";
export function cambioIdiomaPorDefectoSolicitado(idEvento, idioma) {
  return (dispatch) =>
    dispatch({
      type: CAMBIO_IDIOMA_POR_DEFECTO_SOLICITADO,
      payload: { idEvento, idioma },
    });
}

export const CAMBIAR_IDIOMA_POR_DEFECTO =
  "[IFRAME.CONFIG] CAMBIA EL IDIOMA POR DEFECTO DEL IFRAME";
export function cambiarIdioma(idioma) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_IDIOMA_POR_DEFECTO,
      payload: idioma,
    });
}

/**
 *
 *
 * INSCRIPCIONES PRIVADAS
 *
 *
 */
export const CAMBIAR_PRIVACIDAD_IFRAME =
  "[IFRAME.CONFIG] CAMBIA LA PRIVACIDAD DEL IFRAME (PÚBLICO/PRIVADO)";
export function cambiarPrivacidadIframe(nuevaPrivacidad) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_PRIVACIDAD_IFRAME,
      payload: nuevaPrivacidad,
    });
}
