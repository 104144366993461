import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";

//COMPONENTS
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  Paper,
  Button,
  IconButton,
  Icon,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SERVICES
import { CrearAbstract } from "../../../../services/eventosService/abstractUploadService";

export const ModalCrearAbstract = (props) => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const [titulo, setTitulo] = React.useState("");
  const [autores, setAutores] = React.useState([]);
  const [nombre, setNombre] = React.useState("");
  const [apellido1, setApellido1] = React.useState("");
  const [apellido2, setApellido2] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [camposCustom, setCamposCustom] = React.useState([]);
  const [principal, setPrincipal] = React.useState("");
  const [categoria, setCategoria] = React.useState(false);

  let autoresBD = abstract_data.autores ? abstract_data.autores : [];

  let id_evento = abstract_data.evento.data.result.ID;
  let categorias = abstract_data.categorias;
  let campos_autor = abstract_data.campos_autor;
  let currentTipoAbstract = abstract_data.currentTipoAbstract;

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "modal_crear_abstract",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "modal_crear_abstract",
      });
    }
  };

  const addAutor = (e) => {
    e.preventDefault();
    console.log(autores);
    //comprobamos que solo exista un autor principal
    let num_principales = autores.filter((row) => row.principal === true);
    if (num_principales.length > 0 && principal) {
      setSnackbar(
        "Solo es posible añadir un autor principal. Si quiere indicar un autor principal diferente primero debe eliminar el que ya fue añadido.",
        "error"
      );
      return;
    }
    //comprobamos que no se exceda el número máximo de autores si esta configurado
    if (
      currentTipoAbstract.max_autores > 0 &&
      autores.length === currentTipoAbstract.max_autores
    ) {
      setSnackbar(
        `El número máximo de autores para este tipo de comunicación es de ${currentTipoAbstract.max_autores}. Si desea añadir alguno más debe borrar alguno previamente.`,
        "error"
      );
      return;
    }
    //comprobamos el email ya se ha utilizado en un autor anterior
    let repetido = false;
    autores.map((row) => {
      if (row.email == email) {
        repetido = true;
      }
    });
    if (repetido) {
      setSnackbar(
        "La dirección de email ya está siendo utilizada por otro autor",
        "error"
      );
    } else {
      let newAutor = {
        nombre,
        apellido1,
        apellido2,
        email,
        campos_json: JSON.stringify(camposCustom),
        principal,
      };
      setAutores([...autores, newAutor]);
      dispatch(Actions.setAutores([...autores, newAutor]));
      //Limpiamos los datos del formulario
      setNombre("");
      setApellido1("");
      setApellido2("");
      setEmail("");
      setPrincipal(false);
      //limpiamos campos custom del state
      setCamposCustom([]);
      //y del formulario
      Array.from(document.querySelectorAll("#custom_fields_autor")).forEach(
        (input) => (input.value = "")
      );

      console.log(autores);
    }
  };

  const addCampoCustom = (campo, value) => {
    //primero eliminamos el campo anterior si ya existia
    let cc_aux = camposCustom.filter((row) => row.id_campo !== campo.ID);
    let newElement = {
      id_campo: campo.ID,
      nombre: campo.nombre,
      type: campo.tipo,
      value: value,
    };
    cc_aux.push(newElement);
    setCamposCustom(cc_aux);
    console.log(cc_aux);
  };

  const handleClose = () => {
    props.setDisplayModalIndividual(false);
    dispatch(Actions.setAutores([]));
  };

  const deleteAutor = (index) => {
    let aux_array = autores;
    aux_array.splice(index, 1);
    setAutores(aux_array);
    dispatch(Actions.setAutores(aux_array));
  };

  let habilitadoBTNCrear = false;

  const handleSubmit = () => {
    //si no ha puesto un titulo mostramos error
    if (!titulo || titulo.length === 0) {
      setSnackbar("Debe indicar un titulo para la comunicación", "error");
      return;
    }
    //si existen categorias y no ha seleccionado ninguna, mostramos error
    if (categorias.length > 0 && !categoria) {
      setSnackbar(
        "Debe seleccionar una categoria para crear el resumen.",
        "error"
      );
      return;
    }
    //comprobamos si se ha añadido al menos un autor principal
    let hay_autor_principal = autores.filter((row) => row.principal === true);
    if (hay_autor_principal.length === 0) {
      setSnackbar(
        "Debe indicar, al menos un autor principal de la comunicación",
        "error"
      );
      return;
    }
    let form = {
      id_usuario: abstract_data.id_usuario,
      id_evento: id_evento,
      titulo: titulo,
      autores: JSON.stringify(autores),
      id_tipo_abstract: props.tipoAbstract.ID,
      id_categoria: categoria ? categoria : 0,
    };
    CrearAbstract(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(res.data.message, "error");
      } else {
        setSnackbar("Se ha creado correctamente la comunicación", "success");
        //El abstract ya esta en redux asi que vamos al editor
        console.log("Este es el abstract creado!!");
        console.log(res.data.result);
        dispatch(Actions.setCurrentAbstract(res.data.result));
        dispatch(Actions.setCurrentTipoAbstract(props.tipoAbstract));
        props.handleNext();
      }
    });
  };

  const handleLoadDataContactoAdmin = async () => {
    let usuario = abstract_data.usuario;
    let campos_autor = abstract_data.campos_autor;
    //copiamos datos fijos
    setNombre(usuario.nombre);
    setApellido1(usuario.apellido1);
    setApellido2(usuario.apellido2);
    setEmail(usuario.email);
    //Borramos los datos actuales del state de camposcustom
    setCamposCustom([]);
    //comprobamos si el usuario tiene datos de campos custom
    if (usuario.campos_json) {
      let data_cc_usuario = JSON.parse(usuario.campos_json);
      console.log("DATOS CUSTOM DEL USUARIO:", data_cc_usuario);
      console.log("Campos custom para el autor:", campos_autor);
      let aux_array = [];
      campos_autor.map((row) => {
        data_cc_usuario.map((row2) => {
          if (row.nombre === row2.nombre) {
            let aux_register = {
              id_campo: row.ID,
              nombre: row.nombre,
              type: row.type,
              value: row2.value,
            };
            aux_array.push(aux_register);
          }
        });
      });
      setCamposCustom(aux_array);
    }
  };

  return (
    <Dialog
      open={props.displayModalIndividual}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
      fullWidth={true}
    >
      <ToastContainer
        enableMultiContainer
        containerId="modal_crear_abstract"
        transition={Slide}
      />
      <DialogTitle id="form-dialog-title">Crear Nueva Comunicación</DialogTitle>
      <DialogContent>
        {/* <ToastContainer /> */}
        <DialogContentText>
          Complete los siguientes datos para crear una nueva comunicación.
        </DialogContentText>
        <Grid container>
          <Grid item xs={8} className="p-5">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Título"
              type="text"
              required
              fullWidth
              onChange={(e) => setTitulo(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} className="p-5">
            {categorias.length > 0 && (
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">
                  Categoria/Tema
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  // value={age}
                  onChange={(e) => setCategoria(e.target.value)}
                >
                  {categorias.map((row) => {
                    return <MenuItem value={row.ID}>{row.nombre}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        <Grid className="mt-10" container>
          <Grid item xs={6} className="p-5">
            <Paper className="p-5 min-h-xs" elevation={3}>
              <Grid container>
                <Grid className="text-18 font-bold" item xs={6}>
                  Añadir Autores:
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-10"
                    onClick={handleLoadDataContactoAdmin}
                  >
                    <Icon>save</Icon>Cargar datos de contacto administrativo
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className="text-11 text-gray-500">
                  Complete los datos y pulse "Añadir".
                </Grid>
              </Grid>
              <form onSubmit={addAutor}>
                <Grid container spacing={2} className="mt-5">
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      id="name"
                      label="Nombre"
                      type="text"
                      required
                      fullWidth
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      id="surname1"
                      label="Primer Apellido"
                      type="text"
                      required
                      fullWidth
                      value={apellido1}
                      onChange={(e) => setApellido1(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      id="surname2"
                      label="Segundo Apellido"
                      type="text"
                      fullWidth
                      value={apellido2}
                      onChange={(e) => setApellido2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      id="name"
                      label="Email"
                      value={email}
                      type="email"
                      required
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>

                  {campos_autor &&
                    campos_autor.map((row) => {
                      let fieldvalue = "";
                      let aux_var = camposCustom.filter(
                        (row2) => row2.nombre === row.nombre
                      );
                      if (aux_var.length > 0) {
                        fieldvalue = aux_var[0].value;
                      }
                      return (
                        <Grid item xs={6}>
                          <TextField
                            id="custom_fields_autor"
                            label={row.nombre}
                            variant="outlined"
                            type={row.tipo}
                            required={row.obligatorio ? true : false}
                            value={fieldvalue}
                            onChange={(e) =>
                              addCampoCustom(row, e.target.value)
                            }
                            fullWidth
                          />
                        </Grid>
                      );
                    })}
                  <Grid item xs={6}>
                    <FormControlLabel
                      className="p-5"
                      control={
                        <Checkbox
                          checked={principal}
                          onChange={(e) => setPrincipal(!principal)}
                          name="principal"
                          color="primary"
                        />
                      }
                      label="Autor Principal"
                    />
                  </Grid>

                  <Grid
                    className="flex mt-10 align-bottom items-end justify-end p-5"
                    container
                  >
                    <Button
                      className="mt-5"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Añadir
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={6} className="p-5">
            <Paper className="p-5 min-h-xs" elevation={3}>
              <p className="text-18 font-bold">Lista de Autores:</p>
              <p className="text-11 text-gray-500">
                Podrá reordenar los autores pulsando en las opciones de la
                comunicación a reordenar desde el menú principal y pulsando
                nuevamente en “ordenar autores”.
              </p>
              {autoresBD.map((row, index) => {
                return (
                  <Paper className="m-5 p-5 ">
                    <Grid
                      container
                      className="flex items-center justify-center"
                    >
                      <Grid item xs={10}>
                        <p className="font-bold underline">
                          Autor #{index + 1}
                        </p>
                        <p>
                          <b>Nombre:</b> {row.nombre}
                        </p>
                        <p>
                          <b>Email:</b> {row.email}
                        </p>
                        <p className="text-gray-500 font-bold text-10">
                          {row.principal ? "Autor Principal" : "Co-Autor"}
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={(e) => deleteAutor(index)}
                          aria-label="delete"
                        >
                          <Icon fontSize="default">delete</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={habilitadoBTNCrear}
          onClick={handleSubmit}
          color="primary"
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCrearAbstract;
