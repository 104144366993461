import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import ListaComplementos from "./ListaComplementos";

const useStyles = makeStyles((theme) => ({
  complementos: {
    paddingLeft: "25px",
    paddingRight: "25px",
  },
}));

const Inscripciones = (props) => {
  const classes = useStyles();
  const idTipoInscripcion = props.id;

  const inscripciones = useSelector(
    ({ iframe }) => iframe.compra.inscripciones
  );

  if (inscripciones.length === 0) return <></>;

  return (
    <div className={classes.complementos}>
      {inscripciones
        .filter((inscripcion) => inscripcion.ID === idTipoInscripcion)
        .map((inscripcion, inscripcionIndex) => (
          <ListaComplementos
            key={inscripcionIndex}
            inscriptionNumber={inscripcionIndex}
            complementos={inscripcion.complementos}
          />
        ))}
    </div>
  );
};

export default Inscripciones;
