// import { DashboardConfig } from "./dashboard/DashboardConfig";
import { router as DashboardRouter } from "../../components/dashboard";
import { EventosConfig } from "./eventos/EventosConfig";
import { PerfilConfig } from "./perfil/PerfilConfig";
import { EmpresasConfig } from "./empresas/EmpresasConfig";
import { UsuariosConfig } from "./usuarios/UsuariosConfig";

export const appsConfigs = [
  DashboardRouter,
  EventosConfig,
  PerfilConfig,
  EmpresasConfig,
  UsuariosConfig,
];
