import axios from "axios";
import Global from "../../fuse-configs/Global";
import querystring from "querystring";

// const HEADERS = { headers: Global.headers };
const rutaBase = Global.url + "/dashboard";

// CONTADORES
export const obtenerContadores = () => {
  const requestURL = `${rutaBase}/contadores`;
  return axios.get(requestURL);
};

export const obtenerContadorEmpresa = () => {
  const requestURL = `${rutaBase}/contador/empresa`;
  return axios.get(requestURL);
};

export const obtenerContadorEventos = (userToken) => {
  const requestURL = `${rutaBase}/contador/evento`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};

export const obtenerContadorCompras = (userToken) => {
  const requestURL = `${rutaBase}/contador/compras`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};

export const obtenerContadorTotalCompras = (userToken) => {
  const requestURL = `${rutaBase}/contador/compras/total`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};

// EVENTOS
export const obtenerTodosLosEventos = () => {
  const requestURL = `${rutaBase}/eventos`;
  return axios.get(requestURL);
};

export const obtenerEventosMasActivos = () => {
  const requestURL = `${rutaBase}/eventos/activos`;
  return axios.get(requestURL);
};

export const obtenerUltimosEventos = () => {
  const requestURL = `${rutaBase}/eventos/ultimos`;
  return axios.get(requestURL);
};

export const obtenerTodosLosEventosPorIdEmpresa = (userToken, idEmpresa) => {
  const requestURL = `${rutaBase}/eventos/empresa/${idEmpresa}`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};

// INSCRIPCIONES
export const obtenerInscripcionesGraficaPorIdEmpresa = (
  userToken,
  idEmpresa
) => {
  const requestURL = `${rutaBase}/inscripciones/grafica/empresa/${idEmpresa}`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};

// USUARIOS
export const obtenerUltimosUsuarios = () => {
  const requestURL = `${rutaBase}/usuarios/ultimos`;
  return axios.get(requestURL);
};

//WIDGETS SUPER REVISOR
export const obtenerWidgetsSuperRevisor = (email) => {
  let form = {
    email,
  };
  const requestURL = `${rutaBase}/superrevisor`;
  return axios.post(requestURL, querystring.stringify(form));
};

//WIDGETS REVISOR
export const obtenerWidgetsRevisor = (email) => {
  let form = {
    email,
  };
  const requestURL = `${rutaBase}/revisor`;
  return axios.post(requestURL, querystring.stringify(form));
};
