import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import CreditCardSharpIcon from "@material-ui/icons/CreditCardSharp";
import { useTranslation } from "react-i18next";
import { DatosRedsysParaTPV } from "app/components/iframe/model";
import { obtenerDatosCodificadosTPVRedsys } from "app/services/iframeService";
import Global from "app/fuse-configs/Global";

const useStyles = makeStyles({
  btn_submit: {
    marginTop: "20px",
  },
  container: {
    margin: "0 auto",
    width: "50%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
  },
  paymentMethods: {
    marginTop: "20px",
  },
  paymentIcon: {
    fontSize: "80px",
    marginTop: "15px",
  },
  paymentIconText: {
    marginTop: "10px",
  },
});

const PagoRedsys = ({ tpv, idioma, styles }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { debugMode } = Global;

  const datosFacturacion = useSelector(
    ({ iframe }) => iframe.pagos.datosFacturacion
  );

  const { total: importe, idCompra } = datosFacturacion;

  const [redsysData, setRedsysData] = useState({});

  useEffect(() => {
    obtenerDatosTPVRedsysCodificados();
    // eslint-disable-next-line
  }, []);

  const obtenerDatosTPVRedsysCodificados = async () => {
    let codigoComercio = tpv.code;
    let terminal = tpv.terminal;
    let claveSecreta = tpv.clave;
    let idEvento = tpv.eventId;

    try {
      const datosRedsys = new DatosRedsysParaTPV({
        idioma,
        importe,
        idCompra,
        codigoComercio,
        terminal,
        claveSecreta,
      });
      const { data } = await obtenerDatosCodificadosTPVRedsys(
        idEvento,
        datosRedsys,
        styles.theme
      );
      const { result: datosCodificados, status } = data;

      if (!status) throw new Error("Error en el status");

      setRedsysData((oldParameters) => ({
        ...oldParameters,
        ...datosCodificados,
      }));
    } catch (e) {
      if (debugMode) {
        console.log("ERROR", e);
      }
    }
  };

  const redirectToRedsys = () => {
    document.getElementById("fghrtredfd").submit();
  };

  return (
    <div>
      {Object.keys(redsysData).length > 0 && (
        <div>
          <form
            action={redsysData.url}
            method="POST"
            name="from"
            id="fghrtredfd"
          >
            <input
              type="hidden"
              name="Ds_SignatureVersion"
              value={redsysData.signatureVersion}
            />
            <input
              type="hidden"
              name="Ds_MerchantParameters"
              value={redsysData.merchantParameters}
            />
            <input
              type="hidden"
              name="Ds_Signature"
              value={redsysData.signature}
            />
          </form>
          <CreditCardSharpIcon
            className={classes.paymentIcon}
            onClick={redirectToRedsys}
          />
          <Typography className={classes.paymentIconText}>
            {t("Tarjeta de crédito")}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PagoRedsys;
