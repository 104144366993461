import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Checkbox, makeStyles, Grid } from "@material-ui/core";
import {
  cambiarEstadoConformePoliticaPrivacidad,
  cambiarEstadoAceptaNotificacionesComerciales,
} from "../store/actions";

const useStyles = makeStyles({
  checkboxes: {
    margin: "10px 10px",
  },
  privacyPolitics: {
    marginTop: "10px",
  },
  commercialNotifications: {
    marginTop: "10px",
  },
  checkboxesText: {
    fontSize: "13px",
  },
});

const Consentimientos = ({ idioma }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const iframe = useSelector(({ iframe }) => iframe);
  const { ID: idEvento } = iframe.evento.datos;
  const { politicaPrivacidad, notificacionesComerciales } = iframe.privacidad;
  const iframeConfig = iframe.evento.datos.iframe_config
    ? JSON.parse(iframe.evento.datos.iframe_config)
    : null;

  let txt_custom_privacidad = false;
  let txt_custom_notificaciones = false;
  let CheckboxNotifActivado = "Si";

  //comprobamos si los textos personalizados existen o no para que no de error
  if (iframeConfig) {
    if (iframeConfig.TXT_PrivacidadYCondiciones) {
      txt_custom_privacidad = iframeConfig.TXT_PrivacidadYCondiciones;
    }

    if (iframeConfig.TXT_NotificacionesComerciales) {
      txt_custom_notificaciones = iframeConfig.TXT_NotificacionesComerciales;
    }
    if (iframeConfig.Checkbox_notif_activado) {
      CheckboxNotifActivado = iframeConfig.Checkbox_notif_activado;
    }
  }

  // const {
  //   consentimientos: { politicaPrivacidad, notificacionesComerciales },
  // } = iframe;

  // const {
  //   evento: { id: idEvento },
  // } = iframe;

  const actualizarPoliticaPrivacidad = (nuevoEstado) => {
    dispatch(cambiarEstadoConformePoliticaPrivacidad(nuevoEstado));
  };

  const actualizarNotificacionesComerciales = (nuevoEstado) => {
    dispatch(cambiarEstadoAceptaNotificacionesComerciales(nuevoEstado));
  };

  return (
    <div className={classes.checkboxes}>
      {/* POLITICA DE PRIVACIDAD */}
      {txt_custom_privacidad ? (
        <Grid container>
          <Grid item xs={1}>
            <Checkbox
              name="privacyPolitics"
              checked={politicaPrivacidad}
              onChange={(e) => actualizarPoliticaPrivacidad(e.target.checked)}
              color="default"
              id="privacy"
            />
          </Grid>
          <Grid item xs={11} className="flex inline-block">
            <div
              className="mb-auto mt-auto"
              dangerouslySetInnerHTML={{
                __html: iframeConfig.TXT_PrivacidadYCondiciones,
              }}
            ></div>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.privacyPolitics}>
          <Checkbox
            name="privacyPolitics"
            checked={politicaPrivacidad}
            onChange={(e) => actualizarPoliticaPrivacidad(e.target.checked)}
            color="default"
            id="privacy"
          />

          <label className={classes.checkboxesText} htmlFor="privacy">
            {t("Acepto las ")}
            <Link
              to={`/buy/${idioma}/event/${idEvento}/termsConditions`}
              target="_blank"
            >
              {t("condiciones de venta")}
            </Link>
            {t(" y la ")}
            <Link
              to={`/buy/${idioma}/event/${idEvento}/privacyPolitics`}
              target="_blank"
            >
              {t("política de privacidad")}
            </Link>
            {t(" del organizador.")}
          </label>
        </div>
      )}

      {/* NOTIFICACIONES COMERCIALES */}
      {CheckboxNotifActivado === "Si" && (
        <div>
          {txt_custom_notificaciones ? (
            <Grid container>
              <Grid item xs={1}>
                <Checkbox
                  name="commercialNotifications"
                  checked={notificacionesComerciales}
                  onChange={(e) =>
                    actualizarNotificacionesComerciales(e.target.checked)
                  }
                  color="default"
                  id="comercial"
                />
              </Grid>
              <Grid item xs={11} className="flex inline-block">
                <div
                  className="mb-auto mt-auto"
                  dangerouslySetInnerHTML={{
                    __html: iframeConfig.TXT_NotificacionesComerciales,
                  }}
                ></div>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.commercialNotifications}>
              <Checkbox
                name="commercialNotifications"
                checked={notificacionesComerciales}
                onChange={(e) =>
                  actualizarNotificacionesComerciales(e.target.checked)
                }
                color="default"
                id="comercial"
              />

              <label className={classes.checkboxesText} htmlFor="comercial">
                {t(
                  "Deseo recibir notificaciones comerciales del organizador y de los patrocinadores."
                )}
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Consentimientos;
