import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//MATERIAL UI
import {
  Grid,
  Button,
  Icon,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { FuseAnimate } from "@fuse";
//WYSIWYG EDITOR
import { Editor } from "@tinymce/tinymce-react";

//SERVICES
import {
  guardarBorradorAbstract,
  uploadAbstractFile,
  deleteAbstractFile,
} from "../../../../services/eventosService/abstractUploadService";

//REDUX
import * as Actions from "../../store/actions";

//ALERTS
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BoxEditorSeccion1 = () => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const [editorContent, setEditorContent] = useState(
    abstract_data.currentAbstract.contenido_seccion1
  );
  let { max_palabras_seccion1 } = abstract_data.currentTipoAbstract;
  // //en caso de ser 0 o menos, se considera desactivado
  // max_palabras_seccion1 =
  //   max_palabras_seccion1 <= 0 ? 10000 : max_palabras_seccion1;
  const [displayWordsAlert, setDisplayWordsAlert] = useState(false);
  const [currentWords, setCurrentWords] = useState(0);

  const id_abstract = abstract_data.currentAbstract.ID;
  const id_evento = abstract_data.evento.data.result.ID;
  const nombre_seccion = abstract_data.evento.data.result.seccion1_nombre;

  useEffect(() => {
    const AutosaveInterval = setInterval(() => {
      autoSave(abstract_data.currentAbstract.contenido_seccion1, editorContent);
    }, 15000);
    return () => clearInterval(AutosaveInterval);
  }, [abstract_data.currentAbstract.contenido_seccion1, editorContent]);

  const autoSave = (oldState, newState) => {
    //si se ha sobrepasado el limite de palabras no guardamos!
    if (currentWords > max_palabras_seccion1) {
      console.log("Autosave Skipped (Words limit exceeded) (INTRODUCCION)");
      return;
    } else {
      console.log(
        `INTRODUCCION: Palabras en editor=${currentWords} / Maximo Palabras=${max_palabras_seccion1}`
      );
    }
    if (oldState !== newState) {
      let form = {
        id_abstract: abstract_data.currentAbstract.ID,
        content: newState,
        parte: "seccion1",
      };
      guardarBorradorAbstract(form).then((res) => {
        if (!res.data.status) {
          setSnackbar(
            `Se intento guardar una copia automatica de la sección ${nombre_seccion} pero no fue posible.`,
            "error"
          );
        } else {
          //actualizamos el contenido de abstract en store redux
          dispatch(Actions.setCurrentAbstract(res.data.result));
          //Limpiamos tambien imagenes que no se utilizan
          dispatch(
            Actions.cleanAbstractImages(
              id_evento,
              abstract_data.currentAbstract.ID,
              "seccion1"
            )
          );
          setSnackbar(
            `Sección ${nombre_seccion} guardado automaticamente.`,
            "success"
          );
        }
      });
      console.log("Trigger Autosave after 15 seconds. (Introduccion)");
    } else {
      console.log("Autosave Skipped (Introduccion)");
    }
  };

  const handleEditorChange = (content, editor) => {
    // console.log(content);
    setEditorContent(content);
    //Si esta configurado un máximo de palabras, mostramos el contador, sino lo ocultamos
    if (max_palabras_seccion1 > 0) {
      let wordcount = editor.plugins.wordcount;
      let palabras = wordcount.body.getWordCount();
      setCurrentWords(palabras);
      setDisplayWordsAlert(palabras > max_palabras_seccion1 ? true : false);
    }
  };

  //para usarlo poner metodo onInit en el editor y referenciar este funcion
  const disableEditor = (content, editor) => {
    editor.mode.set("readonly");
  };

  const saveAbstractDraft = () => {
    console.log("Se ha guardado el contenido pulsando el boton save!!");
    console.log(editorContent);
    //Si ha sobrepasado el limite de palabras no guardamos y notificamos
    if (currentWords > max_palabras_seccion1) {
      setSnackbar(
        `No es posible guardar la sección ${nombre_seccion} ya que excede el máximo de palabras (${max_palabras_seccion1})`,
        "error"
      );
      return;
    }
    let form = {
      id_abstract: abstract_data.currentAbstract.ID,
      content: editorContent,
      parte: "seccion1",
    };
    guardarBorradorAbstract(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          `Hubo un error al guardar la sección ${nombre_seccion}. Intentelo de nuevo más tarde`,
          "error"
        );
      } else {
        //actualizamos el contenido de abstract en store redux
        dispatch(Actions.setCurrentAbstract(res.data.result));
        //Limpiamos tambien las imagenes no utilizadas
        dispatch(
          Actions.cleanAbstractImages(
            id_evento,
            abstract_data.currentAbstract.ID,
            "seccion1"
          )
        );
        setSnackbar(
          `Sección ${nombre_seccion} guardada correctamente.`,
          "success"
        );
      }
    });
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "main_toast",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "main_toast",
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6}>
          <div className="text-left m-10">
            <Button
              variant="contained"
              color="primary"
              onClick={saveAbstractDraft}
            >
              <Icon>save</Icon> Guardar Borrador
            </Button>
          </div>
        </Grid>
        {displayWordsAlert && (
          <FuseAnimate
            delay="600"
            duration="500"
            animation="transition.bounceIn"
            loop={true}
          >
            <Grid item xs={6}>
              <div className="text-right m-10">
                <Paper
                  elevation={5}
                  className="bg-red text-white text-10 flex align-middle items-center justify-center min-h-32 h-32 p-5"
                >
                  <Icon fontSize="small">warning</Icon> Su documento sobrepasa
                  el máximo de palabras permitido ({max_palabras_seccion1})
                </Paper>
              </div>
            </Grid>
          </FuseAnimate>
        )}
        {!displayWordsAlert && currentWords > 0 && (
          <Grid item xs={6}>
            <Grid container className="flex items-end justify-end align-right">
              <div className="text-right m-10">
                <Paper
                  elevation={5}
                  className="text-11 flex align-middle items-center justify-center min-h-32 h-32 p-5"
                >
                  {currentWords}/{max_palabras_seccion1} palabras
                </Paper>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container className="p-5">
        <Paper elevation={5} className="p-5">
          <Grid item xs={12}>
            <Icon fontSize="small" className="text-blue">
              info
            </Icon>
          </Grid>
          <Grid item xs={12} className="text-11 text-gray-500">
            Para insertar imágenes/tablas/enlaces, en el bloque que desee dentro
            del texto, pulse la pestaña “insertar”, seleccione la opción que
            requiera y siga las instrucciones de la plataforma.
          </Grid>
        </Paper>
      </Grid>
      <Editor
        apiKey="jz4lpbd9uom0a61p5qbhylfy63m3agkug7zrzbxi50kmt7sr"
        initialValue={abstract_data.currentAbstract.contenido_seccion1}
        init={{
          readonly: true,
          height: 400,
          images_upload_url: `${process.env.REACT_APP_BACKEND_URL}/eventos/uploadAbstractImage/${id_evento}/${id_abstract}`,
          language: "es",
          plugins: [
            "advlist autosave autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount print fullscreen media hr save wordcount",
          ],
          autosave_restore_when_empty: true,
          autosave_prefix: "tinymce-autosave-{id}-",
          // menubar: "file view insert",
          menubar: "insert",
          toolbar:
            "undo redo save | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | hr image media | print | fullscreen restoredraft wordcount help",
        }}
        // onInit={disableEditor}
        onEditorChange={handleEditorChange}
      />
    </Grid>
  );
};

export default BoxEditorSeccion1;
