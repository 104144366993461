import * as Actions from "../actions";

const initialState = {
  contadores: {
    contadorEmpresas: 0,
    contadorEventos: 0,
    contadorCompras: 0,
    contadorTotalCompras: 0,
    cargando: true,
    error: null,
  },
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CARGA_CONTADORES_METRICAS_SOLICITADA:
      return {
        ...state,
        contadores: {
          ...state.contadores,
          cargando: true,
        },
      };
    case Actions.CARGA_CONTADORES_EXITOSA:
      const {
        contadorTotalCompras,
        contadorCompras,
        contadorEventos,
        contadorEmpresas,
      } = action.payload;
      return {
        ...state,
        contadores: {
          ...state.contadores,
          contadorTotalCompras,
          contadorCompras,
          contadorEventos,
          contadorEmpresas,
        },
      };
    case Actions.CARGA_CONTADORES_ERRONEA:
      return {
        ...state,
        contadores: {
          ...state.contadores,
          error: action.payload,
        },
      };
    case Actions.CARGA_CONTADORES_FINALIZADA:
      return { ...state, contadores: { ...state.contadores, cargando: false } };
    default:
      return state;
  }
};
