export const styles = {
  datosTransferencia: {
    fontSize: 8,
    margin: [0, 10, 0, 0],
  },
  tableConcepts: {
    margin: [0, 25, 0, 0],
  },
  eventLogo: {
    margin: [0, 0, 0, 0],
  },
  rgpd: {
    fontSize: 8,
    color: "gray",
    margin: [0, 25, 0, 10],
  },
  assistantInfoLine: {
    fontSize: 8,
  },
  assistantSubtitle: {
    fontSize: 8,
    margin: [0, 30, 0, 10],
  },
  bottomLine: {
    colSpan: 3,
    rowSpan: 1,
  },
  bottomCenter: {
    margin: [0, 5, 0, 5],
    alignment: "center",
    fontSize: 11,
    bold: true,
  },
  bottomCenterLess: {
    margin: [-7, 5, 0, 5],
    alignment: "center",
    fontSize: 11,
    bold: true,
  },
  bottomFooterText: {
    margin: [0, 5, 69, 5],
    alignment: "left",
    bold: true,
    fontSize: 11,
  },
  blankBetween: {
    margin: [0, 10, 0, 10],
  },
  blank: {
    margin: [275, 10, 0, 10],
  },
  centerCell: {
    margin: [0, 10, 0, 10],
    alignment: "center",
    fontSize: 10,
  },
  conceptCell: {
    bold: true,
    fontSize: 10,
    margin: [0, 10, 0, 10],
  },
  total: {
    fontSize: 11,
    bold: true,
    alignment: "center",
    margin: [20, 10, 20, 10],
  },
  iva: {
    fontSize: 11,
    bold: true,
    margin: [30, 10, 30, 10],
  },
  cost: {
    fontSize: 11,
    bold: true,
    margin: [10, 10, 10, 10],
  },
  concept: {
    fontSize: 11,
    bold: true,
    margin: [0, 10, 190, 10],
  },
  eventData: {
    alignment: "right",
    bold: true,
    fontSize: 8,
    margin: [0, -55, 0, 40],
  },
  clientData: {
    bold: true,
    fontSize: 10,
  },
  clientDataLine: {
    fontSize: 9,
    bold: false,
  },
  date: {
    margin: [0, 5, 0, 0],
    fontSize: 10,
    bold: false,
  },
  titleLeft: {
    margin: [0, 0, 0, 0],
  },
  headerLeft: {
    fontSize: 20,
    bold: true,
    alignment: "left",
    margin: [0, 0, 0, 0],
  },
  subtitleLeft: {
    fontSize: 10,
    margin: [0, 0, 0, 0],
  },
  header: {
    fontSize: 14,
    bold: true,
    alignment: "right",
    margin: [0, -85, 0, 60],
  },
  subheader: {
    fontSize: 10,
    bold: false,
  },
  subheaderTop: {
    fontSize: 10,
    bold: false,
    margin: [0, 10, 0, 0],
  },
  superMargin: {
    margin: [20, 0, 40, 0],
    fontSize: 15,
  },
  cifEmpresa: {
    margin: [0, -5, 0, 25],
    fontSize: 10,
  },
};
