import { takeEvery, call, put } from "redux-saga/effects";
import {
  cambiarColorTemaIframe,
  cargaConfigErronea,
  cargaConfigFinalizada,
  CARGA_CONFIG_SOLICITADA,
  cambiarVisibilidadIframe,
  cambiarTemaIframe
} from "../actions";
import { obtenerConfiguracionIframe } from "app/services/iframeService";

export function* observador() {
  yield takeEvery(CARGA_CONFIG_SOLICITADA, cargaConfig);
}

function* cargaConfig(action) {
  try {
    const idEvento = action.payload;
    const { data } = yield call(obtenerConfiguracionIframe, idEvento);
    const { result, status } = data;
    if (!status) throw new Error("Error en el status");
    const { color, privado, colorFondo, colorFuente, transparente } = result;
    yield put(cambiarVisibilidadIframe(privado === 0));
    yield put(cambiarColorTemaIframe(color));
    yield put(cambiarTemaIframe({ colorFondo, colorFuente, transparente }));
  } catch (e) {
    yield put(cargaConfigErronea(e));
  } finally {
    yield put(cargaConfigFinalizada());
  }
}
