import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotFound from "app/main/apps/components/NotFound";
import { useTranslation } from "react-i18next";
import { withStyler } from "../../wrappers";

const useStyles = makeStyles({
  container: {
    margin: "0 auto",
    width: "50%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
    textAlign: "center",
  },
});

const PaymentFree = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const buyId = props.match.params.buyId;
  const [wrongAccess, setWrongAccess] = useState(false);

  useEffect(() => {
    if (!buyId) setWrongAccess(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {wrongAccess ? (
        <NotFound />
      ) : (
        <Paper
          className={classes.container}
          style={{ backgroundColor: props.styles.backgroundColor }}
        >
          <Grid container justify="center">
            <Grid item xs={12}>
              <CheckCircleIcon
                style={{
                  color: green[500],
                  fontSize: 100,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 20 }}>
                {t(
                  "Su inscripción ha sido realizada correctamente. Le hemos enviado un correo electrónico con el justificante de inscripción (en caso de no encontrarlo revise su bandeja de SPAM)."
                )}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default withStyler(PaymentFree);
