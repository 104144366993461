import React from "react";
import MaterialTable from "material-table";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button
 } from "@material-ui/core";
import { useTranslation } from "react-i18next";


import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ModalAbstractsRevisores(props) {
  const { t } = useTranslation();
  let data = props.revisoresAbstracts;

  const handleClose = () => {
    props.setDisplayModalConsultas(false);
  };

  /* function dateFormat(str_date) {
    let date = new Date(str_date);
    let date_output =
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    return date_output;
  } */

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_inscripciones",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_inscripciones",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_inscripciones",
      });
    }
  };
  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId="tabla_inscripciones"
        transition={Slide}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={props.displayModalConsultas}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <MaterialTable
            title={t("Comunicaciones asignadas a los revisores")}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} " + t("de") + " {count}",
                labelRowsPerPage: t("Filas por página"),
                labelRowsSelect: t("Filas"),
                firstAriaLabel: t("Primera página"),
                firstTooltip: t("Primera página"),
                previousAriaLabel: t("Anterior página"),
                previousTooltip: t("Anterior página"),
                nextAriaLabel: t("Siguiente página"),
                nextTooltip: t("Siguiente página"),
                lastAriaLabel: t("Última página"),
                lastTooltip: t("Última página"),
              },
              toolbar: {
                nRowsSelected: "{0} fila(s) seleccionadas",
                searchTooltip: t("Buscar registro"),
                searchPlaceholder: t("Buscar registro"),
              },
              header: {
                actions: t("Acciones"),
              },
              body: {
                editRow: {
                  saveTooltip: t("Guardar"),
                  cancelTooltip: t("Cancelar"),
                  deleteText: t("Eliminar"),
                },
                emptyDataSourceMessage: t("No hay registros disponibles"),
                filterRow: {
                  filterTooltip: t("Filtrar"),
                },
              },
            }}
            columns={[
              { title: "Nombre", field: "nombre" },
              { title: t("Apellidos"), field: "apellidos" },
              { title: t("Email"), field: "email" },
              { title: t("Título comunicación"), field: "titulo" },
              { title: t("Número de comunicación"), field: "num_abstract" },
              { title: t("ID comunicación"), field: "ID" }
            ]}
            data={data}
            /* options={{
              exportButton: true,
              exportCsv: () => handleExport(),
            }} */
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={ (e) => handleClose()} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
