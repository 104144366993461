import {
  cambiarCantidadInscripciones,
  recalcularPrecio,
} from "../../../store/actions";

export default function useMenuTiposInscripcion({ iframe, dispatch }) {
  const { compra, iframeConfig } = iframe;

  const { tiposInscripcion, inscripciones } = compra;

  const { array: tiposIArray } = tiposInscripcion;

  const { publico } = iframeConfig;

  const cambiarCantidadEntradas = (
    nuevaCantidad,
    indice,
    prevCantidadEntradas
  ) => {
    if (nuevaCantidad === prevCantidadEntradas) return;

    dispatch(
      cambiarCantidadInscripciones({
        indice,
        nuevaCantidad,
        prevCantidadEntradas,
      })
    );

    dispatch(recalcularPrecio());
  };

  const cantidadDeInscripciones = (tipoInscripcion) => {
    const tI = tipoInscripcion;

    const iframePrivado = !publico;

    /* FUNCIONALIDAD DEL IFRAME PRIVADO */
    if (iframePrivado && inscripciones.length > 0) {
      const [unicaInscripcionSeleccionada] = inscripciones;
      if (tI.ID === unicaInscripcionSeleccionada.ID) {
        return [...Array(2).keys()];
      } else {
        return [...Array(1).keys()];
      }
    }

    if (tiposIArray.length === 1) {
      /**
       * Cuándo sólo hay un tipo de inscripción,
       * que empiece el desplegable por 1 hasta n en vez de 0 hasta n.
       */
      return devuelveCantidadEmpezandoPorUno(tI);
    }

    /* FUNCIONALIDAD DEL IFRAME PÚBLICO */
    let numerosDelDesplegable = [
      ...Array(tI.limite_compra === 0 ? 12 : tI.limite_compra + 1).keys(),
    ].filter((numero) => numero >= tI.limite_minimo);

    /**
     * Añades el 0, si es que no está ya (por ello el uso de ...),
     * por si el limite_minimo fuese > 0, para que el cliente no tenga
     * que añadir obligatoriamente esa inscripción.
     *
     * Eliminas duplicados usando Set por si el 0 ya estuviese presente
     */
    return [...new Set([0, ...numerosDelDesplegable])];
  };

  const devuelveCantidadEmpezandoPorUno = (tipoInscripcion) => {
    const limiteDeCompra = tipoInscripcion.limite_compra;
    const noHayLimiteDeCompra = limiteDeCompra === 0;
    const limiteEstandar = 12;

    const cantidadesArr = [
      ...Array(noHayLimiteDeCompra ? limiteEstandar : limiteDeCompra).keys(),
    ];

    const incrementarEnUno = (x) => x + 1;

    return cantidadesArr.map(incrementarEnUno);
  };

  const tipoInscripcionDeshabilitada = (tI) => {
    const iframePrivado = !publico;
    if (iframePrivado && inscripciones.length > 0) {
      const [unicaInscripcionSeleccionada] = inscripciones;
      return tI.ID !== unicaInscripcionSeleccionada.ID;
    }
    return false;
  };

  return {
    cambiarCantidadEntradas,
    cantidadDeInscripciones,
    tipoInscripcionDeshabilitada,
  };
}
