import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import {
  Grid,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@material-ui/core";
import * as Actions from "./store/actions";
import reducer from "./store/reducers";
import NotFound from "app/main/apps/components/NotFound";
import { Loading } from "app/shared";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FusePageSimple, DemoContent } from "@fuse";
//ALERTS
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Global from "../../fuse-configs/Global";

//SERVICES
import { LoginAutorAbstract } from "../../services/eventosService/abstractUploadService";

//COMPONENTES
import VistaLogin from "./VistaLogin/VistaLogin";
import VistaAbstract from "./VistaAbstract/VistaAbstract";
import VistaCrear from "./VistaCrear/VistaCrear";
import VistaPresentar from "./VistaPresentar/VistaPresentar";

//WYSIWYG EDITOR
import { Editor } from "@tinymce/tinymce-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Login/Registro",
    "Crear/Seleccionar Comunicación",
    "Edición de Comunicación",
    "Presentar",
  ];
}

function AbstractUpload(props) {
  let id_evento = props.match.params.id_evento;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  let event_data = abstract_data.evento;
  let campos_autor = abstract_data.campos_autor;
  let campos_contacto = abstract_data.campos_contacto;
  let tipos_abstract = abstract_data.tipos_abstract;

  useEffect(() => {
    dispatch(Actions.getEventInfoAbstract(id_evento));
    dispatch(Actions.getCamposAutorEvento(id_evento));
    dispatch(Actions.getCamposContactoAdministrativoEvento(id_evento));
    dispatch(Actions.getTiposAbstract(id_evento));
    dispatch(Actions.getCategoriasAbstract(id_evento));
  }, [dispatch]);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <VistaLogin
            handleNext={handleNext}
            id_evento={id_evento}
            campos_contacto={campos_contacto}
          />
        );
      case 1:
        return <VistaCrear handleNext={handleNext} id_evento={id_evento} />;
      case 2:
        return <VistaAbstract />;
      case 3:
        return (
          <VistaPresentar id_evento={id_evento} setActiveStep={setActiveStep} />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log("Step num:", activeStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if (!event_data || !campos_autor || !tipos_abstract) {
    return (
      <FusePageSimple
        classes={{
          root: classes.layoutRoot,
        }}
        content={
          <div className="flex items-center justify-center text-center">
            <Loading loadingMessage={t("Cargando...")} />
          </div>
        }
      />
    );
  } else {
    //no existe el evento o no esta activada la subida de abstracts
    //falta controlar abstracts activados en evento en el endpoint!!
    if (!event_data.data.status) {
      return <NotFound />;
    }
  }

  // console.log(event_data.data);

  return (
    <FusePageSimple
      classes={{
        root: classes.layoutRoot,
      }}
      // header={
      //   <div className="p-24">
      //     <h4>Header</h4>
      //   </div>
      // }
      // contentToolbar={
      //   <div className="px-24">
      //     <h4>Content Toolbar</h4>
      //   </div>
      // }
      content={
        <div>
          <ToastContainer containerId="main_toast" enableMultiContainer />
          <Grid className="p-10">
            {/* <Grid item xs={12} className="flex items-center justify-center">
              <img
                // className="max-h-256"
                className="max-h-128"
                src={`${Global.url_s3_bucket}/${event_data.data.result.img_evento}`}
                alt="imagen del evento"
              />
            </Grid> */}

            {/* <Grid
              item
              xs={12}
              className="p-24 flex items-center justify-center"
            >
              <Typography className="text-center">
                <p className="text-xs">
                  {t("Presentación de abstracts para")}
                  {": "}
                </p>
                <p className="font-bold text-xl">
                  {event_data.data.result.nombre}
                </p>
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              className="mr-auto ml-auto lg:max-w-4xl xl:max-w-5xl"
            >
              <Paper className="p-20" elevation={3}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div className="flex items-center text-center justify-center">
                  {activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={handleReset}>Inicio</Button>
                    </div>
                  ) : (
                    <div className="w-full">
                      <Typography className={classes.instructions}>
                        {getStepContent(activeStep)}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Atrás
                        </Button>
                        {activeStep !== steps.length - 1 && (
                          <Button
                            disabled={activeStep === 0 || activeStep === 1}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            {activeStep === steps.length - 1
                              ? "Publicar"
                              : "Siguiente"}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
}

export default withReducer("Abstract", reducer)(AbstractUpload);
