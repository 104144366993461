export const resetBackgrounds = () => {
  document.getElementsByTagName("html")[0].style.backgroundColor =
    "rgba(255,255,255,0.8)";
  document.getElementById("fuse-layout").style.backgroundColor = "transparent";
};

export const concatThemeToRoute = (route, theme) => {
  if (theme === "") {
    return route;
  } else {
    return `${route}?theme=${theme}`;
  }
};

export const mountSearchWithTheme = (theme) => {
  if (theme === "") return "";
  return `?theme=${theme}`;
};
