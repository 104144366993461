import React from "react";
import {
  makeStyles,
  Grid,
  Chip,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { cambiarEstadoComplemento, recalcularPrecio } from "../store/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "25px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "12px",
    color: "grey",
    marginTop: "9px",
  },
  description: {
    marginLeft: "31px",
    color: "grey",
    marginTop: "-5px",
    fontSize: "12px",
  },
}));

let ListaComplementos = (props) => {
  const { t } = useTranslation();
  const complementos = props.complementos;
  const classes = useStyles();
  const hayFacturacion = useSelector(
    ({ iframe }) => iframe.evento.datos.hayFacturacion
  );
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery("(max-width:670px)");

  const toggle = (escogido, idUnico) => {
    dispatch(cambiarEstadoComplemento({ nuevoEstado: { escogido }, idUnico }));
    dispatch(recalcularPrecio());
  };

  return (
    <React.Fragment>
      {complementos && complementos.length > 0 && (
        <Paper>
          <div className={classes.container}>
            <Grid
              container
              spacing={mobileScreen ? 0 : 2}
              direction={mobileScreen ? "column" : "row"}
            >
              <Grid item xs={3}>
                <div className={classes.title}> {t("Complementos")} </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.subtitle}>
                  {" "}
                  ({t("Inscripción")} #{props.inscriptionNumber + 1}){" "}
                </div>
              </Grid>
            </Grid>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {complementos.map((complemento, complementoIndex) => (
                  <Grid key={complementoIndex} item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={complemento.escogido}
                          onChange={(e) =>
                            toggle(e.target.checked, complemento.idUnico)
                          }
                          value={complemento.nombre}
                        />
                      }
                      label={complemento.nombre}
                    />
                    {hayFacturacion && (
                      <Chip
                        label={
                          complemento.precio === 0
                            ? t("Gratis")
                            : complemento.precio + "€"
                        }
                      />
                    )}
                    <div className={classes.description}>
                      {complemento.descripcion}
                    </div>
                  </Grid>
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default ListaComplementos;
