import React from "react";
import { useTranslation } from "react-i18next";

import { Button, makeStyles } from "@material-ui/core";
import { useButton } from "./hooks";

const styles = {
  btn_submit: {
    marginTop: "20px",
    width: "100%",
    marginBottom: "30px",
  },
};

const useStyles = makeStyles(styles);

const ContinuarCompraButton = ({ action }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { color } = useButton();

  return (
    <Button
      className={classes.btn_submit}
      color="primary"
      onClick={action}
      style={{
        backgroundColor: color ? color : "#2D323E",
      }}
      variant="contained"
    >
      {t("Continuar")}
    </Button>
  );
};

export default ContinuarCompraButton;
