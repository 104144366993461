/**
 * ================================
 *
 * CARGA CODIGO DE TRANSFERENCIA
 *
 * ================================
 */
export const CARGA_CODIGO_TRANSFERENCIA_SOLICITADA =
  "[IFRAME.PAGOS] SE HA SOLICITADO CARGAR EL CODIGO DE TRANSFERENCIA PARA UN PAGO";

export function solicitarCargaCodigoTransferencia(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CODIGO_TRANSFERENCIA_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_CODIGO_TRANSFERENCIA_CORRECTA =
  "[IFRAME.PAGOS] SE HA CARGADO EL CODIGO DE TRANSFERENCIA CORRECTAMENTE";

export function cargaCodigoTransferenciaExitosa(codigoTransferencia) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CODIGO_TRANSFERENCIA_CORRECTA,
      payload: codigoTransferencia,
    });
}

export const CARGA_CODIGO_TRANSFERENCIA_ERRONEA =
  "[IFRAME.PAGOS] SE HA PRODUCIDO UN ERROR AL CARGAR EL CODIGO DE TRANSFERENCIA";

export function cargaCodigoTransferenciaErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CODIGO_TRANSFERENCIA_ERRONEA,
      payload: error,
    });
}

export const CARGA_CODIGO_TRANSFERENCIA_FINALIZADA =
  "[IFRAME.PAGOS] LA CARGA DEL CODIGO DE TRANSFERENCIA HA FINALIZADO";

export function cargaCodigoTransferenciaFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_CODIGO_TRANSFERENCIA_FINALIZADA,
    });
}

/**
 * ================================
 *
 * CARGA CUENTA DE TRANSFERENCIA
 *
 * ================================
 */
export const CARGA_CUENTA_TRANSFERENCIA_SOLICITADA =
  "[IFRAME.PAGOS] SE HA SOLICITADO LA CARGA DE LA CUENTA DE TRANSFERENCIA ASOCIADA AL EVENTO";

export function solicitarCargaCuentaTransferencia(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CUENTA_TRANSFERENCIA_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_CUENTA_TRANSFERENCIA_CORRECTA =
  "[IFRAME.PAGOS] SE HA CARGADO LA CUENTA DE TRANSFERENCIA ASOCIADA AL EVENTO CORRECTAMENTE";

export function cargaCuentaTransferenciaExitosa(cuentaTransferencia) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CUENTA_TRANSFERENCIA_CORRECTA,
      payload: cuentaTransferencia,
    });
}

export const CARGA_CUENTA_TRANSFERENCIA_ERRONEA =
  "[IFRAME.PAGOS] SE HA PRODUCIDO UN ERROR AL CARGAR LA CUENTA DE TRANSFERENCIA ASOCIADA AL EVENTO";

export function cargaCuentaTransferenciaErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CUENTA_TRANSFERENCIA_ERRONEA,
      payload: error,
    });
}

export const CARGA_CUENTA_TRANSFERENCIA_FINALIZADA =
  "[IFRAME.PAGOS] LA CARGA DE LA CUENTA DE TRANSFERENCIA ASOCIADA AL EVENTO HA FINALIZADO";

export function cargaCuentaTransferenciaFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_CUENTA_TRANSFERENCIA_FINALIZADA,
    });
}

/**
 * ================================
 *
 * CARGA PREFIJO Y SUFIJO DE UNA PROFORMA
 *
 * ================================
 */
export const CARGA_PREFIJO_SUFIJO_PROFORMA_SOLICITADA =
  "[IFRAME.PAGOS] SE HA SOLICITADO LA CARGA DEL PREFIJO Y EL SUFIJO PARA LA GENERACIÓN DE UNA PROFORMA";

export function solicitarCargaPrefijoSufijoProforma(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_PREFIJO_SUFIJO_PROFORMA_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_PREFIJO_SUFIJO_PROFORMA_CORRECTA =
  "[IFRAME.PAGOS] SE HAN CARGADO EL PREFIJO Y EL SUFIJO CORRECTAMENTE";

export function cargaPrefijoSufijoProformaExitosa(prefijo, sufijo) {
  return (dispatch) =>
    dispatch({
      type: CARGA_PREFIJO_SUFIJO_PROFORMA_CORRECTA,
      payload: { prefijo, sufijo },
    });
}

export const CARGA_PREFIJO_SUFIJO_PROFORMA_ERRONEA =
  "[IFRAME.PAGOS] SE HA PRODUCIDO UN ERROR AL CARGAR EL PREFIJO Y EL SUFIJO";

export function cargaPrefijoSufijoProformaErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_PREFIJO_SUFIJO_PROFORMA_ERRONEA,
      payload: error,
    });
}

export const CARGA_PREFIJO_SUFIJO_PROFORMA_FINALIZADA =
  "[IFRAME.PAGOS] LA CARGA DEL PREFIJO Y EL SUFIJO HA FINALIZADO";

export function cargaPrefijoSufijoProformaFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_PREFIJO_SUFIJO_PROFORMA_FINALIZADA,
    });
}

/**
 * ================================
 *
 * GUARDA DATOS DE FACTURACION
 *
 * ================================
 */
export const GUARDA_DATOS_FACTURACION =
  "[IFRAME.PAGOS] GUARDA LOS DATOS DE FACTURACION";

export function guardaDatosFacturacion(datos) {
  return (dispatch) =>
    dispatch({
      type: GUARDA_DATOS_FACTURACION,
      payload: datos,
    });
}
