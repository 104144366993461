export default {
  container: {
    width: "50%",
    margin: "0 auto",
    marginTop: "10%",
    padding: "3%",
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: "20px",
  },
  icon: {
    color: "red",
    fontSize: "50px",
  },
  text: {
    fontSize: "20px",
  },
};
