class DatosRedsysParaTPV {
  constructor({
    idioma,
    idCompra,
    
  }) {
    this.idioma = idioma;
    this.idCompra = idCompra;
   
  }
}

export default DatosRedsysParaTPV;
