import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  Paper,
  Icon,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import * as Actions from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Services
import { SigninUsuarioAbstract } from "../../../../services/eventosService/abstractUploadService";

const RegisterBox = (props) => {
  console.log(
    "ESTOS SON LOS CAMPOS DEL CONTACTO ADMINISTRATIVO:",
    props.campos_contacto
  );
  const [nombre, setNombre] = useState("");
  const [apellido1, setApellido1] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [camposCustom, setCamposCustom] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [politica1, setPolitica1] = React.useState(false);
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_login",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_login",
      });
    }
  };

  const handleSignin = (e) => {
    e.preventDefault();
    if (password.length >= 8) {
      let form = {
        id_evento: props.id_evento,
        nombre: nombre,
        apellido1,
        apellido2,
        email: email,
        password: password,
        campos_contacto: JSON.stringify(camposCustom),
      };
      SigninUsuarioAbstract(form).then((res) => {
        if (!res.data.status) {
          setSnackbar(res.data.message, "error");
        } else {
          console.log(res.data);
          setSnackbar("Registro realizado con éxito", "success");
          dispatch(Actions.setIdUsuario(res.data.result[0].ID));
          props.handleNext();
        }
      });
    } else {
      setSnackbar("El password debe tener al menos 8 carácteres.", "error");
    }
  };

  const addCampoCustom = (campo, value) => {
    //primero eliminamos el campo anterior si ya existia
    let cc_aux = camposCustom.filter((row) => row.id_campo !== campo.ID);
    let newElement = {
      id_campo: campo.ID,
      nombre: campo.nombre,
      type: campo.tipo,
      value: value,
    };
    cc_aux.push(newElement);
    setCamposCustom(cc_aux);
    console.log(cc_aux);
  };

  const goToLogin = () => {
    props.handleChangeIndex(0);
  };

  return (
    <Paper className="p-10 m-5 min-h-sm flex items-center justify-center">
      {/* <ToastContainer enableMultiContainer containerId="abstract_register" /> */}
      <form onSubmit={handleSignin}>
        <Icon fontSize="large">person_add</Icon>
        <Typography variant="h6">Registro</Typography>
        {/* <Typography className="mt-5" variant="h7">
          Inserte los siguientes datos para registrarse:
        </Typography> */}
        <Grid
          container
          className="flex items-center justify-center text-center"
        >
          <Grid item className="text-12 mr-1">
            ¿Ya está registrado?
          </Grid>
          <Grid
            item
            onClick={goToLogin}
            className="text-12 text-blue cursor-pointer"
          >
            Iniciar sesión
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} className="p-5">
            <TextField
              label="Nombre"
              variant="outlined"
              required
              onChange={(e) => setNombre(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} className="p-5">
            <TextField
              label="Primer Apellido"
              variant="outlined"
              required
              onChange={(e) => setApellido1(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} className="p-5">
            <TextField
              label="Segundo Apellido"
              variant="outlined"
              // required
              onChange={(e) => setApellido2(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="p-5">
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={6} className="p-5">
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={6} className="p-5">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid>
          {props.campos_contacto &&
            props.campos_contacto.map((row) => {
              return (
                <Grid item xs={6} className="p-5">
                  <TextField
                    label={row.nombre}
                    variant="outlined"
                    type={row.tipo}
                    required={row.obligatorio ? true : false}
                    onChange={(e) => addCampoCustom(row, e.target.value)}
                    fullWidth
                  />
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          className="flex items-center justify-center text-center"
        >
          <Grid item xs={12}>
            (Los datos de este usuario serán utilizados como contacto
            administrativo de las comunicaciónes que éste presente)
          </Grid>
        </Grid>

        <Grid item xs={12} className="text-11">
          <Checkbox
            checked={politica1}
            onChange={(e) => setPolitica1(e.target.checked)}
            name="politica1"
            color="primary"
            required
          />
          Acepto la
          <Link
            to={`/buy/es/event/${abstract_data.evento.data.result.ID}/privacyPolitics`}
            target="_blank"
          >
            {" "}
            politica de privacidad{" "}
          </Link>{" "}
          del organizador.
        </Grid>

        <Button
          className="mt-10"
          variant="contained"
          color="primary"
          type="submit"
        >
          Registrarse
        </Button>
      </form>
    </Paper>
  );
};

export default RegisterBox;
