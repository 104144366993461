import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormularioCIF } from "./hooks";

/* MATERIAL-UI */
import makeStyles from "@material-ui/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";

/* BOTONES */
import { VerificarCIFButton, DesplegableIdiomas } from "../../botones";

import styles from "./FormularioCIF.styles";
const useStyles = makeStyles(styles);

const FormularioCIF = ({ idEvento }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [error, setError] = useState(false);

  const mostrarError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  const {
    intentos,
    cif,
    setCif,
    comprobarCif,
    comprobandoCif,
  } = useFormularioCIF({ idEvento, mostrarError });

  if (intentos >= 3) {
    return (
      <Paper className={classes.container}>
        <Typography>{t("Demasiados intentos")}</Typography>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.container}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={9}>
            <Typography>
              {t("Introduce la clave para acceder a las inscripciones")}
            </Typography>
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={2}>
            <DesplegableIdiomas />
          </Grid>
        </Grid>

        <TextField
          fullWidth={true}
          required
          variant="outlined"
          type="text"
          label={t("Clave")}
          value={cif}
          onChange={(e) => setCif(e.target.value)}
        />
        {comprobandoCif ? (
          <Typography>{t("Comprobando clave...")}</Typography>
        ) : (
          <VerificarCIFButton action={comprobarCif} />
        )}
        <Grow
          in={error}
          style={{ transformOrigin: "0 0 0" }}
          {...(error ? { timeout: 1000 } : {})}
        >
          <div className={classes.errorBanner}>
            <Typography>
              {t("La clave proporcionada no es correcta.")}
            </Typography>
          </div>
        </Grow>
      </Paper>
    );
  }
};

export default FormularioCIF;
