import {
  getImageBase64FromS3,
  enviarBadgesCompraOnline,
  obtenerCodigoTransferenciaUnico,
} from "app/services/eventosService";

import {
  obtenerEventoActivo,
  obtenerProductosEvento,
  obtenerMetodosPagoEvento,
  obtenerEmpresaOrganizadoraEvento,
} from "app/services/iframeService";

import {
  obtenerIdUnicoProformaPorIdEvento,
  obtenerPrefijoSufijoProformaPorIdEvento,
} from "app/services/eventosService/pagosService";

import Global from "app/fuse-configs/Global";
import { PrefijosSufijos } from "app/model/facturaPrefijoSufijo/FacturaPrefijoSufijo";

const { debugMode } = Global;

export const cargaProductosEvento = async (idEvento) => {
  try {
    const { data } = await obtenerProductosEvento(idEvento);
    const { status, result } = data;
    if (!status) throw new Error("Error en el status.");
    return result;
  } catch (e) {
    if (debugMode) {
      console.log("Error en Iframe.logic.js/cargaProductosEvento");
    }
    return null;
  }
};

export const cargaMetodosDePago = async (idEvento) => {
  try {
    const { data } = await obtenerMetodosPagoEvento(idEvento);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status.");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log("Error en Iframe.logic.js/cargaMetodosDePago");
    }

    return null;
  }
};

export const cargaImagenEvento = async (idEvento, nombreImagen) => {
  try {
    const { data } = await getImageBase64FromS3(idEvento, nombreImagen);
    const { result, status } = data;

    if (!status) throw new Error("Error en status");

    return result;
  } catch (e) {
    if (debugMode) console.log("Error en cargaImagenEvento", e);
    // return base64 por defecto
  }
};

export const cargaDatosEmpresaOrganizadora = async (idEvento) => {
  try {
    const { data } = await obtenerEmpresaOrganizadoraEvento(idEvento);
    const { result, status } = data;
    if (!status) throw new Error("Error en status");
    return result;
  } catch (e) {
    if (debugMode) console.log("Error en cargaDatosEmpresaOrganiza", e);
    // return ??
  }
};

export const cargaInformacionEvento = async (idEvento) => {
  try {
    const { data } = await obtenerEventoActivo(idEvento);
    const { result, status } = data;

    if (!status) throw new Error("Error en status");

    return result;
  } catch (e) {
    if (debugMode) console.log("Error en cargaInformacionEvento", e);
    // return ??
  }
};

export const cargaIdProforma = async (idEvento) => {
  try {
    const { data } = await obtenerIdUnicoProformaPorIdEvento(idEvento);
    const { result, status } = data;

    if (!status) throw new Error("Error en status");

    return result;
  } catch (e) {
    if (debugMode) console.log("Error en cargaIdProforma", e);
    // return ??
  }
};

export const cargaPrefijosSufijos = async (idEvento) => {
  try {
    const { data } = await obtenerPrefijoSufijoProformaPorIdEvento(idEvento);
    const { status, result } = data;

    if (!status) throw new Error("Error en status");
    return result;
  } catch (e) {
    if (debugMode) console.log("Error en cargaPrefijosSufijos", e);
    return new PrefijosSufijos();
  }
};

export const enviarCorreoEventoOnline = async (idCompra) => {
  try {
    await enviarBadgesCompraOnline(idCompra);
    // const { data } = await enviarBadgesCompraOnline(idCompra);
    // const { status } = data;
    // if (!status) throw new Error("Error en el status");

    return true;
  } catch (e) {
    if (debugMode) {
      console.log("Error en Iframe.logic.js/enviarCorreoEventoOnline", e);
    }
    return null;
  }
};

export const obtenerCodigoTransferencia = async () => {
  try {
    const { data } = await obtenerCodigoTransferenciaUnico();
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en Iframe.logic.js/obtenerCodigoTransferenciaUnico",
        e
      );
      return null;
    }
  }
};

export const cargarCodigoTransferencia = async () => {
  try {
    const { data } = await obtenerCodigoTransferenciaUnico();
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log("Error en Iframe.logic.js/cargarCodigoTransferencia", e);
      return null;
    }
  }
};
