export const CARGAR_WIDGETS_REVISOR_SOLICITADO =
  "[DASHBOARD] CARGA WIDGETS REVISOR";
export function solicitarCargaWidgetsRevisor(email) {
  return (dispatch) =>
    dispatch({
      type: CARGAR_WIDGETS_REVISOR_SOLICITADO,
      payload: email,
    });
}

export const CARGA_WIDGETS_REVISOR_EXITOSA =
  "[DASHBOARD] CARGA WIDGETS REVISOR EXITOSA";
export function cargaWidgetsRevisorExitosa(counterData, chartData) {
  return (dispatch) =>
    dispatch({
      type: CARGA_WIDGETS_REVISOR_EXITOSA,
      payload: {
        counterData,
        chartData,
      },
    });
}

export const CARGA_WIDGETS_REVISOR_ERRONEA =
  "[DASHBOARD] CARGA WIDGETS REVISOR ERRONEA";
export function cargaWidgetsRevisorErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_WIDGETS_REVISOR_ERRONEA,
      payload: error,
    });
}

export const CARGA_WIDGETS_REVISOR_FINALIZADA =
  "[DASHBOARD] CARGA WIDGETS REVISOR FINALIZADA";
export function cargaWidgetsRevisorFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_WIDGETS_REVISOR_FINALIZADA,
    });
}
