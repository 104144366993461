import {
  CAMBIAR_ESTADO_CONFORME_POLITICA_PRIVACIDAD,
  CAMBIAR_ESTADO_ACEPTA_NOTIFICACIONES_COMERCIALES,
} from "../actions";

const initialState = {
  politicaPrivacidad: false,
  notificacionesComerciales: false,
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case CAMBIAR_ESTADO_CONFORME_POLITICA_PRIVACIDAD:
      return {
        ...state,
        politicaPrivacidad: action.payload,
      };
    case CAMBIAR_ESTADO_ACEPTA_NOTIFICACIONES_COMERCIALES:
      return {
        ...state,
        notificacionesComerciales: action.payload,
      };

    default:
      return state;
  }
};
