import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTranslation } from "react-i18next";
// import { resetBackgrounds } from "app/components/iframe/utils/Styler";
// import { themes } from "../../../styles";
import { withStyler } from "../../wrappers";

const useStyles = makeStyles({
  container: {
    margin: "0 auto",
    width: "50%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
    textAlign: "center",
  },
  btn_submit: {
    marginTop: "20px",
  },
});

const RedsysPaymentError = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const idioma = props.match.params.idioma;
  // const queryParameters = props.location.search;

  // const [backgroundColor, setBackgroundColor] = useState("white");

  useEffect(() => {
    i18n.changeLanguage(idioma);
    // applyTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const applyTheme = () => {
  //   const theme = queryParameters.split("=")[1];

  //   if (!theme) return;

  //   if (theme === themes.LIGHT) {
  //     setBackgroundColor("transparent");
  //     resetBackgrounds();
  //   }
  // };

  return (
    <React.Fragment>
      <Paper
        className={classes.container}
        style={{ backgroundColor: props.styles.backgroundColor }}
      >
        <Grid container justify="center">
          <Grid item xs={12}>
            <CancelIcon
              style={{
                color: red[500],
                fontSize: 200,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 20 }}>
              {t(
                "Hubo un error al realizar el pago, por favor vuelva a intentarlo"
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default withStyler(RedsysPaymentError);
