import React from "react";
import Styler from "../Styler";

const withStyler = (component) => {
    return class extends React.Component {
        render () {
            return (
                <Styler {...this.props}>
                    {component}
                </Styler>
            )
        }
    }
}

export default withStyler;