import React, { useState } from "react";
import {
  Avatar,
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "app/auth/store/actions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout as resetearStore } from "app/components/auth/store/actions";

function UserMenu(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const logout = () => {
    dispatch(resetearStore());
    dispatch(authActions.logoutUser());
    userMenuClose();
  };

  const roleToString = (role) => {
    switch (role) {
      case 1:
        return "superadmin";
      case 2:
        return "admin";
      case 3:
        return "admin_empresa";
      case 4:
        return "user_empresa";
      case 5:
        return "super_revisor";
      case 6:
        return "revisor";
      default:
        return "";
    }
  };
  console.log(user.data);
  return (
    <React.Fragment>
      <Button className="h-64" onClick={userMenuClick}>
        {user.data.photoURL ? (
          <Avatar className="" alt="user photo" src={user.data.photoURL} />
        ) : (
          <Avatar className="">{user.data.displayName[0]}</Avatar>
        )}

        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {user.data.displayName}
          </Typography>
          <Typography className="text-11 capitalize" color="textSecondary">
            {roleToString(user.role)}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role ? (
          <React.Fragment>
            <MenuItem component={Link} to="/login">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Register" />
            </MenuItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MenuItem component={Link} to="/perfil" onClick={userMenuClose}>
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("Mi perfil")} />
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("Cerrar sesión")} />
            </MenuItem>
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  );
}

export default UserMenu;
