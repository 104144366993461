import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";

//COMPONENTS
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
  Grid,
  Paper,
  Icon,
  IconButton,
  TableRow,
} from "@material-ui/core";
import { Loading } from "app/shared";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Actions from "../store/actions";
import reducer from "../store/reducers";

//SERVICES
import { deleteAbstract } from "../../../services/eventosService/abstractUploadService";

import ModalTiposAbstract from "./components/ModalTiposAbstract";
import ModalTablaAutores from "./components/ModalTablaAutores";
import ModalOrdenarAutores from "./components/ModalOrdenarAutores";
import ModalCrearAbstract from "./components/ModalCrearAbstract";
import ModalCrearAbstractGrupal from "./components/ModalCrearAbstractGrupal";
import { refreshCurrentAbstract } from "../store/actions";

const VistaCrear = (props) => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );

  const { currentAbstract, autores } = abstract_data;

  const [
    displayModalTipoAbstract,
    setDisplayModalTipoAbstract,
  ] = React.useState(false);
  const [
    displayModalTablaAutores,
    setDisplayModalTablaAutores,
  ] = React.useState(false);
  const [
    displayModalOrdenarAutores,
    setDisplayModalOrdenarAutores,
  ] = React.useState(false);
  const [displayModalIndividual, setDisplayModalIndividual] = React.useState(
    false
  );
  const [displayModalGrupal, setDisplayModalGrupal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMenu, setSelectedMenu] = React.useState(null);
  let abstract_to_delete = false;

  const handleClickMenu = (event, menu_id) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menu_id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };

  const handleCloseModalOrdenarAutores = () => {
    dispatch(Actions.resetAutores());
    dispatch(Actions.resetCurrentAbstract());
    setDisplayModalOrdenarAutores(false);
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "crud_abstracts",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "crud_abstracts",
      });
    }
    if (type === "alert_dialog") {
      toast.error(
        <div>
          <p>Esta seguro de querer eliminar este borrador?</p>
          <p>(No sera posible deshacer este cambio)</p>
          <div className="flex items-center justify-center">
            <Button
              className="m-5"
              variant="contained"
              color="primary"
              onClick={handleEliminarAbstract}
            >
              Aceptar
            </Button>
            <Button
              // onClick={(e) => setPeticionActiva(false)}
              className="m-5"
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
          </div>
        </div>,
        {
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: false,
          containerId: "crud_abstracts",
          toastId: "confirm_delete",
        }
      );
    }
  };

  const confirmarEliminarAbstract = (id_abstract) => {
    setAnchorEl(null);
    setSelectedMenu(null);
    toast.dismiss("confirm_delete");
    abstract_to_delete = id_abstract;
    setSnackbar("", "alert_dialog");
  };

  const handleEliminarAbstract = () => {
    let form = {
      id_abstract: abstract_to_delete,
      id_evento: props.id_evento,
    };
    deleteAbstract(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          "Hubo un error al tratar de eliminar el borrador. Intentelo de nuevo más tarde",
          "error"
        );
      } else {
        setSnackbar("El borrador ha sido eliminado correctamente", "success");
        dispatch(Actions.getAbstractsUsuario(abstract_data.id_usuario));
      }
    });
  };

  const handleClickOpen = () => {
    //comprobamos si hay mas de un tipo de comunicacion
    //en caso de ser solo 1, no mostramos el modal intermedio de seleccion
    //de tipo de comunicacion

    if (abstract_data.tipos_abstract.length === 1) {
      dispatch(Actions.setCurrentTipoAbstract(abstract_data.tipos_abstract[0]));
      if (abstract_data.tipos_abstract[0].grupal === 0) {
        setDisplayModalIndividual(true);
      } else {
        setDisplayModalGrupal(true);
      }
    } else {
      setDisplayModalTipoAbstract(true);
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   dispatch(Actions.setCoAutores([]));
  // };

  const loadAbstract = (row) => {
    dispatch(Actions.setCurrentAbstract(row));
    //OBTENEMOS LOS DATOS DEL TIPO DE ABSTRACT
    let id_tipo_actual = row.id_tipo_abstract;
    let tipos_abstract = abstract_data.tipos_abstract;
    let [tipo_actual] = tipos_abstract.filter(
      (tipo) => tipo.ID == id_tipo_actual
    );
    console.log("Tipo actual!!", tipo_actual);
    dispatch(Actions.setCurrentTipoAbstract(tipo_actual));
    //si el tipo de abstract es grupal, cargamos aportaciones
    if (tipo_actual.grupal == 1) {
      dispatch(Actions.getAportaciones(row.ID));
    }

    props.handleNext();
  };

  // const addCoAutor = (e) => {
  //   if (nombre.length < 3 || dni.length < 9) {
  //     setSnackbar(
  //       "Alguno de los datos del Co-Autor no es correcto o esta incompleto. Reviselo e intentelo de nuevo",
  //       "error"
  //     );
  //   } else {
  //     //comprobamos si el dni o el email ya se han utilizado en un co-autor anterior
  //     let repetido = false;
  //     coautores.map((row) => {
  //       if (row.dni == dni || row.email == email) {
  //         repetido = true;
  //       }
  //     });
  //     if (repetido) {
  //       setSnackbar(
  //         "Ya has incluido este Dni y/o email en un co-autor.",
  //         "error"
  //       );
  //     } else {
  //       let newCoAutor = {
  //         nombre,
  //         dni: dni.toUpperCase(),
  //         email,
  //       };
  //       setCoautores([...coautores, newCoAutor]);
  //       dispatch(Actions.setCoAutores([...coautores, newCoAutor]));
  //       //Limpiamos los datos del formulario
  //       setNombre("");
  //       setDni("");
  //       setEmail("");

  //       console.log(coautores);
  //     }
  //   }
  // };

  // const deleteCoAutor = (index) => {
  //   let aux_array = coautores;
  //   aux_array.splice(index, 1);
  //   setCoautores(aux_array);
  //   dispatch(Actions.setCoAutores(aux_array));
  // };

  const handleOpenTablaAutores = (row) => {
    setAnchorEl(null);
    setSelectedMenu(null);
    setDisplayModalTablaAutores(true);
    dispatch(Actions.setCurrentAbstract(row));
    dispatch(Actions.getAutores(row.ID));
  };

  const handleOpenOrdenarAutores = (row) => {
    setAnchorEl(null);
    setSelectedMenu(null);
    setDisplayModalOrdenarAutores(true);
    dispatch(Actions.setCurrentAbstract(row));
    dispatch(Actions.getAutores(row.ID));
  };

  useEffect(() => {
    dispatch(Actions.getAbstractsUsuario(abstract_data.id_usuario));
    dispatch(Actions.getUsuarioAbstracts(abstract_data.id_usuario));
    dispatch(Actions.resetAutores());
  }, [dispatch]);

  if (!abstract_data.abstracts || !abstract_data.usuario) {
    return <Loading loadingMessage="Cargando..." />;
  }
  const fechaHoy = new Date();
  let fechaFin = new Date(abstract_data.evento.data.result.fecha_fin_abstract);
  fechaFin.setHours(fechaFin.getHours() - 1);

  let crearDeshabilitado = false;
  if (fechaHoy >= fechaFin) {
    crearDeshabilitado = true;
  }
  // True creado para que se deshabilite el boton

  return (
    <div className="w-full">
      <ToastContainer
        enableMultiContainer
        containerId="crud_abstracts"
        transition={Slide}
      />
      {/* BOTON DE CREAR NUEVA COMUNICACIÓN */}
      {abstract_data.abstracts && (
        <div>
          {abstract_data.evento.data.result.max_abstracts >
            abstract_data.abstracts.data.result.length && (
            <Grid
              container
              className="mt-20 mb-56 flex items-center justify-center text-center"
            >
              <Button
                disabled={crearDeshabilitado}
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
              >
                <Icon className="mr-5">add_circle</Icon> Crear Nueva
                Comunicación
              </Button>
            </Grid>
          )}
        </div>
      )}
      {/* LISTADO DE ABSTRACTS DEL AUTOR */}
      <Grid
        container
        className="w-full flex items-center justify-center text-center"
      >
        {abstract_data.abstracts.data.result.map((row, index) => {
          let estado = "Pendiente";
          if (row.publicado) {
            switch (row.aceptado) {
              case 0:
                estado = "Rechazado";
                break;
              case 1:
                estado = "Aceptado";
                break;
              case 2:
                estado = "Pendiente";
                break;
              case 3:
                estado = "Pendiente comunicación final";
                break;
              case 4:
                estado = "Pendiente de revisar comunicación final";
                break;
              case 5:
                estado = "Rechazada comunicación final";
                break;
              default:
                estado = "Pendiente";
                break;
            }
          } else {
            estado = "Borrador";
          }

          return (
            <Paper className="max-w-lg w-xl p-10 m-10 bg-gray-300">
              <Grid container>
                <Grid item xs={2}>
                  <p className="font-bold">ID: {row.num_abstract}</p>
                  <p>
                    {/* <b>Titulo:</b> */}
                    {row.titulo}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p className="font-bold">
                    <b>Estado</b>
                  </p>
                  <p>{estado}</p>
                </Grid>
                <Grid item xs={2}>
                  <p className="font-bold">
                    <b>Tipo</b>
                  </p>
                  <p>{row.nombre_tipo}</p>
                </Grid>
                <Grid item xs={2}>
                  <p className="font-bold">
                    <b>Nota Provisional</b>
                  </p>
                  <p>{row.calificacion_provisional}</p>
                </Grid>
                {/* <Grid item xs={2}>
                  <p className="font-bold">
                    <b>Nota Final</b>
                  </p>
                  <p>{row.calificacion}</p>
                </Grid> */}
                {row.evaluacion_anticipada == 1 && (
                  <Grid item xs={1}>
                    <Tooltip title="Evaluacion Anticipada">
                      <IconButton aria-label="eval_anticipada">
                        <Icon>update</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid className="text-right" item xs={2}>
                  <IconButton
                    onClick={(e) => handleClickMenu(e, row.ID)}
                    aria-controls={`menu-${row.ID}`}
                    aria-haspopup="true"
                    aria-label="Opciones"
                  >
                    <button variant="contained" color="primary">
                      Opciones
                    </button>
                  </IconButton>
                  <Menu
                    id={`menu-${row.ID}`}
                    anchorEl={anchorEl}
                    keepMounted
                    // open={Boolean(anchorEl)}
                    open={selectedMenu == row.ID}
                    onClose={handleCloseMenu}
                  >
                    {!row.evaluacion_anticipada && (
                      <MenuItem
                        id={row.ID}
                        disabled={crearDeshabilitado}
                        onClick={(e) => loadAbstract(row)}
                      >
                        <Icon className="mr-5">edit</Icon>Ver/Editar
                        comunicación
                      </MenuItem>
                    )}
                    {row.grupal == 0 && (
                      <MenuItem
                        id={row.ID}
                        onClick={(e) => handleOpenTablaAutores(row)}
                      >
                        <Icon className="mr-5">people</Icon>Editar autores
                      </MenuItem>
                    )}
                    {row.grupal == 0 && (
                      <MenuItem
                        id={row.ID}
                        onClick={(e) => handleOpenOrdenarAutores(row)}
                      >
                        <Icon className="mr-5">low_priority</Icon>Orden de
                        autores
                      </MenuItem>
                    )}
                    {!row.publicado && (
                      <MenuItem
                        id={row.ID}
                        onClick={(e) => confirmarEliminarAbstract(row.ID)}
                      >
                        <Icon className="mr-5">delete</Icon>Eliminar
                        Comunicación
                      </MenuItem>
                    )}
                    {row.aceptado === 3 && (
                      <MenuItem id={row.ID} onClick={(e) => loadAbstract(row)}>
                        <Icon className="mr-5">edit</Icon> Enviar segunda
                        entrega
                      </MenuItem>
                    )}
                  </Menu>

                  {/* {!row.evaluacion_anticipada && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-5"
                      onClick={(e) => loadAbstract(row)}
                    >
                      Abrir
                    </Button>
                  )}
                  {!row.publicado && (
                    <IconButton
                      className=" cursor-pointer"
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={(e) => confirmarEliminarAbstract(row.ID)}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  )} */}
                </Grid>
              </Grid>
            </Paper>
          );
        })}
      </Grid>

      <ModalTiposAbstract
        handleNext={props.handleNext}
        displayModalTipoAbstract={displayModalTipoAbstract}
        setDisplayModalTipoAbstract={setDisplayModalTipoAbstract}
      />
      <ModalCrearAbstract
        handleNext={props.handleNext}
        displayModalIndividual={displayModalIndividual}
        setDisplayModalIndividual={setDisplayModalIndividual}
        tipoAbstract={abstract_data.tipos_abstract[0]}
      />
      <ModalCrearAbstractGrupal
        handleNext={props.handleNext}
        displayModalGrupal={displayModalGrupal}
        setDisplayModalGrupal={setDisplayModalGrupal}
        tipoAbstract={abstract_data.tipos_abstract[0]}
      />
      <ModalTablaAutores
        displayModalTablaAutores={displayModalTablaAutores}
        setDisplayModalTablaAutores={setDisplayModalTablaAutores}
      />
      {currentAbstract && autores && (
        <ModalOrdenarAutores
          displayModalOrdenarAutores={displayModalOrdenarAutores}
          setDisplayModalOrdenarAutores={setDisplayModalOrdenarAutores}
        />
      )}
    </div>
  );
};

export default withReducer("Abstract", reducer)(VistaCrear);
