class FormularioInscripcion {
  constructor({
    nombre = "",
    primerApellido = "",
    segundoApellido = "",
    correo = "",
    custom = [],
  }) {
    this.nombre = nombre;
    this.primerApellido = primerApellido;
    this.segundoApellido = segundoApellido;
    this.correo = correo;
    this.custom = custom;
  }
}

export default FormularioInscripcion;
