import * as Actions from '../actions';

const initialState = {
    data: null
};

const tablaEventosReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_TABLA_EVENTOS:
            return {
                ...state,
                data: {...action.payload}
            };
        default:
            return state;
    }
};

export default tablaEventosReducer;