import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// MATERIAL - UI
import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// SERVICES
import {
  actualizarNombreFacturaPorIdCompra,
  subirDocumentoS3,
} from "app/services/eventosService";

// REPOSITORY
import {
  facturaYaExiste,
  obtenPrefijoSufijo,
  obtenIdUnicoFactura,
  obtenDatosFactura,
  obtenPieFactura,
  obtenImagenEventoEnBase64,
  obtenEventoPorId,
  enviarCorreoEventoOnline,
  enviarCorreoEventoPresencial,
} from "./RedsysPaymentSuccesful.repository";

// COMPONENTS
import Loading from "../../Loading";
import NotFound from "app/main/apps/components/NotFound";
import { useDispatch } from "react-redux";
import { cambiarMensajePantallaCarga } from "../../../store/actions";

// UTILS
import { GeneradorFactura } from "app/components/iframe/utils";
import { completeNumber } from "app/model/Helper";
import {
  concatThemeToRoute,
  mountSearchWithTheme,
  resetBackgrounds,
} from "app/components/iframe/utils/Styler";

import { themes } from "../../../styles";
import { withStyler } from "../../wrappers";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100%",
  },
  paper: {
    margin: "0 auto",
    width: "50%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
    textAlign: "center",
  },
  subtitle: {
    marginTop: "20px",
  },
});

const RedsysPaymentSuccesful = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const eventId = props.match.params.eventId;
  const buyId = props.match.params.buyId;
  const idioma = props.match.params.idioma;
  const [wrongAccess, setWrongAccess] = useState(false);
  const [UIIsLoading, setUIIsLoading] = useState(true);
  // const [backgroundColor, setBackgroundColor] = useState("white");
  // const [theme, setTheme] = useState("");
  const history = useHistory();

  useEffect(() => {
    cambiarMensajeCargando(t("Recibiendo datos de la pasarela..."));
    // applyTheme();
    if (window.location.href.split("&")[1] !== undefined) {
      quitarDatosEnviadosPorRedsys();
    } else {
      i18n.changeLanguage(idioma);
      determinarAccion();
    }
    // eslint-disable-next-line
  }, [window.location.href]);

  // const applyTheme = () => {
  //   const queryParameters = props.location.search;
  //   console.log("QUERY PARAMETERS", queryParameters);
  //   const temaEscogido = queryParameters.split("=")[1];

  //   if (!temaEscogido) return;

  //   if (temaEscogido === themes.LIGHT) {
  //     setBackgroundColor("transparent");
  //     setTheme(themes.LIGHT);
  //     resetBackgrounds();
  //   }
  // };

  const cambiarMensajeCargando = (msg) => {
    dispatch(cambiarMensajePantallaCarga(msg));
  };

  const quitarDatosEnviadosPorRedsys = () => {
    let queryParameters = props.location.search;
    let tema = queryParameters.split("&")[0].split("=")[1];
    let pathname = `/buy/${idioma}/event/payment/redsys/success/${eventId}/${buyId}`;
    history.push({ pathname, search: mountSearchWithTheme(tema) });
  };

  const determinarAccion = async () => {
    const loHaHecho = await haRefrescado();

    if (loHaHecho === null) {
      // error en la peticion
      setWrongAccess(true);
      return;
    }

    if (loHaHecho) {
      // ya está todo enviado, no hay nada más que hacer.
      setUIIsLoading(false);
      return;
    }

    enviarProductosPagados();
  };

  const haRefrescado = async () => await facturaYaExiste(buyId);

  const enviarProductosPagados = async () => {
    const resultados = await Promise.all([
      obtenPrefijoSufijo(eventId),
      obtenIdUnicoFactura(eventId),
      obtenDatosFactura(eventId, buyId),
      obtenPieFactura(buyId),
      obtenEventoPorId(eventId),
    ]);

    if (resultados.some((resultado) => resultado === null)) {
      // TODO: Poner pantalla de error.
      return;
    }

    const [
      { prefijo, sufijo },
      idUnico,
      datosFactura,
      pieFactura,
      evento,
    ] = resultados;

    const nombreImagen = datosFactura.credentials.organizer.img_evento;
    const imagenBase64 = await obtenImagenEventoEnBase64(eventId, nombreImagen);
    const nombreFactura = prefijo + completeNumber(idUnico) + sufijo;

    /**
     * CAMBIAR IDIOMA AL DESEADO PARA LOS DOCUMENTOS
     */
    const idiomaUsuario = i18n.language;
    const idiomaDocs = evento.idioma;
    localStorage.setItem("language", idiomaDocs);
    i18n.changeLanguage(idiomaDocs);

    const doc = await new GeneradorFactura(
      datosFactura,
      nombreFactura,
      imagenBase64,
      pieFactura,
      t
    )
      .createPDF()
      .toBase64();

    subirDocumentoS3("factura", nombreFactura, eventId, doc);

    /**
     * VOLVER A DEJAR EL IDIOMA COMO EL USUARIO LO HABIA ESCOGIDO
     */
    localStorage.setItem("language", idiomaUsuario);
    i18n.changeLanguage(idiomaUsuario);

    await actualizarNombreFacturaPorIdCompra(buyId, nombreFactura);
    enviarCorreos(evento.online);

    setUIIsLoading(false);
  };

  const enviarCorreos = (tipoEvento) => {
    if (tipoEvento === 0) {
      // 0 === PRESENCIAL
      enviarCorreoEventoPresencial(buyId);
    } else {
      // 1 === ONLINE
      enviarCorreoEventoOnline(buyId);
    }
  };

  if (wrongAccess) return <NotFound />;
  if (UIIsLoading)
    return (
      <Loading
        backgroundColor={props.styles.backgroundColor}
        message={t("Recibiendo datos de la pasarela...")}
      />
    );

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: props.styles.backgroundColor }}
    >
      <Paper
        className={classes.paper}
        style={{ backgroundColor: props.styles.backgroundColor }}
      >
        <Grid container justify="center">
          <Grid item xs={12}>
            <CheckCircleIcon
              style={{
                color: green[500],
                fontSize: 100,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 20 }}>
              {t("¡Pago realizado correctamente!")}
            </Typography>
            <Typography className={classes.subtitle}>
              {t(
                "Transcurridos unos minutos, recibirá un correo con toda la información."
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default withStyler(RedsysPaymentSuccesful);
