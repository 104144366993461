import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mime from "mime-types";

//Variables de entorno
import Global from "../../../../fuse-configs/Global";

//COMPONENTS
//MATERIAL UI
import {
  Grid,
  Button,
  Icon,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//REDUX
import * as Actions from "../../store/actions";

//SERVICES
import {
  uploadAbstractFile,
  deleteAbstractFile,
} from "../../../../services/eventosService/abstractUploadService";

const BoxArchivosAbstract = (props) => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const id_evento = abstract_data.evento.data.result.ID;
  const id_abstract = abstract_data.currentAbstract.ID;
  const {
    max_archivos,
    tam_max_archivo,
    extensiones_permitidas,
  } = abstract_data.currentTipoAbstract;
  let urls_adjuntos = abstract_data.currentAbstract.urls_adjuntos;
  if (urls_adjuntos) {
    urls_adjuntos = JSON.parse(urls_adjuntos);
  }

  //obtenemos array de mime-types a partir de las extensiones indicadas
  let check_extensiones = false;
  let array_mime_types = [];
  if (extensiones_permitidas) {
    if (JSON.parse(extensiones_permitidas).length > 0) {
      check_extensiones = true;
      JSON.parse(extensiones_permitidas).map((row) => {
        array_mime_types.push(mime.lookup(row));
      });
    }
  }
  console.log("ESTE ES EL ARRAY DE MIME-TYPES PERMITIDOS:", array_mime_types);

  const handleFileUpload = (e) => {
    let file = e.target.files[0];
    console.log("Este es el archivo seleccionado:");
    console.log(e.target.files[0]);
    //comprobamos si se ha elegido archivo o no
    if (!file) {
      console.log("no se ha seleccionado archivo");
      return;
    }
    //comprobamos el tamaño del archivo en caso de tener limite
    if (tam_max_archivo > 0) {
      if (tam_max_archivo * 1024 * 1024 < file.size) {
        setSnackbar(
          `No es posible subir archivos que pesen más de ${tam_max_archivo}Mb`,
          "error"
        );
        return;
      }
    }
    //comprobamos el número de archivos subidos
    let archivos_subidos;
    if (!urls_adjuntos) {
      archivos_subidos = 0;
    } else {
      archivos_subidos = urls_adjuntos.length;
    }
    if (max_archivos) {
      if (max_archivos <= archivos_subidos) {
        setSnackbar(
          `No es posible subir más de ${max_archivos} archivos.`,
          "error"
        );
        return;
      }
    }
    //comprobamos tipo de archivo si se ha configurado
    if (check_extensiones) {
      let array_match = array_mime_types.filter((row) => row === file.type);
      if (array_match.length === 0) {
        let str_extensiones = "";
        JSON.parse(extensiones_permitidas).map((row, index) => {
          if (index == 0) {
            str_extensiones += `.${row}`;
          } else {
            str_extensiones += `, .${row}`;
          }
        });
        setSnackbar(
          `Solo es posible subir archivos con las siguientes extensiones (${str_extensiones})`,
          "error"
        );
        return;
      }
    }
    let fd = new FormData();
    fd.append("file", file, file.name);
    uploadAbstractFile(id_evento, id_abstract, fd).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          "Hubo un error al subir el archivo, intentelo de nuevo más tarde",
          "error"
        );
      } else {
        setSnackbar(
          `El archivo ${file.name} fue subido correctamente.`,
          "success"
        );
        //actualizamos los datos del abstract para ver la nueva lista de archivos subidos
        dispatch(Actions.refreshCurrentAbstract(id_abstract));
      }
    });
  };

  const handleDeleteFile = (filename) => {
    let form = {
      id_evento,
      id_abstract,
      filename,
    };
    deleteAbstractFile(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          "Hubo un error al intentar borrar el archivo. Intentelo de nuevo más tarde.",
          "error"
        );
      } else {
        setSnackbar("El archivo fue borrado correctamente", "success");
        dispatch(Actions.refreshCurrentAbstract(id_abstract));
      }
    });
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "subir_archivos",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "subir_archivos",
      });
    }
  };

  return (
    <Grid item xs={4}>
      <ToastContainer containerId="subir_archivos" enableMultiContainer />
      <Paper className="ml-5 pt-10 pb-10" elevation={4}>
        <p>Subir archivos:</p>
        {max_archivos > 0 && (
          <Grid item className="text-11 text-gray-500">
            (Máximo {max_archivos} archivos)
          </Grid>
        )}
        <input
          // className={classes.input}
          style={{ display: "none" }}
          id="raised-button-file"
          // multiple
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="raised-button-file">
          <Button
            className="mt-10 mb-10"
            variant="contained"
            color="primary"
            component="span"
            // className={classes.button}
          >
            <Icon>attach_file</Icon> Subir Archivos
          </Button>
        </label>
        {urls_adjuntos &&
          urls_adjuntos.map((row) => {
            return (
              <Paper className="p-5 m-5" elevation={3}>
                <Grid container>
                  <Grid
                    item
                    xs={10}
                    className=" cursor-pointer"
                    onClick={(e) =>
                      window.open(
                        `${Global.url_s3_bucket}/abstracts/${id_evento}/${id_abstract}/${row}`
                      )
                    }
                  >
                    <Icon>insert_drive_file</Icon>
                    <p className="text-10">{row}</p>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Eliminar" aria-label="add">
                      <IconButton
                        className=" cursor-pointer"
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={(e) => handleDeleteFile(row)}
                      >
                        <Icon fontSize="small">delete</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
      </Paper>
    </Grid>
  );
};

export default BoxArchivosAbstract;
