import { takeEvery, put } from "redux-saga/effects";
import { FormularioInscripcion } from "../../model";
import {
  CARGA_FORMULARIOS_SOLICITADA,
  cargaFormulariosErronea,
  cargaFormulariosFinalizada,
  guardarFormulariosInscripcion,
} from "../actions";

export function* observador() {
  yield takeEvery(CARGA_FORMULARIOS_SOLICITADA, cargaFormularios);
}

export function* cargaFormularios(action) {
  try {
    let formulariosRaw = action.payload;
    let formularios = [];
    let custom;

    formulariosRaw.map((f) => {
      const { camposCustom, cantidadEntradas } = f;

      for (let i = 0; i < cantidadEntradas; i++) {
        custom = [];

        if (camposCustom && camposCustom.length > 0) {
          custom = camposCustom.map((c) => ({ ...c, value: "" }));
          // Ordena campos custom tal como el que creó el campo, especificó
          custom = custom.sort((a, b) => (a.orden > b.orden ? 1 : -1));
        }

        formularios.push(new FormularioInscripcion({ custom }));
      }

      return f;
    });

    yield put(guardarFormulariosInscripcion(formularios));
  } catch (e) {
    yield put(cargaFormulariosErronea(e));
  } finally {
    yield put(cargaFormulariosFinalizada());
  }
}
