export default {
  container: {
    margin: "0 auto",
    width: "50%",
    maxWidth: "500px",
    padding: "20px",
    marginTop: "20px",
    textAlign: "center",
  },
};
