import moment from "moment";
import { parseForShowing } from "app/model/Helper";
import { styles } from "./GeneradorProforma.styles";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DatosProforma } from "../DatosProforma";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class GeneradorProforma {
  constructor(datosProfoma = new DatosProforma(), t) {
    this.definicionDocumento = generarProforma(datosProfoma, t);
    this.pdf = null;
  }

  createPDF() {
    this.pdf = pdfMake.createPdf(this.definicionDocumento);
    return this;
  }

  toBlob() {
    return new Promise((res, rej) => {
      if (!this.pdf) rej("PDF does not exist");
      this.pdf.getBlob(res);
    });
  }

  toBase64() {
    return new Promise((res, rej) => {
      if (!this.pdf) rej("PDF does not exist");
      this.pdf.getBase64(res);
    });
  }

  abrirEnNavegador() {
    this.pdf.open();
  }

  descargarPDF(nombrePDF) {
    this.pdf.download(nombrePDF, (err) => {
      console.log("ERROR AL DESCARGAR", err);
    });
  }
}

const generarProforma = (datosProforma = new DatosProforma(), t) => {
  if (!datosProforma.politicaPrivacidad) {
    // Si la politica está vacía, ponemos la de por defecto
    datosProforma.politicaPrivacidad = proformaRGPD(
      datosProforma.evento.organizador.nombre,
      datosProforma.evento.organizador.cif,
      datosProforma.evento.organizador.direccion,
      datosProforma.evento.organizador.email,
      t
    );
  }

  let {
    groupedComplements,
    groupedInscriptions,
  } = agrupaInscripcionesYComplementos(datosProforma.inscripciones);

  let { tableRows, grossTotal } = generateTableRows(
    groupedComplements,
    groupedInscriptions,
    t
  );

  let datosFooter = {
    precioBrutoQueDeberiaSer: grossTotal,
    total: datosProforma.costes.total,
    iva: datosProforma.costes.iva,
    costesDistribucionBrutos: datosProforma.costes.distribucionBrutos,
  };

  let tableFooter = generateTableFooter(datosFooter, t);

  let assistantInfo = generateAssistantInfo(datosProforma.inscripciones);

  let content = [];

  if (datosProforma.evento.imagen) {
    content.push({
      image: `data:image/jpeg;base64,${datosProforma.evento.imagen}`,
      fit: [80, 80],
      alignment: "left",
      style: "eventLogo",
    });
  }

  content = [
    ...content,
    [
      {
        stack: [
          {
            text: datosProforma.evento.organizador.nombre,
            style: "subheaderTop",
          },
          {
            text: datosProforma.evento.organizador.direccion,
            style: "subheader",
          },
          {
            text: datosProforma.evento.organizador.municipio,
            style: "subheader",
          },
          { text: datosProforma.evento.organizador.pais, style: "subheader" },
        ],
        style: "headerLeft",
      },
      {
        stack: [
          { text: t("FACTURA PROFORMA"), style: "titleLeft" },
          { text: datosProforma.codigoDocumento, style: "subtitleLeft" },
          { text: moment().format("DD/MM/YY"), style: "date" },
        ],
        style: "header",
      },
      {
        text: t("CIF:") + ` ${datosProforma.evento.organizador.cif}`,
        style: "cifEmpresa",
      },
      {
        stack: [
          t("FACTURA PROFORMA A:"),
          {
            text: datosProforma.datosFacturacion.nombre.toUpperCase(),
            style: "clientDataLine",
          },
          {
            text: datosProforma.datosFacturacion.cif.toUpperCase(),
            style: "clientDataLine",
          },
          {
            text: datosProforma.datosFacturacion.telefono,
            style: "clientDataLine",
          },
          {
            text: datosProforma.datosFacturacion.direccion,
            style: "clientDataLine",
          },
        ],
        style: "clientData",
      },
      {
        text: datosProforma.evento.nombre,
        style: "eventData",
      },
      {
        style: "tableConcepts",
        table: {
          body: tableRows,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.3;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },

      {
        style: "tableExample",
        table: {
          body: tableFooter,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#F3F3F3"
              : "#F3F3F3";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "gray" : "gray";
          },
        },
      },
      {
        text: t("DATOS DEL/LOS INSCRITOS:"),
        style: "assistantSubtitle",
      },
      ...assistantInfo,
      generarPieDatosTransferenciaProforma(
        datosProforma.cuentaTransferencia,
        t
      ),
      {
        text: datosProforma.politicaPrivacidad,
        style: "rgpd",
      },
    ],
  ];

  let docDefinition = {
    content,
    styles,
  };

  return docDefinition;
};

let generateTableRows = (groupedComplements, groupedInscriptions, t) => {
  let tableRows = [
    [
      { text: t("CONCEPTO"), style: "concept" },
      { text: t("IMPORTE"), style: "cost" },
      { text: t("IVA"), style: "iva" },
      { text: t("Total"), style: "total" },
    ],
  ];

  let iva;
  let gross;
  let totalWithTaxes;
  let grossTotal = 0;

  groupedInscriptions.map(
    ({ nombreDocumento, precio, cantidad, iva_aplicado, precioAplicado }) => {
      gross = precioAplicado / (1 + iva_aplicado / 100);
      gross = parseFloat(gross.toFixed(2));
      iva = gross * (iva_aplicado / 100);
      iva = parseFloat(iva.toFixed(2));
      totalWithTaxes = precioAplicado;
      totalWithTaxes = parseFloat(totalWithTaxes.toFixed(2));

      let row = [
        {
          text: nombreDocumento.toUpperCase() + " (x" + cantidad + ")",
          style: "conceptCell",
        },
        { text: parseForShowing(gross) + "€", style: "centerCell" },
        { text: iva_aplicado + "%", style: "centerCell" },
        { text: parseForShowing(totalWithTaxes) + "€", style: "centerCell" },
      ];

      tableRows.push(row);

      grossTotal += gross;

      return true;
    }
  );

  groupedComplements.map(
    ({ nombreDocumento, precio, cantidad, iva_aplicado, precioAplicado }) => {
      gross = precioAplicado / (1 + iva_aplicado / 100);
      gross = parseFloat(gross.toFixed(2));
      iva = gross * (iva_aplicado / 100);
      iva = parseFloat(iva.toFixed(2));
      totalWithTaxes = precioAplicado;
      totalWithTaxes = parseFloat(totalWithTaxes.toFixed(2));

      let row = [
        {
          text: nombreDocumento.toUpperCase() + " (x" + cantidad + ")",
          style: "conceptCell",
        },
        { text: parseForShowing(gross) + "€", style: "centerCell" },
        { text: iva_aplicado + "%", style: "centerCell" },
        { text: parseForShowing(totalWithTaxes) + "€", style: "centerCell" },
      ];

      tableRows.push(row);

      grossTotal += gross;

      return true;
    }
  );

  return { tableRows, grossTotal };
};

let generateTableFooter = (datos, t) => {
  let { precioBrutoQueDeberiaSer, total, iva } = datos;
  let { costesDistribucionBrutos } = datos;

  let tableFooter = [
    // Columna vacía para definir tamaños de la tabla
    [
      { text: "", style: "concept" },
      { text: "", style: "cost" },
      { text: "", style: "iva" },
      { text: "", style: "total" },
    ],
    // Total neto
    [
      { text: "", style: "blank" },
      { text: t("Subtotal"), style: "bottomFooterText" },
      {
        text: `${parseForShowing(precioBrutoQueDeberiaSer)}€`,
        style: "bottomCenter",
      },
      { text: "", style: "blankBetween" },
    ],
  ];

  if (Number(costesDistribucionBrutos) > 0) {
    // Si no hay gastos de distribución, no lo añadimos al pie de la tabla
    tableFooter = [
      ...tableFooter,
      // Gastos de distribución brutos
      [
        { text: "", style: "blank" },
        { text: t("Gastos dist."), style: "bottomFooterText" },
        {
          text: `${parseForShowing(costesDistribucionBrutos)}€`,
          style: "bottomCenter",
        },
        { text: "", style: "blankBetween" },
      ],
    ];
  }

  if (datos.descuento) {
    // Descuento
    tableFooter.push([
      { text: "", style: "blank" },
      { text: t("Descuento"), style: "bottomFooterText" },
      {
        text: `- ${parseForShowing(datos.descuento)}€`,
        style: "bottomCenterLess",
      },
      { text: "", style: "blankBetween" },
    ]);
  }

  tableFooter = [
    ...tableFooter,
    // IVA aplicado (en valor absoluto)
    [
      { text: "", style: "blank" },
      { text: t("IVA"), style: "bottomFooterText" },
      {
        text: `${parseForShowing(iva)}€`,
        style: "bottomCenter",
      },
      { text: "", style: "blankBetween" },
    ],
    [
      { text: "", style: "blank" },
      { text: "_________", style: "bottomLine" },
      "",
      "",
    ],
    // Total
    [
      { text: "", style: "blank" },
      { text: t("Total"), style: "bottomFooterText" },
      { text: `${parseForShowing(total)}€`, style: "bottomCenter" },
      { text: "", style: "blankBetween" },
    ],
  ];

  return tableFooter;
};

let generateAssistantInfo = (inscriptions) => {
  let pdfElement = { text: "", style: "assistantInfoLine" };

  let pdfElements = [];

  inscriptions.map(({ formulario }) => {
    pdfElement.text = `- ${formulario.nombre} ${formulario.primerApellido} ${formulario.segundoApellido}`;
    pdfElements.push({ ...pdfElement });

    return formulario;
  });

  return pdfElements;
};

const generarPieDatosTransferenciaProforma = (cuentaTransferencia, t) => {
  if (cuentaTransferencia === "" || !cuentaTransferencia) {
    return {};
  }

  return {
    stack: [
      t("Cuenta de pago:") + ` ${cuentaTransferencia}`,
      t("Estado de pago: Pendiente"),
    ],
    style: "datosTransferencia",
  };
};

const proformaRGPD = (company, cif, address, email, t) => {
  let rgpd = t("La información contenida en este documento(s), enviada desde");
  rgpd += ` ${company} `;
  rgpd += t("con C.I.F.");
  rgpd += ` ${cif} `;
  rgpd += t(
    "es confidencial/privilegiada y está destinada a ser leída sólo por la(s) persona(s) a la(s) que va dirigida. Le recordamos que sus datos han sido incorporados en el sistema de tratamiento de"
  );
  rgpd += ` ${company} `;
  rgpd += t(
    "y que siempre y cuando se cumplan los requisitos exigidos por la normativa, usted podrá ejercer sus derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición/revocación, en los términos que establece la normativa vigente en materia de protección de datos, dirigiendo su petición a la dirección postal"
  );
  rgpd += ` ${address} `;
  rgpd += t("o bien a través de correo electrónico");
  rgpd += ` ${email}. `;
  rgpd += t(
    "Si usted lee este documento y no es el destinatario señalado, el empleado o el agente responsable de entregar el mensaje al destinatario, o ha recibido esta comunicación por error, le informamos que está totalmente prohibida, y puede ser ilegal, cualquier divulgación, distribución o reproducción de esta comunicación, y le rogamos que nos lo notifique inmediatamente y nos devuelva el mensaje original a la dirección arriba mencionada."
  );
  return rgpd;
};

const agrupaInscripcionesYComplementos = (inscriptions) => {
  let groupedComplements = [];
  let groupedInscriptions = [];
  let inscriptionMatchIndex = 0;
  let complementMatchIndex = 0;

  inscriptions.map((inscription) => {
    inscriptionMatchIndex = groupedInscriptions.findIndex(
      (giInscription) => giInscription.ID == inscription.ID
    );

    /* MAPEO DE INSCRIPCIONES */
    // si ya se ha encontrado previamente, se añade +1 a cantidad
    if (inscriptionMatchIndex != -1) {
      groupedInscriptions[inscriptionMatchIndex].cantidad++;
      groupedInscriptions[inscriptionMatchIndex].precioAplicado +=
        inscription.precioAplicado;
    }
    // sino, se añade
    else {
      inscription["cantidad"] = 1;
      groupedInscriptions.push({ ...inscription });
    }

    /* MAPEO DE COMPLEMENTOS */
    inscription.complementos.map((complement) => {
      if (complement.escogido) {
        complementMatchIndex = groupedComplements.findIndex(
          (gcComplement) => gcComplement.ID == complement.ID
        );
        // si ya se ha encontrado previamente, se añade +1 a cantidad
        if (complementMatchIndex != -1) {
          groupedComplements[complementMatchIndex].cantidad++;
          groupedComplements[complementMatchIndex].precioAplicado +=
            complement.precioAplicado;
        }
        // sino, se añade
        else {
          complement["cantidad"] = 1;
          groupedComplements.push({ ...complement });
        }
      }

      return complement;
    });

    return inscription;
  });

  return {
    groupedComplements,
    groupedInscriptions,
  };
};
