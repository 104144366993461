import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import ModalCrearAbstract from "./ModalCrearAbstract";
import ModalCrearAbstractGrupal from "./ModalCrearAbstractGrupal";

import * as Actions from "../../store/actions";

export default function ModalTiposAbstract(props) {
  const dispatch = useDispatch();
  const [tipoAbstract, setTipoAbstract] = useState(false);
  const [displayModalIndividual, setDisplayModalIndividual] = useState(false);
  const [displayModalGrupal, setDisplayModalGrupal] = useState(false);

  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );

  let tipos_abstract = abstract_data.tipos_abstract;

  const handleClose = () => {
    props.setDisplayModalTipoAbstract(false);
    setTipoAbstract(false);
  };

  const handleSelectTipo = (tipo) => {
    let [rowTipo] = tipos_abstract.filter((row) => row.ID == tipo);
    console.log("El tipo seleccionado es", rowTipo);
    setTipoAbstract(rowTipo);
    dispatch(Actions.setCurrentTipoAbstract(rowTipo));
  };

  const handleCrear = () => {
    if (!tipoAbstract) {
      console.log("Debe seleccionar un tipo de resumen!!");
      return;
    }
    if (tipoAbstract.grupal == 0) {
      setDisplayModalIndividual(true);
      props.setDisplayModalTipoAbstract(false);
    }
    if (tipoAbstract.grupal == 1) {
      setDisplayModalGrupal(true);
      props.setDisplayModalTipoAbstract(false);
    }
    //reseteamos la lista de co-autores antes de abrir el modal de crear abstract
    dispatch(Actions.resetAutores());
  };

  return (
    <div>
      <Dialog
        open={props.displayModalTipoAbstract}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Seleccione un tipo de Comunicación:"}
        </DialogTitle>
        <DialogContent>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-filled-label">
              Tipo de comunicación
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              //   value={age}
              onChange={(e) => handleSelectTipo(e.target.value)}
            >
              {tipos_abstract.map((row) => {
                return <MenuItem value={row.ID}>{row.nombre}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCrear} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODALS */}
      <ModalCrearAbstract
        displayModalIndividual={displayModalIndividual}
        setDisplayModalIndividual={setDisplayModalIndividual}
        handleNext={props.handleNext}
        tipoAbstract={tipoAbstract}
      />
      <ModalCrearAbstractGrupal
        displayModalGrupal={displayModalGrupal}
        setDisplayModalGrupal={setDisplayModalGrupal}
        handleNext={props.handleNext}
        tipoAbstract={tipoAbstract}
      />
    </div>
  );
}
