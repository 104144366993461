import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  Paper,
  Icon,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import * as Actions from "../../store/actions";

//COMPONENTS
import ModalRecuperarPassword from "./ModalRecuperarPassword";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Services
import { LoginUsuarioAbstract } from "../../../../services/eventosService/abstractUploadService";

const LoginBox = (props) => {
  const [dniLogin, setDniLogin] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [displayModalPassword, setDisplayModalPassword] = useState(false);
  const dispatch = useDispatch();

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_login",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_login",
      });
    }
  };

  const goToRegister = () => {
    props.handleChangeIndex(1);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (password.length <= 0) {
      return;
    }
    if (password.length >= 8) {
      let form = {
        email,
        password,
        id_evento: props.id_evento,
      };
      LoginUsuarioAbstract(form).then((res) => {
        if (!res.data.status) {
          setSnackbar(
            "Error al iniciar sesión. Por favor, compruebe su email y/o password.",
            "error"
          );
        } else {
          console.log(res.data);
          dispatch(Actions.setIdUsuario(res.data.result[0].ID));
          props.handleNext();
        }
      });
    } else {
      setSnackbar("El password introducido no es válido.", "error");
    }
  };

  return (
    <Paper className="p-5 m-5 min-h-sm flex items-center justify-center">
      {/* <ToastContainer enableMultiContainer containerId="abstract_login" /> */}
      <div>
        <Icon fontSize="large">person_outline</Icon>
        <Typography variant="h6">Iniciar Sesión</Typography>
        <Grid
          container
          className="flex items-center justify-center text-center"
        >
          <Grid item className="text-12 mr-1">
            ¿No tiene una cuenta?
          </Grid>
          <Grid
            item
            onClick={goToRegister}
            className="text-12 text-blue cursor-pointer"
          >
            Registrarse
          </Grid>
        </Grid>

        <form onSubmit={handleLogin}>
          <Grid container className="flex items-center justify-center">
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    className="mt-5"
                    id="outlined-basic"
                    label="Email"
                    type="email"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className="mt-5">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className="mt-5 flex items-center justify-center text-center"
          >
            <Grid
              item
              onClick={(e) => setDisplayModalPassword(true)}
              className="text-12 text-blue cursor-pointer"
            >
              ¿Olvidaste tu contraseña?
            </Grid>
          </Grid>

          <Button
            className="mt-10"
            variant="contained"
            color="primary"
            type="submit"
          >
            Acceder
          </Button>
        </form>
      </div>
      {/* MODAL RECUPERAR PASSWORD */}
      <ModalRecuperarPassword
        displayModalPassword={displayModalPassword}
        setDisplayModalPassword={setDisplayModalPassword}
      />
    </Paper>
  );
};

export default LoginBox;
