import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

//components
import { toast, ToastContainer, Slide } from "react-toastify";

//SERVICES
import { setCategoriaAbstract } from "app/services/eventosService/abstractsService";

//ACTIONS
import * as Actions from "../store/actions";

export default function ModalAsignarCategoria(props) {
  const dispatch = useDispatch();
  const [categoria, setCategoria] = React.useState(props.currentCategory);
  const user = useSelector(({ authX }) => authX.user.data);
  const { categorias } = useSelector(({ abstracts }) => abstracts.abstracts);

  const handleClose = () => {
    props.setOpenModalCategorias(false);
  };

  const handleChange = (e) => {
    setCategoria(e.target.value);
  };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "asigna_cat",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "asigna_cat",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "asigna_cat",
      });
    }
  };

  const guardarCategoria = () => {
    setCategoriaAbstract(props.id_abstract, categoria).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          "Hubo un error al asignar la categoria. Intenlo de nuevo más tarde.",
          "error"
        );
      } else {
        setSnackbar("Categoria asociada correctamente", "success");
        props.setOpenModalCategorias(false);
        dispatch(Actions.getAbstractInfo(user.token, props.id_abstract));
      }
    });
  };

  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId="asigna_cat"
        transition={Slide}
      />
      <Dialog
        open={props.openModalCategorias}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Asignar Categoria</DialogTitle>
        <DialogContent>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-filled-label">
              Categoria
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={categoria}
              onChange={handleChange}
            >
              {categorias.map((row) => {
                return <MenuItem value={row.ID}>{row.nombre}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={guardarCategoria} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
