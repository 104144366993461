import React from "react";

export const EventosConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/eventos",
      component: React.lazy(() => import("./Eventos")),
    },
    {
      path: "/crearEventos",
      component: React.lazy(() => import("./crearEventos")),
    },
    {
      path: "/evento/:id",
      component: React.lazy(() => import("./Evento")),
    },
    {
      path: "/asistenteEvento/:id",
      component: React.lazy(() => import("./AsistenteConfig")),
    },
  ],
};
