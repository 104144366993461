import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // DEBUG controla los console.logs de i18next
    debug: false,
    lng: "es",
    // fallbackLng: "en",
    supportedLngs: ["en", "es", "ca"],
    preload: ["en", "es", "ca"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    saveMissingTo: "all",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      addPath: "/locales/add/{{lng}}/{{ns}}",
    },
    keySeparator: false,
    nsSeparator: false,
    returnEmptyString: false,
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      return key;
    },
  });
export default i18n;
