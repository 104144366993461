import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@material-ui/core";

import styles from "./InscripcionPrivadaRealizada.styles";
import { makeStyles } from "@material-ui/styles";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(styles);

const InscripcionPrivadaRealizada = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <div className={classes.iconContainer}>
        <CancelIcon className={classes.icon} color="action" />
      </div>
      <Typography className={classes.text}>
        {t("¡Inscripción ya realizada!")}
      </Typography>
    </Paper>
  );
};

export default InscripcionPrivadaRealizada;
