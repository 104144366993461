import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  TextField,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Icon,
} from "@material-ui/core";

import Global from "app/fuse-configs/Global.js";

import { toast, ToastContainer, Slide } from "react-toastify";

//ACTIONS
import * as Actions from "../store/actions";

//SERVICES
import { guardarCorreccionProvisional } from "app/services/eventosService/abstractsService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ModalCorreccionProvisional(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ authX }) => authX.user);
  const { currentAbstract, criteriosEvaluacion, abstractConfig } = useSelector(
    ({ abstracts }) => abstracts.abstracts
  );
  const [comentario, setComentario] = React.useState(
    currentAbstract.comentario_calificacion_prov
  );
  const [calificacion, setCalificacion] = React.useState(
    currentAbstract.calificacion_provisional
  );
  const [aceptado, setAceptado] = React.useState(currentAbstract.aceptado);
  const [expanded, setExpanded] = React.useState(false);
  const [criterios, setCriterios] = React.useState([]);
  let valuesCriteriosDefault = {};
  criteriosEvaluacion.map((row) => {
    valuesCriteriosDefault[row.nombre] = 0;
  });
  const [valuesCriterios, setValuesCriterios] = React.useState(
    valuesCriteriosDefault
  );
  // Esto lo puedes usar donde quieras ya, como por ejemplo en el input de Calificación Global
  const [notaMediaTotal, setNotaMediaTotal] = React.useState(0);

  useEffect(() => {
    // Recalculamos la nota media
    let sum = 0;

    for (let criterio in valuesCriterios) {
      sum += valuesCriterios[criterio];
    }
    let mediaTotal = sum / Object.keys(valuesCriterios).length;
    setNotaMediaTotal(mediaTotal);
  }, [valuesCriterios]);

  let urls_adjuntos = [];
  if (currentAbstract.urls_adjuntos) {
    urls_adjuntos = JSON.parse(currentAbstract.urls_adjuntos);
  }

  //NOMBRES/DESCR PERSONALIZADAS DE SECCIONES DE TEXTO
  let {
    seccion1_nombre,
    seccion1_descripcion,
    seccion2_nombre,
    seccion2_descripcion,
    seccion3_nombre,
    seccion3_descripcion,
    seccion4_nombre,
    seccion4_descripcion,
    seccion_aux1_nombre,
    seccion_aux1_descripcion,
  } = abstractConfig;
  seccion1_nombre = seccion1_nombre ? seccion1_nombre : "Introduccion";
  seccion1_descripcion = seccion1_descripcion
    ? seccion1_descripcion
    : "Introducción de la comunicación";
  seccion2_nombre = seccion2_nombre
    ? seccion2_nombre
    : "Cuerpo de la comunicación";
  seccion2_descripcion = seccion2_descripcion
    ? seccion2_descripcion
    : "(objetivos,método,recursos)";
  seccion3_nombre = seccion3_nombre ? seccion3_nombre : "Discusión";
  seccion3_descripcion = seccion3_descripcion
    ? seccion3_descripcion
    : "(resultados y conclusiones)";
  seccion4_nombre = seccion4_nombre ? seccion4_nombre : "Bibliografia";
  seccion4_descripcion = seccion4_descripcion
    ? seccion4_descripcion
    : "Referencias Bibliográficas";
  seccion_aux1_nombre = seccion_aux1_nombre ? seccion_aux1_nombre : "Anexo";
  seccion_aux1_descripcion = seccion_aux1_descripcion
    ? seccion_aux1_descripcion
    : "Seccion auxiliar (No cuenta para el total de palabras general)";

  // console.log("Este es el rol de usuario:");
  // console.log(user);
  // console.log(typeof user);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "corregir_abstract",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "corregir_abstract",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "corregir_abstract",
      });
    }
  };

  const handleClose = () => {
    props.setOpenModalCorreccionProvisional(false);
  };

  const handleChangeCriterios = (crit, value) => {
    //eliminamos el criterio anterior con mismo id
    let aux_array = criterios.filter((row) => row.ID !== crit.ID);
    //comprobamos que el valor introducido no esta vacio
    let new_entry = {
      ID: crit.ID,
      name: crit.nombre,
      value,
    };
    if (value.length > 0) {
      aux_array.push(new_entry);
    }
    setCriterios(aux_array);

    setValuesCriterios({
      ...valuesCriterios,
      [crit.nombre]: parseFloat(value),
    });
  };

  const handleSaveCorreccionProvisional = async () => {
    /* console.log("Comentario:", comentario);
    console.log("Calificacion:", calificacion);
    console.log("Aceptado:", aceptado);
    console.log("Criterios evaluacion", criteriosEvaluacion); */
    //comprobamos si se ha indicado la calificacion global y por criterio
    if (!notaMediaTotal || criterios.length !== criteriosEvaluacion.length) {
      setSnackbar(
        "Debe completar la calificación general y todos los criterios de evaluación",
        "error"
      );
      return;
    }
    let form = {
      comentario: comentario ? comentario : "",
      calificacion: notaMediaTotal,
      criterios: JSON.stringify(criterios),
      id_abstract: currentAbstract.ID,
    };
    let { data } = await guardarCorreccionProvisional(user.data.token, form);
    let { status } = data;
    if (status) {
      setSnackbar("Correción realizada correctamente.", "success");
      props.setOpenModalCorreccionProvisional(false);
      dispatch(Actions.getAbstractInfo(user.data.token, props.id_abstract));
      dispatch(Actions.getCorrecionesAbstract(props.id_abstract));
      dispatch(Actions.solicitaCargaAbstractsEvento(user.data.token));
    } else {
      setSnackbar(
        "Hubo un problema al guardar la correción. Intentelo de nuevo más tarde.",
        "error"
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={props.openModalCorreccionProvisional}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Correccion Provisional
        </DialogTitle>
        <DialogContent>
          <ToastContainer
            enableMultiContainer
            containerId="corregir_abstract"
            transition={Slide}
          />
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          <Grid container>
            <Grid className="p-10" item xs={8}>
              {/* SECCION 1 TEXTO */}
              <ExpansionPanel
                className="bg-gray-300"
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {seccion1_nombre}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {seccion1_descripcion}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paper
                    elevation={5}
                    // className="h-screen min-h-screen max-h-screen overflow-scroll"
                    className="h-640 overflow-scroll p-10 w-full"
                    dangerouslySetInnerHTML={{
                      __html: currentAbstract.contenido_seccion1,
                    }}
                  ></Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* SECCION 2 TEXTO */}
              <ExpansionPanel
                className="bg-gray-300"
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>
                    {seccion2_nombre}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {seccion2_descripcion}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paper
                    elevation={5}
                    // className="h-screen min-h-screen max-h-screen overflow-scroll"
                    className="h-640 overflow-scroll p-10 w-full"
                    dangerouslySetInnerHTML={{
                      __html: currentAbstract.contenido_seccion2,
                    }}
                  ></Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* SECCION 3 TEXTO */}
              <ExpansionPanel
                className="bg-gray-300"
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography className={classes.heading}>
                    {seccion3_nombre}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {seccion3_descripcion}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paper
                    elevation={5}
                    // className="h-screen min-h-screen max-h-screen overflow-scroll"
                    className="h-640 overflow-scroll p-10 w-full"
                    dangerouslySetInnerHTML={{
                      __html: currentAbstract.contenido_seccion3,
                    }}
                  ></Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* SECCION 4 TEXTO */}
              <ExpansionPanel
                className="bg-gray-300"
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.heading}>
                    {seccion4_nombre}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {seccion4_descripcion}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paper
                    elevation={5}
                    // className="h-screen min-h-screen max-h-screen overflow-scroll"
                    className="h-640 overflow-scroll p-10 w-full"
                    dangerouslySetInnerHTML={{
                      __html: currentAbstract.contenido_seccion4,
                    }}
                  ></Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* SECCION AUX 1 TEXTO */}
              <ExpansionPanel
                className="bg-gray-300"
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography className={classes.heading}>
                    {seccion_aux1_nombre}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {seccion_aux1_descripcion}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Paper
                    elevation={5}
                    // className="h-screen min-h-screen max-h-screen overflow-scroll"
                    className="h-640 overflow-scroll p-10 w-full"
                    dangerouslySetInnerHTML={{
                      __html: currentAbstract.contenido_seccion_aux1,
                    }}
                  ></Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid className="p-10" item xs={4}>
              <Paper
                className="mb-10 pt-10 pb-10 max-h-256 overflow-scroll"
                elevation={4}
              >
                <Grid
                  container
                  className="flex items-center justify-center text-center"
                >
                  <Grid item xs={12}>
                    Archivos adjuntos:
                  </Grid>
                </Grid>

                {urls_adjuntos &&
                  urls_adjuntos.map((row) => {
                    return (
                      <Paper
                        className="p-5 m-5 flex items-center justify-center text-center"
                        elevation={3}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className=" cursor-pointer"
                            onClick={(e) =>
                              window.open(
                                `${Global.url_s3_bucket}/abstracts/${currentAbstract.id_evento}/${currentAbstract.ID}/${row}`
                              )
                            }
                          >
                            <Icon>insert_drive_file</Icon>
                            <p className="text-10">{row}</p>
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })}
              </Paper>
              <TextField
                fullWidth
                id="filled-read-only-input"
                label="Calificación"
                defaultValue={0}
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
                value={notaMediaTotal}
              />
              {/* CRITERIOS DE EVALUACION */}
              <Paper className="p-5 mb-5" elevation={5}>
                <Grid container>
                  <Grid item xs={12} className="mb-5">
                    Criterios de Evaluación
                  </Grid>
                  <Grid item xs={12}>
                    {criteriosEvaluacion.map((row) => {
                      let curr_crit_arr = [];
                      if (currentAbstract.criterios_json_prov) {
                        curr_crit_arr = JSON.parse(
                          currentAbstract.criterios_json_prov
                        ).filter((row2) => row2.ID == row.ID);
                      }
                      let curr_crit = "";
                      if (curr_crit_arr.length > 0) {
                        curr_crit = curr_crit_arr[0].value;
                      }
                      return (
                        <TextField
                          fullWidth
                          // defaultValue={currentAbstract.calificacion}
                          variant="filled"
                          className="mb-10"
                          defaultValue={curr_crit}
                          type="number"
                          inputProps={{ min: 0 }}
                          label={row.nombre}
                          onChange={(e) =>
                            handleChangeCriterios(row, e.target.value)
                          }
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </Paper>
              <TextField
                id="filled-multiline-static"
                label="Comentario de corrección"
                className="mb-10"
                defaultValue={currentAbstract.comentario_calificacion_prov}
                multiline
                rows={8}
                fullWidth
                variant="filled"
                onChange={(e) => setComentario(e.target.value)}
              />
              {/* {user.role === 5 && (
                <>
                  <FormControl variant="filled" className="mb-10" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Aceptado
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      defaultValue={currentAbstract.aceptado}
                      onChange={(e) => setAceptado(e.target.value)}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Si</MenuItem>
                    </Select>
                  </FormControl>
                  <Grid className="text-11" item xs={12}>
                    Una vez seleccionada la opción, será enviado un email al
                    autor notificando el resultado de la presentación de su
                    abstract.
                  </Grid>
                </>
              )} */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSaveCorreccionProvisional}
            color="primary"
            autoFocus
          >
            Corregir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
