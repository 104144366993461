import * as Actions from "../actions";

const initialState = {
  inscripcionesGrafica: [],
};

const inscripcionesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GUARDAR_INSCRIPCIONES_GRAFICA:
      return {
        ...state,
        inscripcionesGrafica: action.payload,
      };
    default:
      return state;
  }
};

export default inscripcionesReducer;
