class RegistroInscripciones {
  constructor({
    idEvento,
    inscripciones,
    total,
    datosFacturacion,
    nombreProforma,
    notificacionesComerciales,
    idDescuento,
    iva,
    codigoTransferencia,
    idioma,
  }) {
    this.idEvento = idEvento;
    this.inscripciones = inscripciones;
    this.total = total;
    this.datosFacturacion = datosFacturacion;
    this.nombreProforma = nombreProforma;
    this.notificacionesComerciales = notificacionesComerciales;
    this.idDescuento = idDescuento;
    this.iva = iva;
    this.codigoTransferencia = codigoTransferencia;
    this.idioma = idioma;
  }
}

export default RegistroInscripciones;
