import { useSelector, useDispatch } from "react-redux";
import {
  actualizarDatosComprador,
  guardarValidezFormularioComprador,
} from "app/components/iframe/store/actions";
import { validador } from "../utils";

function useFormularioComprador() {
  const dispatch = useDispatch();
  const iframe = useSelector(({ iframe }) => iframe);

  const { compra, iframeConfig } = iframe;

  // COMPRA
  const { comprador } = compra;

  // CONFIG
  const { publico } = iframeConfig;

  const actualizarComprador = (campo, valor) => {
    dispatch(actualizarDatosComprador(campo, valor));
    if (campo === "telefono") {
      validarFormulario(valor);
    }
  };

  const validarFormulario = (valor) => {
    // Reglas que quieras validar
    const tlfValido = validador.validarTelefono(valor);
    // Guardar en redux
    dispatch(guardarValidezFormularioComprador(tlfValido));
  };

  return {
    comprador,
    actualizarComprador,
    validarFormulario,
    privado: !publico,
  };
}

export default useFormularioComprador;
