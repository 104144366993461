import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//actions
import { solicitaCargaAbstractsEvento } from "../store/actions";

//services
import { eliminarAsignacionesRevisores } from "../../../services/eventosService/abstractsService";

export default function ModalEliminarAsignaciones(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ authX }) => authX.user.data);
  const { id_evento } = useSelector(({ abstracts }) => abstracts.abstracts);

  const handleClose = () => {
    dispatch(solicitaCargaAbstractsEvento(user.token));
    props.setDisplayModalEliminar(false);
  };

  const handleSubmit = async () => {
    let form = { id_evento };
    let result = await eliminarAsignacionesRevisores(form);
    if (!result.data.status) {
      setSnackbar(
        "No fue posiblle eliminar las asignaciones en este momento. Intentelo de nuevo más tarde.",
        "error"
      );
    } else {
      handleClose();
      setSnackbar("Asignaciones eliminadas correctamente.", "success");
    }
  };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
  };

  return (
    <div>
      <Dialog
        open={props.displayModalEliminar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ToastContainer
          enableMultiContainer
          containerId="tabla_op"
          transition={Slide}
        />
        <DialogTitle id="alert-dialog-title">
          Eliminar todas las asignaciones
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              <Grid item xs={12}>
                ¿Esta seguro de querer eliminar todas las asignaciones de
                revisión?
              </Grid>
              <Grid item xs={12} className="mt-10">
                Esta acción eliminará todas las asignaciones de revisiones por
                parte de los revisores. No obstante, las
                <b> revisiones/calificaciones</b> ya realizadas <b>no serán</b>{" "}
                eliminadas.
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
