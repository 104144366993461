import axios from "axios";
import Global from "app/fuse-configs/Global";
import querystring from "querystring";

export const CARGA_ABSTRACTS_SOLICITADA =
  "[ABSTRACTS] SOLICITAR CARGA ABSTRACTS";
export function solicitaCargaAbstractsEvento(token) {
  axios.defaults.headers.common["token"] = token;
  return (dispatch) =>
    dispatch({
      type: CARGA_ABSTRACTS_SOLICITADA,
      payload: token,
    });
}

export const CARGA_ABSTRACTS_EXITO = "[ABSTRACTS] CARGA ABSTRACTS EXITO";
export function cargaAbstractsEvento(abstracts) {
  return (dispatch) =>
    dispatch({
      type: CARGA_ABSTRACTS_EXITO,
      payload: abstracts,
    });
}

export const CARGA_ABSTRACTS_ERROR = "[ABSTRACTS] CARGA ABSTRACTS ERROR";
export function cargaAbstractsErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_ABSTRACTS_ERROR,
      payload: error,
    });
}

export const CARGA_ABSTRACTS_FINALIZADO =
  "[ABSTRACTS] CARGA ABSTRACTS FINALIZADO";
export function cargaAbstractsFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_ABSTRACTS_FINALIZADO,
    });
}

export const GET_ABSTRACT_INFO = "[ABSTRACTS] GET ABSTRACT INFO";
export function getAbstractInfo(token, id_abstract) {
  axios.defaults.headers.common["token"] = token;
  const url = Global.url;
  let form = {
    id_abstract,
  };
  const request = axios.post(
    url + "/eventos/getAbstractInfo",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_ABSTRACT_INFO,
        payload: response,
      })
    );
}

export const LOAD_ABSTRACT_INFO = "[ABSTRACTS] LOAD_ABSTRACT_INFO";
export function loadAbstractInfo(abstract) {
  return (dispatch) =>
    dispatch({
      type: LOAD_ABSTRACT_INFO,
      payload: abstract,
    });
}

export const GET_COAUTORES_ABSTRACT = "[ABSTRACTS] GET COAUTORES ABSTRACT";
export function getCoautoresAbstract(token, id_abstract) {
  axios.defaults.headers.common["token"] = token;
  const url = Global.url;
  let form = {
    id_abstract,
  };
  const request = axios.post(
    url + "/eventos/getCoautores",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_COAUTORES_ABSTRACT,
        payload: response,
      })
    );
}

export const RESET_CURRENT_ABSTRACT = "[ABSTRACTS] RESET CURRENT ABSTRACT";
export function resetCurrentAbstract() {
  return (dispatch) =>
    dispatch({
      type: RESET_CURRENT_ABSTRACT,
    });
}

export const CARGA_CATEGORIAS_ABSTRACT_EVENTO =
  "[ABSTRACTS] CARGA CATEGORIAS ABSTRACT EVENTO";
export function cargaCategoriasAbstractEvento(categorias) {
  return (dispatch) =>
    dispatch({
      type: CARGA_CATEGORIAS_ABSTRACT_EVENTO,
      payload: categorias,
    });
}

export const CARGA_REVISORES_EVENTO = "[ABSTRACTS] CARGA REVISORES EVENTO";
export function cargaRevisoresEvento(revisores) {
  return (dispatch) =>
    dispatch({
      type: CARGA_REVISORES_EVENTO,
      payload: revisores,
    });
}

export const LOAD_ID_EVENTO = "[ABSTRACTS] LOAD_ID_EVENTO";
export function loadIdEvento(id_evento) {
  return (dispatch) =>
    dispatch({
      type: LOAD_ID_EVENTO,
      payload: id_evento,
    });
}

export const GET_ABSTRACT_CONFIG = "[ABSTRACTS] GET_ABSTRACT_CONFIG";
export function getAbstractConfig(token, id_evento) {
  axios.defaults.headers.common["token"] = token;
  const url = Global.url;
  let form = {
    id_evento,
  };
  const request = axios.post(
    url + "/eventos/getAbstractUploadConfig",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_ABSTRACT_CONFIG,
        payload: response,
      })
    );
}

export const GET_CURRENT_REVISORES = "[ABSTRACTS] GET_CURRENT_REVISORES";
export function getCurrentRevisores(id_abstract, id_evento) {
  const url = Global.url;
  let form = {
    id_abstract,
    id_evento,
  };
  const request = axios.post(
    url + "/abstracts/getCurrentRevisores",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CURRENT_REVISORES,
        payload: response,
      })
    );
}

export const RESET_REVISORES_ASIGNADOS =
  "[ABSTRACTS] RESET_REVISORES_ASIGNADOS";
export function resetRevisoresAsignados() {
  return (dispatch) =>
    dispatch({
      type: RESET_REVISORES_ASIGNADOS,
    });
}

export const LOAD_CRITERIOS_EVALUACION =
  "[ABSTRACTS] LOAD_CRITERIOS_EVALUACION";
export function cargaCriteriosEvaluacion(criterios) {
  return (dispatch) =>
    dispatch({
      type: LOAD_CRITERIOS_EVALUACION,
      payload: criterios,
    });
}

export const GET_CORRECIONES_ABSTRACT = "[ABSTRACTS] GET_CORRECIONES_ABSTRACT";
export function getCorrecionesAbstract(id_abstract) {
  const url = Global.url;
  let form = {
    id_abstract,
  };
  const request = axios.post(
    url + "/abstracts/getCorrecionesAbstract",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CORRECIONES_ABSTRACT,
        payload: response,
      })
    );
}

export const GET_CORRECIONES_ABSTRACT_PRIMERA_FASE =
  "[ABSTRACTS] GET_CORRECIONES_ABSTRACT_PRIMERA_FASE";
export function getCorrecionesAbstractPrimeraFase(id_abstract) {
  const url = Global.url;
  let form = {
    id_abstract,
  };
  const request = axios.post(
    url + "/abstracts/getCorrecionesAbstractPrimeraFase",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CORRECIONES_ABSTRACT_PRIMERA_FASE,
        payload: response,
      })
    );
}

export const CARGA_TIPOS_COMUNICACION_EVENTO =
  "[ABSTRACTS] CARGA_TIPOS_COMUNICACION_EVENTO";
export function cargaTiposComunicacionEvento(tipos_com) {
  return (dispatch) =>
    dispatch({
      type: CARGA_TIPOS_COMUNICACION_EVENTO,
      payload: tipos_com,
    });
}

export const UPDATE_REVISORES_EVENTO = "[ABSTRACTS] UPDATE_REVISORES_EVENTO";
export function updateRevisoresEvento(id_evento) {
  const url = Global.url;
  let form = {
    id_evento,
  };
  const request = axios.post(
    url + "/eventos/getRevisoresEvento",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: UPDATE_REVISORES_EVENTO,
        payload: response,
      })
    );
}

export const GET_NOTIFICATIONS_INFO = "[ABSTRACTS] GET_NOTIFICATIONS_INFO";
export function getNotificationsInfo(id_evento) {
  const url = Global.url;
  let form = {
    id_evento,
  };
  const request = axios.post(
    url + "/abstracts/getNotificationsInfo",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_NOTIFICATIONS_INFO,
        payload: response,
      })
    );
}

export const RESET_NOTIFICATIONS_INFO = "[ABSTRACTS] RESET_NOTIFICATIONS_INFO";
export function resetNotificationsInfo() {
  return (dispatch) =>
    dispatch({
      type: RESET_NOTIFICATIONS_INFO,
    });
}

export const GET_NOMBRES_AUTORES = "[ABSTRACTS] GET_NOMBRES_AUTORES";
export function getNombreAutores(id_abstract) {
  const url = Global.url;
  let form = {
    id_abstract,
  };
  const request = axios.post(
    url + "/abstracts/getNombreAutores",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_NOMBRES_AUTORES,
        payload: response,
      })
    );
}

export const GET_REVISORES_ABSTRACTS = "[ABSTRACTS] GET_REVISORES_ABSTRACTS";
export function getRevisoresAbstracts(id_evento) {
  const url = Global.url;
  let form = {
    id_evento,
  };
  const request = axios.post(
    url + "/abstracts/getRevisoresAbstracts",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_REVISORES_ABSTRACTS,
        payload: response,
      })
    );
}
