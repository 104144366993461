import {
  CARGA_CODIGO_TRANSFERENCIA_SOLICITADA,
  CARGA_CODIGO_TRANSFERENCIA_CORRECTA,
  CARGA_CODIGO_TRANSFERENCIA_ERRONEA,
  CARGA_CODIGO_TRANSFERENCIA_FINALIZADA,
  CARGA_CUENTA_TRANSFERENCIA_SOLICITADA,
  CARGA_CUENTA_TRANSFERENCIA_CORRECTA,
  CARGA_CUENTA_TRANSFERENCIA_ERRONEA,
  CARGA_CUENTA_TRANSFERENCIA_FINALIZADA,
  CARGA_PREFIJO_SUFIJO_PROFORMA_SOLICITADA,
  CARGA_PREFIJO_SUFIJO_PROFORMA_CORRECTA,
  CARGA_PREFIJO_SUFIJO_PROFORMA_ERRONEA,
  CARGA_PREFIJO_SUFIJO_PROFORMA_FINALIZADA,
  GUARDA_DATOS_FACTURACION,
} from "../actions";

const initialState = {
  codigoTransferencia: {
    contenido: "",
    error: null,
    cargando: true,
  },
  cuentaTransferencia: {
    contenido: "",
    error: null,
    cargando: true,
  },
  prefijosSufijos: {
    contenido: {
      prefijo: "",
      sufijo: "",
    },
    error: null,
    cargando: true,
  },
  datosFacturacion: null,
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case CARGA_CODIGO_TRANSFERENCIA_SOLICITADA:
      return {
        ...state,
        codigoTransferencia: { ...state.codigoTransferencia, cargando: true },
      };
    case CARGA_CODIGO_TRANSFERENCIA_CORRECTA:
      return {
        ...state,
        codigoTransferencia: {
          ...state.codigoTransferencia,
          contenido: action.payload,
        },
      };

    case CARGA_CODIGO_TRANSFERENCIA_ERRONEA:
      return {
        ...state,
        codigoTransferencia: {
          ...state.codigoTransferencia,
          error: action.payload,
        },
      };

    case CARGA_CODIGO_TRANSFERENCIA_FINALIZADA:
      return {
        ...state,
        codigoTransferencia: {
          ...state.codigoTransferencia,
          cargando: false,
        },
      };

    case CARGA_CUENTA_TRANSFERENCIA_SOLICITADA:
      return {
        ...state,
        cuentaTransferencia: { ...state.cuentaTransferencia, cargando: true },
      };
    case CARGA_CUENTA_TRANSFERENCIA_CORRECTA:
      return {
        ...state,
        cuentaTransferencia: {
          ...state.cuentaTransferencia,
          contenido: action.payload,
        },
      };

    case CARGA_CUENTA_TRANSFERENCIA_ERRONEA:
      return {
        ...state,
        cuentaTransferencia: {
          ...state.cuentaTransferencia,
          error: action.payload,
        },
      };

    case CARGA_CUENTA_TRANSFERENCIA_FINALIZADA:
      return {
        ...state,
        cuentaTransferencia: {
          ...state.cuentaTransferencia,
          cargando: false,
        },
      };

    case CARGA_PREFIJO_SUFIJO_PROFORMA_SOLICITADA:
      return {
        ...state,
        prefijosSufijos: {
          ...state.prefijosSufijos,
          cargando: true,
        },
      };

    case CARGA_PREFIJO_SUFIJO_PROFORMA_CORRECTA:
      return {
        ...state,
        prefijosSufijos: {
          ...state.prefijosSufijos,
          contenido: {
            prefijo: action.payload.prefijo,
            sufijo: action.payload.sufijo,
          },
        },
      };

    case CARGA_PREFIJO_SUFIJO_PROFORMA_ERRONEA:
      return {
        ...state,
        prefijosSufijos: {
          ...state.prefijosSufijos,
          error: action.payload,
        },
      };

    case CARGA_PREFIJO_SUFIJO_PROFORMA_FINALIZADA:
      return {
        ...state,
        prefijosSufijos: {
          ...state.prefijosSufijos,
          cargando: false,
        },
      };

    case GUARDA_DATOS_FACTURACION:
      return { ...state, datosFacturacion: action.payload };

    default:
      return state;
  }
};
