import axios from "axios";
import Global from "../../../../fuse-configs/Global";
import querystring from "querystring";

export const GET_EMAIL_EDITOR_EVENTS = "[EMAILEDITOR] GET EVENTS EMAIL EDITOR";
export const RESET_EMAIL_EDITOR_EVENTS =
  "[EMAILEDITOR] RESET EVENTS EMAIL EDITOR";
export const LOAD_EMAIL_DESIGN = "[EMAILEDITOR] LOAD EMAIL DESIGN";
export const RESET_EMAIL_DESIGN = "[EMAILEDITOR] RESET EMAIL DESIGN";

export function getEventsForEmailEditor(token) {
  const url = Global.url;
  let form = { dummy: "dummy" };
  axios.defaults.headers.common["token"] = token;
  const request = axios.post(
    url + "/eventos/getEventsForEmailEditor",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EMAIL_EDITOR_EVENTS,
        payload: response,
      })
    );
}

export function resetEventsEmailEditor() {
  return (dispatch) => {
    dispatch({
      type: RESET_EMAIL_EDITOR_EVENTS,
    });
  };
}
