import { takeEvery, call, put } from "redux-saga/effects";

import {
  // ULTIMOS USUARIOS
  SOLICITAR_CARGA_ULTIMOS_USUARIOS,
  cargaUltimosUsuariosExitosa,
  cargaUltimosUsuariosErronea,
  cargaUltimosUsuariosFinalizada,
} from "../actions";
import { obtenerUltimosUsuarios } from "app/services/eventosService";

export function* observador() {
  yield takeEvery(SOLICITAR_CARGA_ULTIMOS_USUARIOS, cargaUltimosUsuarios);
}

function* cargaUltimosUsuarios() {
  try {
    const { data } = yield call(obtenerUltimosUsuarios);
    const { status, result: usuarios } = data;

    if (!status) throw new Error("Error en el status");

    yield put(cargaUltimosUsuariosExitosa(usuarios));
  } catch (e) {
    yield put(cargaUltimosUsuariosErronea(e));
  } finally {
    yield put(cargaUltimosUsuariosFinalizada());
  }
}
