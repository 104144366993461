import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import { makeStyles } from "@material-ui/core/styles";

//COMPONENTS
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  Icon,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Loading } from "app/shared";
import { Link } from "react-router-dom";

//alerts

import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SERVICES
import {
  publishAbstract,
  publishSymposium,
} from "../../../services/eventosService/abstractUploadService";

//REDUX
import * as Actions from "../store/actions";
import reducer from "../store/reducers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const countWords = (texto) => {
  if (texto === "") {
    return 0;
  }
  texto = texto.replace(/<[^>]*>/g, " ");
  texto = texto.replace(/\s+/g, " ");
  texto = texto.trim();
  var n = texto.split(" ").length;
  console.log("Este es el texto:", texto);
  console.log("Total palabras: ", n);
  return n;
};

const VistaPresentar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPublishedMessage, setShowPublishedMessage] = React.useState(false);
  const [tipoPresentacion, setTipoPresentacion] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [politica1, setPolitica1] = React.useState(false);
  const [politica2, setPolitica2] = React.useState(false);
  const [evaluacionAnticipada, setEvaluacionAnticipada] = React.useState(false);
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );

  let {
    contenido_seccion1,
    contenido_seccion2,
    contenido_seccion3,
  } = abstract_data.currentAbstract;

  let total_palabras = countWords(
    `${contenido_seccion1 ? contenido_seccion1 : ""}${
      contenido_seccion2 ? contenido_seccion2 : ""
    }${contenido_seccion3 ? contenido_seccion3 : ""}`
  );

  useEffect(() => {
    //ponemos el scroll arriba
    document.getElementById("c_vista_presentar").scrollIntoView(0, -200);
    dispatch(Actions.getAutores(abstract_data.currentAbstract.ID));
  }, [dispatch]);

  // console.log("Es el abstract grupal???", abstract_data.currentAbstract.grupal);
  // console.log(
  //   "Tamaño de aportaciones:",
  //   abstract_data.aportacionesAbstract.length
  // );

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "publicar_abstract",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "publicar_abstract",
      });
    }
    if (type == "info") {
      toast.info(message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "publicar_abstract",
      });
    }
  };
  let pulsado = false;

  const handlePublish = async () => {
    //COMPROBAMOS SI EL ABSTRCT ESTA OK
    //comprobamos el máximo de palabras total

    let { max_palabras_total } = abstract_data.currentTipoAbstract;
    if (max_palabras_total > 0 && max_palabras_total < total_palabras) {
      setSnackbar(
        "El contenido de su comunicación excede el máximo total de palabras. Modifique el contenido para que se ajuste a este límite e intente presentar de nuevo.",
        "error"
      );
      return;
    }
    //bloqueamos boton publicar
    setSubmitting(true);
    //comprobamos si se ha indicado texto en alguna de las secciones
    let abstract_vacio;
    let txt_seccion1 = abstract_data.currentAbstract.contenido_seccion1
      ? abstract_data.currentAbstract.contenido_seccion1
      : "";
    let txt_seccion2 = abstract_data.currentAbstract.contenido_seccion2
      ? abstract_data.currentAbstract.contenido_seccion2
      : "";
    let txt_seccion3 = abstract_data.currentAbstract.contenido_seccion3
      ? abstract_data.currentAbstract.contenido_seccion3
      : "";
    let txt_seccion4 = abstract_data.currentAbstract.contenido_seccion4
      ? abstract_data.currentAbstract.contenido_seccion4
      : "";
    let txt_seccion_aux1 = abstract_data.currentAbstract.contenido_seccion_aux1
      ? abstract_data.currentAbstract.contenido_seccion_aux1
      : "";
    let contenido_en_comunicacion = `${txt_seccion1}${txt_seccion2}${txt_seccion3}${txt_seccion4}${txt_seccion_aux1}`;

    if (contenido_en_comunicacion) {
      if (contenido_en_comunicacion.length === 0) {
        abstract_vacio = true;
      } else {
        abstract_vacio = false;
      }
    } else {
      abstract_vacio = true;
    }
    let adjuntos_vacio = true;
    //comprobamos antes si los adjuntos no son "null"
    if (abstract_data.currentAbstract.urls_adjuntos) {
      adjuntos_vacio =
        JSON.parse(abstract_data.currentAbstract.urls_adjuntos).length === 0;
    }
    //comprobamos si ha indicado la relacion de aportaciones en el caso de que sea grupal
    if (
      abstract_data.currentAbstract.grupal == 1 &&
      abstract_data.aportacionesAbstract.length == 0
    ) {
      setSnackbar(
        "Debe indicar la relación de aportaciones para este trabajo grupal",
        "error"
      );
      return;
    }
    //comprobamos que envia algo
    if (abstract_vacio && adjuntos_vacio) {
      pulsado = false;
      setSnackbar(
        "La comunicación no tiene ningún contenido de texto y/o ningún archivo adjunto. Debe rellenar su comunicación con el editor de texto o subir los archivos correspondientes para poder presentarlo.",
        "error"
      );
    } else {
      pulsado = true;
      setSnackbar(
        "Su petición se está realizando. Por favor, espere unos segundos.",
        "info"
      );
      let ahora = new Date().toISOString();
      let form = {
        id_abstract: abstract_data.currentAbstract.ID,
        fecha_presentado: ahora,
        publicado: abstract_data.currentAbstract.publicado,
        tipo_presentacion:
          abstract_data.evento.data.result.tipo_presentacion === 3
            ? tipoPresentacion
            : abstract_data.evento.data.result.tipo_presentacion,
        evaluacionAnticipada,
        estado: abstract_data.currentAbstract.aceptado,
      };
      let result;
      //Llamamos a un endpoint u otro dependiendo del tipo de comunicacion
      if (abstract_data.currentTipoAbstract.grupal === 1) {
        result = await publishSymposium(form);
      } else {
        result = await publishAbstract(form);
      }

      if (!result.data.status) {
        setSnackbar(
          "Hubo un problema al intentar presentar la comunicación. Intentelo de nuevo más tarde",
          "error"
        );
      } else {
        setSnackbar("Comunicación presentada correctamente.", "success");
        setShowPublishedMessage(true);
        //volvemos al paso de crear abstracts al paso de crear abstracts.
        setTimeout(() => {
          props.setActiveStep(1);
        }, 5000);
      }
    }
    setSubmitting(false);
  };

  if (!abstract_data.autores) {
    return (
      <div
        id="c_vista_presentar"
        className="flex items-center justify-center text-center"
      >
        <Loading loadingMessage={"Cargando..."} />
      </div>
    );
  }

  return (
    <Grid id="c_vista_presentar" container>
      <ToastContainer
        enableMultiContainer
        containerId="publicar_abstract"
        transition={Slide}
      />
      {showPublishedMessage ? (
        <Grid item xs={12}>
          <Paper elevation={3} className="text-left p-20">
            <Typography variant="h4" className="mb-32">
              Comunicación presentada correctamente:
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12} className="mb-32">
                  {abstract_data.currentAbstract.presentado == 0 ? (
                    <p>
                      En unos instantes recibirá un email confirmando la
                      correcta subida de su comunicación.
                    </p>
                  ) : (
                    <p>
                      Le confirmamos que los cambios en su comunicación se han
                      realizado correctamente.
                    </p>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={3} className="text-left p-20">
            <Typography variant="h4" className="mb-32">
              Resumen de Comunicación:
            </Typography>
            <Grid container>
              {/* RESUMEN ABSTRACT */}
              <Grid item xs={3}>
                <Grid item xs={12} className="mb-32">
                  <p className="font-bold">Titulo:</p>
                  <p className="text-12">
                    {abstract_data.currentAbstract.titulo}
                  </p>
                </Grid>
                {abstract_data.currentAbstract.grupal === 0 ? (
                  <Grid container>
                    <Grid item xs={12} className="mb-32">
                      <p className="font-bold">Autores:</p>
                      {abstract_data.autores.map((row) => {
                        return (
                          <p>
                            - {row.nombre} {row.apellido1} {row.apellido2}{" "}
                            {row.principal === 1
                              ? "(Autor Principal)"
                              : "(Co-Autor)"}
                          </p>
                        );
                      })}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12} className="mb-32">
                      <p className="font-bold">Autores y Aportaciones:</p>
                      <ul className="text-12">
                        {abstract_data.aportacionesAbstract.map((row) => {
                          return (
                            <li>
                              {row.nombre_autor} {row.apellido1_autor}{" "}
                              {row.apellido2_autor} - "{row.titulo}" (
                              {row.num_abstract})
                            </li>
                          );
                        })}
                      </ul>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {/* SELECCION DE EVALUACIÓN ANTICIPADA */}
              {abstract_data.currentTipoAbstract.evaluacion_anticipada == 1 &&
              false ? (
                <Grid
                  item
                  xs={3}
                  className="flex items-center justify-center text-center p-5"
                >
                  <Grid
                    container
                    className="flex items-center justify-center text-center border-1 p-5 min-h-256"
                  >
                    <Grid item xs={12} className="mb-20 text-11">
                      <Typography variant="h5">
                        Evaluacion Anticipada
                      </Typography>
                      Seleccione para priorizar la correción de su comunicación.
                      Tenga en cuenta que si selecciona la evaluación anticipada
                      ya no podra realizar cambios en su comunicación.
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={evaluacionAnticipada}
                            onChange={(e) =>
                              setEvaluacionAnticipada(!evaluacionAnticipada)
                            }
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Solicitar Evaluación Anticipada"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={3}></Grid>
              )}
              {/* SELECTOR DE TIPO PRESENTACION */}

              <Grid
                item
                xs={3}
                className="flex items-center justify-center text-center p-5"
              >
                {abstract_data.evento.data.result.tipo_presentacion === 3 && (
                  <Grid
                    container
                    className="flex items-center justify-center text-center border-1 p-5 min-h-256"
                  >
                    <Grid item xs={12} className="mb-20">
                      <Typography variant="h5">
                        Seleccione una modalidad de presentación
                      </Typography>
                      Debe seleccionar una modalidad de presentación para
                      presentar su comunicación.
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Modalidad de presentación
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(e) => setTipoPresentacion(e.target.value)}
                          label="Tipo de presentación"
                        >
                          <MenuItem value={1}>Presencial</MenuItem>
                          <MenuItem value={2}>Virtual</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* BOTON + AVISO PUBLICAR ABSTRACT */}
              <Grid
                item
                xs={3}
                className="flex items-center justify-center text-center p-5"
              >
                <Grid
                  container
                  className="flex items-center justify-center text-center border-1 p-5 min-h-256"
                >
                  <Grid item xs={12} className="mb-20">
                    <Typography variant="h5">
                      {abstract_data.currentAbstract.aceptado === 3
                        ? "Presentar Comunicación Final"
                        : "Presentar Comunicación"}{" "}
                    </Typography>
                    Una vez presentada la comunicación podrá editarla pero no
                    eliminara.
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={
                        (!tipoPresentacion &&
                          pulsado &&
                          abstract_data.evento.data.result.tipo_presentacion ===
                            3) ||
                        submitting ||
                        !politica1 ||
                        !politica2
                      }
                      size="large"
                      variant="contained"
                      color="primary"
                      className="text-20"
                      onClick={handlePublish}
                    >
                      <Icon fontSize="large">publish</Icon> Presentar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* CONTAINER CHECKBOXES POLITICAS */}
            <Grid
              container
              className="flex items-center justify-center text-center mt-56"
            >
              <Grid item xs={12} className="text-11">
                <Checkbox
                  checked={politica1}
                  onChange={(e) => setPolitica1(e.target.checked)}
                  name="politica1"
                  color="primary"
                />
                Acepto la
                <Link
                  to={`/buy/es/event/${abstract_data.evento.data.result.ID}/privacyPolitics`}
                  target="_blank"
                >
                  {" "}
                  politica de privacidad{" "}
                </Link>{" "}
                del organizador.
              </Grid>
              <Grid item xs={12} className="text-11">
                <Checkbox
                  checked={politica2}
                  onChange={(e) => setPolitica2(e.target.checked)}
                  name="politica2"
                  color="primary"
                />
                Acepto la publicación de mi comunicación en la documentación que
                se genere del {abstract_data.evento.data.result.nombre}, en
                cualquier tipo de soporte.
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default withReducer("Abstract", reducer)(VistaPresentar);
