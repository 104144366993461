import * as Actions from "../actions";
import * as utils from "../../utils";

const initialState = {
  tiposInscripcion: {
    array: [],
    cargando: true,
    error: null,
  },
  inscripciones: [],
  descuentos: {
    array: [],
    cargando: false,
    error: null,
  },
  precioBruto: 0,
  costesDistribucionBrutos: 0,
  iva: 0,
  total: 0,
  comprador: {
    nombre: "",
    cif: "",
    direccion: "",
    correo: "",
    telefono: "",
    tlfError: "Número de telefono inválido",
    datosValidos: true,
  },
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CARGA_PRODUCTOS_SOLICITADA:
      return {
        ...state,
        tiposInscripcion: { ...state.tiposInscripcion, cargando: true },
      };
    case Actions.CARGA_PRODUCTOS_CORRECTA:
      return {
        ...state,
        tiposInscripcion: { ...state.tiposInscripcion, array: action.payload },
      };
    case Actions.CARGA_PRODUCTOS_ERRONEA:
      return {
        ...state,
        tiposInscripcion: { ...state.tiposInscripcion, error: action.payload },
      };
    case Actions.CARGA_PRODUCTOS_FINALIZADA:
      return {
        ...state,
        tiposInscripcion: { ...state.tiposInscripcion, cargando: false },
      };
    case Actions.GUARDAR_DESCUENTO:
      return {
        ...state,
        descuentos: {
          ...state.descuentos,
          array: [...state.descuentos.array, action.payload],
        },
      };
    case Actions.CAMBIAR_CANTIDAD_INSCRIPCIONES:
      let { indice, nuevaCantidad, prevCantidadEntradas } = action.payload;
      let mergeInscripciones = [];
      let inscripcionActual;
      let inscripciones = state.inscripciones;

      state.tiposInscripcion.array.map((tipoInscripcion, tiIndex) => {
        if (tiIndex === indice) {
          if (prevCantidadEntradas < nuevaCantidad) {
            // No eliminamos las ya existentes, sólo añadimos las nuevas
            // asi conservamos el estado de los complementos seleccionados
            for (let i = prevCantidadEntradas; i < nuevaCantidad; i++) {
              // TODO: Dar un id unico ya no es necesario, borrar
              inscripcionActual = utils.daIdUnicoAInscripcionYComplementos(
                tipoInscripcion
              );
              mergeInscripciones.push({
                ...inscripcionActual,
                precio: inscripcionActual.precio_online,
              });
            }
          } else {
            inscripciones = state.inscripciones.filter(
              (i) => i.ID !== tipoInscripcion.ID
            );

            for (let i = 0; i < nuevaCantidad; i++) {
              // TODO: Dar un id unico ya no es necesario, borrar
              inscripcionActual = utils.daIdUnicoAInscripcionYComplementos(
                tipoInscripcion
              );

              mergeInscripciones.push({
                ...inscripcionActual,
                precio: inscripcionActual.precio_online,
              });
            }
          }
        }

        return tipoInscripcion;
      });

      inscripciones = [...inscripciones, ...mergeInscripciones];
      // ORDENAMOS las inscripciones por id ascendente tal y como la BD nos los ha devuelto
      inscripciones = utils.sortAscendentByField(inscripciones, "ID");

      return {
        ...state,
        inscripciones,
        tiposInscripcion: {
          ...state.tiposInscripcion,
          array: state.tiposInscripcion.array.map((ti, i) =>
            i === indice ? { ...ti, cantidadEntradas: nuevaCantidad } : ti
          ),
        },
      };

    case Actions.RECALCULAR_PRECIO:
      let {
        inscripciones: i,
        iva,
        total,
        costesDistribucionBrutos,
        precioBruto,
      } = utils.calcularCosteInscripciones(
        state.inscripciones,
        state.descuentos.array
      );

      return {
        ...state,
        total,
        iva,
        inscripciones: i,
        costesDistribucionBrutos,
        precioBruto,
      };

    case Actions.CAMBIAR_ESTADO_COMPLEMENTO:
      const { nuevoEstado, idUnico } = action.payload;
      return {
        ...state,
        inscripciones: state.inscripciones.map((i) => {
          i.complementos = i.complementos.map((c) =>
            c.idUnico === idUnico ? { ...c, ...nuevoEstado } : c
          );
          return i;
        }),
      };

    case Actions.ACTUALIZAR_DATOS_COMPRADOR:
      const { campo, valor } = action.payload;
      return {
        ...state,
        comprador: {
          ...state.comprador,
          [campo]: valor,
        },
      };

    case Actions.ACTUALIZAR_COMPRADOR:
      const comprador = action.payload;
      return { ...state, comprador: { ...state.comprador, ...comprador } };

    case Actions.GUARDAR_VALIDEZ_FORMULARIO_COMPRADOR:
      return {
        ...state,
        comprador: {
          ...state.comprador,
          datosValidos: action.payload,
        },
      };

    case Actions.GUARDAR_FORMULARIOS_INSCRIPCION:
      let inscCopia = [...state.inscripciones];
      let forms = action.payload;
      inscCopia = inscCopia.map((i, indice) => {
        return { ...i, formulario: forms[indice] };
      });
      return {
        ...state,
        inscripciones: inscCopia,
      };

    case Actions.MODIFICAR_FORMULARIO_INSCRIPCION_CAMPO_ESTANDAR:
      const indiceInscripcion = action.payload.indiceInscripcion;
      const campoModificado = action.payload.campo;
      const valorModificado = action.payload.valor;

      return {
        ...state,
        inscripciones: state.inscripciones.map((i, indice) => {
          if (indice === indiceInscripcion)
            return {
              ...i,
              formulario: {
                ...i.formulario,
                [campoModificado]: valorModificado,
              },
            };
          else return i;
        }),
      };

    case Actions.MODIFICAR_FORMULARIO_INSCRIPCION_CAMPO_CUSTOM:
      const indiceInsc = action.payload.indiceInscripcion;
      const valorMod = action.payload.valor;
      const indiceCampoCustom = action.payload.indiceCampoCustom;

      return {
        ...state,
        inscripciones: state.inscripciones.map((i, indice) => {
          if (indice === indiceInsc)
            return {
              ...i,
              formulario: {
                ...i.formulario,
                custom: i.formulario.custom.map((campoCustom, indiceCustom) => {
                  if (indiceCustom === indiceCampoCustom) {
                    return { ...campoCustom, value: valorMod };
                  } else return campoCustom;
                }),
              },
            };
          else return i;
        }),
      };

    default:
      return state;
  }
};
