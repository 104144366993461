/**
 *
 *
 * CONFIGURACIÓN
 *
 *
 */
export const CARGA_CONFIG_SOLICITADA =
  "[IFRAME.CONFIG] SE HA SOLICITADO LA CARGA DE LA CONFIGURACIÓN";
export function solicitarCargaConfig(idEvento) {
  return (dispatch) =>
    dispatch({ type: CARGA_CONFIG_SOLICITADA, payload: idEvento });
}

export const CARGA_CONFIG_FINALIZADA =
  "[IFRAME.CONFIG] LA CARGA DE LA CONFIGURACIÓN HA FINALIZADO";
export function cargaConfigFinalizada() {
  return (dispatch) => dispatch({ type: CARGA_CONFIG_FINALIZADA });
}

export const CARGA_CONFIG_EXITOSA =
  "[IFRAME.CONFIG] LA CARGA DE LA CONFIGURACIÓN HA SIDO EXITOSA";
export function cargaConfigExitosa(config) {
  return (dispatch) =>
    dispatch({ type: CARGA_CONFIG_EXITOSA, payload: config });
}

export const CARGA_CONFIG_ERRONEA =
  "[IFRAME.CONFIG] LA CARGA DE LA CONFIGURACIÓN HA SIDO ERRÓNEA";
export function cargaConfigErronea(error) {
  return (dispatch) => dispatch({ type: CARGA_CONFIG_ERRONEA, payload: error });
}

/**
 *
 *
 * MENSAJE DE CARGA
 *
 *
 */
export const CAMBIAR_MENSAJE_DE_CARGA =
  "[IFRAME.CONFIG] CAMBIAR EL MENSAJE DE LA PANTALLA DE CARGA";

export function cambiarMensajePantallaCarga(mensaje) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_MENSAJE_DE_CARGA,
      payload: mensaje,
    });
}

/**
 *
 *
 *
 * COLOR
 *
 *
 */
export const CAMBIAR_COLOR_TEMA_IFRAME =
  "[IFRAME.CONFIG] CAMBIA EL COLOR DEL TEMA DEL IFRAME";

export function cambiarColorTemaIframe(color) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_COLOR_TEMA_IFRAME,
      payload: color,
    });
}

/**
 *
 *
 *
 * PUBLICO PRIVADO
 *
 *
 *
 */
export const CAMBIAR_IFRAME_PUBLICO_PRIVADO =
  "[IFRAME.CONFIG] CAMBIA EL ESTADO DEL IFRAME A PÚBLICO O PRIVADO";

export function cambiarVisibilidadIframe(publicoPrivado) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_IFRAME_PUBLICO_PRIVADO,
      payload: publicoPrivado,
    });
}

/**
 *
 *
 *
 * OCULTAR LOGIN IFRAME PRIVADO
 *
 *
 *
 */
export const OCULTAR_LOGIN_IFRAME_PRIVADO =
  "[IFRAME.CONFIG] OCULTA EL LOGIN DEL IFRAME PRIVADO";

export function ocultarLoginIframePrivado() {
  return (dispatch) => dispatch({ type: OCULTAR_LOGIN_IFRAME_PRIVADO });
}

/**
 * 
 * 
 * TEMA
 * 
 * 
 */
export const CAMBIAR_TEMA_IFRAME = "[IFRAME.CONFIG.TEMA] CAMBIA EL TEMA DEL IFRAME";
export function cambiarTemaIframe(tema) {
  return (dispatch) => dispatch({
    type: CAMBIAR_TEMA_IFRAME,
    payload: tema
  })
}

/**
 * Controlamos que ya no es la primera vez que aparece
 * por el tema de hacer scroll, la primera vez no se hace
 * scroll por que si no afectaria a la página donde esté
 * incrustado el iframe.
 */
export const PANTALLA_DE_CARGA_APARECE_POR_SEGUNDA_VEZ =
  "[IFRAME.CONFIG] ES LA SEGUNDA VEZ QUE SALE LA PANTALLA DE CARGA";

export function pantallaDeCargaAparecePorSegundaVez() {
  return (dispatch) =>
    dispatch({
      type: PANTALLA_DE_CARGA_APARECE_POR_SEGUNDA_VEZ,
    });
}
