import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    borderRadius: "3px",
    backgroundColor: "#FF6347",
    padding: "10px",
  },
  alert: {},
});

const ListaAlertas = ({ alertas }) => {
  const classes = useStyles();
  const [listaAlertas, setListaAlertas] = useState(alertas);

  return (
    <div className={classes.container}>
      {listaAlertas &&
        listaAlertas.length > 0 &&
        listaAlertas.map((alerta, aKey) => (
          <Typography className={classes.alert} key={aKey}>
            {alerta.text}
          </Typography>
        ))}
    </div>
  );
};

export default ListaAlertas;
