import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { appsConfigs } from "app/main/apps/appsConfigs";
import { legalConfig } from "app/main/legal/LegalConfig";

import { router as AuthRouter } from "../components/auth";
import { router as IframeRouter } from "../components/iframe";
import { router as AbstractUploadRouter } from "../components/abstractUpload";
import { router as AbstractRouter } from "../components/abstracts";
import { router as MailEditorRouter } from "../components/maileditor";
import NotFound from "app/main/apps/components/NotFound";

const routeConfigs = [
  ...appsConfigs,
  AuthRouter,
  IframeRouter,
  legalConfig,
  AbstractUploadRouter,
  AbstractRouter,
  MailEditorRouter,
];

// TODO: React lazy loading here
const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/apps/dashboard" />,
  },
  {
    path: "/login",
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/signin",
    component: () => <Redirect to="/signin" />,
  },
  {
    path: "/forgottenPassword",
    component: () => <Redirect to="/forgottenPassword" />,
  },
  {
    path: "/testapp",
    component: () => <Redirect to="/apps/dashboards/analytics" />,
  },
  {
    path: "/recoverPassword",
    component: () => <Redirect to="/recoverPassword" />,
  },
  {
    path: "*",
    component: () => <NotFound />,
  },
];

export default routes;
