import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { Grid, Menu } from "@material-ui/core";

//COMPONENTS
import { Skeleton } from "app/shared";
import ModalAsignarCategoria from "./modalAsignarCategoria";
import ModalAsignarRevisor from "./modalAsignarRevisor";
import ModalCorregir from "./modalCorregir";

//Actions
import {
  resetCurrentAbstract,
  getAbstractInfo,
  getCoautoresAbstract,
} from "../store/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  negrita: { fontWeight: "bold" },
}));

export default function ModalVerAbstract(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const [open, setOpen] = React.useState(false);
  const user = useSelector(({ authX }) => authX.user.data);
  const { currentAbstract, coautores } = useSelector(
    ({ abstracts }) => abstracts.abstracts
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalCategorias, setOpenModalCategorias] = React.useState(false);
  const [openModalRevisores, setOpenModalRevisores] = React.useState(false);
  const [openModalCorregir, setOpenModalCorregir] = React.useState(false);
  const abstract = props.currentAbstract;

  useEffect(() => {
    dispatch(getAbstractInfo(user.token, props.currentAbstract.ID));
    dispatch(getCoautoresAbstract(user.token, props.currentAbstract.ID));
  }, [dispatch]);

  let hay_adjuntos = false;
  if (currentAbstract) {
    if (currentAbstract.urls_adjuntos) {
      if (JSON.parse(abstract.urls_adjuntos).length > 0) {
        hay_adjuntos = true;
      }
    }
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    props.setDisplayModal(false);
    dispatch(resetCurrentAbstract());
  };

  // const handleAsignarCategoria = () => {
  //   setOpenModalCategorias(true);
  //   setAnchorEl(false);
  // };

  // const handleAsignarRevisor = () => {
  //   setOpenModalRevisores(true);
  //   setAnchorEl(false);
  // };

  const handleCorregir = () => {
    setOpenModalCorregir(true);
    setAnchorEl(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.displayModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Resumen de la comunicación
          <div className="flex items-end justify-end text-right">
            {abstract.num_calificaciones === 0 && (abstract.aceptado === 2 || abstract.aceptado === 4) && (
              <Button
                variant="contained"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleCorregir}
                onClose={handleClose}
              >
                {" "}
                Corregir
                {/* <MenuItem onClick={handleAsignarRevisor}>
                Asignar/Reasignar Revisor
              </MenuItem>
              <MenuItem onClick={handleAsignarCategoria}>
                Asignar/Reasignar Categoria
              </MenuItem> */}
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          {!currentAbstract || !coautores ? (
            <div>
              <Skeleton height={"25rem"} />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <b>Titulo: </b>
                {currentAbstract.titulo}
              </Grid>
              <Grid item xs={12}>
                <b>Categoria: </b>
                {currentAbstract.nombre_categoria
                  ? currentAbstract.nombre_categoria
                  : ""}
              </Grid>
              {/* <Grid item xs={12}>
                <b>Autor: </b>
                {currentAbstract.nombre_autor}
              </Grid> */}
              {/*<Grid item xs={12}>*/}
              {/*  <b>Calificación: </b>*/}
              {/*  {currentAbstract.calificacion_provisional*/}
              {/*    ? currentAbstract.calificacion_provisional*/}
              {/*    : "No calificado"}*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <b>Aporta texto: </b>
                {currentAbstract.contenido_seccion2 && currentAbstract.contenido_seccion2.length > 0 ? "Si" : "No"}
              </Grid>
              <Grid item xs={12}>
                <b>Aporta archivos: </b>
                {hay_adjuntos ? "Si" : "No"}
              </Grid>
              {/*  <Grid item xs={12}>
                <b>Revisor: </b>
                {currentAbstract.nombre_revisor &&
                currentAbstract.apellidos_revisor
                  ? `${currentAbstract.nombre_revisor} ${currentAbstract.apellidos_revisor}`
                  : "No Asignado"}
              </Grid> }
              <Grid item xs={12}>
                <b>Fecha Presentado: </b>
                {currentAbstract.fecha_publicado
                  ? new Date(currentAbstract.fecha_publicado).toLocaleString(
                      "es-ES",
                      {
                        timeZone: "UTC",
                      }
                    )
                  : "---"}
              </Grid> 
              <Grid item xs={12}>
                <b>Fecha de Asignación a revisor: </b>
                {currentAbstract.fecha_asignado
                  ? new Date(currentAbstract.fecha_asignado).toLocaleString(
                      "es-ES",
                      {
                        timeZone: "UTC",
                      }
                    )
                  : "---"}
                    </Grid>*/}
              <Grid item xs={12}>
                <b>Fecha limite de correción: </b>
                {currentAbstract.fecha_max_correcion
                  ? new Date(
                      currentAbstract.fecha_max_correcion
                    ).toLocaleString("es-ES", {
                      timeZone: "UTC",
                    })
                  : "---"}
              </Grid>
              <Grid item xs={12}>
                <b>Palabras clave: </b>
                {currentAbstract.keywords
                  ? JSON.parse(currentAbstract.keywords).map((row) => (
                      <span>
                        {row}
                        {", "}
                      </span>
                    ))
                  : "No seleccionadas"}
              </Grid>
              {/* <Grid item xs={12}>
                <b>Fecha de correción: </b>
                {currentAbstract.fecha_corregido
                  ? new Date(currentAbstract.fecha_corregido).toLocaleString(
                      "es-ES",
                      {
                        timeZone: "UTC",
                      }
                    )
                  : "---"}
              </Grid>
               <Grid item xs={12}>
                <b>Coautores:</b>
                <ul>
                  {coautores.map((row) => {
                    return <li>{row.nombre}</li>;
                  })}
                </ul>
              </Grid> */}

              {currentAbstract.calificacion_primera_fase ? (
                  <Grid item xs={12}>
                    <b>Calificación de la primera fase: </b>
                    { parseFloat(currentAbstract.calificacion_primera_fase).toFixed(2)}
                  </Grid>
              ) : null}
              {currentAbstract.comentario_primera_fase ? (
                  <Grid item xs={12}>
                    <b>Comentario de la primera fase: </b>
                    { currentAbstract.comentario_primera_fase}
                  </Grid>
              ) : null}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL ASIGNAR CATEGORIA */}
      {currentAbstract && (
        <div>
          <ModalAsignarCategoria
            openModalCategorias={openModalCategorias}
            setOpenModalCategorias={setOpenModalCategorias}
            currentCategory={currentAbstract.id_categoria}
            id_abstract={currentAbstract.ID}
          />
          {/*<ModalAsignarRevisor
            openModalRevisores={openModalRevisores}
            setOpenModalRevisores={setOpenModalRevisores}
            currentRevisor={currentAbstract.id_revisor}
            id_abstract={currentAbstract.ID}
          />*/}
          <ModalCorregir
            openModalCorregir={openModalCorregir}
            setOpenModalCorregir={setOpenModalCorregir}
            currentRevisor={currentAbstract.id_revisor}
            id_abstract={currentAbstract.ID}
          />
        </div>
      )}
    </React.Fragment>
  );
}
