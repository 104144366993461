import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export let getTiposDeEntradaPorEvento = (id_evento) => {
  let requestURL = Global.url + "/eventos/tiposEntrada/" + id_evento;
  return axios.get(requestURL);
};

// TODO: Cambiar url
export let getMetodosPagoPorEvento = (id_evento) => {
  let requestURL =
    Global.url + "/eventos/entradas/" + id_evento + "/metodosPago";
  return axios.get(requestURL);
};

export let obtenerDatosDeFacturaPorIdEventoIdCompra = (idCompra, idEvento) => {
  let requestURL =
    Global.url + "/eventos/" + idEvento + "/payment/" + idCompra + "/data";
  return axios.get(requestURL);
};

export let borrarEntrada = (idEntradaEliminar, userToken) => {
  let requestURL = Global.url + "/eventos/borrarEntrada/" + idEntradaEliminar;
  return axios.delete(requestURL, HEADERS);
};

export let getCamposCustomEntrada = (idTipoEntrada) => {
  let requestURL =
    Global.url + "/eventos/entradas/" + idTipoEntrada + "/camposCustom";
  return axios.get(requestURL, HEADERS);
};

export let getEventInfo = (eventID) => {
  let requestURL = Global.url + `/eventos/${eventID}/info`;
  return axios.get(requestURL);
};

export let sendBadges = (id_compra) => {
  let requestURL = Global.url + "/operaciones/enviarMailBadgesCompra";
  return axios.post(requestURL, querystring.stringify({ id_compra }), HEADERS);
};

export let sendEmailPayAfter = (id_compra) => {
  let requestURL = Global.url + "/operaciones/enviarEmailPagarDespues";
  return axios.post(requestURL, querystring.stringify({ id_compra }));
};

export let enviarCorreoPagarDespues = (idCompra, idioma) => {
  let requestURL = Global.url + "/operaciones/enviarEmailPagarDespues";
  return axios.post(
    requestURL,
    querystring.stringify({ id_compra: idCompra, idioma })
  );
};

export let getImageBase64FromS3 = (eventID, imageName) => {
  let requestURL = Global.url + "/eventos/" + eventID + "/getLogo/" + imageName;
  return axios.get(requestURL, HEADERS);
};

export let isBuyTimedOut = (buyId) => {
  let requestURL = Global.url + "/compras/" + buyId + "/checkTimeOut";
  return axios.get(requestURL, HEADERS);
};

export let getBillFooter = (buyId) => {
  let requestURL = Global.url + "/compras/" + buyId + "/billFooter";
  return axios.get(requestURL, HEADERS);
};

export let actualizarNombreFacturaPorIdCompra = (idCompra, nombreFactura) => {
  let requestURL = Global.url + "/compras/" + idCompra + "/nombreFactura";
  return axios.put(
    requestURL,
    querystring.stringify({ nombreFactura }),
    HEADERS
  );
};
