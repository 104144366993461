import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";
const HEADERS = { headers: Global.headers };

export let getAbstractsEvento = (token) => {
  let requestURL = Global.url + "/eventos/getAbstractsEvento";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(token), HEADERS);
};

export let getAbstractCategories = (id_evento) => {
  let requestURL = Global.url + "/eventos/getCategoriasAbstract";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
};

export let getRevisoresEvento = (id_evento) => {
  let requestURL = Global.url + "/eventos/getRevisoresEvento";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
};

export let setCategoriaAbstract = (id_abstract, id_categoria) => {
  let requestURL = Global.url + "/eventos/setCategoriaAbstract";
  let form = { id_abstract, id_categoria };
  return axios.post(requestURL, querystring.stringify(form));
};

export let setRevisoresAbstract = (form) => {
  let requestURL = Global.url + "/abstracts/setRevisoresAbstract";
  return axios.post(requestURL, querystring.stringify(form));
};

export let setAsignacionAutomaticaAbstract = (form) => {
  let requestURL = Global.url + "/eventos/setAsignacionAutomaticaAbstract";
  return axios.post(requestURL, querystring.stringify(form));
};

export let guardarCorrecionAbstract = (token, form) => {
  let requestURL = Global.url + "/eventos/guardarCorrecionAbstract";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form));
};

export let guardarCorrecionFinalAbstract = (token, form) => {
  let requestURL = Global.url + "/eventos/guardarCorrecionFinalAbstract";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form));
};

export let guardarCorreccionProvisional = (token, form) => {
  let requestURL = Global.url + "/eventos/guardarCorreccionProvisional";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form));
};

export let getCriteriosEvaluacionEvento = (id_evento) => {
  let requestURL = Global.url + "/abstracts/getEventAbstractCriteria";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
};

export let eliminarCorrecion = (id_correcion) => {
  let requestURL = Global.url + "/abstracts/eliminarCorrecion";
  let form = { id_correcion };
  return axios.post(requestURL, querystring.stringify(form));
};

export let solicitarComunicacionFinal = (id_abstract) => {
  let requestURL = Global.url + "/eventos/solicitarComunicacionFinal";
  let form = { id_abstract };
  return axios.post(requestURL, querystring.stringify(form));
};

export let aceptarComunicaciones = (id_evento, notaCorte) => {
  let requestURL = Global.url + "/abstracts/aceptarComunicaciones";
  let form = { id_evento, notaCorte };
  return axios.post(requestURL, querystring.stringify(form));
};

export let mailAceptarComunicaciones = (id_evento) => {
  let requestURL = Global.url + "/abstracts/mailAceptarComunicaciones";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
};

export let getTiposComunicacionEvento = (id_evento) => {
  let requestURL = Global.url + "/abstracts/getTiposComunicacionEvento";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
};

export let setFiltroAsignacionRevisor = (form) => {
  let requestURL = Global.url + "/abstracts/setFiltroAsignacionRevisor";
  return axios.post(requestURL, querystring.stringify(form));
};

export let eliminarFiltroAsignacionRevisor = (form) => {
  let requestURL = Global.url + "/abstracts/eliminarFiltroAsignacionRevisor";
  return axios.post(requestURL, querystring.stringify(form));
};

export let getAsignacionesComunicaciones = (form) => {
  let requestURL = Global.url + "/abstracts/getAsignacionesComunicaciones";
  return axios.post(requestURL, querystring.stringify(form));
};

export let eliminarAsignacionesRevisores = (form) => {
  let requestURL = Global.url + "/abstracts/eliminarAsignacionesRevisores";
  return axios.post(requestURL, querystring.stringify(form));
};

export let notificarAsignacionesRevisores = (form) => {
  let requestURL = Global.url + "/abstracts/notificarAsignacionesRevisores";
  return axios.post(requestURL, querystring.stringify(form));
};

export let notificarResultadoResumen = (form) => {
  let requestURL = Global.url + "/abstracts/notificarResultadoResumen";
  return axios.post(requestURL, querystring.stringify(form));
};

export let notificarSegundaFaseAutor = (form) => {
  let requestURL = Global.url + "/abstracts/notificarSegundaFaseAutor";
  return axios.post(requestURL, querystring.stringify(form));
};

export let descartarComunicacion = (id_abstract) => {
  let requestURL = Global.url + "/abstracts/descartarComunicacion";
  let form = { id_abstract };
  return axios.post(requestURL, querystring.stringify(form));
};

/* export let getAbstractAutorAdministrativo = (id_evento) => {
  let requestURL = Global.url + "/abstracts/getAbstractAutorAdministrativo";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
};

export let getNumberOfAbstracts = (id_evento) => {
  let requestURL = Global.url + "/abstracts/getNumberOfAbstracts";
  let form = { id_evento };
  return axios.post(requestURL, querystring.stringify(form));
}; */