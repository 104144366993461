import React from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
// import ModalEditarOperacion from "./modalEditarOperacion";
import { Icon, Tooltip } from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import XLSX from "xlsx";

//COMPONENTS
import { Skeleton } from "app/shared";
import ModalVerAbstractRevisor from "./modalVerAbstractRevisor";

export default function MaterialTableDemo(props) {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = React.useState(false);
  const [currentAbstract, setCurrentAbstract] = React.useState();
  const { abstractsData, cargando } = useSelector(
    ({ abstracts }) => abstracts.abstracts.abstracts
  );
  console.log(abstractsData);
  if (cargando) return <Skeleton height={"35rem"} />;

  // const handleEdit = () => {
  //   console.log("pulsado a boton de editar");
  //   console.log("LISTADO DE ASISTENTES!!");
  //   console.log(props.operaciones);
  // };

  //   const handleExport = () => {
  //     console.log(props.operaciones);
  //     //primero creamos un array de arrays que contendra columnas y filas
  //     let data = [
  //       [
  //         "ID",
  //         "ID AUTOR",
  //         "ID EVENTO",
  //         "TITULO",
  //         "Email",
  //         "Telefono",
  //         "Total",
  //         "Total Pagado",
  //         "Pagado",
  //         "Reembolsado",
  //         "Método de Pago",
  //         "Factura Proforma",
  //         "Factura",
  //         "Factura de Abono",
  //         "Fecha de Inscripcion",
  //         "Fecha de Compra",
  //       ],
  //     ];

  //     //ahora mapeamos las operaciones añadiendolas al array
  //     props.operaciones.map((row) => {
  //       let pagado;
  //       if (row.total == 0) {
  //         pagado = "Gratuito";
  //       } else if (row.reembolsado == 1) {
  //         pagado = "Reembolsado";
  //       } else {
  //         pagado = row.pagado === 1 ? "Si" : "No";
  //       }
  //       //seteamos texto para metodos de pago
  //       let metodo = "";
  //       switch (row.metodo_pago) {
  //         case 1:
  //           metodo = "Tarjeta de Crédito";
  //           break;
  //         case 2:
  //           metodo = "Transferencia Bancaria";
  //           break;
  //         default:
  //           break;
  //       }
  //       data.push([
  //         row.ID,
  //         row.nombre,
  //         row.cif,
  //         row.direccion,
  //         row.email,
  //         row.telefono,
  //         row.total,
  //         row.total_pagado,
  //         pagado,
  //         row.reembolsado === 1 ? "Si" : "No",
  //         metodo,
  //         row.num_proforma,
  //         row.num_factura,
  //         row.num_factura_reembolso,
  //         row.fecha_inscripcion
  //           ? new Date(row.fecha_inscripcion).toLocaleString("en-GB")
  //           : "",
  //         row.fecha_compra
  //           ? new Date(row.fecha_compra).toLocaleString("en-GB")
  //           : "",
  //       ]);
  //     });
  //     var worksheet = XLSX.utils.aoa_to_sheet(data);
  //     var new_workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(new_workbook, worksheet, "Operaciones");
  //     //con este funcion escribe el archivo y lo descarga directamente en el navegador
  //     XLSX.writeFile(new_workbook, `${props.id_evento}-Operaciones.xlsx`);
  //   };

  // const handleViewBill = (row) => {
  //   if (row.num_factura !== 0 && row.pagado === 1 && row.total > 0) {
  //     console.log("La operacion tiene factura.");
  //     window.open(
  //       `${process.env.REACT_APP_S3_BUCKET}/facturas/${props.id_evento}/${row.num_factura}.pdf`,
  //       "_blank"
  //     );
  //   } else {
  //     if (row.total === 0) {
  //       setSnackbar(
  //         "Esta operación es una invitación o no tiene coste, por lo que no se generará factura.",
  //         "info"
  //       );
  //     } else {
  //       console.log("La operacion NO tiene factura.");
  //       setSnackbar(
  //         "La factura se genera en el momento del pago de la operacion. Podra visuarlizarla y descargarla una vez completado el pago.",
  //         "error"
  //       );
  //     }
  //   }
  // };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
  };

  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId="tabla_op"
        transition={Slide}
      />
      <MaterialTable
        title={t("Listado de comunicaciones")}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("de") + " {count}",
            labelRowsPerPage: t("Filas por página"),
            labelRowsSelect: t("Filas"),
            firstAriaLabel: t("Primera página"),
            firstTooltip: t("Primera página"),
            previousAriaLabel: t("Anterior página"),
            previousTooltip: t("Anterior página"),
            nextAriaLabel: t("Siguiente página"),
            nextTooltip: t("Siguiente página"),
            lastAriaLabel: t("Última página"),
            lastTooltip: t("Última página"),
          },
          toolbar: {
            nRowsSelected: "{0} fila(s) seleccionadas",
            searchTooltip: t("Buscar registro"),
            searchPlaceholder: t("Buscar registro"),
            exportName: t("Exportar Excel"),
            exportTitle: t("Exportar"),
            exportAriaLabel: t("Exportar Excel"),
          },
          header: {
            actions: t("Acciones"),
          },
          body: {
            editRow: {
              saveTooltip: t("Guardar"),
              cancelTooltip: t("Cancelar"),
              deleteText: t("Eliminar"),
            },
            addTooltip: t("Añadir"),
            deleteTooltip: t("Eliminar"),
            editTooltip: t("Editar"),
            emptyDataSourceMessage: t("No hay registros disponibles"),
            filterRow: {
              filterTooltip: t("Filtrar"),
            },
          },
        }}
        columns={[
          { title: "ID", field: "num_abstract" },
          { title: t("Titulo"), field: "titulo" },
          {
            /* title: t("Autor"), field: "nombre_autor" */
          },
          { title: t("Categoria"), field: "nombre_categoria" },
          {
            title: t("Fecha límite correción"),
            field: "fecha_max_correcion",
            render: (rowData) => {
              try {
                if (
                  !rowData.fecha_max_correcion ||
                  rowData.fecha_max_correcion == "0000-00-00 00:00:00"
                ) {
                  return "-";
                }
                return new Date(rowData.fecha_max_correcion).toLocaleString(
                  "es-ES",
                  {
                    timeZone: "UTC",
                  }
                );
              } catch (e) {
                console.log(e.toString());
                return "-";
              }
            },
          },
          /* {
            title: t("Fecha Corrección"),
            field: "fecha_corregido",
            render: (rowData) => {
              try {
                if (
                  !rowData.fecha_corregido ||
                  rowData.fecha_corregido == "0000-00-00 00:00:00"
                ) {
                  return "-";
                }
                return new Date(rowData.fecha_corregido).toLocaleString(
                  "es-ES",
                  {
                    timeZone: "UTC",
                  }
                );
              } catch (e) {
                console.log(e.toString());
                return "-";
              }
            },
          }, */
          /*           { title: t("Calificación"), field: "calificacion_provisional" },
           */ {
            title: t("Evaluado"),
            field: "num_calificaciones",
            render: (rowData) => {
              try {
                if (
                  rowData.num_calificaciones > 0 &&
                  !rowData.calificacion_provisional
                ) {
                  return <div className="text-green-500 font-bold">Si</div>;
                } else if (
                  rowData.calificacion_provisional &&
                  rowData.num_calificaciones > 0
                ) {
                  return <div className="text-green-500 font-bold">Si</div>;
                } else {
                  return <div className="text-red-500 font-bold">No</div>;
                }
              } catch (e) {
                console.log(e.toString());
                return "-";
              }
            },
          },
          // {
          //   title: t("Estado"),
          //   field: "aceptado",
          //   render: (rowData) => {
          //     try {
          //       if (rowData.aceptado === 2) {
          //         return "Pendiente";
          //       } else if (rowData.aceptado === 3) {
          //         return "Pendiente de comunicación final";
          //       } else {
          //         return "Pendiente revisión final";
          //       }
          //     } catch (e) {
          //       console.log(e.toString());
          //       return "-";
          //     }
          //   },
          // },

          // {
          //   title: t("Revisor"),
          //   field: "nombre_revisor",
          //   render: (rowData) => {
          //     if (rowData.nombre_revisor && rowData.apellidos_revisor) {
          //       return `${rowData.nombre_revisor} ${rowData.apellidos_revisor}`;
          //     } else {
          //       return <div className="text-red">No Asignado</div>;
          //     }
          //   },
          // },
          //   {
          //     title: t("Pagado"),
          //     field: "str_pagado",
          //     render: (rowData) => {
          //       if (rowData.reembolsado === 1) {
          //         return (
          //           <Tooltip title="Reembolsado">
          //             <Icon className="text-orange">money_off</Icon>
          //           </Tooltip>
          //         );
          //       } else if (rowData.total == 0) {
          //         return (
          //           <Tooltip title="Gratuito">
          //             <Icon className="text-blue-400">money_off</Icon>
          //           </Tooltip>
          //         );
          //       } else {
          //         switch (rowData.pagado) {
          //           case 0:
          //             return (
          //               <Tooltip title="No pagado">
          //                 <Icon className="text-red">close</Icon>
          //               </Tooltip>
          //             );
          //           case 1:
          //             return (
          //               <Tooltip title="Pagado">
          //                 <Icon className="text-green">check</Icon>
          //               </Tooltip>
          //             );
          //           default:
          //         }
          //       }
          //     },
          //   },
        ]}
        // data={[
        //   { nombre: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
        //   { nombre: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
        // ]}
        data={abstractsData.filter(
          (row) => row.aceptado !== 0 && 
          row.aceptado !== 1 && 
          row.aceptado !== 5
        )}
        actions={[
          {
            icon: "edit",
            tooltip: t("Editar"),
            onClick: (event, rowData) => {
              // alert("You saved " + rowData.name);
              setCurrentAbstract(rowData);
              setDisplayModal(true);
            },
          },
          // {
          //   icon: "insert_drive_file",
          //   tooltip: t("Ver factura"),
          //   onClick: (event, rowData) => {
          //     handleViewBill(rowData);
          //   },
          // },
          // {
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => alert("You want to delete " + rowData.name)
          // }
        ]}
        options={{
          pageSize: 10,
        }}
        // options={{
        //   exportButton: true,
        //   exportCsv: () => handleExport(),
        // }}
      />

      {displayModal && currentAbstract && (
        <ModalVerAbstractRevisor
          displayModal
          currentAbstract={currentAbstract}
          setCurrentAbstract={setCurrentAbstract}
          setDisplayModal={setDisplayModal}
        />
      )}
    </div>
  );
}
