import React from "react";

export const UsuariosConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/usuarios",
      component: React.lazy(() => import("./Usuarios")),
    },
  ],
};
