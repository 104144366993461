import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    marginTop: "200px",
    width: "100%",
  },
  loading: {
    textAlign: "center",
  },
  logo: {
    display: "block",
    margin: "0 auto",
  },
  loadingMessage: {
    marginTop: "10px",
    marginBottom: "20px",
  },
}));

const Loading = (props) => {
  const classes = useStyles();
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    if (props.icon) setShowIcon(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={classes.container} justify="center">
      <Grid item xs={12}>
        <div className={classes.loading}>
          {showIcon && (
            <img
              className={classes.logo}
              src="assets/images/idcongress/logo_dark.png"
              alt="logo"
            />
          )}
          {props.loadingMessage !== "" && (
            <Typography className={classes.loadingMessage}>
              {props.loadingMessage}
            </Typography>
          )}
          <CircularProgress style={{ color: "#5479B6" }} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Loading;
