/* eslint-disable eqeqeq */

import { log } from "./Debugger";
export class Discounter {
  static applyDiscountIfExists(discounts, entity, concepto, cupo) {
    // ya se han aplicado todos los descuentos o no existen
    if (cupo == 0 || !cupo)
      return { nuevoPrecio: entity.precio, nuevoCupo: cupo };

    // Concepto = 1 --> Inscripcion
    // Concepto = 2 --> Complemento
    let descuentoExistente = discounts.find(
      (d) => d.id_concepto == entity.ID && d.id_tipo_concepto == concepto
    );

    // No hay descuento
    if (!descuentoExistente)
      return { nuevoPrecio: entity.precio, nuevoCupo: cupo };

    // Si hay descuento
    let { id_tipo, valor } = descuentoExistente;

    // Hay descuento
    // 1 --> porcentaje
    // 2 --> cantidad fija
    let nuevoPrecio =
      id_tipo == 2
        ? entity.precio - valor
        : entity.precio - entity.precio * (valor / 100);

    return { nuevoPrecio, nuevoCupo: cupo - 1 };
  }

  static applyGlobalDiscountsIfExist(discounts, total) {
    // No hay descuentos
    if (discounts.length == 0) return total;
    log("Hay descuento global");

    let descuentoGlobal = discounts.find((d) => d.global_compra == 1);
    log("Descuento global encontrado -->");
    log(descuentoGlobal);

    // No hay descuento global o aún no se ha escogido nada
    // en el iframe
    if (!descuentoGlobal || !total) return total;
    log("Hay descuento global -->");
    log(descuentoGlobal);

    if (descuentoGlobal.id_tipo == 2) {
      // 2 --> cantidad fija, evitamos precios negativos
      log("Es una cantidad fija -->" + descuentoGlobal.valor);
      return total - descuentoGlobal.valor >= 0
        ? total - descuentoGlobal.valor
        : 0;
    } else {
      // 1 --> porcentaje
      log("Es un porcentaje -->" + descuentoGlobal.valor + "%");
      return total - total * (descuentoGlobal.valor / 100);
    }
  }

  static ponderarDescuentoGlobalSobreTodosLosProductos(
    porcentajeDescuentoGlobal,
    inscripciones
  ) {
    let inscripcionesRecalculadas = [];
    let inscripcionRecalculada;
    let complementoRecalculado;
    let brutoAplicado;
    let totalAplicado;

    inscripcionesRecalculadas = inscripciones.map((inscripcion) => {
      inscripcionRecalculada = { ...inscripcion };

      brutoAplicado = inscripcionRecalculada.precio * porcentajeDescuentoGlobal;
      brutoAplicado = parseFloat(brutoAplicado.toFixed(2));

      totalAplicado =
        brutoAplicado * (1 + inscripcionRecalculada.iva_aplicado / 100);
      totalAplicado = parseFloat(totalAplicado.toFixed(2));

      inscripcionRecalculada.precioAplicado = totalAplicado;

      inscripcionRecalculada.complementos = inscripcionRecalculada.complementos.map(
        (complemento) => {
          complementoRecalculado = { ...complemento };

          brutoAplicado =
            complementoRecalculado.precio * porcentajeDescuentoGlobal;
          brutoAplicado = parseFloat(brutoAplicado.toFixed(2));

          totalAplicado =
            brutoAplicado * (1 + complementoRecalculado.iva_aplicado / 100);
          totalAplicado = parseFloat(totalAplicado.toFixed(2));

          complementoRecalculado.precioAplicado = totalAplicado;

          return complementoRecalculado;
        }
      );

      return inscripcionRecalculada;
    });

    return inscripcionesRecalculadas;
  }
}

export const calcularCosteInscripciones = (inscripciones, descuentos) => {
  let inscripcionesRecalculadas = [];
  let cupoInscripciones, cupoComplementos;
  let ultimoIdInscripcion;
  let listaIvas = {};

  // Precios
  let total = 0;
  let iva = 0;
  let costesDistribucionBrutos = 0;
  let precioBruto = 0;

  let hayDescuentos = descuentos.length > 0;

  if (hayDescuentos) {
    let [{ cupo_compra }] = descuentos;
    // El cupo compra es el mismo para usarlo en inscripciones y en comp.
    // Ej: Cupo: 3 -> Se puede usar en 3 inscrip. y 3 comps.
    cupoInscripciones = cupoComplementos = cupo_compra;
  }

  inscripcionesRecalculadas = inscripciones.map((i) => {
    // Calcular coste de la inscripción
    let {
      precioBruto: pbI,
      precioAplicado: paI,
      iva: ivaI,
      nuevoCupo,
      gastosDistribucion,
    } = calcularCosteInscripcion(i, descuentos, cupoInscripciones);

    cupoInscripciones = nuevoCupo;

    // Sólo aplicamos gastos de distribución por cada tipo de inscripción
    if (ultimoIdInscripcion !== i.ID) {
      costesDistribucionBrutos += gastosDistribucion;
    }
    ultimoIdInscripcion = i.ID;

    // Calcular coste de sus complementos asociados y escogidos por el usuario.
    let {
      complementos,
      precioBruto: pbC,
      iva: ivaC,
      precioAplicado: paC,
    } = calcularCosteComplementos(
      i.complementos,
      descuentos,
      cupoComplementos,
      listaIvas
    );

    precioBruto += pbI + pbC;
    iva += ivaI + ivaC;
    total += paI + paC;

    // Actualizamos el estado de la inscripcion
    return { ...i, precioAplicado: parseFloat(paI.toFixed(2)), complementos };
  });

  // Sólo se permiten descuentos globales, si no hay diferentes IVAS
  let noHayMasDeUnTipoDeIva = Object.keys(listaIvas).length < 2;
  if (noHayMasDeUnTipoDeIva) {
    let precioBrutoAntiguo = precioBruto;
    log("Precio bruto antiguo -->" + precioBrutoAntiguo);

    precioBruto = Discounter.applyGlobalDiscountsIfExist(
      descuentos,
      precioBruto
    );
    log("Precio bruto aplicado -->" + precioBruto);
    if (precioBrutoAntiguo > 0) {
      iva *= precioBruto / precioBrutoAntiguo;
      inscripcionesRecalculadas = Discounter.ponderarDescuentoGlobalSobreTodosLosProductos(
        precioBruto / precioBrutoAntiguo,
        inscripciones
      );
    }

    if (precioBruto != precioBrutoAntiguo) {
      // Si son diferentes, es que se ha aplicado un descuento global
      // luego el total se recalcula con el nuevo precioBruto y el nuevo iva
      total = precioBruto + iva;
    }
  }

  // Aplicamos siempre un 21% de IVA a los gastos de distribución
  total += costesDistribucionBrutos * 1.21;
  iva += costesDistribucionBrutos * 0.21;
  iva = parseFloat(iva.toFixed(2));
  total = parseFloat(total.toFixed(2));

  return {
    inscripciones: inscripcionesRecalculadas,
    precioBruto,
    total,
    iva,
    costesDistribucionBrutos,
  };
};

export const calcularCosteInscripcion = (
  inscripcion,
  descuentos,
  cupoInscripciones
) => {
  let {
    nuevoPrecio: precioBruto,
    nuevoCupo,
  } = Discounter.applyDiscountIfExists(
    descuentos,
    inscripcion,
    1,
    cupoInscripciones
  );

  let iva = precioBruto * (inscripcion.iva_aplicado / 100);
  let precioAplicado = iva + precioBruto;
  let gastosDistribucion = inscripcion.gastos_distribucion;

  // Nuevo cupo es el cupo de veces que ese descuento se puede seguir aplicando
  return { precioBruto, precioAplicado, iva, nuevoCupo, gastosDistribucion };
};

export const calcularCosteComplementos = (
  complementos,
  descuentos,
  cupoComplementos,
  listaIvas
) => {
  let precioBrutoTotal = 0;
  let ivaTotal = 0;
  let precioAplicadoTotal = 0;
  let cupo = cupoComplementos;

  let complementosRecalculados = complementos.map((c) => {
    if (c.escogido) {
      let {
        precioBruto,
        precioAplicado,
        iva,
        nuevoCupo,
      } = calcularCosteComplemento(c, descuentos, cupo, listaIvas);

      cupo = nuevoCupo;
      precioBrutoTotal += precioBruto;
      ivaTotal += iva;
      precioAplicadoTotal += precioAplicado;

      return { ...c, precioAplicado: parseFloat(precioAplicado.toFixed(2)) };
    } else {
      return c;
    }
  });

  return {
    complementos: complementosRecalculados,
    precioBruto: precioBrutoTotal,
    iva: ivaTotal,
    precioAplicado: precioAplicadoTotal,
  };
};

export const calcularCosteComplemento = (
  complemento,
  descuentos,
  cupoComplementos,
  listaIvas
) => {
  let {
    nuevoPrecio: precioBruto,
    nuevoCupo,
  } = Discounter.applyDiscountIfExists(
    descuentos,
    complemento,
    2,
    cupoComplementos
  );

  let iva = precioBruto * (complemento.iva_aplicado / 100);
  let precioAplicado = iva + precioBruto;

  guardarIvaEnListaIvas(listaIvas, complemento.iva_aplicado, iva);

  return { precioBruto, precioAplicado, iva, nuevoCupo };
};

export const guardarIvaEnListaIvas = (listaIvas, porcentajeIva, cantidad) => {
  if (porcentajeIva === 0) return;

  let porcentajeYaExiste = listaIvas[porcentajeIva];

  if (porcentajeYaExiste) {
    // añadir cantidad
    listaIvas[porcentajeIva] += cantidad;
  } else {
    // crear indice
    listaIvas[porcentajeIva] = cantidad;
  }
};
