import {
  CARGA_DATOS_EVENTO_FINALIZADA,
  CARGA_DATOS_EVENTO_SOLICITADA,
  CARGA_DATOS_EVENTO_ERRONEA,
  CARGA_DATOS_EVENTO_CORRECTA,
  CARGA_IMAGEN_EVENTO_SOLICITADA,
  CARGA_IMAGEN_EVENTO_CORRECTA,
  CARGA_IMAGEN_EVENTO_ERRONEA,
  CARGA_IMAGEN_EVENTO_FINALIZADA,
  CARGA_DATOS_EMPRESA_ORGANIZADORA_SOLICITADA,
  CARGA_DATOS_EMPRESA_ORGANIZADORA_CORRECTA,
  CARGA_DATOS_EMPRESA_ORGANIZADORA_ERRONEA,
  CARGA_DATOS_EMPRESA_ORGANIZADORA_FINALIZADA,
  EVENTO_CADUCADO,
} from "../actions";

const initialState = {
  cargando: true,
  caducado: null,
  error: null,
  datos: {
    ID: null,
    hayFacturacion: false,
    nombre: null,
    idioma: "es",
    imagen: {
      contenido: "",
      cargando: true,
      error: null,
    },
    politicaPrivacidad: "",
    terminosCondiciones: "",
    empresaOrganizadora: {
      contenido: {},
      cargando: true,
      error: null,
    },
  },
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case CARGA_DATOS_EVENTO_SOLICITADA:
      return { ...state, cargando: true };
    case CARGA_DATOS_EVENTO_CORRECTA:
      return {
        ...state,
        datos: { ...state.datos, ...action.payload },
      };
    case CARGA_DATOS_EVENTO_ERRONEA:
      return { ...state, error: action.payload };
    case CARGA_DATOS_EVENTO_FINALIZADA:
      return { ...state, cargando: false };
    case CARGA_IMAGEN_EVENTO_SOLICITADA:
      return {
        ...state,
        datos: {
          ...state.datos,
          imagen: {
            ...state.datos.imagen,
            cargando: true,
          },
        },
      };
    case CARGA_IMAGEN_EVENTO_CORRECTA:
      return {
        ...state,
        datos: {
          ...state.datos,
          imagen: {
            ...state.datos.imagen,
            contenido: action.payload,
          },
        },
      };
    case CARGA_IMAGEN_EVENTO_ERRONEA:
      return {
        ...state,
        datos: {
          ...state.datos,
          imagen: {
            ...state.datos.imagen,
            error: action.payload,
          },
        },
      };
    case CARGA_IMAGEN_EVENTO_FINALIZADA:
      return {
        ...state,
        datos: {
          ...state.datos,
          imagen: {
            ...state.datos.imagen,
            cargando: false,
          },
        },
      };

    case CARGA_DATOS_EMPRESA_ORGANIZADORA_SOLICITADA:
      return {
        ...state,
        datos: {
          ...state.datos,
          empresaOrganizadora: {
            ...state.datos.empresaOrganizadora,
            cargando: true,
          },
        },
      };

    case CARGA_DATOS_EMPRESA_ORGANIZADORA_CORRECTA:
      return {
        ...state,
        datos: {
          ...state.datos,
          empresaOrganizadora: {
            ...state.datos.empresaOrganizadora,
            contenido: action.payload,
          },
        },
      };

    case CARGA_DATOS_EMPRESA_ORGANIZADORA_ERRONEA:
      return {
        ...state,
        datos: {
          ...state.datos,
          empresaOrganizadora: {
            ...state.datos.empresaOrganizadora,
            error: action.payload,
          },
        },
      };

    case CARGA_DATOS_EMPRESA_ORGANIZADORA_FINALIZADA:
      return {
        ...state,
        datos: {
          ...state.datos,
          empresaOrganizadora: {
            ...state.datos.empresaOrganizadora,
            cargando: false,
          },
        },
      };

    case EVENTO_CADUCADO:
      return {
        ...state,
        caducado: true,
      };

    default:
      return state;
  }
};
