import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBuyTimedOut } from "app/services/eventosService";

import Loading from "../../Loading";
import {
  obtenerDatosPagoDeCompra,
  obtenerDatosCodificadosTPVRedsys,
} from "app/services/iframeService";
import { DatosRedsysParaTPV } from "app/components/iframe/model";
import Global from "app/fuse-configs/Global";

const MENSAJE_CARGA = "Redirigiendo a pasarela de pago ...";
const MENSAJE_ERROR =
  "Se ha excedido el tiempo para realizar la inscripción. Debe volver a realizarla. Disculpe las molestias.";

const DirectPaymentFromEmail = (props) => {
  const { t, i18n } = useTranslation();
  const [loadingMessage, setLoadingMessage] = useState(t(MENSAJE_CARGA));

  const idCompra = props.match.params.buyId;
  const idioma = props.match.params.idioma;

  const { debugMode } = Global;

  const [redsysData, setRedsysData] = useState({});

  useEffect(() => {
    i18n.changeLanguage(idioma);
    if (idCompra) continuarHaciaPasarela(idCompra);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continuarHaciaPasarela = async (idCompra) => {
    try {
      const caducado = await hanPasadoMasDe15Min(idCompra);
      if (caducado) {
        setLoadingMessage(t(MENSAJE_ERROR));
        return;
      }

      await obtenerDatosTPVRedsysCodificados();
      setTimeout(redirigirARedsys, 1000);
    } catch (e) {
      setLoadingMessage(t(MENSAJE_ERROR));
      return;
    }
  };

  const hanPasadoMasDe15Min = async (idCompra) => {
    let { data } = await isBuyTimedOut(idCompra);
    let { status, result: hanPasado } = data;

    if (!status) {
      throw new Error("Error en la llamada");
    }

    return hanPasado;
  };

  const serializeTPV = (config) => {
    const [url, clave, code, terminal, tokenizacion] = JSON.parse(config);
    return {
      url,
      clave,
      code,
      terminal,
      tokenizacion,
    };
  };

  const obtenerDatosTPVRedsysCodificados = async () => {
    let response = await obtenerDatosPagoDeCompra(idCompra);
    let paymentData = response.data.result;
    let tpv = serializeTPV(paymentData.config);

    let codigoComercio = tpv.code;
    let terminal = tpv.terminal;
    let claveSecreta = tpv.clave;
    let idEvento = paymentData.id_evento;
    let importe = paymentData.total;

    try {
      const datosRedsys = new DatosRedsysParaTPV({
        idioma,
        importe,
        idCompra,
        codigoComercio,
        terminal,
        claveSecreta,
      });

      const { data } = await obtenerDatosCodificadosTPVRedsys(
        idEvento,
        datosRedsys
      );
      const { result: datosCodificados, status } = data;

      if (!status) throw new Error("Error en el status");

      setRedsysData((oldParameters) => ({
        ...oldParameters,
        ...datosCodificados,
      }));
    } catch (e) {
      if (debugMode) {
        console.log("ERROR", e);
      }
    }
  };

  const redirigirARedsys = () => {
    document.getElementById("fghrtredfd").submit();
  };

  if (Object.keys(redsysData).length === 0) {
    return <Loading message={loadingMessage} />;
  }

  return (
    <form action={redsysData.url} method="POST" name="from" id="fghrtredfd">
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        value={redsysData.signatureVersion}
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        value={redsysData.merchantParameters}
      />
      <input type="hidden" name="Ds_Signature" value={redsysData.signature} />
    </form>
  );
};

export default DirectPaymentFromEmail;
