export const CARGA_INSCRITOS_PRIVADOS_SOLICITADA =
  "[APP.IFRAME.INSCRITOS_PRIVADOS] CARGA DE INSCRITOS PRIVADOS SOLICITADA";
export function solicitarCargaInscritosPrivados(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_INSCRITOS_PRIVADOS_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_INSCRITOS_PRIVADOS_EXITOSA =
  "[APP.IFRAME.INSCRITOS_PRIVADOS] CARGA INSCRITOS PRIVADOS EXITOSA";
export function cargaInscritosPrivadosExitosa(inscritosPrivados) {
  return (dispatch) =>
    dispatch({
      type: CARGA_INSCRITOS_PRIVADOS_EXITOSA,
      payload: inscritosPrivados,
    });
}

export const CARGA_INSCRITOS_PRIVADOS_ERRONEA =
  "[APP.IFRAME.INSCRITOS_PRIVADOS] CARGA INSCRITOS PRIVADOS ERRÓNEA";
export function cargaInscritosPrivadosErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_INSCRITOS_PRIVADOS_ERRONEA,
      payload: error,
    });
}

export const CARGA_INSCRITOS_PRIVADOS_FINALIZADA =
  "[APP.IFRAME.INSCRITOS_PRIVADOS] CARGA INSCRITOS PRIVADOS FINALIZADA";
export function cargaInscritosPrivadosFinalizada() {
  return (dispatch) => dispatch({ type: CARGA_INSCRITOS_PRIVADOS_FINALIZADA });
}

export const GUARDAR_INSCRITOS_PRIVADOS =
  "[APP.IFRAME.INSCRITOS_PRIVADOS] GUARDA LOS INSCRITOS PRIVADOS";
export function guardarInscritosPrivados(inscritosPrivados) {
  return (dispatch) =>
    dispatch({
      type: GUARDAR_INSCRITOS_PRIVADOS,
      payload: inscritosPrivados,
    });
}
