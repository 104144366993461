import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export let obtenerEstadoFacturacionPorIdEvento = (idEvento) => {
  let requestURL = Global.url + "/eventos/" + idEvento + "/facturacion";
  return axios.get(requestURL);
};

export const obtenerEventoPorId = (id) => {
  const requestURL = `${Global.url}/eventos/${id}`;
  return axios.get(requestURL);
};

export const obtenerTipoEventoPorId = (id) => {
  const requestURL = `${Global.url}/eventos/${id}/tipo`;
  return axios.get(requestURL);
};

export let crearEvento = (nuevoEvento, userToken) => {
  let requestURL = Global.url + "/eventos/crear";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(nuevoEvento), HEADERS);
};

export let crearEventoOnline = (nuevoEvento, userToken) => {
  let requestURL = Global.url + "/eventos/crearOnline";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(nuevoEvento), HEADERS);
};

export let actualizarEvento = (eventoActualizado, userToken) => {
  let requestURL = Global.url + "/eventos/actualizar";
  axios.defaults.headers.common["token"] = userToken;
  return axios.put(
    requestURL,
    querystring.stringify(eventoActualizado),
    HEADERS
  );
};

export let borrarEvento = (idEvento, userToken) => {
  let requestURL = Global.url + "/eventos/eliminar/" + idEvento;
  axios.defaults.headers.common["token"] = userToken;
  return axios.delete(requestURL, HEADERS);
};

export let getProformas = (eventId, userToken) => {
  let requestURL = `${Global.url}/eventos/${eventId}/proformas`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL);
};

export let getOrganizerCredentials = (eventID) => {
  let requestURL = `${Global.url}/eventos/${eventID}/organizer`;
  return axios.get(requestURL);
};

export let changeIframeColor = (iframeColor, eventID, userToken) => {
  let requestURL = `${Global.url}/iframe/${eventID}/iframeColor`;
  return axios.post(requestURL, querystring.stringify({ color: iframeColor }));
};

export let cambiarEstadoFacturacion = (userToken, idEvento, nuevoEstado) => {
  let requestURL = `${Global.url}/eventos/${idEvento}/facturacion/estado`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(
    requestURL,
    querystring.stringify({ nuevoEstado }, HEADERS)
  );
};

export let multipleSearchEventsAndUsers = (userToken, coincidence) => {
  let requestURL = `${Global.url}/eventos/search/multiple/${coincidence}`;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL, HEADERS);
};

export const enviarBadgesCompraOnline = (idCompra) => {
  let requestURL = `${Global.url}/operaciones/reenviarBadgesCompraOnline`;
  return axios.post(
    requestURL,
    querystring.stringify({ id_compra: idCompra }),
    HEADERS
  );
};
