import React from "react";
import { useTranslation } from "react-i18next";

import { Button, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useButton } from "./hooks";

const styles = {
  buttonBack: {
    marginLeft: "20px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

const useStyles = makeStyles(styles);

const VolverAtrasButton = ({ action }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { color } = useButton();

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={action}
      startIcon={<ArrowBackIcon style={{ fontSize: 25 }} />}
      className={classes.buttonBack}
      style={{
        backgroundColor: color ? color : "#E0E0E0",
      }}
    >
      {t("Volver atrás")}
    </Button>
  );
};

export default VolverAtrasButton;
