class ConfigIframe {
  constructor({
    ID = 0,
    idEvento = 0,
    idioma = "es",
    color = "#2D323E" /* color por defecto del iframe */,
    politicaPrivacidad = null,
    notificacionesComerciales = null,
    privado = false,
  }) {
    this.ID = ID;
    this.idEvento = idEvento;
    this.idioma = idioma;
    this.color = color;
    this.politicaPrivacidad = politicaPrivacidad;
    this.notificacionesComerciales = notificacionesComerciales;
    this.privado = privado !== 0;
  }
}

export default ConfigIframe;
