import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { toast, ToastContainer, Slide } from "react-toastify";
import {
  Grid,
  Menu,
  Paper,
  Tooltip,
  IconButton,
  Icon,
} from "@material-ui/core";
import jwtDecode from "jwt-decode";

//COMPONENTS
import { Skeleton } from "app/shared";
import ModalAsignarCategoria from "./modalAsignarCategoria";
import ModalAsignarRevisor from "./modalAsignarRevisor";
import ModalCorregir from "./modalCorregir";
import ModalCorrecionFinal from "./modalCorrecionFinal";
import ModalCorreccionProvisional from "./modalCorrecionProvisional";

//Actions
import {
  resetCurrentAbstract,
  resetRevisoresAsignados,
  solicitaCargaAbstractsEvento,
  getCorrecionesAbstract,
  getAbstractInfo,
  getNombreAutores,
} from "../store/actions";

//Services
import {
  eliminarCorrecion,
  solicitarComunicacionFinal,
  descartarComunicacion,
} from "../../../services/eventosService/abstractsService";

const setSnackbar = (message, type) => {
  if (type === "success") {
    toast.success("✔️ " + message, {
      position: "bottom-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      containerId: "modalVerAbs",
    });
  }
  if (type === "error") {
    toast.error("❌ " + message, {
      position: "bottom-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      containerId: "modalVerAbs",
    });
  }
  if (type === "info") {
    toast.info("ℹ️ " + message, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      containerId: "modalVerAbs",
    });
  }
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const StyledInput = ({ title, data }) => {
  return (
    <Grid container>
      <Grid xs={12} className="text-10 text-gray-500 font-bold">
        {title}
      </Grid>
      <Grid xs={12} className="text-12">
        {data}
      </Grid>
    </Grid>
  );
};

const CriteriaBlockContent = (crit_row) => {
  let crit_jsx = [];
  JSON.parse(crit_row).map((row, index) => {
    if (index === 0) {
      crit_jsx.push(
        <>
          <b>{row.name}:</b> {row.value}
        </>
      );
    } else {
      crit_jsx.push(
        <>
          {" "}
          / <b>{row.name}:</b> {row.value}
        </>
      );
    }
  });
  return crit_jsx;
};

export default function ModalVerAbstract(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const [open, setOpen] = React.useState(false);
  const user = useSelector(({ authX }) => authX.user.data);
  let { usuario } = jwtDecode(user.token);
  let id_usuario = usuario[0].ID;
  const {
    currentAbstract,
    id_evento,
    currentRevisoresAsignados,
    currentRevisoresNoAsignados,
    currentCorrecionesAbstract,
    abstractConfig,
    currentCorrecionesAbstractPrimeraFase,
    nombreAutores,
  } = useSelector(({ abstracts }) => abstracts.abstracts);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalCategorias, setOpenModalCategorias] = React.useState(false);
  const [openModalRevisores, setOpenModalRevisores] = React.useState(false);
  const [openModalCorregir, setOpenModalCorregir] = React.useState(false);
  const [openModalCorrecionFinal, setOpenModalCorrecionFinal] = React.useState(
    false
  );

  console.log(nombreAutores);

  const [
    openModalCorreccionProvisional,
    setOpenModalCorreccionProvisional,
  ] = React.useState(false);
  const [lockCorreciones, setLockCorreciones] = React.useState(true);
  const [
    disabledComunicacionFinalBtn,
    setDisabledComunicacionFinalBtn,
  ] = React.useState(false);
  const fechaMaxCorrecion = props.fechaMaxCorrecion;

  useEffect(() => {
    // dispatch(getAbstractInfo(user.token, props.currentAbstract.ID));
    // dispatch(getCoautoresAbstract(user.token, props.currentAbstract.ID));
  }, [dispatch]);

  let hay_adjuntos = false;
  if (currentAbstract) {
    if (currentAbstract.urls_adjuntos) {
      if (JSON.parse(currentAbstract.urls_adjuntos).length > 0) {
        hay_adjuntos = true;
      }
    }
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    props.setDisplayModal(false);
    dispatch(solicitaCargaAbstractsEvento(user.token));
    dispatch(resetCurrentAbstract());
    dispatch(resetRevisoresAsignados());
  };

  const handleAsignarCategoria = () => {
    setOpenModalCategorias(true);
    setAnchorEl(false);
  };

  const handleAsignarRevisor = () => {
    setOpenModalRevisores(true);
    setAnchorEl(false);
  };

  const handleCorregir = () => {
    setOpenModalCorregir(true);
    setAnchorEl(false);
  };

  const handleCorrecionFinal = () => {
    setOpenModalCorrecionFinal(true);
    setAnchorEl(false);
  };

  const handleCorreccionProvisional = () => {
    setOpenModalCorreccionProvisional(true);
    setAnchorEl(false);
  };

  const handleDeleteCorrecion = async (id_correcion) => {
    let result = await eliminarCorrecion(id_correcion);
    if (!result.data.status) {
      console.log("delete - fail!");
    } else {
      console.log("delete - ok!");
      //recargamos correciones
      dispatch(getCorrecionesAbstract(currentAbstract.ID));
      dispatch(getAbstractInfo(user.token, currentAbstract.ID));
      //recargamos datos del abstract
    }
  };

  const handleDescartarComunicacion = async (id_abstract) => {
    let resultado = await descartarComunicacion(currentAbstract.ID);
    if (!resultado.data.status) {
      setSnackbar("No se ha podido descartar la comunicación", "error");
      handleCloseMenu();
    } else {
      setSnackbar("Se ha descartado la comunicación", "success");
      handleClose();
    }
  };

  const handleSolicitarComunicacionFinal = async (id_abstract) => {
    setDisabledComunicacionFinalBtn(true);
    setSnackbar(
      "Se esta enviando el correo para solicitar la comunicación final. Espere un momento, por favor.",
      "info"
    );
    let result = await solicitarComunicacionFinal(id_abstract);
    if (!result.data.status) {
      setSnackbar(
        "No se ha podido modificar el estado de la comunicación",
        "error"
      );
      handleClose();
      setDisabledComunicacionFinalBtn(false);
    } else {
      //recargamos correciones
      dispatch(solicitaCargaAbstractsEvento(user.token));
      dispatch(getAbstractInfo(user.token, currentAbstract.ID));
      //recargamos datos del abstract
      setSnackbar("Se ha modificado el estado de la comunicación", "success");
      handleClose();
    }
  };

  const checkAbstractAsignado = () => {
    //si el abstract no tiene ningun revisor asignado devolvemos false
    if (currentRevisoresAsignados.length === 0) {
      return false;
    }
    let filtered = currentRevisoresAsignados.filter(
      (row) => parseInt(row.ID) === parseInt(id_usuario)
    );
    let result = filtered.length > 0 ? true : false;
    return result;
  };

  return (
    <React.Fragment>
      <ToastContainer
        enableMultiContainer
        containerId="modalVerAbs"
        transition={Slide}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={props.displayModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Resumen de la comunicación
          <div className="flex items-end justify-end text-right">
            <Button
              variant="contained"
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
            >
              Opciones
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={(e) => handleAsignarRevisor()}>
                Asignar/Reasignar Revisor
              </MenuItem>
              <MenuItem onClick={(e) => handleAsignarCategoria()}>
                Asignar/Reasignar Categoria
              </MenuItem>
              {/* DEJAR CORREGIR SOLO SI LO TIENE ASIGNADO */}
              {/*checkAbstractAsignado() && (
                <MenuItem onClick={handleCorregir}>Corregir</MenuItem>
              )*/}
              {currentAbstract.aceptado === 2 && (
                <MenuItem onClick={handleCorreccionProvisional}>
                  Corregir
                </MenuItem>
              )}
              {currentAbstract.aceptado === 4 && (
                <MenuItem onClick={handleCorrecionFinal}>
                  Correccion Final
                </MenuItem>
              )}
              {currentAbstract.aceptado === 2 ||
                (currentAbstract.aceptado === 4 && (
                  <MenuItem onClick={handleDescartarComunicacion}>
                    Descartar comunicación
                  </MenuItem>
                ))}
              {currentAbstract.calificacion_provisional &&
              currentAbstract.id_evento !== 147 &&
              currentAbstract.calificacion_provisional !== "" &&
              currentAbstract.aceptado !== 4 ? (
                <MenuItem
                  disabled={disabledComunicacionFinalBtn}
                  onClick={(e) =>
                    handleSolicitarComunicacionFinal(currentAbstract.ID)
                  }
                >
                  Solicitar comunicación final
                </MenuItem>
              ) : null}
            </Menu>
          </div>
        </DialogTitle>
        <DialogContent>
          {!currentAbstract ||
          !currentAbstract ||
          !currentRevisoresAsignados ||
          !currentCorrecionesAbstract ? (
            <div>
              <Skeleton height={"25rem"} />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12} className="mb-5 p-5">
                <Grid container>
                  <Grid xs={11}>
                    <StyledInput title="Titulo" data={currentAbstract.titulo} />
                  </Grid>
                  <Grid
                    xs={1}
                    className="flex items-center justify-center text-center border-1 border-gray-500 rounded-lg p-2 font-bold"
                  >
                    <StyledInput
                      title="Nota media revisores"
                      data={
                        currentAbstract.nota_media
                          ? parseFloat(currentAbstract.nota_media).toFixed(1)
                          : "---"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* DETALLES DEL ABSTRACT */}
              <Grid item xs={12}>
                <Paper elevation={5} className="p-5">
                  <Grid item container className="mb-5">
                    <Grid item xs={4}>
                      <StyledInput
                        title="Categoria"
                        data={
                          currentAbstract.nombre_categoria
                            ? currentAbstract.nombre_categoria
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <StyledInput
                        title="Aporta texto"
                        data={
                          currentAbstract.contenido_seccion2 !== null &&
                          currentAbstract.contenido_seccion2.length > 1
                            ? "Si"
                            : "No"
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <StyledInput
                        title="Aporta archivos"
                        data={hay_adjuntos ? "Si" : "No"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container className="mb-5">
                    <Grid item xs={4}>
                      <StyledInput
                        title="Fecha Presentado"
                        data={
                          currentAbstract.fecha_publicado
                            ? new Date(
                                currentAbstract.fecha_publicado
                              ).toLocaleString()
                            : "---"
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <StyledInput
                        title="Fecha limite de correción:"
                        data={
                          currentAbstract.fecha_max_correcion
                            ? new Date(
                                currentAbstract.fecha_max_correcion
                              ).toLocaleString("ES-es", { timeZone: "UTC" })
                            : "---"
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <StyledInput title="Fecha Evaluacion Final" data={""} />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <StyledInput
                      title="Palabras clave:"
                      data={
                        currentAbstract.keywords
                          ? JSON.parse(currentAbstract.keywords).map((row) => (
                              <span>
                                {row}
                                {", "}
                              </span>
                            ))
                          : "No seleccionadas"
                      }
                    />
                  </Grid>
                  {
                    <Grid item xs={12}>
                      <StyledInput
                        title="Autores:"
                        data={nombreAutores.map((row) => (
                          <span>
                            {row.nombre} {row.apellido1} {row.apellido2}
                            {", "}
                          </span>
                        ))}
                      />
                    </Grid>
                  }
                </Paper>
              </Grid>
              {/* CALIFICACIONES*/}
              {currentCorrecionesAbstract.length > 0 && currentAbstract && (
                <Grid item xs={12} className="mt-5">
                  <Paper elevation={5} className="bg-gray-300 p-5">
                    <Grid container>
                      <Grid item xs={6} className="text-12 font-bold mb-5">
                        CALIFICACIONES
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="text-12 font-bold mb-5 flex items-end justify-end"
                      >
                        <IconButton
                          onClick={(e) => setLockCorreciones(!lockCorreciones)}
                          aria-label="toggle-lock"
                        >
                          {lockCorreciones ? (
                            <Icon fontSize="small">lock</Icon>
                          ) : (
                            <Icon fontSize="small">lock_open</Icon>
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                    {currentAbstract.criterios_json && (
                      <Grid item xs={12} className="mt-5">
                        <Paper elevation={5} className="p-5 mb-5">
                          <Grid item container className="mb-5">
                            <Grid item xs={3}>
                              <StyledInput
                                title="Revisor"
                                data="SuperRevisor"
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <StyledInput
                                title="Calificacion Global"
                                data={currentAbstract.calificacion}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StyledInput
                                title="Criterios de Evaluación"
                                data={CriteriaBlockContent(
                                  currentAbstract.criterios_json
                                )}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StyledInput
                                title="Comentarios"
                                data={currentAbstract.comentario_calificacion}
                              />
                            </Grid>
                            {/* <Grid item xs={1}>
                              {!lockCorreciones && (
                                <Tooltip title="Eliminar">
                                  <IconButton
                                    onClick={(e) =>
                                      handleDeleteCorrecion(currentAbstract.ID)
                                    }
                                    aria-label="delete"
                                  >
                                    <Icon fontSize="small">delete</Icon>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid> */}
                          </Grid>
                        </Paper>
                      </Grid>
                    )}
                    {currentCorrecionesAbstract.map((row) => {
                      return (
                        <Paper elevation={5} className="p-5 mb-5">
                          <Grid item container className="mb-5">
                            <Grid item xs={3}>
                              <StyledInput
                                title="Revisor"
                                data={`${row.nombre} ${row.apellidos}`}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <StyledInput
                                title="Calificacion Global"
                                data={row.calificacion}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StyledInput
                                title="Criterios de Evaluación"
                                data={CriteriaBlockContent(row.criterios_json)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StyledInput
                                title="Comentarios"
                                data={row.comentarios}
                              />
                            </Grid>
                            {/* <Grid item xs={1}>
                              {!lockCorreciones && (
                                <Tooltip title="Eliminar">
                                  <IconButton
                                    onClick={(e) =>
                                      handleDeleteCorrecion(row.ID)
                                    }
                                    aria-label="delete"
                                  >
                                    <Icon fontSize="small">delete</Icon>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid> */}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Paper>
                </Grid>
              )}
            </Grid>
          )}

          {/* CORRECIONES 2a FASE*/}
          {currentCorrecionesAbstractPrimeraFase &&
            currentCorrecionesAbstractPrimeraFase.length && (
              <Grid item xs={12} className="mt-5">
                <Paper elevation={5} className="bg-gray-300 p-5">
                  <Grid container>
                    <Grid item xs={6} className="text-12 font-bold mb-5">
                      Calificación 1 Fase
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="text-12 font-bold mb-5 flex items-end justify-end"
                    >
                      <IconButton
                        onClick={(e) => setLockCorreciones(!lockCorreciones)}
                        aria-label="toggle-lock"
                      >
                        {lockCorreciones ? (
                          <Icon fontSize="small">lock</Icon>
                        ) : (
                          <Icon fontSize="small">lock_open</Icon>
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  {currentAbstract.criterios_json_prov && (
                    <Grid item xs={12} className="mt-5">
                      <Paper elevation={5} className="p-5 mb-5">
                        <Grid item container className="mb-5">
                          <Grid item xs={3}>
                            <StyledInput title="Revisor" data="SuperRevisor" />
                          </Grid>
                          <Grid item xs={2}>
                            <StyledInput
                              title="Calificacion Global"
                              data={currentAbstract.calificacion_provisional}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <StyledInput
                              title="Criterios de Evaluación"
                              data={CriteriaBlockContent(
                                currentAbstract.criterios_json_prov
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <StyledInput
                              title="Comentarios"
                              data={
                                currentAbstract.comentario_calificacion_prov
                              }
                            />
                          </Grid>
                          {/* <Grid item xs={1}>
                            {!lockCorreciones && (
                              <Tooltip title="Eliminar">
                                <IconButton
                                  onClick={(e) =>
                                    handleDeleteCorrecion(currentAbstract.ID)
                                  }
                                  aria-label="delete"
                                >
                                  <Icon fontSize="small">delete</Icon>
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid> */}
                        </Grid>
                      </Paper>
                    </Grid>
                  )}
                  {currentCorrecionesAbstractPrimeraFase.map((row) => {
                    return (
                      <Paper elevation={5} className="p-5 mb-5">
                        <Grid item container className="mb-5">
                          <Grid item xs={3}>
                            <StyledInput
                              title="Revisor"
                              data={`${row.nombre} ${row.apellidos}`}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <StyledInput
                              title="Calificacion Global"
                              data={row.calificacion}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <StyledInput
                              title="Criterios de Evaluación"
                              data={CriteriaBlockContent(row.criterios_json)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <StyledInput
                              title="Comentarios"
                              data={row.comentarios}
                            />
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                      </Paper>
                    );
                  })}
                </Paper>
              </Grid>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL ASIGNAR CATEGORIA */}
      {currentAbstract && (
        <div>
          <ModalAsignarCategoria
            openModalCategorias={openModalCategorias}
            setOpenModalCategorias={setOpenModalCategorias}
            currentCategory={currentAbstract.id_categoria}
            id_abstract={currentAbstract.ID}
          />

          <ModalAsignarRevisor
            openModalRevisores={openModalRevisores}
            setOpenModalRevisores={setOpenModalRevisores}
            currentRevisor={currentAbstract.id_revisor}
            id_abstract={currentAbstract.ID}
            id_evento={id_evento}
            fechaMaxCorrecion={fechaMaxCorrecion}
          />

          <ModalCorregir
            openModalCorregir={openModalCorregir}
            setOpenModalCorregir={setOpenModalCorregir}
            currentRevisor={currentAbstract.id_revisor}
            id_abstract={currentAbstract.ID}
          />
          <ModalCorrecionFinal
            openModalCorrecionFinal={openModalCorrecionFinal}
            setOpenModalCorrecionFinal={setOpenModalCorrecionFinal}
            currentRevisor={currentAbstract.id_revisor}
            id_abstract={currentAbstract.ID}
          />
          <ModalCorreccionProvisional
            openModalCorreccionProvisional={openModalCorreccionProvisional}
            setOpenModalCorreccionProvisional={
              setOpenModalCorreccionProvisional
            }
            currentRevisor={currentAbstract.id_revisor}
            id_abstract={currentAbstract.ID}
          />
        </div>
      )}
    </React.Fragment>
  );
}
