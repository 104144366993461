import {
  CARGA_FORMULARIOS_SOLICITADA,
  CARGA_FORMULARIOS_ERRONEA,
  CARGA_FORMULARIOS_FINALIZADA,
} from "../actions";

const initialState = {
  cargando: false,
  error: null,
};

export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case CARGA_FORMULARIOS_SOLICITADA:
      return { ...state, cargando: true };

    case CARGA_FORMULARIOS_ERRONEA:
      return { ...state, error: action.payload };

    case CARGA_FORMULARIOS_FINALIZADA:
      return { ...state, cargando: false };

    default:
      return state;
  }
};
