import { combineReducers } from "redux";
import fuse from "./fuse";
import auth from "app/auth/store/reducers";
import quickPanel from "app/fuse-layouts/shared-components/quickPanel/store/reducers";

import { reducer as formReducer } from "redux-form";
import { reducer as iframe } from "app/components/iframe/store/reducers";
import { reducer as dashboard } from "app/components/dashboard/store/reducers";
import { reducer as authX } from "app/components/auth/store/reducers";
import { reducer as abstracts } from "app/components/abstracts/store/reducers";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    authX,
    fuse,
    quickPanel,
    form: formReducer,
    iframe,
    dashboard,
    abstracts,
    ...asyncReducers,
  });

export default createReducer;
