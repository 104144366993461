export const styles = {
  container: {
    margin: "0 auto",
    maxWidth: "920px",
    width: "100%",
  },
  containerListaInscripciones: {
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  container_inscription: {
    margin: "0 auto",
    maxWidth: "700px",
    width: "100%",
  },
  btn_submit: {
    marginTop: "20px",
    width: "100%",
    marginBottom: "30px",
  },
  footer_banner: {
    padding: "3px 10px",
  },
  footer_banner_text_small: {
    padding: "3px 10px",
    fontSize: "13px !important",
  },
  footer_banner_big: {
    padding: "0px 10px",
    fontSize: "20px !important",
    fontWeight: "bold",
    textAlign: "right",
  },
  footer_banner_small: {
    padding: "3px 10px",
    fontSize: "15px !important",
    fontWeight: "bold",
    textAlign: "right",
  },
  footer_banner_main: {
    padding: "10px 10px",
  },
  footer: {
    width: "95%",
    margin: "0 auto",
  },
  footer_bottom: {
    marginTop: "10px",
  },
  footer_iva: {
    textAlign: "right",
  },
  btn_submit_buy: {
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  checkboxes: {
    margin: "10px 10px",
  },
  privacyPolitics: {
    marginTop: "10px",
  },
  commercialNotifications: {
    marginTop: "10px",
  },
  buttonBack: {
    marginLeft: "20px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  checkboxesText: {
    fontSize: "13px",
  },
};
