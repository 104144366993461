import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export let getComplementoById = (complementoId, userToken) => {
  let requestURL = Global.url + "/eventos/getTipoComplemento/" + complementoId;
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL, HEADERS);
};

export let getComplementosByEntradaId = (entradaId, userToken) => {
  let requestURL =
    Global.url + "/eventos/entrada/" + entradaId + "/complementos";
  axios.defaults.headers.common["token"] = userToken;
  return axios.get(requestURL, HEADERS);
};

export let crearComplemento = (nuevoComplemento, userToken) => {
  let requestURL = Global.url + "/eventos/crearTipoComplemento";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(
    requestURL,
    querystring.stringify(nuevoComplemento),
    HEADERS
  );
};

export let crearComplementoAplicaInscripcion = (
  nuevoComplementoAplica,
  userToken
) => {
  let requestURL = Global.url + "/eventos/crearTipoComplementoAplica";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(
    requestURL,
    querystring.stringify(nuevoComplementoAplica),
    HEADERS
  );
};

export let actualizarComplemento = (complementoActualizado, userToken) => {
  let requestURL = Global.url + "/eventos/actualizarTipoComplemento";
  axios.defaults.headers.common["token"] = userToken;
  return axios.put(
    requestURL,
    querystring.stringify(complementoActualizado),
    HEADERS
  );
};

export let borrarComplemento = (complementoId, userToken) => {
  let requestURL =
    Global.url + "/eventos/eliminarTipoComplemento/" + complementoId;
  axios.defaults.headers.common["token"] = userToken;
  return axios.delete(requestURL, HEADERS);
};
