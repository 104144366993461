import * as Actions from "../actions";

const initialState = {
  todosLosEventos: {
    array: [],
    cargando: true,
    error: null,
  },
  eventosMasActivos: {
    array: [],
    cargando: true,
    error: null,
  },
  ultimosEventos: {
    array: [],
    cargando: true,
    error: null,
  },
  listaEventosEmpresa: {
    array: [],
    cargando: true,
    error: null,
  },
};

// TODO: Finalizar reducer y probar que funciona en Dashboard SuperAdmin
export const reducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SOLICITAR_CARGA_TODOS_LOS_EVENTOS:
      return {
        ...state,
        todosLosEventos: {
          ...state.todosLosEventos,
          cargando: true,
        },
      };

    case Actions.CARGA_TODOS_LOS_EVENTOS_EXITOSA:
      return {
        ...state,
        todosLosEventos: {
          ...state.todosLosEventos,
          array: action.payload,
        },
      };

    case Actions.CARGA_TODOS_LOS_EVENTOS_ERRONEA:
      return {
        ...state,
        todosLosEventos: {
          ...state.todosLosEventos,
          error: action.payload,
        },
      };

    case Actions.CARGA_TODOS_LOS_EVENTOS_FINALIZADA:
      return {
        ...state,
        todosLosEventos: {
          ...state.todosLosEventos,
          cargando: false,
        },
      };

    case Actions.SOLICITAR_CARGA_ULTIMOS_EVENTOS:
      return {
        ...state,
        ultimosEventos: {
          ...state.ultimosEventos,
          cargando: true,
        },
      };

    case Actions.CARGA_ULTIMOS_EVENTOS_EXITOSA:
      return {
        ...state,
        ultimosEventos: {
          ...state.ultimosEventos,
          array: action.payload,
        },
      };

    case Actions.CARGA_ULTIMOS_EVENTOS_ERRONEA:
      return {
        ...state,
        ultimosEventos: {
          ...state.ultimosEventos,
          error: action.payload,
        },
      };

    case Actions.CARGA_ULTIMOS_EVENTOS_FINALIZADA:
      return {
        ...state,
        ultimosEventos: {
          ...state.ultimosEventos,
          cargando: false,
        },
      };

    case Actions.SOLICITAR_CARGA_EVENTOS_MAS_ACTIVOS:
      return {
        ...state,
        eventosMasActivos: {
          ...state.eventosMasActivos,
          cargando: true,
        },
      };

    case Actions.CARGA_EVENTOS_MAS_ACTIVOS_EXITOSA:
      return {
        ...state,
        eventosMasActivos: {
          ...state.eventosMasActivos,
          array: action.payload,
        },
      };

    case Actions.CARGA_EVENTOS_MAS_ACTIVOS_ERRONEA:
      return {
        ...state,
        eventosMasActivos: {
          ...state.eventosMasActivos,
          error: action.payload,
        },
      };

    case Actions.CARGA_EVENTOS_MAS_ACTIVOS_FINALIZADA:
      return {
        ...state,
        eventosMasActivos: {
          ...state.eventosMasActivos,
          cargando: false,
        },
      };

    default:
      return state;
  }
};
