import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";

//COMPONENTS
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Grid,
  Paper,
  Button,
  IconButton,
  Icon,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SERVICES
import { CrearAbstract } from "../../../../services/eventosService/abstractUploadService";

export const ModalCrearAbstractGrupal = (props) => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  const [titulo, setTitulo] = React.useState("");
  const [coautores, setCoautores] = React.useState([]);
  const [autores, setAutores] = React.useState([]);
  const [nombre, setNombre] = React.useState("");
  const [dni, setDni] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [categoria, setCategoria] = React.useState(false);
  let id_evento = abstract_data.evento.data.result.ID;
  let categorias = abstract_data.categorias;
  let id_usuario = abstract_data.id_usuario;

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "modal_crear_abstract",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "modal_crear_abstract",
      });
    }
  };

  // const addCoAutor = (e) => {
  //   if (nombre.length < 3 || dni.length < 9) {
  //     setSnackbar(
  //       "Alguno de los datos del Co-Autor no es correcto o esta incompleto. Reviselo e intentelo de nuevo",
  //       "error"
  //     );
  //   } else {
  //     //comprobamos si el dni o el email ya se han utilizado en un co-autor anterior
  //     let repetido = false;
  //     coautores.map((row) => {
  //       if (row.dni == dni || row.email == email) {
  //         repetido = true;
  //       }
  //     });
  //     if (repetido) {
  //       setSnackbar(
  //         "Ya has incluido este Dni y/o email en un co-autor.",
  //         "error"
  //       );
  //     } else {
  //       let newCoAutor = {
  //         nombre,
  //         dni: dni.toUpperCase(),
  //         email,
  //       };
  //       setCoautores([...coautores, newCoAutor]);
  //       dispatch(Actions.setCoAutores([...coautores, newCoAutor]));
  //       //Limpiamos los datos del formulario
  //       setNombre("");
  //       setDni("");
  //       setEmail("");

  //       console.log(coautores);
  //     }
  //   }
  // };

  const handleClose = () => {
    props.setDisplayModalGrupal(false);
    // dispatch(Actions.setCoAutores([]));
  };

  // const deleteCoAutor = (index) => {
  //   let aux_array = coautores;
  //   aux_array.splice(index, 1);
  //   setCoautores(aux_array);
  //   dispatch(Actions.setCoAutores(aux_array));
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    //si existen categorias y no ha seleccionado ninguna, mostramos error
    if (categorias.length > 0 && !categoria) {
      setSnackbar(
        "Debe seleccionar una categoria para crear el resumen.",
        "error"
      );
      return;
    }
    let form = {
      id_usuario,
      id_evento: id_evento,
      titulo: titulo,
      autores: JSON.stringify([]),
      id_tipo_abstract: props.tipoAbstract.ID,
      id_categoria: categoria ? categoria : 0,
    };
    CrearAbstract(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(res.data.message, "error");
      } else {
        //El abstract ya esta en redux asi que vamos al editor
        console.log("Este es el abstract creado!!");
        console.log(res.data.result);
        dispatch(Actions.setCurrentAbstract(res.data.result));
        dispatch(Actions.setCurrentTipoAbstract(props.tipoAbstract));
        //reseteamos la lista de aportaciones (por si habia algo anterior cargado)
        dispatch(Actions.resetAportaciones());
        props.handleNext();
      }
    });
  };

  return (
    <Dialog
      open={props.displayModalGrupal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      fullWidth={true}
    >
      <ToastContainer
        enableMultiContainer
        containerId="modal_crear_abstract"
        transition={Slide}
      />
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">
          Crear Nueva Comunicación
        </DialogTitle>
        <DialogContent>
          <ToastContainer />
          <DialogContentText>
            Complete los siguientes datos para crear una nueva comunicación.
          </DialogContentText>

          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Título"
                type="text"
                required
                fullWidth
                onChange={(e) => setTitulo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {categorias.length > 0 && (
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Categoria/Tema
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    // value={age}
                    onChange={(e) => setCategoria(e.target.value)}
                  >
                    {categorias.map((row) => {
                      return <MenuItem value={row.ID}>{row.nombre}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary">
            Crear
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalCrearAbstractGrupal;
