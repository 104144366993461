import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Paper,
} from "@material-ui/core";

//ALERTS
//ALERTS
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SERVICES
import { updatePresentador } from "../../../../services/eventosService/abstractUploadService";

export default function BoxPresentador() {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  let { aportacionesAbstract, currentAbstract } = abstract_data;
  let filteredAutores = [];
  aportacionesAbstract.map((row) => {
    if (filteredAutores.length > 0) {
      let aux_array = filteredAutores.filter(
        (row2) => row2.id_autor !== row.id_autor
      );
      filteredAutores = aux_array;
      filteredAutores.push(row);
    } else {
      filteredAutores.push(row);
    }
  });
  console.log("Estos son los autores filtrados", filteredAutores);
  const [value, setValue] = React.useState(
    `${currentAbstract.id_autor_presentador}`
  );

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_presentador",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_presentador",
      });
    }
  };

  const handleChange = async (event) => {
    setValue(event.target.value);
    let form = {
      id_autor: event.target.value,
      id_abstract: currentAbstract.ID,
    };
    let res = await updatePresentador(form);
    if (!res.data.status) {
      setSnackbar(
        "Hubo un error al intentar asignar el presentador. Intentelo de nuevo más tarde",
        "error"
      );
    } else {
      setSnackbar("Presentador asignado correctamente.", "success");
    }
  };

  return (
    <Grid container className="flex items-center justify-center text-center">
      <ToastContainer
        className="mt-52"
        enableMultiContainer
        containerId="abstract_presentador"
        transition={Slide}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            Seleccione al Autor Presentador:
          </Grid>
          <Grid item xs={12} className="text-11 text-gray-500">
            Debe añadir aportaciones para que los autores esten disponibles en
            la selección.
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Seleccione el presentador</FormLabel> */}
          <Paper elevation={5} className="p-5 mt-10">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              {filteredAutores.map((row) => {
                return (
                  <FormControlLabel
                    value={`${row.id_autor}`}
                    control={<Radio />}
                    label={`${row.nombre_autor} ${row.apellido1_autor} ${
                      row.apellido2_autor
                    } ${value == row.id_autor ? " - (Presentador)" : ""}`}
                  />
                );
              })}
            </RadioGroup>
          </Paper>
        </FormControl>
      </Grid>
    </Grid>
  );
}
