import { modificarFormInscripcionCampoEstandar } from "app/components/iframe/store/actions";
import { useDispatch, useSelector } from "react-redux";

function useModeloInscrito({ indiceInscripcion }) {
  const dispatch = useDispatch();
  const {
    nombre,
    primerApellido,
    segundoApellido,
    correo,
    custom,
  } = useSelector(({ iframe }) => iframe.compra.inscripciones)[
    indiceInscripcion
  ].formulario;

  const modificar = (campo, valor) => {
    dispatch(
      modificarFormInscripcionCampoEstandar({ indiceInscripcion, campo, valor })
    );
  };

  return {
    // Contains objects that can be spread onto <input> elements
    inputProps: {
      nombre: {
        required: true,
        name: "nombre",
        value: nombre,
        onChange: (e) => modificar(e.target.name, e.target.value),
      },
      primerApellido: {
        required: true,
        name: "primerApellido",
        value: primerApellido,
        onChange: (e) => modificar(e.target.name, e.target.value),
      },
      segundoApellido: {
        name: "segundoApellido",
        value: segundoApellido,
        onChange: (e) => modificar(e.target.name, e.target.value),
      },
      correo: {
        required: true,
        name: "correo",
        value: correo,
        onChange: (e) => modificar(e.target.name, e.target.value),
      },
    },
    custom,
  };
}

export default useModeloInscrito;
