import { combineReducers } from "redux";

// REDUCERS
import { reducer as compra } from "./compra.reducer";
import { reducer as pagos } from "./pagos.reducer";
import { reducer as evento } from "./evento.reducer";
import { reducer as iframeConfig } from "./iframeConfig.reducer";
import { reducer as privacidad } from "./privacidad.reducer";
import { reducer as formularios } from "./formularios.reducer";

export const reducer = combineReducers({
  compra,
  pagos,
  evento,
  iframeConfig,
  privacidad,
  formularios,
});
