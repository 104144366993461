import * as Model from "../../model";

export const CARGA_PRODUCTOS_SOLICITADA =
  "[IFRAME.COMPRA] SE HA SOLICITADO CARGAR LOS PRODUCTOS DE UN EVENTO";

export function solicitarCargaProductos(idEvento) {
  return (dispatch) =>
    dispatch({
      type: CARGA_PRODUCTOS_SOLICITADA,
      payload: idEvento,
    });
}

export const CARGA_PRODUCTOS_CORRECTA =
  "[IFRAME.COMPRA] SE HAN CARGADO LOS PRODUCTOS CORRECTAMENTE";

export function cargaProductosExitosa(productos) {
  return (dispatch) =>
    dispatch({
      type: CARGA_PRODUCTOS_CORRECTA,
      payload: productos,
    });
}

export const CARGA_PRODUCTOS_ERRONEA =
  "[IFRAME.COMPRA] SE HA PRODUCIDO UN ERROR AL CARGAR LOS PRODUCTOS";

export function cargaProductosErronea(error) {
  return (dispatch) =>
    dispatch({
      type: CARGA_PRODUCTOS_ERRONEA,
      payload: error,
    });
}

export const CARGA_PRODUCTOS_FINALIZADA =
  "[IFRAME.COMPRA] LA CARGA DE LOS PRODUCTOS HA FINALIZADO";

export function cargaProductosFinalizada() {
  return (dispatch) =>
    dispatch({
      type: CARGA_PRODUCTOS_FINALIZADA,
    });
}

export const GUARDAR_DESCUENTO = "[IFRAME.COMPRA] GUARDA UN DESCUENTO";
export function guardarDescuento(descuento) {
  return (dispatch) =>
    dispatch({
      type: GUARDAR_DESCUENTO,
      payload: descuento,
    });
}

export const RECALCULAR_PRECIO =
  "[IFRAME.COMPRA] RECALCULA LOS PRECIOS DE LOS PRODUCTOS Y DESCUENTOS APLICADOS";
export function recalcularPrecio() {
  return (dispatch) =>
    dispatch({
      type: RECALCULAR_PRECIO,
    });
}

export const CAMBIAR_CANTIDAD_INSCRIPCIONES =
  "[IFRAME.COMPRA] CAMBIA LA CANTIDAD DE INSCRIPCIONES ESCOGIDAS";
export function cambiarCantidadInscripciones(datos) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_CANTIDAD_INSCRIPCIONES,
      payload: datos,
    });
}

export const CAMBIAR_ESTADO_COMPLEMENTO =
  "[IFRAME.COMPRA] CAMBIA EL ESTADO DE UN COMPLEMENTO (ESCOGIDO/NO ESCOGIDO)";
export function cambiarEstadoComplemento(datos) {
  return (dispatch) =>
    dispatch({
      type: CAMBIAR_ESTADO_COMPLEMENTO,
      payload: datos,
    });
}

/**
 * COMPRADOR
 */
export const ACTUALIZAR_DATOS_COMPRADOR =
  "[IFRAME.COMPRA] ACTUALIZAR DATOS DE COMPRADOR";
export function actualizarDatosComprador(campo, valor) {
  return (dispatch) =>
    dispatch({ type: ACTUALIZAR_DATOS_COMPRADOR, payload: { campo, valor } });
}

export const ACTUALIZAR_COMPRADOR =
  "[IFRAME.COMPRA] ACTUALIZA EL COMPRADOR ENTERO";
export function actualizarComprador(comprador = new Model.Comprador()) {
  return (dispatch) =>
    dispatch({
      type: ACTUALIZAR_COMPRADOR,
      payload: comprador,
    });
}

export const GUARDAR_VALIDEZ_FORMULARIO_COMPRADOR =
  "[IFRAME.COMPRA] VALIDEZ DEL FORMULARIO DE COMPRADOR";
export function guardarValidezFormularioComprador(validez) {
  return (dispatch) =>
    dispatch({ type: GUARDAR_VALIDEZ_FORMULARIO_COMPRADOR, payload: validez });
}

/**
 * FORMULARIOS
 */
export const GUARDAR_FORMULARIOS_INSCRIPCION =
  "[IFRAME.COMPRA] GUARDA LOS FORMULARIOS DE CADA INSCRIPCIÓN ASOCIÁNDOLOS A ESTAS";
export function guardarFormulariosInscripcion(forms) {
  return (dispatch) =>
    dispatch({ type: GUARDAR_FORMULARIOS_INSCRIPCION, payload: forms });
}

export const MODIFICAR_FORMULARIO_INSCRIPCION_CAMPO_ESTANDAR =
  "[IFRAME.COMPRA] MODIFICAR FORMULARIO DE INSCRIPCION";
export function modificarFormInscripcionCampoEstandar(form) {
  return (dispatch) =>
    dispatch({
      type: MODIFICAR_FORMULARIO_INSCRIPCION_CAMPO_ESTANDAR,
      payload: form,
    });
}

export const MODIFICAR_FORMULARIO_INSCRIPCION_CAMPO_CUSTOM =
  "[IFRAME.FORMULARIOS] MODIFICAR FORMULARIO EXPERIMENTAL OTROS";
export function modificarFormInscripcionCampoCustom(form) {
  return (dispatch) =>
    dispatch({
      type: MODIFICAR_FORMULARIO_INSCRIPCION_CAMPO_CUSTOM,
      payload: form,
    });
}
