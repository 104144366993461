import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

// const HEADERS = { headers: Global.headers };
const baseURL = Global.url + "/documentos";

export let subirDocumentoS3 = (tipo, nombre, idEvento, contenido) => {
  let requestURL = `${baseURL}/${tipo}/${nombre}/${idEvento}`;
  return axios.post(requestURL, querystring.stringify({ contenido }));
};
