import React from "react";

import { makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styles from "./EventoFinalizado.styles";

const useStyles = makeStyles(styles);

export default function EventoFinalizado() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      id="loadingContainer"
      className={classes.container}
      container
      justify="center"
      alignItems="center"
    >
      <Grid item xs={10}>
        <div className={classes.loading}>{t("Evento finalizado")}.</div>
      </Grid>
    </Grid>
  );
}
