import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
} from "@material-ui/core";

//alerts
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MaterialTable from "material-table";

import ModalEditarAutor from "./ModalEditarAutor";
import ModalCrearAutor from "./ModalCrearAutor";

import * as Actions from "../../store/actions";

//SERVICES
import { deleteAutor } from "app/services/eventosService/abstractUploadService";

export default function ModalTiposAbstract(props) {
  const dispatch = useDispatch();
  const [displayModalEditarAutor, setDisplayModalEditarAutor] = useState(false);
  const [displayModalCrearAutor, setDisplayModalCrearAutor] = useState(false);
  const [currentAutor, setCurrentAutor] = useState(false);

  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );

  const handleClose = () => {
    props.setDisplayModalTablaAutores(false);
    dispatch(Actions.resetAutores());
    dispatch(Actions.resetCurrentAbstract());
  };

  const handleEditarAutor = (row) => {
    setCurrentAutor(row);
    setDisplayModalEditarAutor(true);
  };

  const confirmDeleteAutor = (rowData) => {
    //Si el autor es el principal, no puede borrarse
    if (rowData.principal == 1) {
      setSnackbar(
        "No es posible eliminar el autor principal. Si lo desea puede editar sus datos.",
        "error"
      );
      return;
    }
    toast.error(
      <div>
        <p>Esta seguro de querer eliminar este autor?</p>
        <p>(No sera posible deshacer este cambio)</p>
        <div className="flex items-center justify-center">
          <Button
            className="m-5"
            variant="contained"
            color="primary"
            onClick={(e) => handleDeleteAutor(rowData.ID)}
          >
            Aceptar
          </Button>
          <Button
            // onClick={(e) => setPeticionActiva(false)}
            className="m-5"
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
        </div>
      </div>,
      {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: false,
        containerId: "tabla_autores",
        toastId: "confirm_delete",
      }
    );
  };

  const handleDeleteAutor = async (id_autor) => {
    let form = {
      id_autor,
    };
    let result = await deleteAutor(form);
    if (!result.data.status) {
      setSnackbar(
        "Hubo un problema al intentar borrar el autor. Intentelo de nuevo más tarde.",
        "error"
      );
      return;
    } else {
      setSnackbar("Autor eliminado correctamente", "success");
      //Actualizamos listado de autores.
      dispatch(Actions.getAutores(abstract_data.currentAbstract.ID));
    }
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_autores",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_autores",
      });
    }
  };

  let columns = [
    { title: "Nombre", field: "nombre" },
    { title: "1º Apellido", field: "apellido1" },
    { title: "2º Apellido", field: "apellido2" },
    { title: "Email", field: "email" },
    {
      title: "Autor Principal",
      field: "principal",
      lookup: { 0: "No", 1: "Si" },
    },
  ];

  if (!abstract_data.currentAbstract || !abstract_data.autores) {
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open={props.displayModalTablaAutores}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="p-10">
          <div className="flex items-center text-center justify-center w-full">
            {"Cargando"}...
          </div>
          <div className="mt-10 flex items-center text-center justify-center w-full">
            <CircularProgress />
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        open={props.displayModalTablaAutores}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ToastContainer
          enableMultiContainer
          containerId="tabla_autores"
          transition={Slide}
        />
        <DialogContent>
          <MaterialTable
            title={
              <Grid container>
                <Grid item xs={12}>
                  {"Listado de Autores:"}
                </Grid>
                <Grid item xs={12} className="text-12 text-gray-500">
                  Comunicación "{abstract_data.currentAbstract.titulo}"
                </Grid>
              </Grid>
            }
            columns={columns}
            data={abstract_data.autores}
            localization={{
              body: {
                editRow: {
                  deleteText: "Esta seguro de querer borrar el autor?",
                },
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Editar",
                onClick: (event, rowData) => {
                  handleEditarAutor(rowData);
                },
              },
              {
                icon: "delete",
                tooltip: "Eliminar",
                onClick: (event, rowData) => {
                  confirmDeleteAutor(rowData);
                },
              },
              {
                icon: "add",
                tooltip: "Nuevo Autor",
                isFreeAction: true,
                onClick: (event) => {
                  setDisplayModalCrearAutor(true);
                },
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {currentAutor && displayModalEditarAutor && (
        <ModalEditarAutor
          currentAutor={currentAutor}
          displayModalEditarAutor={displayModalEditarAutor}
          setDisplayModalEditarAutor={setDisplayModalEditarAutor}
        />
      )}
      {displayModalCrearAutor && (
        <ModalCrearAutor
          displayModalCrearAutor={displayModalCrearAutor}
          setDisplayModalCrearAutor={setDisplayModalCrearAutor}
        />
      )}
    </div>
  );
}
