export default {
  container: {
    backgroundColor: "white !important",
    height: "100vh",
    width: "100%",
  },
  loading: {
    textAlign: "center",
  },
  logo: {
    display: "block",
    margin: "0 auto",
  },
  loadingMessage: {
    marginTop: "10px",
    marginBottom: "20px",
  },
};
