import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {},
});

export const TablaWrapper = ({ columnas, registros, campos }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Tabla
      columnas={columnas}
      registros={registros}
      campos={campos}
      history={history}
      t={t}
    />
  );
};

export const Tabla = ({ columnas, registros, campos, history, t }) => {
  const classes = useStyles();

  useEffect(() => {}, [columnas, campos, registros, t]);
  const redirectTo = (path) => {
    history.push(path);
  };

  return (
    <Paper className={classes.root}>
      {t && (
        <Table className={classes.table} aria-label="simple table">
          <TableHead className="bg-blue">
            <TableRow>
              {columnas.map((columna, colI) => {
                if (colI === 0) {
                  return (
                    <TableCell key={colI} className="text-white p-3">
                      {t(columna.titulo)}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={colI}
                      className="text-white p-3"
                      align="right"
                    >
                      {t(columna.titulo)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {registros &&
              registros.array &&
              registros.array.map((registro, rI) => (
                <TableRow key={rI}>
                  {campos.map((campo, campoI) => {
                    if (campoI === 0) {
                      return (
                        <TableCell
                          key={campoI}
                          className="p-3"
                          component="th"
                          scope="row"
                        >
                          {campo.link ? (
                            <div onClick={() => redirectTo(registro.ruta)}>
                              {registro[campo.nombre]}
                            </div>
                          ) : (
                            registro[campo.nombre]
                          )}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={campoI} className="p-3" align="right">
                          {campo.link ? (
                            <div onClick={() => redirectTo(registro.ruta)}>
                              {registro[campo.nombre]}
                            </div>
                          ) : (
                            registro[campo.nombre]
                          )}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};

export default TablaWrapper;
