import { combineReducers } from "redux";

// REDUCERS
import emailEditorReducer from "./emaileditor.reducer";

const reducer = combineReducers({
  emailEditorReducer,
});

export default reducer;
