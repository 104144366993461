import { combineReducers } from "redux";
import tablas_top from "./tablas_top.reducer";
import counters from "./counters.reducer";
import tabla_eventos from "./tabla_eventos.reducer";
// import eventos from "./eventos.reducer";
import usuarios from "./usuarios.reducer";
import control from "./control.reducer";
import inscripciones from "./inscripciones.reducer";

import { reducer as metricas } from "./metricas.reducer";
import { reducer as eventos } from "./eventos.reducer";
import { reducer as superRevisor } from "./superRevisor.reducer";
import { reducer as revisor } from "./revisor.reducer";

export const reducer = combineReducers({
  tablas_top,
  counters,
  tabla_eventos,
  eventos,
  usuarios,
  control,
  inscripciones,
  metricas,
  superRevisor,
  revisor,
});

// const reducer = combineReducers({
//   tablas_top,
//   counters,
//   tabla_eventos,
//   eventos,
//   usuarios,
//   control,
//   inscripciones,
//   metricas,
// });

// export default reducer;
