import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Chip,
  Divider,
} from "@material-ui/core";
import { useModeloInscrito } from "./hooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { modificarFormInscripcionCampoCustom } from "app/components/iframe/store/actions";
import SelectWithValidation from "../../../SelectWithValidation";

const useStyles = makeStyles({
  container: {
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  radio_group: {
    marginLeft: "10px",
  },
  checkbox: {
    marginLeft: "-3px",
  },
});

export default function FormularioInscripcion({ indice, inscripcion }) {
  const modelo = useModeloInscrito({ indiceInscripcion: indice });
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const modificarCampoCustom = (valor, indiceCampoCustom) => {
    dispatch(
      modificarFormInscripcionCampoCustom({
        indiceInscripcion: indice,
        indiceCampoCustom,
        valor,
      })
    );
  };

  const modificarCampoCustomRespuestaMultiple = (
    escogido,
    nuevoValor,
    conjuntoValoresCampoCustom,
    indiceCampoCustom
  ) => {
    let nuevoConjuntoValoresCampoCustom;
    if (escogido) {
      nuevoConjuntoValoresCampoCustom =
        conjuntoValoresCampoCustom + `${nuevoValor},`;
    } else {
      // Quita comas, elimina el valor que ha sido desmarcado, vuelve a unir los demás en comas, y guarda.
      nuevoConjuntoValoresCampoCustom = conjuntoValoresCampoCustom
        .split(",")
        .filter((el) => el !== nuevoValor)
        .join(",");
    }
    modificarCampoCustom(nuevoConjuntoValoresCampoCustom, indiceCampoCustom);
  };

  const titulo = () => {
    return (
      t("Inscripción Nº") + (indice + 1) + t("- Tipo: ") + inscripcion.nombre
    );
  };

  const contains = (string, substring) => {
    return string.indexOf(substring) !== -1;
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <h3>{titulo()}</h3>
        <Grid item xs={12}>
          {inscripcion &&
            inscripcion.complementos &&
            inscripcion.complementos.map((complemento, key) => {
              if (complemento.escogido) {
                return <Chip key={key} label={complemento.nombre} />;
              } else return null;
            })}
        </Grid>
        {/* CAMPOS FIJOS */}
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              variant="outlined"
              type="text"
              label={t("Nombre")}
              {...modelo.inputProps.nombre}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              variant="outlined"
              type="text"
              label={t("Primer apellido")}
              {...modelo.inputProps.primerApellido}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              variant="outlined"
              type="text"
              label={t("Segundo apellido")}
              {...modelo.inputProps.segundoApellido}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              variant="outlined"
              type="text"
              label={t("Correo")}
              {...modelo.inputProps.correo}
            />
          </Grid>
        </>

        {/* CAMPOS CUSTOM */}
        {modelo.custom &&
          modelo.custom.length > 0 &&
          modelo.custom.map((campoCustom, indiceCustom) => {
            switch (campoCustom.type) {
              case "text":
              case "number":
              case "email":
              case "tel":
                return (
                  <Grid key={indiceCustom} item xs={12}>
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      type={campoCustom.type}
                      name={campoCustom.nombre}
                      label={campoCustom.nombre}
                      required={campoCustom.obligatorio === 1}
                      onChange={(e) =>
                        modificarCampoCustom(e.target.value, indiceCustom)
                      }
                    />
                  </Grid>
                );

              case "textarea":
                return (
                  <Grid key={indiceCustom} item xs={12}>
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      name={campoCustom.nombre}
                      label={campoCustom.nombre}
                      multiline
                      rows="3"
                      required={campoCustom.obligatorio === 1}
                      onChange={(e) =>
                        modificarCampoCustom(e.target.value, indiceCustom)
                      }
                    />
                  </Grid>
                );

              case "checkbox":
                return (
                  <Grid key={indiceCustom} item xs={12}>
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          checked={campoCustom.value ? true : false}
                          name={campoCustom.nombre}
                          onChange={(e) =>
                            modificarCampoCustom(e.target.checked, indiceCustom)
                          }
                        />
                      }
                      label={campoCustom.nombre}
                    />
                  </Grid>
                );

              case "radio":
                /**
                 * El name del fieldset y el name del radio han de ser el mismo
                 * para poder tener multiples radio-groups como required en un
                 * mismo formulario
                 */
                return (
                  <Grid key={indiceCustom} item xs={12}>
                    <FormControl
                      component="fieldset"
                      id={campoCustom.nombre + indiceCustom}
                      className={classes.radio_group}
                    >
                      <FormLabel component="legend">
                        {campoCustom.nombre}
                      </FormLabel>
                      <RadioGroup
                        aria-required
                        aria-label={campoCustom.nombre + indiceCustom}
                        name={campoCustom.nombre}
                        value={campoCustom.value}
                        onChange={(e) =>
                          modificarCampoCustom(e.target.value, indiceCustom)
                        }
                      >
                        {campoCustom.options.length > 0 &&
                          JSON.parse(
                            campoCustom.options
                          ).map((option, radioIndex) => (
                            <FormControlLabel
                              key={radioIndex}
                              value={option}
                              control={
                                <Radio
                                  name={campoCustom.nombre + indiceCustom}
                                  color="primary"
                                  required={campoCustom.obligatorio === 1}
                                />
                              }
                              label={option}
                              labelPlacement="end"
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                );

              case "select":
                return (
                  <Grid key={indiceCustom} item xs={12}>
                    <FormControl variant="filled" fullWidth={true}>
                      <InputLabel required={campoCustom.obligatorio} id={"select-label-" + indiceCustom}>
                        {`${campoCustom.nombre}`}
                      </InputLabel>

                      {campoCustom.obligatorio === 1 ? (
                              <SelectWithValidation
                                  label={campoCustom.nombre}
                                  value={campoCustom.value}
                                  onChange={(e) =>
                                      modificarCampoCustom(e.target.value, indiceCustom)
                                  }
                                  style={{width: "100%"}}
                                  variant="outlined"
                                  required
                              >
                                {campoCustom.options.length > 0 &&
                                JSON.parse(campoCustom.options).map(
                                    (option, optionIndex) => (
                                        <MenuItem key={optionIndex} value={option}>
                                          {option}
                                        </MenuItem>
                                    )
                                )}
                              </SelectWithValidation>
                      )
                          :
                          (
                              <Select
                                  label={campoCustom.nombre}
                                  variant="outlined"
                                  value={campoCustom.value}
                                  required={campoCustom.obligatorio === 1}
                                  onChange={(e) =>
                                      modificarCampoCustom(e.target.value, indiceCustom)
                                  }
                              >
                                {campoCustom.options.length > 0 &&
                                JSON.parse(campoCustom.options).map(
                                    (option, optionIndex) => (
                                        <MenuItem key={optionIndex} value={option}>
                                          {option}
                                        </MenuItem>
                                    )
                                )}
                              </Select>
                      )}



                    </FormControl>
                  </Grid>
                );

              case "checkbox mult":
                // Checkbox de multiple opción
                return (
                  <Grid key={indiceCustom} item xs={12}>
                    <Grid item xs={12}>
                      {campoCustom.nombre}
                    </Grid>

                    <Grid item xs={12}>
                      {campoCustom.options.length > 0 &&
                        JSON.parse(
                          campoCustom.options
                        ).map((option, checkboxIndex) => (
                          <FormControlLabel
                            className={classes.checkbox}
                            key={checkboxIndex}
                            control={
                              <Checkbox
                                checked={contains(campoCustom.value, option)}
                                name={option}
                                onChange={(e) =>
                                  modificarCampoCustomRespuestaMultiple(
                                    e.target.checked,
                                    option,
                                    campoCustom.value,
                                    indiceCustom
                                  )
                                }
                              />
                            }
                            label={option}
                          />
                        ))}
                    </Grid>
                  </Grid>
                );

              default:
                return null;
            }
          })}
      </Grid>
      <Divider />
    </div>
  );
}
