import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//MATERIAL UI
import { Grid, Paper, Icon } from "@material-ui/core";

//COMPONENTS
import BoxArchivosAbstract from "./components/BoxArchivosAbstract";
import EditorsAccordion from "./components/EditorsAccordion";
import user from "app/auth/store/reducers/user.reducer";
import { Loading } from "app/shared";
import { FuseAnimate } from "@fuse";

//REDUX
import * as Actions from "../store/actions";

const countWords = (texto) => {
  if (texto === "") {
    return 0;
  }
  texto = texto.replace(/<[^>]*>/g, " ");
  texto = texto.replace(/\s+/g, " ");
  texto = texto.trim();
  var n = texto.split(" ").length;
  console.log("Este es el texto:", texto);
  console.log("Total palabras: ", n);
  return n;
};

const VistaAbstract = (props) => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  let {
    permite_texto,
    permite_adjuntos,
    max_palabras_total,
  } = abstract_data.currentTipoAbstract;
  let {
    contenido_seccion1,
    contenido_seccion2,
    contenido_seccion3,
    contenido_seccion4,
  } = abstract_data.currentAbstract;
  let totalPalabras = countWords(
    `${contenido_seccion1 ? contenido_seccion1 : ""}${
      contenido_seccion2 ? contenido_seccion2 : ""
    }${contenido_seccion3 ? contenido_seccion3 : ""}
    ${contenido_seccion4 ? contenido_seccion4 : ""}`
  );

  //ponemos el scroll arriba
  useEffect(() => {
    // document.getElementById("c_vista_abstract").scrollIntoView(0, -100);
    dispatch(
      Actions.getAvailableKeywords(abstract_data.currentAbstract.id_categoria)
    );
  }, []);

  if (!abstract_data.currentKeywords) {
    return <Loading loadingMessage="Cargando..." />;
  }

  return (
    <Grid id="c_vista_abstract" container className="w-full">
      <Grid item xs={12}>
        <Grid container>
          {max_palabras_total === 0 || totalPalabras <= max_palabras_total ? (
            <Grid item xs={4}>
              <div className="text-left m-10">
                <Paper
                  elevation={5}
                  className="text-11 flex align-middle items-center justify-center min-h-32 h-32 p-5"
                >
                  <Grid
                    container
                    className="flex items-center justify-center text-center"
                  >
                    <Grid item xs={12}>
                      Total: {totalPalabras}
                      {max_palabras_total > 0
                        ? `/${max_palabras_total}`
                        : ""}{" "}
                      palabras
                    </Grid>
                    <Grid item xs={12} className="text-10 text-gray-500">
                      (Guarde su comunicación para actualizar el número de
                      palabras total)
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <div className="text-left m-10">
                <FuseAnimate
                  delay="300"
                  duration="500"
                  animation="transition.bounceIn"
                  loop={true}
                >
                  <Paper
                    elevation={5}
                    className="bg-red text-white text-10 flex align-middle items-center justify-center min-h-32 h-32"
                  >
                    <Icon fontSize="small" className="mr-5">
                      warning
                    </Icon>
                    Total: {totalPalabras}
                    {max_palabras_total > 0
                      ? `/${max_palabras_total}`
                      : ""}{" "}
                    palabras - Su documento sobrepasa el máximo de palabras
                    permitido.
                  </Paper>
                </FuseAnimate>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          className="flex items-center justify-center text-center p-5"
        >
          <Paper elevation={5} className="p-5">
            <Grid item xs={12}>
              <Icon className="text-blue">info</Icon>
            </Grid>
            <Grid item xs={12} className="text-12">
              Rellene los campos de la comunicación con los textos de su
              trabajo. Recuerde revisar su trabajo y el número de palabras
              totales antes de continuar. Una vez revisado pulse en "siguiente".
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {permite_texto == 1 && <EditorsAccordion />}
      {permite_adjuntos == 1 && <BoxArchivosAbstract />}
    </Grid>
  );
};

export default VistaAbstract;
