import axios from "axios";
import Global from "../../../../fuse-configs/Global";
import querystring from "querystring";

export const GET_EVENT_DATA_ABSTRACT = "[ABSTRACTUPLOAD] GET EVENT DATA";
export const SET_DNI_AUTOR = "[ABSTRACTUPLOAD] SET DNI AUTOR";
export const SET_ID_USUARIO = "[ABSTRACTUPLOAD] SET_ID_USUARIO";
export const GET_ABSTRACTS_USUARIO = "[ABSTRACTUPLOAD] GET_ABSTRACTS_USUARIO";
export const SET_AUTORES = "[ABSTRACTUPLOAD] SET AUTORES";
export const SET_CURRENT_ABSTRACT = "[ABSTRACTUPLOAD] SET CURRENT ABSTRACT";
export const RESET_CURRENT_ABSTRACT = "[ABSTRACTUPLOAD] RESET CURRENT ABSTRACT";
export const CLEAN_ABSTRACT_IMAGES = "[ABSTRACTUPLOAD] CLEAN ABSTRACT IMAGES";
export const GET_ABSTRACT = "[ABSTRACTUPLOAD] GET ABSTRACT";
export const GET_AUTOR = "[ABSTRACTUPLOAD] GET AUTOR";
export const GET_AUTORES = "[ABSTRACTUPLOAD] GET_AUTORES";
export const GET_CATEGORIAS_ABSTRACT =
  "[ABSTRACTUPLOAD] GET CATEGORIAS ABSTRACT";

export const GET_CAMPOS_AUTOR_EVENTO =
  "[ABSTRACTUPLOAD] GET_CAMPOS_AUTOR_EVENTO";

export const GET_TIPOS_ABSTRACT = "[ABSTRACTUPLOAD] GET_TIPOS_ABSTRACT";
export const SET_CURRENT_TIPO_ABSTRACT =
  "[ABSTRACTUPLOAD] SET_CURRENT_TIPO_ABSTRACT";
export const GET_APORTACIONES_ABSTRACT =
  "[ABSTRACTUPLOAD] GET_APORTACIONES_ABSTRACT";
export const RESET_APORTACIONES = "[ABSTRACTUPLOAD] RESET_APORTACIONES";
export const RESET_AUTORES = "[ABSTRACTUPLOAD] RESET_AUTORES";
export const GET_AVAILABLE_KEYWORDS = "[ABSTRACTUPLOAD] GET_AVAILABLE_KEYWORDS";
export const GET_CAMPOS_CONTACTO_ADMIN_EVENTO =
  "[ABSTRACTUPLOAD] GET_CAMPOS_CONTACTO_ADMIN_EVENTO";
export const GET_EVENT_INFO_BY_TOKEN =
  "[ABSTRACTUPLOAD] GET_EVENT_INFO_BY_TOKEN";
export const GET_USUARIO_ABSTRACTS = "[ABSTRACTUPLOAD] GET_USUARIO_ABSTRACTS";

export function getEventInfoAbstract(id_evento) {
  const url = Global.url;
  let form = { id_evento };
  const request = axios.post(
    url + "/eventos/getEventInfoAbstract",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_DATA_ABSTRACT,
        payload: response,
      })
    );
}

export function getAbstractsUsuario(id_usuario) {
  const url = Global.url;
  let form = { id_usuario };
  const request = axios.post(
    url + "/eventos/getAbstractsUsuario",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_ABSTRACTS_USUARIO,
        payload: response,
      })
    );
}

export function refreshCurrentAbstract(id_abstract) {
  const url = Global.url;
  let form = { id_abstract };
  const request = axios.post(
    url + "/eventos/getAbstract",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_ABSTRACT,
        payload: response,
      })
    );
}

export function getAutores(id_abstract) {
  const url = Global.url;
  let form = { id_abstract };
  const request = axios.post(
    url + "/eventos/getAutores",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_AUTORES,
        payload: response,
      })
    );
}

export function getCategoriasAbstract(id_evento) {
  const url = Global.url;
  let form = { id_evento };
  const request = axios.post(
    url + "/eventos/getCategoriasAbstract",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CATEGORIAS_ABSTRACT,
        payload: response,
      })
    );
}

export function cleanAbstractImages(id_evento, id_abstract, parte) {
  const url = Global.url;
  const request = axios.post(
    url + `/eventos/cleanAbstractImages/${id_evento}/${id_abstract}/${parte}`
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: CLEAN_ABSTRACT_IMAGES,
        payload: response,
      })
    );
}

export function setDniAutor(dni) {
  return (dispatch) => {
    dispatch({
      type: SET_DNI_AUTOR,
      payload: dni,
    });
  };
}

export function setIdUsuario(id) {
  return (dispatch) => {
    dispatch({
      type: SET_ID_USUARIO,
      payload: id,
    });
  };
}

export function setAutores(autores) {
  return (dispatch) => {
    dispatch({
      type: SET_AUTORES,
      payload: autores,
    });
  };
}

export function setCurrentAbstract(currentAbstract) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_ABSTRACT,
      payload: currentAbstract,
    });
  };
}

export function getCamposAutorEvento(id_evento) {
  const url = Global.url;
  let form = {
    id_evento,
  };
  const request = axios.post(
    url + "/abstracts/getCamposAutorEvento",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CAMPOS_AUTOR_EVENTO,
        payload: response,
      })
    );
}

export function getTiposAbstract(id_evento) {
  const url = Global.url;
  let form = { id_evento };
  const request = axios.post(
    url + "/abstracts/getTiposAbstractEvento",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_TIPOS_ABSTRACT,
        payload: response,
      })
    );
}

export function setCurrentTipoAbstract(currentTipoAbstract) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_TIPO_ABSTRACT,
      payload: currentTipoAbstract,
    });
  };
}

export function getAportaciones(id_symposium) {
  const url = Global.url;
  let form = { id_symposium };
  const request = axios.post(
    url + "/abstracts/getAportaciones",
    querystring.stringify(form)
  );

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_APORTACIONES_ABSTRACT,
        payload: response,
      })
    );
}

export function resetAportaciones() {
  return (dispatch) => {
    dispatch({
      type: RESET_APORTACIONES,
    });
  };
}

export function resetCurrentAbstract() {
  return (dispatch) => {
    dispatch({
      type: RESET_CURRENT_ABSTRACT,
    });
  };
}

export function resetAutores() {
  return (dispatch) => {
    dispatch({
      type: RESET_AUTORES,
    });
  };
}

export function getAvailableKeywords(id_categoria) {
  const url = Global.url;
  let form = { id_categoria };
  const request = axios.post(
    url + "/abstracts/getAvailableKeywords",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_AVAILABLE_KEYWORDS,
        payload: response,
      })
    );
}

export function getCamposContactoAdministrativoEvento(id_evento) {
  const url = Global.url;
  let form = {
    id_evento,
  };
  const request = axios.post(
    url + "/abstracts/getCamposContactoAdministrativoEvento",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_CAMPOS_CONTACTO_ADMIN_EVENTO,
        payload: response,
      })
    );
}

export function getEventInfoByRecoveryToken(recovery_token) {
  const url = Global.url;
  let form = {
    recovery_token,
  };
  const request = axios.post(
    url + "/abstracts/getEventInfoByRecoveryToken",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_EVENT_INFO_BY_TOKEN,
        payload: response,
      })
    );
}

export function getUsuarioAbstracts(id_usuario) {
  const url = Global.url;
  let form = {
    id_usuario,
  };
  const request = axios.post(
    url + "/abstracts/getUsuarioAbstracts",
    querystring.stringify(form)
  );
  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_USUARIO_ABSTRACTS,
        payload: response,
      })
    );
}
