import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//MATERIAL-UI
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Tooltip,
  IconButton,
  Icon,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";

//ALERTS
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//REDUX
import * as Actions from "../../store/actions";

//SERVICES
import { setAbstractKeywords } from "app/services/eventosService/abstractUploadService";

const BoxKeywords = () => {
  const dispatch = useDispatch();
  const abstract_data = useSelector(
    ({ Abstract }) => Abstract.abstractUploadReducer.data
  );
  let [keyword, setKeyword] = useState("");
  const [checked, setChecked] = React.useState(
    abstract_data.currentAbstract.keywords
      ? JSON.parse(abstract_data.currentAbstract.keywords)
      : []
  );
  let currentKeywords = abstract_data.currentKeywords.map((row) => row.nombre);
  let { ID: id_abstract, keywords } = abstract_data.currentAbstract;

  if (keywords) {
    keywords = JSON.parse(keywords);
  } else {
    keywords = [];
  }

  const createKeyword = () => {
    //comprobamos si no es una keyword vacia
    if (keyword.length === 0) {
      return;
    }
    //comprobamos si la keyword esta duplicada
    let aux_array = keywords.filter((row) => row == keyword);
    if (aux_array.length > 0) {
      setSnackbar("Ya ha utilizado esta palabra clave.", "error");
      return;
    }
    //en caso de que todo este ok la añadimos
    let new_array = keywords;
    new_array.push(keyword);
    console.log("Esta es la lista de keywords", keywords);
    console.log("Este es el id del abstract!! ==> ", id_abstract);
    let form = {
      id_abstract,
      keywords: JSON.stringify(keywords),
    };
    setAbstractKeywords(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          "Hubo un problema al intentar añadir la palabra clave. Intentelo de nuevo más tarde.",
          "error"
        );
      } else {
        setSnackbar("Palabra clave creada correctamente", "success");
        //Reiniciamos input
        setKeyword("");
        //Actualizamos datos del abstract en el store
        dispatch(Actions.refreshCurrentAbstract(id_abstract));
      }
    });
  };

  const deleteKeyword = (row) => {
    console.log("clicado borrar a la categoria", row.ID);
    let new_array = keywords.filter((item) => item !== row);
    console.log("Este es el array despues de borrar:", new_array);
    let form = {
      id_abstract,
      keywords: JSON.stringify(new_array),
    };
    setAbstractKeywords(form).then((res) => {
      if (!res.data.status) {
        setSnackbar(
          "Hubo un problema al tratar de eliminar la palabra clave. Intentelo de nuevo más tarde.",
          "error"
        );
      } else {
        setSnackbar(`Keyword "${row}" borrada correctamente`, "success");
        //Actualizamos datos del abstract en el store
        dispatch(Actions.refreshCurrentAbstract(id_abstract));
      }
    });
  };

  const handleToggle = (value) => async () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log(newChecked);
    setChecked(newChecked);
    let form = {
      id_abstract,
      keywords: JSON.stringify(newChecked),
    };
    let result = await setAbstractKeywords(form);
    if (!result.data.status) {
      setSnackbar(
        "Hubo un problema al tratar de actualizar las palabra clave. Intentelo de nuevo más tarde.",
        "error"
      );
    } else {
      setSnackbar(`Palabras clave actualizadas correctamente`, "success");
      //Actualizamos datos del abstract en el store
      dispatch(Actions.refreshCurrentAbstract(id_abstract));
    }
  };

  const setSnackbar = (message, type) => {
    if (type == "success") {
      toast.success("✔️ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_keywords",
      });
    }
    if (type == "error") {
      toast.error("❌ " + message, {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "abstract_keywords",
      });
    }
  };

  return (
    <Grid item xs={12} className="border-1 rounded-r">
      <ToastContainer
        className="mt-52"
        enableMultiContainer
        containerId="abstract_keywords"
        transition={Slide}
      />
      {currentKeywords.length === 0 && (
        <Grid className="p-10 text-14 text-gray-500" item xs={12}>
          No hay palabras clave disponibles para la categoria seleccionada.
        </Grid>
      )}
      {currentKeywords.length > 0 && (
        <Grid container>
          <Grid className="pt-10 pl-10 text-14 text-gray-500" item xs={12}>
            Por favor, marque las palabras clave que considere oportunas:
          </Grid>
          <Grid
            item
            xs={12}
            className="mt-10 flex items-center justify-center text-center"
          >
            <List dense className="w-512">
              {currentKeywords.map((row) => {
                const labelId = `checkbox-list-secondary-label-${row}`;
                return (
                  <ListItem
                    key={row}
                    button
                    onClick={handleToggle(row)}
                    className="border-1"
                  >
                    <ListItemText id={labelId} primary={row} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(row)}
                        checked={checked.indexOf(row) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default BoxKeywords;
