import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export let crearDescuento = (userToken, form) => {
  let requestURL = Global.url + "/descuentos/crearDescuento";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let editarDescuento = (userToken, form) => {
  let requestURL = Global.url + "/descuentos/editarDescuento";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let eliminarDescuento = (userToken, form) => {
  let requestURL = Global.url + "/descuentos/eliminarDescuento";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let toggleStatusDescuento = (userToken, form) => {
  let requestURL = Global.url + "/descuentos/toggleStatus";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let getDescuento = (userToken, form) => {
  let requestURL = Global.url + "/descuentos/getDescuento";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let getDescuentoPorCodigo = (codigo, idEvento) => {
  let requestURL = Global.url + "/descuentos/" + idEvento + "/" + codigo;
  //   console.log('url', requestURL);
  return axios.get(requestURL);
};

export let importarDescuentos = (userToken, form) => {
  let requestURL = Global.url + "/descuentos/importarDescuentos";
  axios.defaults.headers.common["token"] = userToken;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

/**
 * Obtiene la cantidad de descuentos disponibles para un evento concreto
 * @param {Id del evento cuyos descuentos se contabilizan} idEvento
 */
export const obtenerCantidadDeDescuentosPorEvento = (idEvento) => {
  const requestURL = `${Global.url}/descuentos/${idEvento}/count`;
  return axios.get(requestURL);
};
