import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
// import ModalEditarOperacion from "./modalEditarOperacion";
import { Icon, Tooltip, Grid, TextField, Button } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import XLSX from "xlsx";

//REDUX
import * as Actions from "../store/actions";

//COMPONENTS
import { Skeleton } from "app/shared";
import ModalVerAbstract from "./modalVerAbstract";
import ModalAbstractsRevisores from "./ModalAbstractsRevisores";

export default function MaterialTableDemo(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = React.useState(false);
  const [displayModalConsultas, setDisplayModalConsultas] = React.useState(
    false
  );
  const [fechaMaxCorrecion, setFechaMaxCorrecion] = React.useState(false);
  const { abstractsData, cargando } = useSelector(
    ({ abstracts }) => abstracts.abstracts.abstracts
  );
  const {
    id_evento,
    currentRevisoresAsignados,
    currentRevisoresNoAsignados,
    currentAbstract,
    nombreAutores,
    revisoresAbstracts,
  } = useSelector(({ abstracts }) => abstracts.abstracts);

  useEffect(() => {
    if (id_evento) {
      dispatch(Actions.getRevisoresAbstracts(id_evento));
    }
  }, [id_evento]);

  console.log("revisoresAbstracts", revisoresAbstracts);
  if ((!abstractsData && cargando) || !id_evento)
    return <Skeleton height={"35rem"} />;

  const handleChangeFechaMaxCorrecion = (fecha) => {
    setFechaMaxCorrecion(fecha);
    console.log("Valor de la fecha:", fecha);
    console.log("New date de la fecha", new Date(fecha));
    console.log(
      "New date de la fecha formateada",
      new Date(fecha).toLocaleString()
    );
  };

  const handleOpenModalConsultas = () => {
    setDisplayModalConsultas(true);
  };

  const setSnackbar = (message, type) => {
    if (type === "success") {
      toast.success("✔️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
    if (type === "error") {
      toast.error("❌ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
    if (type === "info") {
      toast.info("ℹ️ " + message, {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId: "tabla_op",
      });
    }
  };

  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId="tabla_op"
        transition={Slide}
      />
      <Grid item xs={12}>
        <TextField
          id="datetime-local"
          label="Fecha máxima de corrección"
          type="datetime-local"
          // defaultValue={new Date().toISOString()}
          // className={classes.textField}
          onChange={(e) => handleChangeFechaMaxCorrecion(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          onClick={(e) => handleOpenModalConsultas()}
          variant="contained"
          color="primary"
        >
          Consultas
        </Button>
      </Grid>
      <MaterialTable
        title={t("Listado de Comunicaciones")}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("de") + " {count}",
            labelRowsPerPage: t("Filas por página"),
            labelRowsSelect: t("Filas"),
            firstAriaLabel: t("Primera página"),
            firstTooltip: t("Primera página"),
            previousAriaLabel: t("Anterior página"),
            previousTooltip: t("Anterior página"),
            nextAriaLabel: t("Siguiente página"),
            nextTooltip: t("Siguiente página"),
            lastAriaLabel: t("Última página"),
            lastTooltip: t("Última página"),
          },
          toolbar: {
            nRowsSelected: "{0} fila(s) seleccionadas",
            searchTooltip: t("Buscar registro"),
            searchPlaceholder: t("Buscar registro"),
            exportName: t("Exportar Excel"),
            exportTitle: t("Exportar"),
            exportAriaLabel: t("Exportar Excel"),
          },
          header: {
            actions: t("Acciones"),
          },
          body: {
            editRow: {
              saveTooltip: t("Guardar"),
              cancelTooltip: t("Cancelar"),
              deleteText: t("Eliminar"),
            },
            addTooltip: t("Añadir"),
            deleteTooltip: t("Eliminar"),
            editTooltip: t("Editar"),
            emptyDataSourceMessage: t("No hay registros disponibles"),
            filterRow: {
              filterTooltip: t("Filtrar"),
            },
          },
        }}
        columns={[
          { title: "ID", field: "num_abstract" },
          { title: t("Titulo"), field: "titulo" },
          { title: t("Tipo"), field: "nombre_tipo_abstract" },
          { title: t("Tema"), field: "nombre_categoria" },
          /*  {
            title: t("Presentación"),
            field: "tipo_presentacion",
            lookup: { 1: "Presencial", 2: "Virtual" },
          }, */
          {
            title: t("Nota Media Revisores"),
            field: "nota_media",
            render: (rowData) => {
              if (rowData.nota_media) {
                return parseFloat(rowData.nota_media).toFixed(1);
              } else {
                return "---";
              }
            },
          },
          {
            title: t("Nota Provisional"),
            field: "calificacion_provisional",
            render: (rowData) => {
              if (rowData.calificacion_provisional) {
                return parseFloat(rowData.calificacion_provisional).toFixed(1);
              } else {
                return "---";
              }
            },
          },
          {
            title: t("Nota Final"),
            field: "calificacion",
            render: (rowData) => {
              if (rowData.calificacion) {
                return parseFloat(rowData.calificacion).toFixed(1);
              } else {
                return "---";
              }
            },
          },

          // {
          //   title: t("Revisor"),
          //   field: "nombre_revisor",
          //   render: (rowData) => {
          //     if (rowData.nombre_revisor && rowData.apellidos_revisor) {
          //       return `${rowData.nombre_revisor} ${rowData.apellidos_revisor}`;
          //     } else {
          //       return <div className="text-red">No Asignado</div>;
          //     }
          //   },
          // },
          { title: t("Nº de Evaluaciones"), field: "num_calificaciones" },
          { title: t("Nº revisores"), field: "num_revisores" },
          {
            title: t("Estado"),
            field: "aceptado",
            lookup: { 0: 'Rechazado',
              1: 'Aceptado',
              2: 'Pendiente',
              3: 'Pendiente comunicación final',
              4: 'Pendiente revisar comunicación final',
              5:  'Rechazada comunicación final'
            },
            render: (rowData) => {
              switch (rowData.aceptado) {
                case 0:
                  return <div className="text-red-300">Rechazado</div>;
                  break;
                case 1:
                  return <div className="text-green-300">Aceptado</div>;
                  break;
                case 2:
                  return "Pendiente";
                  break;
                case 3:
                  return "Pendiente comunicación final";
                  break;
                case 4:
                  return "Pendiente revisar comunicación final";
                  break;
                case 5:
                  return (
                    <div className="text-red-300">
                      Rechazada comunicación final
                    </div>
                  );
                  break;
                default:
                  return "Pendiente";
                  break;
              }
            },
          },
        ]}
        data={abstractsData}
        actions={[
          {
            icon: "edit",
            tooltip: t("Editar"),
            onClick: (event, rowData) => {
              // alert("You saved " + rowData.name);
              // setCurrentAbstract(rowData);
              dispatch(Actions.loadAbstractInfo(rowData));
              dispatch(Actions.getCurrentRevisores(rowData.ID, id_evento));
              dispatch(Actions.getCorrecionesAbstract(rowData.ID));
              dispatch(Actions.getCorrecionesAbstractPrimeraFase(rowData.ID));
              dispatch(Actions.getNombreAutores(rowData.ID));
              setDisplayModal(true);
            },
          },
          // {
          //   icon: "insert_drive_file",
          //   tooltip: t("Ver factura"),
          //   onClick: (event, rowData) => {
          //     handleViewBill(rowData);
          //   },
          // },
          // {
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => alert("You want to delete " + rowData.name)
          // }
        ]}
        options={{
          pageSize: 10,
          filtering: true,
        }}
        // options={{
        //   exportButton: true,
        //   exportCsv: () => handleExport(),
        // }}
      />

      {displayModal &&
        currentRevisoresAsignados &&
        currentRevisoresNoAsignados && (
          <ModalVerAbstract
            displayModal
            setDisplayModal={setDisplayModal}
            currentRevisoresAsignados={currentRevisoresAsignados}
            currentRevisoresNoAsignados={currentRevisoresNoAsignados}
            fechaMaxCorrecion={fechaMaxCorrecion}
          />
        )}
      {
        <ModalAbstractsRevisores
          displayModalConsultas={displayModalConsultas}
          setDisplayModalConsultas={setDisplayModalConsultas}
          id_evento={id_evento}
          revisoresAbstracts={revisoresAbstracts}
        />
      }
    </div>
  );
}
