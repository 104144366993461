import axios from "axios";
import querystring from "querystring";
import Global from "../../fuse-configs/Global";

const HEADERS = { headers: Global.headers };

export let saveEmailCustomTemplate = (token, form) => {
  let requestURL = Global.url + "/eventos/saveEmailCustomTemplate";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let loadEmailDesign = (token, form) => {
  let requestURL = Global.url + "/eventos/loadEmailDesign";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let guardarEmailProgramado = (token, form) => {
  let requestURL = Global.url + "/notificaciones/guardarEmailProgramado";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};

export let actualizarEmailProgramado = (token, form) => {
  let requestURL = Global.url + "/notificaciones/actualizarEmailProgramado";
  axios.defaults.headers.common["token"] = token;
  return axios.post(requestURL, querystring.stringify(form), HEADERS);
};
