import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import decode from "jwt-decode";
import { recuperarLogin } from "app/components/auth/store/actions";
import { guardarToken } from "app/interceptors";

const ProtectedView = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const role = useSelector(({ auth }) => auth.user.role);

  const noTieneRol = role === "";
  const token = localStorage.getItem("jwt_access_token");

  useEffect(() => {
    if (noTieneRol && !token) redirigirLogin();
    if (token) {
      restablecerDatos(obtenRolDeToken(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenRolDeToken = (token) => {
    try {
      let rol = decode(token).role;
      let tokenParaPeticiones = decode(token).data.token;
      guardarToken(tokenParaPeticiones);
      return rol;
    } catch (e) {
      redirigirLogin();
    }
  };

  const restablecerDatos = (rol) => dispatch(recuperarLogin(rol));
  const redirigirLogin = () => history.push("/login");

  return <React.Fragment>{role ? children : ""}</React.Fragment>;
};

export default ProtectedView;
