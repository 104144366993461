import { takeEvery, call, put } from "redux-saga/effects";

import {
  cargaMetodosDePago,
  cargarCodigoTransferencia as cargaCodigo,
  cargaPrefijosSufijos,
} from "../../Iframe.logic";

import {
  CARGA_CODIGO_TRANSFERENCIA_SOLICITADA,
  CARGA_CUENTA_TRANSFERENCIA_SOLICITADA,
  CARGA_PREFIJO_SUFIJO_PROFORMA_SOLICITADA,
  cargaCodigoTransferenciaExitosa,
  cargaCodigoTransferenciaErronea,
  cargaCodigoTransferenciaFinalizada,
  cargaCuentaTransferenciaExitosa,
  cargaCuentaTransferenciaErronea,
  cargaCuentaTransferenciaFinalizada,
  cargaPrefijoSufijoProformaExitosa,
  cargaPrefijoSufijoProformaErronea,
  cargaPrefijoSufijoProformaFinalizada,
} from "../actions";

export function* observador() {
  yield takeEvery(
    CARGA_CODIGO_TRANSFERENCIA_SOLICITADA,
    cargaCodigoTransferencia
  );
  yield takeEvery(
    CARGA_CUENTA_TRANSFERENCIA_SOLICITADA,
    cargaCuentaTransferencia
  );
  yield takeEvery(
    CARGA_PREFIJO_SUFIJO_PROFORMA_SOLICITADA,
    cargaPrefijoSufijoProforma
  );
}

function* cargaCodigoTransferencia(action) {
  try {
    const idEvento = action.payload;
    const codigoTransferencia = yield call(cargaCodigo, idEvento);
    yield put(
      cargaCodigoTransferenciaExitosa(`${idEvento}-${codigoTransferencia}`)
    );
  } catch (e) {
    yield put(cargaCodigoTransferenciaErronea(e));
  } finally {
    yield put(cargaCodigoTransferenciaFinalizada());
  }
}

function* cargaCuentaTransferencia(action) {
  try {
    let cuentaTransferencia;
    const idEvento = action.payload;
    const metodosPago = yield call(cargaMetodosDePago, idEvento);

    // TODO: Extraer de la saga.
    if (metodosPago.length === 0 || !metodosPago) {
      throw new Error("No hay cuenta bancaria asociada");
    }

    for (let m of metodosPago) {
      if (m.metodo.toLowerCase() === "transferencia") {
        let [ct] = JSON.parse(m.config);
        cuentaTransferencia = ct;
      }
    }

    if (!cuentaTransferencia) {
      throw new Error("No hay método de transferencia");
    }

    yield put(cargaCuentaTransferenciaExitosa(cuentaTransferencia));
  } catch (e) {
    yield put(cargaCuentaTransferenciaErronea(e));
  } finally {
    yield put(cargaCuentaTransferenciaFinalizada());
  }
}

function* cargaPrefijoSufijoProforma(action) {
  try {
    const idEvento = action.payload;

    let prefijo = "";
    let sufijo = "";

    const response = yield call(cargaPrefijosSufijos, idEvento);

    if (response) {
      if (response.prefijo) {
        prefijo = response.prefijo;
      }

      if (response.sufijo) {
        sufijo = response.sufijo;
      }
    }

    yield put(cargaPrefijoSufijoProformaExitosa(prefijo, sufijo));
  } catch (e) {
    console.log("ERROR", e);
    yield put(cargaPrefijoSufijoProformaErronea(e));
  } finally {
    yield put(cargaPrefijoSufijoProformaFinalizada());
  }
}
