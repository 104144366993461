export const daIdUnicoAInscripcionYComplementos = (inscripcion) => {
  return {
    ...inscripcion,
    idUnico: randomId(),
    complementos: inscripcion.complementos.map((c) => ({
      ...c,
      idUnico: randomId(),
    })),
  };
};

export const randomId = () => Math.floor(Math.random() * 9999999);
