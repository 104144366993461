import { RegistroBuscador } from "./RegistroBuscador";

export class BuscadorAdaptador {
  constructor(registros = []) {
    this.registros = registros;
  }

  /**
   * @param {campoLabel} Campo del objeto que será usado como label
   * @param {campoId} Campo del objeto que será usado como el id
   */
  adaptar({ campoId, campoLabel }) {
    return this.registros.map(
      (r) => new RegistroBuscador({ id: r[campoId], label: r[campoLabel] })
    );
  }
}
