import Global from "app/fuse-configs/Global";
import {
  facturaYaExistePorIdCompra,
  obtenerDatosDeFacturaPorIdEventoIdCompra,
  getBillFooter,
  getImageBase64FromS3,
  obtenerTipoEventoPorId,
  enviarBadgesCompraOnline,
  sendBadges,
  obtenerEventoPorId,
} from "app/services/eventosService";
import {
  obtenerPrefijoSufijoFacturaPorIdEvento,
  obtenerIdUnicoFacturaPorIdEvento,
} from "app/services/eventosService/pagosService";
const { debugMode } = Global;

export const facturaYaExiste = async (idCompra) => {
  try {
    const { data } = await facturaYaExistePorIdCompra(idCompra);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/facturaYaExiste",
        e
      );
    }
    return null;
  }
};

export const obtenPrefijoSufijo = async (idEvento) => {
  try {
    const { data } = await obtenerPrefijoSufijoFacturaPorIdEvento(idEvento);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/obtenPrefijoSufijo",
        e
      );
    }
    return null;
  }
};

export const obtenIdUnicoFactura = async (idEvento) => {
  try {
    const { data } = await obtenerIdUnicoFacturaPorIdEvento(idEvento);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/obtenIdUnicoFactura",
        e
      );
    }
    return null;
  }
};

export const obtenDatosFactura = async (idEvento, idCompra) => {
  try {
    const { data } = await obtenerDatosDeFacturaPorIdEventoIdCompra(
      idCompra,
      idEvento
    );
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/obtenDatosFactura",
        e
      );
    }
    return null;
  }
};

export const obtenPieFactura = async (idCompra) => {
  try {
    const { data } = await getBillFooter(idCompra);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/obtenPieFactura",
        e
      );
    }
    return null;
  }
};

export const obtenImagenEventoEnBase64 = async (idEvento, nombreImagen) => {
  try {
    const { data } = await getImageBase64FromS3(idEvento, nombreImagen);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    if (result === null || result === "") {
      return "";
    }

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/obtenPieFactura",
        e
      );
    }
    return "";
  }
};

export const obtenInfoDelEvento = async (idEvento) => {
  try {
    const { data } = await obtenerTipoEventoPorId(idEvento);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    if (debugMode) {
      console.log(
        "Error en RedsysPaymentSuccesful.repository.js/obtenInfoDelEvento",
        e
      );
    }
    return null;
  }
};

export const enviarCorreoEventoOnline = async (idCompra) => {
  try {
    const { data } = await enviarBadgesCompraOnline(idCompra);
    const { status } = data;

    // if (!status) throw new Error("Error en el status");

    return true;
  } catch (e) {
    console.log(
      "Error en RedsysPaymentSuccesful.repository.js/enviarCorreoEventoOnline",
      e
    );
    return null;
  }
};

export const enviarCorreoEventoPresencial = async (idCompra) => {
  try {
    const { data } = await sendBadges(idCompra);
    const { status } = data;

    // if (!status) throw new Error("Error en el status");

    return true;
  } catch (e) {
    console.log(
      "Error en RedsysPaymentSuccesful.repository.js/enviarCorreoEventoPresencial",
      e
    );
    return null;
  }
};

export const obtenEventoPorId = async (id) => {
  try {
    const { data } = await obtenerEventoPorId(id);
    const { status, result } = data;

    if (!status) throw new Error("Error en el status");

    return result;
  } catch (e) {
    console.log(
      "Error en RedsysPaymentSuccesful.repository.js/obtenerEventoPorId",
      e
    );
    return null;
  }
};
