var Global = {
  url: process.env.REACT_APP_BACKEND_URL,
  url_s3_bucket: process.env.REACT_APP_S3_BUCKET,
  frontURL: process.env.REACT_APP_FRONTEND_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  redsysURL: process.env.REACT_APP_REDSYS_URL,
  GOOGLE_MAPS_API_KEY: "AIzaSyBggZk8ehWkC92ll_znRUPndIxmWmKIWTI",
  debugMode: process.env.REACT_APP_DEBUG_MODE,
};

export default Global;
