export class PrefijosSufijos {
  constructor(
    idEvento,
    prefijoProforma = "",
    sufijoProforma = "",
    prefijoFactura = "",
    sufijoFactura = "",
    prefijoDevolucion = "",
    sufijoDevolucion = ""
  ) {
    this.idEvento = idEvento;
    this.prefijoProforma = prefijoProforma || "";
    this.sufijoProforma = sufijoProforma || "";
    this.prefijoFactura = prefijoFactura || "";
    this.sufijoFactura = sufijoFactura || "";
    this.prefijoDevolucion = prefijoDevolucion || "";
    this.sufijoDevolucion = sufijoDevolucion || "";
  }
}
