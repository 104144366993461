import { takeEvery, call, put } from "redux-saga/effects";

import {
  CARGAR_WIDGETS_SUPERREVISOR_SOLICITADO,
  cargaWidgetsSuperRevisorExitosa,
  cargaWidgetsSuperRevisorErronea,
  cargaWidgetsSuperRevisorFinalizada,
} from "../actions";

import { obtenerWidgetsSuperRevisor } from "app/services/eventosService";

export function* observador() {
  yield takeEvery(CARGAR_WIDGETS_SUPERREVISOR_SOLICITADO, cargaWidgets);
}

function* cargaWidgets(action) {
  try {
    let email = action.payload;
    const { data } = yield call(obtenerWidgetsSuperRevisor, email);
    const { tableData, chartData } = data;

    yield put(cargaWidgetsSuperRevisorExitosa(tableData, chartData));
  } catch (e) {
    yield put(cargaWidgetsSuperRevisorErronea(e));
  } finally {
    yield put(cargaWidgetsSuperRevisorFinalizada());
  }
}
