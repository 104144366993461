export const GUARDAR_LISTA_EVENTOS =
  "[DASHBOARD-EVENTOS] GUARDA LISTA DE TODOS LOS EVENTOS PARA EL BUSCADOR";
export function guardarTodosLosEventos(eventos) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_LISTA_EVENTOS, payload: eventos });
  };
}
export const GUARDAR_EVENTOS_MAS_ACTIVOS =
  "[DASHBOARD-EVENTOS] GUARDA LISTA DE EVENTOS MÁS ACTIVOS";
export function guardarEventosMasActivos(eventos) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_EVENTOS_MAS_ACTIVOS, payload: eventos });
  };
}

export const GUARDAR_ULTIMOS_EVENTOS =
  "[DASHBOARD-EVENTOS] GUARDA ULTIMOS EVENTOS CREADOS";
export function guardarUltimosEventos(eventos) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_ULTIMOS_EVENTOS, payload: eventos });
  };
}

export const GUARDAR_TODOS_LOS_EVENTOS_EMPRESA =
  "[DASHBOARD-EVENTOS] GUARDA TODOS LOS EVENTOS DE UNA EMPRESA";
export function guardarTodosLosEventosEmpresa(eventos) {
  return (dispatch) => {
    dispatch({ type: GUARDAR_TODOS_LOS_EVENTOS_EMPRESA, payload: eventos });
  };
}

// /**
//  * LISTA DE EVENTOS
//  */
// export const SOLICITAR_CARGA_LISTA_EVENTOS =
//   "[DASHBOARD.EVENTOS] CARGA LISTA DE EVENTOS SOLICITADA";
// export function solicitarCargaListaEventos(tokenAuth) {
//   return (dispatch) => {
//     dispatch({ type: SOLICITAR_CARGA_LISTA_EVENTOS, payload: tokenAuth });
//   };
// }

// export const CARGA_LISTA_EVENTOS_EXITOSA =
//   "[DASHBOARD.METRICAS] SE HA CARGADO LA LISTA DE EVENTOS CON ÉXITO";
// export function cargaListaEventosExitosa(eventos) {
//   return (dispatch) => {
//     dispatch({ type: CARGA_LISTA_EVENTOS_EXITOSA, payload: eventos });
//   };
// }

// export const CARGA_LISTA_EVENTOS_ERRONEA =
//   "[DASHBOARD.METRICAS] SE HA PRODUCIDO UN ERROR AL CARGAR LA LISTA DE EVENTOS";
// export function cargaListaEventosErronea(error) {
//   return (dispatch) => {
//     dispatch({ type: CARGA_LISTA_EVENTOS_ERRONEA, payload: error });
//   };
// }

// export const CARGA_LISTA_EVENTOS_FINALIZADA =
//   "[DASHBOARD.METRICAS] LA CARGA DE LA LISTA DE EVENTOS HA FINALIZADO";
// export function cargaListaEventosFinalizada() {
//   return (dispatch) => {
//     dispatch({ type: CARGA_LISTA_EVENTOS_FINALIZADA });
//   };
// }

/**
 * EVENTOS MÁS ACTIVOS
 */
export const SOLICITAR_CARGA_EVENTOS_MAS_ACTIVOS =
  "[DASHBOARD.EVENTOS] CARGA EVENTOS MÁS ACTIVOS SOLICITADA";
export function solicitarCargaEventosMasActivos(tokenAuth) {
  return (dispatch) => {
    dispatch({ type: SOLICITAR_CARGA_EVENTOS_MAS_ACTIVOS, payload: tokenAuth });
  };
}

export const CARGA_EVENTOS_MAS_ACTIVOS_EXITOSA =
  "[DASHBOARD.METRICAS] SE HAN CARGADO LOS EVENTOS MÁS ACTIVOS CON ÉXITO";
export function cargaEventosMasActivosExitosa(eventos) {
  return (dispatch) => {
    dispatch({ type: CARGA_EVENTOS_MAS_ACTIVOS_EXITOSA, payload: eventos });
  };
}

export const CARGA_EVENTOS_MAS_ACTIVOS_ERRONEA =
  "[DASHBOARD.METRICAS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS EVENTOS MÁS ACTIVOS";
export function cargaEventosMasActivosErronea(error) {
  return (dispatch) => {
    dispatch({ type: CARGA_EVENTOS_MAS_ACTIVOS_ERRONEA, payload: error });
  };
}

export const CARGA_EVENTOS_MAS_ACTIVOS_FINALIZADA =
  "[DASHBOARD.METRICAS] LA CARGA DE LOS EVENTOS MÁS ACTIVOS HA FINALIZADO";
export function cargaEventosMasActivosFinalizada() {
  return (dispatch) => {
    dispatch({ type: CARGA_EVENTOS_MAS_ACTIVOS_FINALIZADA });
  };
}

/**
 * ULTIMOS EVENTOS
 */
export const SOLICITAR_CARGA_ULTIMOS_EVENTOS =
  "[DASHBOARD.EVENTOS] CARGA ÚLTIMOS EVENTOS SOLICITADA";
export function solicitarCargaUltimosEventos(tokenAuth) {
  return (dispatch) => {
    dispatch({ type: SOLICITAR_CARGA_ULTIMOS_EVENTOS, payload: tokenAuth });
  };
}

export const CARGA_ULTIMOS_EVENTOS_EXITOSA =
  "[DASHBOARD.METRICAS] SE HAN CARGADO LOS ÚLTIMOS EVENTOS CON ÉXITO";
export function cargaUltimosEventosExitosa(eventos) {
  return (dispatch) => {
    dispatch({ type: CARGA_ULTIMOS_EVENTOS_EXITOSA, payload: eventos });
  };
}

export const CARGA_ULTIMOS_EVENTOS_ERRONEA =
  "[DASHBOARD.METRICAS] SE HA PRODUCIDO UN ERROR AL CARGAR LOS ÚLTIMOS EVENTOS";
export function cargaUltimosEventosErronea(error) {
  return (dispatch) => {
    dispatch({ type: CARGA_ULTIMOS_EVENTOS_ERRONEA, payload: error });
  };
}

export const CARGA_ULTIMOS_EVENTOS_FINALIZADA =
  "[DASHBOARD.METRICAS] LA CARGA DE LOS ÚLTIMOS EVENTOS HA FINALIZADO";
export function cargaUltimosEventosFinalizada() {
  return (dispatch) => {
    dispatch({ type: CARGA_ULTIMOS_EVENTOS_FINALIZADA });
  };
}

/**
 * TODOS LOS EVENTOS
 */
export const SOLICITAR_CARGA_TODOS_LOS_EVENTOS =
  "[DASHBOARD.EVENTOS] CARGA TODOS LOS EVENTOS";
export function solicitarCargaTodosLosEventos() {
  return (dispatch) => {
    dispatch({ type: SOLICITAR_CARGA_TODOS_LOS_EVENTOS });
  };
}

export const CARGA_TODOS_LOS_EVENTOS_EXITOSA =
  "[DASHBOARD.METRICAS] SE HAN CARGADO TODOS LOS EVENTOS CON ÉXITO";
export function cargaTodosLosEventosExitosa(eventos) {
  return (dispatch) => {
    dispatch({ type: CARGA_TODOS_LOS_EVENTOS_EXITOSA, payload: eventos });
  };
}

export const CARGA_TODOS_LOS_EVENTOS_ERRONEA =
  "[DASHBOARD.METRICAS] SE HA PRODUCIDO UN ERROR AL CARGAR TODOS LOS EVENTOS";
export function cargaTodosLosEventosErronea(error) {
  return (dispatch) => {
    dispatch({ type: CARGA_TODOS_LOS_EVENTOS_ERRONEA, payload: error });
  };
}

export const CARGA_TODOS_LOS_EVENTOS_FINALIZADA =
  "[DASHBOARD.METRICAS] LA CARGA DE TODOS LOS EVENTOS HA FINALIZADO";
export function cargaTodosEventosFinalizada() {
  return (dispatch) => {
    dispatch({ type: CARGA_TODOS_LOS_EVENTOS_FINALIZADA });
  };
}
